<template>

    <!-- <label for="legacy-option-legacyservice_id" class="col-form-label col-form-label-sm col-sm-4">Service</label> -->

    <div class='mb-2'>
        <template v-for="(legacyservice,index) in thislegacyservices" :key="index">
            <div class="row mb-1">
                
                <div class="col-sm-11 port-search">
                    <FormItem :id="'legacyservice-'+index" v-model="legacyservice.legacy_service_id" type="select" :options="legacyServices" />
                </div>
                <div class="col-sm-1 ps-0">
                    <a class="btn btn-sm btn-danger float-right" title="Click to delete row" @click="deleteOption(index)">
                        <i class="far fa-trash-alt"></i>
                    </a>
                </div>
                
            </div>
        </template>
        <div class="btn btn-primary btn-sm float-right mt-2" title="Click to add row" @click="addOption">
            <span class="fa fa-plus-square"></span> &nbsp;{{$t('form.add', ['service'])}}
        </div>
        <div class="clearfix"></div>
    </div>


</template>

<script>
    export default {
        props: ['initialLegacyServices', 'legacyServices'],
        name: 'VoyageLegacyServiceFormComponent',
        computed: {
            thislegacyservices: {
                get() {
                    return this.initialLegacyServices
                }
            }
        },
        watch: {
            initialLegacyServices: {
                deep: true,
                handler(initialLegacyServices) {
                    this.$emit('legacyservicechange', initialLegacyServices)
                }
            }
        },
        methods: {
            addOption(e) {
                this.thislegacyservices.push({
                    id: null,
                    legacy_service_id: null
                });
            },
            deleteOption(index) {
                this.thislegacyservices.splice(index, 1);
            }
        },
        mounted() {
            // this.getRelations()
        }
    }
</script>
<style scoped>
.form-check-input {
    position: relative !important;
    top: 5px;
    left: 16px
}
.column-via-icon{
    flex: 0 0 auto;
    width: 3%
}
.column-via-port{
    flex: 0 0 auto;
    width: 19.25%;
    font-size: .875rem
}
.column-via-port + div{
    padding: 2px 0 0 6px !important
}
</style>