import {ApiService} from '@/services/ApiService'

const URL = '/api/commodity-group'

class CommoditygroupService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
}

export default new CommoditygroupService()