import http from '@/http-common'
import {ApiService} from '@/services/ApiService'

const URL = '/api/relation'

class RelationService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    index() {
      return http.get(`${this.getUrl()}?type=representative`)
    }
    livesearch(query, field, selectFields = ['id']) {
        var params = {
          "lazyEvent": {
            "sortField": ('relation.' + field),
            "sortOrder": 1,
            "searchFrom": "livesearch",
            "searchType": "regex",
            "filters":{
              'global' :{
                "value": query,
                "matchMode":"contains"
              }
            },
            "selectFields": selectFields
          }
        };
        var selectfield = field.split('.');
        if(selectfield.length == 1){
          params.lazyEvent.selectFields.push('relation_id');
          params.lazyEvent.selectFields.push({'relation': ['id', field]});
        } else {
          var selectfieldObject = {}
          selectfieldObject[selectfield[0]] = ['id', selectfield[1]];
          params.lazyEvent.selectFields.push(selectfield[0] + "_id");
          params.lazyEvent.selectFields.push(selectfieldObject);
        }
  
        params.lazyEvent = JSON.stringify(params.lazyEvent);
        const queryParams = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
        const queryUrl = `?type=representative&page=1&` + queryParams
        return http.get(`${this.getUrl()}` + queryUrl)
    }
}

export default new RelationService()