<template v-if="formActive">
    <div id="makeformfields">
        <FormItem id="name" :label="$t('overview.name')" v-model="make.name" :required="true" />
        <FormItem id="code" label="Code" v-model="make.code" :required="true" />
        <FormItem id="manufacturer" :label="$t('crm.manufacturer')" v-model="make.manufacturer_id" type="select" :options="manufacturers" />
    </div>
</template>

<script>
    import relationService from "@/services/RelationService"
    import makeservice from '@/services/MakeService'
    export default {
        name: 'MakeForm',
        props: ['itemData'],
        data() {
            return {
                make: {},
                manufacturers: [],
                submitted: false,
                loading: false,
                invalid: {},
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.make = val    
            },
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                let valid = true;

                let required = document.querySelectorAll('#makeformfields input[required]');

                for(let field of required) {
                    if(!field.value || field.value == this.$t('form.select')) {
                        field.classList.add('is-invalid');
                        valid = false;
                    }
                    else{
                        field.classList.remove('is-invalid');
                    }
                }
                if(valid) valid = this.make.name && this.make.code;
                if(!valid) alert(this.$t('errors.empty_fields'));
                return valid;
            },
            getData() {
                return this.make;
            },
            setData(data) {
                this.make = data;
                this.getManufacturers();
            },
            reset() {
                this.make = {
                    'name': '',
                    'code': '',
                    'manufacturer_id': null
                }
                this.loading = false
                this.invalid = {}
                this.getManufacturers();
            },
            getManufacturers(){
                relationService.index(null, null, 'manufacturer').then(response => {
                    this.manufacturers = response.data;
                }).catch(error => {
                    console.log('error', error)
                })
            },
            submit() {
                if(this.validate()){
                    return makeservice.store(this.make).then(response => {
                        this.$parent.closeModal(response.data, 'make');
                        return true;
                    }).catch(error => {
                        console.log('error', error)
                        return false;
                    });
                }
                else{
                    return false;
                }
            }
        }
    }
</script>
