import http from '@/http-common'

const URL = '/api/file'

class FileService {
  download(id){
    return http.get(`${URL}/download/${id}`, {
        responseType: 'blob'
    })
  }
  getImages(id){
    return http.get(`${URL}/get-images/${id}`)
  }
  upload(data) {
    return http.post(`${URL}/upload`, data)
  }
  delete(id) {
    return http.post(`${URL}/delete`, {'id': id})
  }
}

export default new FileService()