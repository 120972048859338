import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/general-cost'

class GeneralcostService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    search(relationType, relationData) {
        return http.post(`${URL}/search`, {
            "relation_type": relationType,
            "relation_data": relationData
        })
    }
}

export default new GeneralcostService()