import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/contract'

class ContractService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    downloadPDF(id){
        return http.get(`${URL}/download/${id}`, {
            responseType: 'blob'
        })
    }
    getCustomerContracts(customer_id, pol_id, pod_id, carriers, date) {
        return http.post(`${URL}/customer/${customer_id}`, {
            "port_of_loading_id": pol_id,
            "port_of_discharge_id": pod_id,
            "carriers": carriers,
            "date": date
        })
    }
    getTariffs(customer_id, commodityId, conditionId, calculation_unit_id, pol, pod, carriers, date, options = null){
        const optionsList = options ? options.map(opt => ({'port_of_loading_id': opt.port_of_loading_id, 'carrier_id': opt.carrier_id})) : [];
        return http.post(`${URL}/customer/${customer_id}`, {
            "commodity_id": commodityId,
            "condition_id": conditionId,
            "calculation_unit_id": calculation_unit_id,
            "port_of_loading_id": pol,
            "port_of_discharge_id": pod,
            "carriers": carriers,
            "date": date,
            "options": optionsList
        })
    }
}

export default new ContractService()