import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/unit-model'

class ModelService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
}

export default new ModelService()