<template>

    <div :class="{ 'd-none': loaded }">
        <i class="fas fa-spinner fa-spin"></i> {{$t('form.please_wait')}}
    </div>
    <div class="tab-content" id="myTab5Content" v-if="loaded && job.status != 'pending'">

    <div class="w-50" id="customernew" role="tabpanel" aria-labelledby="customernew-tab" v-if="isCreatingNew">
        <FormItem id="ccust" :label="$t('navigation.customers')" type="livesearch" v-model="job.customer_id" :content="job.customer ? job.customer.relation.name : ''" :disabled="true" v-if="userCustomer.id" />
        <FormItem id="ccust" :label="$t('navigation.customers')" type="livesearch" v-model="job.customer_id" :options="customers" @onchange="(a,b) => setCustomerData(a, b, 'customer')" :required="true" v-else />
        <FormItem id="cpol" :label="$t('sales.loading_ports')" type="livesearch" v-model="job.port_of_loading_id" :options="ports" @onchange="(a,b) => setCustomerData(a, b, 'port_of_loading')" :required="true" />
        <FormItem id="cpod" :label="$t('sales.discharge_ports')" type="livesearch" v-model="job.port_of_discharge_id" :options="ports" @onchange="(a,b) => setCustomerData(a, b, 'port_of_discharge')" :required="true" />
    </div>
    <div class="w-50" id="customernew" role="tabpanel" aria-labelledby="customernew-tab" v-else-if="changingPorts">
        <h5>{{$t('operations.mode_change_ports')}}</h5>
        <FormItem id="cpol" :label="$t('sales.loading_ports')" type="livesearch" v-model="job.port_of_loading_id" :options="ports" @onchange="(a,b) => setCustomerData(a, b, 'port_of_loading')" :required="true" />
        <FormItem id="cpod" :label="$t('sales.discharge_ports')" type="livesearch" v-model="job.port_of_discharge_id" :options="ports" @onchange="(a,b) => setCustomerData(a, b, 'port_of_discharge')" :required="true" />
    </div>

    <div class="w-100 position-relative" id="review" role="tabpanel" aria-labelledby="review-tab" v-else-if="!addingUnits">
        <div class="row">  
            <div class="col-sm-12"> </div>
        </div>
        <!--Begin review tab -->
        <h5 class="mt-4 mb-3 fw-bold">{{$t('operations.units_included')}} ({{unitCount}}) </h5>

        <p v-if="job.units.filter(ql => ql.checked === 1).length == 0">{{ $t('form.no_selected', [$tc('navigation.units', 2)]) }}</p>
        <div v-else class="row mb-0 pt-2 transport-unit-table">
            <div class="col-sm-xxs"></div>
            <div class="col-sm-xs required-label col-form-label-sm"> {{$t('form.qt')}} </div>
            <div class="col-sm-1p5 required-labe col-form-label-sm"> {{$tc('navigation.commodities', 1)}} </div>
            <div class="col-sm-xs required-label col-form-label-sm"> {{$t('form.new')}} </div>
            <div class="col-sm-1p5 required-label col-form-label-sm"> {{$t('navigation.makes')}} </div>
            <div class="col-sm-1p5 required-label col-form-label-sm"> {{$t('navigation.models')}} </div>
            <label :class="'col-sm-' + (licensePlateRequired ? '1p5 vin-lp' : '2 vin-only') + ' col-form-label-sm required-label'"> {{ $t('sales.vin') }} </label>
            <label v-show="licensePlateRequired" class="col-sm-1 col-form-label-sm"> {{ $t('sales.license_plate') }} </label>
            <div class="col-sm-1 required-label col-form-label-sm"> {{$t('form.length')}} </div>
            <div class="col-sm-1 required-label col-form-label-sm"> {{$t('form.width')}} </div> 
            <div class="col-sm-1 required-label col-form-label-sm"> {{$t('form.height')}} </div> 
            <div class="col-sm-1 required-label col-form-label-sm"> {{$t('form.weight')}} </div> 
        </div>
        <div :id="isCreatingNew ? 'collapse-x' : 'collapse-1'">
        <div v-for="(jobUnit,index) in job.units" :key="index">
        <template v-if="jobUnit && jobUnit.checked">
            <div class="row transport-unit-table">
                <div class="col-sm-xxs">
                    <FormItem :id="'check-'+index" v-model="jobUnit.checked" type="checkbox" @onchange="(e) => addToSelectedUnits(e, jobUnit)" :required="true" />
                </div>
                <div class="col-sm-xs">
                    <FormItem :id="'qt-'+index" v-model="jobUnit.quantity" :disabled="true" />
                </div>
                <div class="col-sm-1p5">
                    <FormItem :id="'comm-'+index" v-model="jobUnit.commodity_id" type="select" :options="commodities" :disabled="true" />
                </div>
                <div class="col-sm-xs">
                    <FormItem :id="'cond-'+index" v-model="jobUnit.condition_id" type="slider" :options="[1,2]" :disabled="true" />
                </div>
                <div class="col-sm-1p5">
                    <FormItem :id="'make-'+index" v-model="jobUnit.unit_make_id" type="livesearch" :service="makeService" :content="getMM(jobUnit, 'make')" @onchange="(id, name) => {setMM(jobUnit, name, 'make'); validateTabOne(id, index)}" :required="true" />
                </div>
                <div class="col-sm-1p5">
                    <FormItem :id="'model-'+index" v-model="jobUnit.unit_model_id" type="livesearch" :service="modelService" :required="true" 
                              :content="getMM(jobUnit, 'model')" @onchange="(id, name) => {setMM(jobUnit, name, 'model'); validateTabOne(id, index)}"
                              :disabled="!jobUnit.unit_make_id || !jobUnit.commodity_id" :extraOptions="{'make_id': jobUnit.unit_make_id, 'comm_id': jobUnit.commodity_id}"/>
                </div>
                <div :class="'col-sm-' + (licensePlateRequired ? '1p5 vin-lp' : '2 vin-only')" v-if="jobUnit.quantity == 1">
                    <input class="form-control form-control-sm license-plate" v-model="jobUnit.chassis_number" @keydown="licensePlateAlterInput" @input="validateTabOne($event, index)" 
                          @change="toUpper($event.target, jobUnit); checkForDupes(jobUnit)" :required="true" :style="{'backgroundColor': checkChassisNr(jobUnit)}" :data-id="jobUnit.id">
                    <div class="text-danger col-form-label-sm" v-if="jobUnit.duplicate"><i class="fa fa-warning"></i>&ensp;{{$t('errors.duplicate_in_db', ['VIN', jobUnit.duplicate.by])}}</div>
                </div>
                <div :class="'col-sm-' + (licensePlateRequired ? '1p5 vin-lp' : '2 vin-only') + ' has-validation'" v-else>
                    <textarea @input="validateTabOne($event, index)" wrap="off" v-model="jobUnit.chassis_number" class="form-control form-control-sm license-plate" :required="true" :data-id="jobUnit.id"
                              @keydown="licensePlateAlterInput" @change="toUpper($event.target, jobUnit); checkForDupes(jobUnit)" :style="{'backgroundColor': checkChassisNr(jobUnit)}"></textarea>
                    <div class="invalid-feedback one-license-plate" v-show="jobUnit.chassis_number != null"><i class="fa fa-warning"></i>&ensp;<span>Hi</span></div>
                    <div class="text-danger col-form-label-sm duplicate-text" v-if="jobUnit.duplicate"><i class="fa fa-warning"></i>&ensp;{{$t('errors.duplicate_in_db', [jobUnit.duplicate.number, jobUnit.duplicate.by])}}</div>
                </div>
                <div v-if="licensePlateRequired" class="col-sm-1 license-column">
                    <input class="form-control form-control-sm license-plate" v-model="jobUnit.license_plate" @keydown="licensePlateAlterInput" @input="validateTabOne($event, index)" 
                          @change="toUpper($event.target)" v-if="jobUnit.quantity == 1">
                    <template v-else>
                        <textarea @input="validateTabOne($event, index)" wrap="off" v-model="jobUnit.license_plate" class="form-control form-control-sm license-plate"
                                  @keydown="licensePlateAlterInput" @change="toUpper($event.target)"></textarea>
                        <div class="invalid-feedback multi-license-plate" v-show="jobUnit.chassis_number != null"><i class="fa fa-warning"></i>&ensp;<span>Hi</span></div>
                    </template>
                </div>
                <div class="col-sm-1">
                    <FormItem :id="'ln-field-'+index" v-model="jobUnit.booked_measurement.length" type="number-with-tags" endTag="cm" @oninput="validateTabOne" :required="true" />
                </div>
                <div class="col-sm-1">
                    <FormItem :id="'wi-field-'+index" v-model="jobUnit.booked_measurement.width" type="number-with-tags" endTag="cm" @oninput="validateTabOne" :required="true" />
                </div> 
                <div class="col-sm-1">
                    <FormItem :id="'ht-field-'+index" v-model="jobUnit.booked_measurement.height" type="number-with-tags" endTag="cm" @oninput="validateTabOne" :required="true" />
                </div> 
                <div class="col-sm-1">
                    <FormItem :id="'wt-field-'+index" v-model="jobUnit.booked_measurement.weight" type="number-with-tags" endTag="kg" @oninput="validateTabOne" :required="true" />
                </div>
                <div class="col-sm-auto" >
                    <button class="btn btn-sm float-right" type="button" title="Click to delete item" @click="deleteSeaTransportline(index)"><i class="far fa-trash-alt text-danger"></i></button>
                </div>
            </div>
            <div class="row transport-unit-table">
                <div class="col-sm-xxs"></div>
                <div class="col-sm-1">
                    <FormItem :id="'byear-'+index" v-model="jobUnit.construction_year" :placeholder="$t('sales.build_year')" />
                </div>
                <div class="col-sm-2" style="width: 13.15% !important">
                    <FormItem :id="'colour-'+index" v-model="jobUnit.colour_id" type="select" :options="colours" :placeholder="$t('sales.select_x', [$t('navigation.colours')])" />
                </div>
                <div class="col-sm-2" style="width: 11.1% !important">
                    <FormItem :id="'hscode-'+index" v-model="jobUnit.hs_code" type="text" :placeholder="$t('navigation.hs_codes')" />
                </div>
                <div class="col-sm-2" style="width: 11.1% !important" v-show="jobUnit.quantity > 1">
                    <button class="btn btn-sm btn-primary" type="button" @click="showSplitUnitBox(jobUnit, index)"><i class="fa fa-link-slash pe-2"></i>{{$t('form.split')}}</button>
                </div>
            </div>
            <div class="row transport-unit-table row mb-3">
                <div class="col-sm-2 me-5"></div><i class="col-sm-2" v-show="jobUnit.description">{{ $t('form.description') + ': ' + jobUnit.description }}</i>
            </div>
            <div class="border-left ms-3">
            <template v-for="(unit, unindex) in jobUnit.stacked_units" :key="unindex">
                <div class="row transport-unit-table">
                    <div class="col-sm-auto" style="width: 1.8%"></div>
                    <div class="col-sm-auto" style="width: 3.8%">
                        <FormItem :id="'qt-'+index+'-'+unindex" v-model="unit.quantity" :disabled="true" />
                    </div>
                    <div class="col-sm-auto" style="width: 11.3%">
                        <FormItem :id="'comm-'+index+'-'+unindex" v-model="unit.commodity_id" type="select" :options="commodities" :disabled="true" />
                    </div>
                    <div class="col-sm-xs">
                        <FormItem :id="'cond-'+index+'-'+unindex" v-model="unit.condition_id" type="slider" :options="[1,2]" :disabled="true" />
                    </div>
                    <div class="col-sm-1p5">
                        <FormItem :id="'make-'+index+'-'+unindex" v-model="unit.unit_make_id" type="livesearch" :service="makeService" :content="getMM(unit, 'make')" @onchange="(id, name) => {setMM(unit, name, 'make'); validateTabOne(id, index)}" :required="true" />
                    </div>
                    <div class="col-sm-auto" style="width: 11.3%">
                        <FormItem :id="'model-'+index+'-'+unindex" v-model="unit.unit_model_id" type="livesearch" :service="modelService" :required="true" 
                              :content="getMM(unit, 'model')" @onchange="(id, name) => {setMM(unit, name, 'model'); validateTabOne(id, index)}"
                              :disabled="!unit.unit_make_id || !unit.commodity_id" :extraOptions="{'make_id': unit.unit_make_id, 'comm_id': unit.commodity_id}"/>
                    </div>
                    <div :class="'col-sm-' + (licensePlateRequired ? '1p5 vin-lp' : '2 vin-only')" v-if="unit.quantity == 1">
                        <input class="form-control form-control-sm license-plate" v-model="unit.chassis_number" @keydown="licensePlateAlterInput" @input="validateTabOne($event, index)" 
                            @change="toUpper($event.target, unit)" :required="true" :style="{'backgroundColor': checkChassisNr(unit)}">
                    </div>
                    <div :class="'col-sm-' + (licensePlateRequired ? '1p5 vin-lp' : '2 vin-only') + ' has-validation'" v-else>
                        <textarea @input="validateTabOne($event, index)" wrap="off" v-model="unit.chassis_number" class="form-control form-control-sm license-plate" :required="true"
                                @keydown="licensePlateAlterInput" @change="toUpper($event.target, unit)" :style="{'backgroundColor': checkChassisNr(unit)}"></textarea>
                        <div class="invalid-feedback one-license-plate" v-show="unit.chassis_number != null"><i class="fa fa-warning"></i>&ensp;<span>Hi</span></div>
                    </div>
                    <div v-if="licensePlateRequired" class="col-sm-1 license-column">
                        <input class="form-control form-control-sm license-plate" v-model="unit.license_plate" @keydown="licensePlateAlterInput" @input="validateTabOne($event, index)" 
                            @change="toUpper($event.target)" v-if="unit.quantity == 1">
                        <template v-else>
                            <textarea @input="validateTabOne($event, index)" wrap="off" v-model="unit.license_plate" class="form-control form-control-sm license-plate"
                                    @keydown="licensePlateAlterInput" @change="toUpper($event.target)"></textarea>
                            <div class="invalid-feedback multi-license-plate" v-show="unit.chassis_number != null"><i class="fa fa-warning"></i>&ensp;<span>Hi</span></div>
                        </template>
                    </div>
                </div>
                <div class="row transport-unit-table">
                    <div class="col-sm-auto" style="width: 1.8%"></div>
                    <div class="col-sm-1">
                        <FormItem :id="'byear-'+index+'-'+unindex" v-model="unit.construction_year" :placeholder="$t('sales.build_year')" />
                    </div>
                    <div class="col-sm-2" style="width: 12.3% !important">
                        <FormItem :id="'colour-'+index+'-'+unindex" v-model="unit.colour_id" type="select" :options="colours" :placeholder="$t('sales.select_x', [$t('navigation.colours')])" />
                    </div>
                    <div class="col-sm-2" style="width: 11.1% !important">
                        <FormItem :id="'hscode-'+index+'-'+unindex" v-model="unit.hs_code" type="text" :placeholder="$t('navigation.hs_codes')" />
                    </div>
                </div>
                <div class="row transport-unit-table row mb-3">
                    <div class="col-sm-2 me-5"></div><i class="col-sm-2" v-show="unit.description">{{ $t('form.description') + ': ' + unit.description }}</i>
                </div>
            </template>
            </div>
            </template>
        </div></div>

        <template v-if="selectedUnits.length < job.units.length">
            <h5 class="mt-4 mb-3 fw-bold">{{ $t('operations.pending_units') }}</h5>
            <div class="row mb-0 pt-2 transport-unit-table">
                <div class="col-sm-xxs"></div>
                <div class="col-sm-xs col-form-label-sm"> {{$t('form.qt')}} </div>
                <div class="col-sm-1p5 col-form-label-sm"> {{$tc('navigation.commodities', 1)}} </div>
                <div class="col-sm-xs col-form-label-sm"> {{$t('form.new')}} </div>
                <div class="col-sm-1p5 col-form-label-sm"> {{$t('navigation.makes')}} </div>
                <div class="col-sm-1p5 col-form-label-sm"> {{$t('navigation.models')}} </div>
                <label :class="'col-sm-' + (licensePlateRequired ? '1p5' : '2') + ' col-form-label-sm'"> {{ $t('sales.vin') }} </label>
                <label v-show="licensePlateRequired" class="col-sm-1 col-form-label-sm"> {{ $t('sales.license_plate') }} </label>
                <div class="col-sm-1 col-form-label-sm"> {{$t('form.length')}} </div>
                <div class="col-sm-1 col-form-label-sm"> {{$t('form.width')}} </div> 
                <div class="col-sm-1 col-form-label-sm"> {{$t('form.height')}} </div> 
                <div class="col-sm-1 col-form-label-sm"> {{$t('form.weight')}} </div> 
            </div>
        </template>
        <div :id="isCreatingNew ? 'collapse-y' : 'collapse-2'">
        <div v-for="(jobUnit,index) in job.units" :key="index" class="row transport-unit-table">
        <template v-if="jobUnit && jobUnit.checked === 0">
            <div class="row transport-unit-table">
                <div class="col-sm-xxs">
                    <FormItem :id="'check-'+index" v-model="jobUnit.checked" type="checkbox" @onclick="(e) => addToSelectedUnits(e, jobUnit)" />
                </div>
                <div class="col-sm-xs">
                    <FormItem :id="'qt-'+index" v-model="jobUnit.quantity" :disabled="true" />
                </div>
                <div class="col-sm-1p5">
                    <FormItem :id="'comm-'+index" v-model="jobUnit.commodity_id" type="select" :options="commodities" :disabled="true" />
                </div>
                <div class="col-sm-xs">
                    <FormItem :id="'cond-'+index" v-model="jobUnit.condition_id" type="slider" :options="[1,2]" :disabled="true" />
                </div>
                <div class="col-sm-1p5">
                    <FormItem :id="'make-'+index" v-model="jobUnit.unit_make_id" type="livesearch" :service="makeService" :content="getMM(jobUnit, 'make')" />
                </div>
                <div class="col-sm-1p5">
                    <FormItem :id="'model-'+index" v-model="jobUnit.unit_model_id" type="livesearch" :service="modelService" :content="getMM(jobUnit, 'model')" 
                              :disabled="!jobUnit.unit_make_id || !jobUnit.commodity_id" :extraOptions="{'make_id': jobUnit.unit_make_id, 'comm_id': jobUnit.commodity_id}" />
                </div>
                <div :class="'col-sm-' + (licensePlateRequired ? '1p5 vin-lp' : '2 vin-only')" v-if="jobUnit.quantity == 1">
                    <FormItem :id="'chass-'+index" v-model="jobUnit.chassis_number" />
                </div>
                <div :class="'col-sm-' + (licensePlateRequired ? '1p5 vin-lp' : '2 vin-only')" v-else>
                    <textarea @input="validateTabOne($event, index)" wrap="off" v-model="jobUnit.chassis_number" class="form-control form-control-sm license-plate" :required="true"
                              @keydown="licensePlateAlterInput" @change="toUpper($event.target, jobUnit)"></textarea>
                </div>
                <div v-if="licensePlateRequired" class="col-sm-1">
                    <FormItem :id="'lp-'+index" type="license_plate" v-model="jobUnit.license_plate" />
                </div>
                <div class="col-sm-1">
                    <FormItem :id="'ln-field-'+index" v-model="jobUnit.booked_measurement.length" type="number-with-tags" endTag="cm" :disabled="jobUnit.measured" />
                </div>
                <div class="col-sm-1">
                    <FormItem :id="'wi-field-'+index" v-model="jobUnit.booked_measurement.width" type="number-with-tags" endTag="cm" :disabled="jobUnit.measured" />
                </div> 
                <div class="col-sm-1">
                    <FormItem :id="'ht-field-'+index" v-model="jobUnit.booked_measurement.height" type="number-with-tags" endTag="cm" :disabled="jobUnit.measured" />
                </div> 
                <div class="col-sm-1">
                    <FormItem :id="'wt-field-'+index" v-model="jobUnit.booked_measurement.weight" type="number-with-tags" endTag="kg" :disabled="jobUnit.measured" />
                </div>
                <div class="col-sm-auto" >
                    <button class="btn btn-sm float-right" type="button" title="Click to delete item" @click="deleteSeaTransportline(index)"><i class="far fa-trash-alt text-danger"></i></button>
                </div>
            </div>
            <div class="row transport-unit-table">
                <div class="col-sm-xxs"></div>
                <div class="col-sm-1">
                    <FormItem :id="'byear-'+index" v-model="jobUnit.construction_year" :placeholder="$t('sales.build_year')" />
                </div>
                <div class="col-sm-2" style="width: 12.1% !important">
                    <FormItem :id="'colour-'+index" v-model="jobUnit.colour_id" type="select" :options="colours" :placeholder="$t('sales.select_x', [$t('navigation.colours')])" />
                </div>
                <div class="col-sm-2" style="width: 12.1% !important">
                    <FormItem :id="'hscode-'+index" v-model="jobUnit.hs_code" type="text" :placeholder="$t('navigation.hs_codes')" />
                </div>
                <div class="col-sm-auto" v-show="jobUnit.description">
                    {{ $t('form.description') + ': ' + jobUnit.description }}
                </div>
            </div>
            <div class="border-left ms-3" style="width: calc(100% - 10px)">
            <template v-for="(unit, uindex) in jobUnit.stacked_units" :key="uindex">
                <div class="row transport-unit-table">
                    <div class="col-sm-auto" style="width: 1.8%"></div>
                    <div class="col-sm-auto" style="width: 3.8%">
                        <FormItem :id="'qt-'+index+'-'+unindex" v-model="unit.quantity" :disabled="true" />
                    </div>
                    <div class="col-sm-auto" style="width: 11.3%">
                        <FormItem :id="'comm-'+index+'-'+unindex" v-model="unit.commodity_id" type="select" :options="commodities" :disabled="true" />
                    </div>
                    <div class="col-sm-xs">
                        <FormItem :id="'cond-'+index+'-'+unindex" v-model="unit.condition_id" type="slider" :options="[1,2]" :disabled="true" />
                    </div>
                    <div class="col-sm-1p5">
                        <FormItem :id="'make-'+index+'-'+unindex" v-model="unit.unit_make_id" type="livesearch" :service="makeService" :content="getMM(unit, 'make')" @onchange="(id, name) => {setMM(unit, name, 'make'); validateTabOne(id, index)}" />
                    </div>
                    <div class="col-sm-auto" style="width: 11.3%">
                        <FormItem :id="'model-'+index+'-'+unindex" v-model="unit.unit_model_id" type="livesearch" :service="modelService" 
                              :content="getMM(unit, 'model')" @onchange="(id, name) => {setMM(unit, name, 'model'); validateTabOne(id, index)}"
                              :disabled="!unit.unit_make_id || !unit.commodity_id" :extraOptions="{'make_id': unit.unit_make_id, 'comm_id': unit.commodity_id}"/>
                    </div>
                    <div :class="'col-sm-' + (licensePlateRequired ? '1p5 vin-lp' : '2 vin-only')" v-if="unit.quantity == 1">
                        <input class="form-control form-control-sm license-plate" v-model="unit.chassis_number" @keydown="licensePlateAlterInput" @input="validateTabOne($event, index)" 
                            @change="toUpper($event.target, unit)" :style="{'backgroundColor': checkChassisNr(unit)}">
                    </div>
                    <div :class="'col-sm-' + (licensePlateRequired ? '1p5 vin-lp' : '2 vin-only') + ' has-validation'" v-else>
                        <textarea @input="validateTabOne($event, index)" wrap="off" v-model="unit.chassis_number" class="form-control form-control-sm license-plate"
                                @keydown="licensePlateAlterInput" @change="toUpper($event.target, unit)" :style="{'backgroundColor': checkChassisNr(unit)}"></textarea>
                        <div class="invalid-feedback one-license-plate" v-show="unit.chassis_number != null"><i class="fa fa-warning"></i>&ensp;<span>Hi</span></div>
                    </div>
                    <div v-if="licensePlateRequired" class="col-sm-1 license-column">
                        <input class="form-control form-control-sm license-plate" v-model="unit.license_plate" @keydown="licensePlateAlterInput" @input="validateTabOne($event, index)" 
                            @change="toUpper($event.target)" v-if="unit.quantity == 1">
                        <template v-else>
                            <textarea @input="validateTabOne($event, index)" wrap="off" v-model="unit.license_plate" class="form-control form-control-sm license-plate"
                                    @keydown="licensePlateAlterInput" @change="toUpper($event.target)"></textarea>
                            <div class="invalid-feedback multi-license-plate" v-show="unit.chassis_number != null"><i class="fa fa-warning"></i>&ensp;<span>Hi</span></div>
                        </template>
                    </div>
                </div>
                <div class="row transport-unit-table">
                    <div class="col-sm-auto" style="width: 1.8%"></div>
                    <div class="col-sm-1">
                        <FormItem :id="'byear-'+index+'-'+unindex" v-model="unit.construction_year" :placeholder="$t('sales.build_year')" />
                    </div>
                    <div class="col-sm-2" style="width: 12.3% !important">
                        <FormItem :id="'colour-'+index+'-'+unindex" v-model="unit.colour_id" type="select" :options="colours" :placeholder="$t('sales.select_x', [$t('navigation.colours')])" />
                    </div>
                    <div class="col-sm-2" style="width: 11.1% !important">
                        <FormItem :id="'hscode-'+index+'-'+unindex" v-model="unit.hs_code" type="text" :placeholder="$t('navigation.hs_codes')" />
                    </div>
                </div>
            </template>
            </div>
            </template>
        </div></div>
        
    </div>
    <!--end tab --> 

    <!--Begin ininerary tab --> 
    <div class="w-100" id="ininerary" role="tabpanel" aria-labelledby="ininerary-tab" v-show="job.port_of_loading_id && job.port_of_discharge_id && !addingUnits">

        <h5 class="mt-4 mb-3 fw-bold" @click="textX">{{customVoyage ? $t('operations.manual_voyage') : $t('operations.select_itinerary')}}</h5>
        <div v-show="filteredVoyages.length == 0 && !customVoyage">{{$t('form.no_available', [$tc('navigation.voyages', 2)])}}</div>

        <div v-show="customVoyage">
            <AddmanualVoyageComponentVue :itemData="{}" :fromJobOrQuote="true" ref="addmanvoyageform" />
        </div>
        <template v-if="!searchingVoyages && !customVoyage">
            <div class="row fw-bold" v-show="filteredVoyages.length > 0">
                <div class="col-sm-half"> &nbsp; </div>
                <div class="col-sm-3"> {{$tc('navigation.vessels', 1)}} / {{$tc('navigation.voyages', 1)}} </div>
                <div class="col-sm-2"> ETD </div>
                <div class="col-sm-2"> ETA </div>
                <div class="col-sm-2"> {{$tc('navigation.carriers', 1)}} </div>
                <div class="col-sm-1"> {{$t('overview.type')}} </div>
                <div class="col-sm-1"> {{$t('overview.status')}} </div>
            </div>
            <template v-for="voyageInfo in filteredVoyages" :key="voyageInfo.id">
                <div class="row highlight-on-hover" :data-featured="preferredVoyageIds.includes(voyageInfo.id)">
                    <div class="col-sm-half">
                        <FormItem :id="voyageInfo.id" :idField="voyageInfo.id" nameField="voyage" v-model="selectedItinerary" type="radio" @onclick="setItinerary(voyageInfo)" :disabled="voyageInfo.voyage_status_id != 1 || voyageInfo.disabled" />
                    </div>
                    <label class="col-sm-3"  :for="voyageInfo.id"> {{voyageInfo.vessel}} {{voyageInfo.number}} <i class="fa fa-star ms-3 text-primary"></i> </label>
                    <div class="col-sm-2"> {{ voyageInfo.pol_date ? formatDate(voyageInfo.pol_date, "shortDate") : '-' }} </div>
                    <div class="col-sm-2"> {{ voyageInfo.pod_date ? formatDate(voyageInfo.pod_date, "shortDate") : '-' }} </div>
                    <div class="col-sm-2"> {{ voyageInfo.carrier }} </div>
                    <div class="col-sm-1"> {{"Direct"}} </div>
                    <div :class="'col-sm-auto' + (voyageInfo.voyage_status_id != 1 ? ' text-danger' : '')"> {{$t('voyage_status.' + voyageInfo.voyage_status_id)}} </div>
                </div>
            </template>

            <div v-if="manualChosen"></div>
            <div class="d-flex justify-content-center my-4" v-else-if="filteredVoyagesTransshipment === null">
                <button class="btn btn-light w-50" type="button" @click="getVoyagesTransshipment()">
                    <i class="fa fa-chevron-down"></i>&ensp;{{$t('sales.voyage_trans_see')}}
                </button>
            </div>
            <template v-else-if="searchingVoyagesTransshipment">
                <h5 class="mt-5 mb-3 fw-bold">{{$t('sales.voyage_trans')}}</h5>
                <div class="loading-block"><i class="fa fa-spinner fa-spin fa-xl"></i></div>
            </template>
            <div v-else>
                <div class="d-flex mt-5 mb-3 ">
                    <h5 class="fw-bold d-block col-8">{{$t('sales.voyage_trans')}}</h5>
                    <div class="col-4">
                        <FormItem id="sortoptions" type="buttongroup" v-model="sortOptionTransshipment" :options="sortOptions" sizeItem="12" @onchange="sortTransshipmentVoyages(null)" />
                    </div>
                </div>

                <div class="row fw-bold">
                    <div class="col-sm-auto">&emsp;</div>
                    <div class="col-sm-3"> </div>
                    <div class="col-sm-2"> ETD </div>
                    <div class="col-sm-2 ps-0"> Transshipment </div>
                    <div class="col-sm-3"> </div>
                    <div class="col-sm-1"> ETA </div>
                </div>
                <label v-for="voyageInfo in filteredVoyagesTransshipment" :key="voyageInfo.id_string" :for="voyageInfo.id_string" class="row py-1 border-top row" :data-featured="preferredVoyageIds.includes(voyageInfo.id_string)">
                    <div class="col-sm-auto" style="padding-top: 10px">
                        <FormItem :id="voyageInfo.id_string" v-model="selectedItinerary" type="radio" nameField="voyage" :idField="voyageInfo.id_string" @onclick="setItinerary(voyageInfo, true)" />  
                    </div>
                    <div class="col-sm-1 ps-5">
                        <img v-if="carrierLogos[voyageInfo.carrier_1]" :src="carrierLogos[voyageInfo.carrier_1]" height="42" width="42" draggable="false" />
                    </div>
                    <div class="col-sm-2"> 
                        {{ voyageInfo.vessel_1 + ' ' + voyageInfo.number_1 }} <i class="fa fa-star ms-2 text-primary"></i> <br />
                        <span class="text-muted col-form-label-sm"> {{ voyageInfo.carrier_1 }} </span>
                    </div>
                    <div class="col-sm-2"> {{ formatDate(voyageInfo.pol_date, "longDateAmerican") }} </div>
                    <div class="col-sm-2 ps-0"> 
                        {{ voyageInfo.tport_name }} <br />
                        <span class="text-muted col-form-label-sm"> {{ voyageInfo.trans_time + ' ' + $tc('time.day', voyageInfo.trans_time).toLowerCase() }} </span>
                    </div>
                    <div class="col-sm-1 ps-5">
                        <img v-if="carrierLogos[voyageInfo.carrier_2]" :src="carrierLogos[voyageInfo.carrier_2]" height="42" width="42" draggable="false" />
                    </div>
                    <div class="col-sm-2"> 
                        {{ voyageInfo.vessel_2 + ' ' + voyageInfo.number_2 }}  <br />
                        <span class="text-muted col-form-label-sm"> {{ voyageInfo.carrier_2 }} </span>
                    </div>
                    <div class="col-sm-1 pe-0"> {{ formatDate(voyageInfo.pod_date, "longDateAmerican") }} </div>
                </label>
                <div v-show="filteredVoyagesTransshipment.length == 0">
                    <div>{{$t('form.no_available', [$tc('navigation.voyages', 2)])}}</div>
                    <button v-show="false" type="button" class="btn btn-primary" @click="getVoyagesTransshipment(3)">{{$t('operations.more_transshipments')}}</button>
                </div>
            </div>
        </template>
        <div v-else-if="!customVoyage"> {{$t('form.please_wait')}}</div>
        <div class="d-flex justify-content-center my-4" v-if="!searchingVoyages && !manualChosen">
            <button class="btn btn-primary btn-sm w-25" type="button" @click="setManualVoyageForm(!customVoyage)">
                <i :class="{'fa fa-plus': !customVoyage, 'fa fa-arrow-left': customVoyage}"></i>
                &ensp;{{customVoyage ? $t('overview.back') : $t('operations.manual_voyage')}}
            </button>
        </div>

    </div>
    <!--end tab --> 
    <!--Begin add units tab --> 

    <div class="w-100 py-3" :class="{'border-top': !addingUnits}" id="addunits" role="tabpanel" aria-labelledby="addunits-tab" v-show="(isCreatingNew && selectedItinerary) || addingUnits">
        <h5 class="mb-1 fw-bold">{{$tc('navigation.units', 2)}}</h5>
        <div :id="isCreatingNew ? 'collapse-1' : 'collapse-x'">
        <div v-for="(jobUnit,index) in job.units" :key="jobUnit.order_by">
            <div class="row mb-0 pt-2 transport-unit-table">
                <div class="col-sm-xs col-form-label-sm"> {{$t('form.qt')}} </div>
                <div class="col-sm-1p5 col-form-label-sm"> {{$tc('navigation.commodities', 1)}} </div>
                <div class="col-sm-xs col-form-label-sm"> {{$t('form.new')}} </div>
                <div class="col-sm-1p5 col-form-label-sm"> {{$t('navigation.makes')}} </div>
                <div class="col-sm-1p5 col-form-label-sm"> {{$t('navigation.models')}} </div>
                <label :class="'col-sm-' + (licensePlateRequired ? '1p5' : '2') + ' col-form-label-sm'"> {{ $t('sales.vin') }} </label>
                <label v-show="licensePlateRequired" class="col-sm-1 col-form-label-sm"> {{ $t('sales.license_plate') }} </label>
                <div class="col-sm-1 col-form-label-sm"> {{$t('form.length')}} </div>
                <div class="col-sm-1 col-form-label-sm"> {{$t('form.width')}} </div> 
                <div class="col-sm-1 col-form-label-sm"> {{$t('form.height')}} </div> 
                <div class="col-sm-1 col-form-label-sm"> {{$t('form.weight')}} </div> 
            </div>
            <div class="row transport-unit-table">
                <div></div>
                <div class="col-sm-xs">
                    <FormItem :id="'qt-'+index" v-model="jobUnit.quantity" @onchange="validateTabOne(id, index)" :required="true" />
                </div>
                <div class="col-sm-1p5">
                    <FormItem :id="'comm-'+index" v-model="jobUnit.commodity_id" type="select" :options="commodities" :required="true" 
                              @onchange="(e) => {jobUnit.commodity = e.target.selectedOptions[0].innerHTML; $nextTick(() => validateTabOne(id, index))}"/>
                </div>
                <div class="col-sm-xs">
                    <FormItem :id="'cond-'+index" v-model="jobUnit.condition_id" type="slider" :options="[1,2]" @onchange="validateTabOne(id, index)" />
                </div>
                <div class="col-sm-1p5">
                    <FormItem :id="'make-'+index" v-model="jobUnit.unit_make_id" type="livesearch" :service="makeService" :content="getMM(jobUnit, 'make')" 
                            @onchange="(id, name) => {jobUnit.make = name; setMM(jobUnit, name, 'make'); validateTabOne(id, index)}" :required="true" />
                </div>
                <div class="col-sm-1p5">
                    <FormItem :id="'model-'+index" v-model="jobUnit.unit_model_id" type="livesearch" :service="modelService" :content="getMM(jobUnit, 'model')" :required="true"
                            :disabled="!jobUnit.unit_make_id || !jobUnit.commodity_id" @onchange="(id, name) => {jobUnit.model = name; setMM(jobUnit, name, 'model'); validateTabOne(id, index)}"
                            :extraOptions="{'make_id': jobUnit.unit_make_id, 'comm_id': jobUnit.commodity_id}" />
                </div>
                <div :class="'col-sm-' + (licensePlateRequired ? '1p5 vin-lp' : '2 vin-only')" v-if="jobUnit.quantity == 1">
                    <input class="form-control form-control-sm license-plate" v-model="jobUnit.chassis_number" @keydown="licensePlateAlterInput" @input="validateTabOne($event, index)" 
                          @change="toUpper($event.target, jobUnit); checkForDupes(jobUnit, 0)" :style="{'backgroundColor': checkChassisNr(jobUnit)}" :required="true" :data-id="jobUnit.order_by">
                    <div class="text-danger col-form-label-sm" v-if="jobUnit.duplicate"><i class="fa fa-warning"></i>&ensp;{{$t('errors.duplicate_in_db', ['VIN', jobUnit.duplicate.by])}}</div>
                </div>
                <div :class="'col-sm-' + (licensePlateRequired ? '1p5 vin-lp' : '2 vin-only') + ' has-validation'" v-else>
                    <textarea @input="validateTabOne($event, index)" wrap="off" v-model="jobUnit.chassis_number" class="form-control form-control-sm license-plate" :data-id="jobUnit.order_by"
                              @keydown="licensePlateAlterInput" @change="toUpper($event.target, jobUnit); checkForDupes(jobUnit, 0)" :required="true" :style="{'backgroundColor': checkChassisNr(jobUnit)}"></textarea>
                    <div class="invalid-feedback one-license-plate" v-show="jobUnit.chassis_number != null"><i class="fa fa-warning"></i>&ensp;<span>Hi</span></div>
                    <div class="text-danger col-form-label-sm duplicate-text" v-if="jobUnit.duplicate"><i class="fa fa-warning"></i>&ensp;{{$t('errors.duplicate_in_db', [jobUnit.duplicate.number, jobUnit.duplicate.by])}}</div>
                </div>
                <div v-if="licensePlateRequired" class="col-sm-1 license-column">
                    <input class="form-control form-control-sm license-plate" v-model="jobUnit.license_plate" @keydown="licensePlateAlterInput" @input="validateTabOne($event, index)" 
                          @change="toUpper($event.target)" v-if="jobUnit.quantity == 1">
                    <template v-else>
                        <textarea @input="validateTabOne($event, index)" wrap="off" v-model="jobUnit.license_plate" class="form-control form-control-sm license-plate"
                                  @keydown="licensePlateAlterInput" @change="toUpper($event.target)"></textarea>
                        <div class="invalid-feedback multi-license-plate" v-show="jobUnit.chassis_number != null"><i class="fa fa-warning"></i>&ensp;<span>Hi</span></div>
                    </template>
                </div>
                <div class="col-sm-1">
                    <FormItem :id="'ln-field-'+index" v-model="jobUnit.booked_measurement.length" type="number-with-tags" endTag="cm" @oninput="validateTabOne" :required="true" />
                </div>
                <div class="col-sm-1">
                    <FormItem :id="'wi-field-'+index" v-model="jobUnit.booked_measurement.width" type="number-with-tags" endTag="cm" @oninput="validateTabOne" :required="true" />
                </div> 
                <div class="col-sm-1">
                    <FormItem :id="'ht-field-'+index" v-model="jobUnit.booked_measurement.height" type="number-with-tags" endTag="cm" @oninput="validateTabOne" :required="true" />
                </div> 
                <div class="col-sm-1">
                    <FormItem :id="'wt-field-'+index" v-model="jobUnit.booked_measurement.weight" type="number-with-tags" endTag="kg" @oninput="validateTabOne" :required="true" />
                </div>
                <div class="col-sm-auto" >
                    <button class="btn btn-sm float-right" type="button" title="Click to delete item" @click="deleteSeaTransportline(index)"><i class="far fa-trash-alt text-danger"></i></button>
                </div>
            </div>
            <div class="row transport-unit-table">
                <div class="col-sm-1" style="width: 6.4% !important">
                    <FormItem :id="'byear-'+index" v-model="jobUnit.construction_year" :placeholder="$t('sales.build_year')" />
                </div>
                <div class="col-sm-2" style="width: 10% !important">
                    <FormItem :id="'colour-'+index" v-model="jobUnit.colour_id" type="select" :options="colours" :placeholder="$t('sales.select_x', [$t('navigation.colours')])" />
                </div>
                <div class="col-sm-2" style="width: 8.4% !important">
                    <FormItem :id="'hscode-'+index" v-model="jobUnit.hs_code" type="select" :placeholder="$t('navigation.hs_codes')" />
                </div>
                <div class="col-sm-auto">
                    <button class="btn btn-sm btn-primary" type="button" title="Add stacked unit" @click="addStackedUnit(index, 'stacked')">
                        <i class="fa fa-plus"></i>&ensp;{{$t('form.add', ['stacked'])}}
                    </button>
                </div>
                <div class="col-sm-auto">
                    <button class="btn btn-sm btn-primary" type="button" title="Add connected unit" @click="addStackedUnit(index, 'connected')" v-if="!jobUnit.stacked_units.find(ju => ju.stacking_type == 'connected')">
                        <i class="fa fa-link"></i>&ensp;{{$t('form.add', ['connected'])}}
                    </button>
                </div>
            </div>

            <div class="border-left">
            <template v-for="(unit, uindex) in jobUnit.stacked_units" :key="unit.order_by">
                <div class="row transport-unit-table">
                    <div class="col-sm-auto" style="width: 0.8%"></div>
                    <div class="col-sm-auto" style="width: 4.0%">
                        <input class="form-control form-control-sm required-field" v-model="unit.quantity" type="number" @change="validateTabOne($event, index)" :required="true">   
                    </div>
                    <div class="col-sm-1p5">
                        <select v-model="unit.commodity_id" @change="{unit.commodity = $event.target.selectedOptions[0].innerHTML; validateTabOne($event, index)}" 
                                class="form-select form-select-sm commodity_id required-field" :required="true">
                            <option value=''>{{$t('form.select')}}</option>
                            <option v-for="commodity in commodities" :key="commodity.id" :value="commodity.id"> {{commodity.name}} </option>
                        </select>
                    </div>
                    <div class="col-sm-xs">
                        <FormItem :id="'cond-'+index+'-'+uindex" v-model="unit.condition_id" type="slider" :options="[1,2]" @onchange="validateTabOne(id, index)" />
                    </div>
                    <div class="col-sm-1p5">
                        <FormItem :id="'make-'+index+'-'+uindex" v-model="unit.unit_make_id" type="livesearch" :service="makeService" @onchange="(i, n) => {unit.unit_make_name = n; unit.unit_model_id = null}"
                                :content="unit.make ? unit.make.name : ''" :disabled="!unit.commodity_id" :required="true" />
                    </div>
                    <div class="col-sm-1p5">
                        <FormItem :id="'model-'+index+'-'+uindex" v-model="unit.unit_model_id" type="livesearch" :service="modelService" @onchange="(i, n) => {unit.unit_model_name = n}"
                                :content="unit.model ? unit.model.name : ''" :disabled="!unit.unit_make_id || !unit.commodity_id"
                                :extraOptions="{'make_id': unit.unit_make_id, 'comm_id': unit.commodity_id}" :required="true" />
                    </div>
                    <div :class="'col-sm-' + (licensePlateRequired ? '1p5 vin-lp' : '2 vin-only')" v-if="unit.quantity == 1">
                    <input class="form-control form-control-sm license-plate" v-model="unit.chassis_number" @keydown="licensePlateAlterInput" @input="validateTabOne($event, index)" 
                          @change="toUpper($event.target, unit)" :style="{'backgroundColor': checkChassisNr(unit)}" :required="true">
                    </div>
                    <div :class="'col-sm-' + (licensePlateRequired ? '1p5 vin-lp' : '2 vin-only') + ' has-validation'" v-else>
                        <textarea @input="validateTabOne($event, index)" wrap="off" v-model="unit.chassis_number" class="form-control form-control-sm license-plate"
                                @keydown="licensePlateAlterInput" @change="toUpper($event.target, unit)" :style="{'backgroundColor': checkChassisNr(unit)}" :required="true"></textarea>
                        <div class="invalid-feedback one-license-plate" v-show="unit.chassis_number != null"><i class="fa fa-warning"></i>&ensp;<span>Hi</span></div>
                    </div>
                    <div v-if="licensePlateRequired" class="col-sm-1 license-column">
                        <input class="form-control form-control-sm license-plate" v-model="unit.license_plate" @keydown="licensePlateAlterInput" @input="validateTabOne($event, index)" 
                            @change="toUpper($event.target)" v-if="unit.quantity == 1">
                        <template v-else>
                            <textarea @input="validateTabOne($event, index)" wrap="off" v-model="unit.license_plate" class="form-control form-control-sm license-plate"
                                    @keydown="licensePlateAlterInput" @change="toUpper($event.target)"></textarea>
                            <div class="invalid-feedback multi-license-plate" v-show="unit.chassis_number != null"><i class="fa fa-warning"></i>&ensp;<span>Hi</span></div>
                        </template>
                    </div>
                    <div class="col">
                        <button class="btn btn-sm float-right" type="button" title="Click to delete item" @click="deleteStackedUnit(jobUnit, uindex)"><i class="far fa-trash-alt text-danger"></i></button>
                    </div>
                </div>
                <div class="row transport-unit-table">
                    <div class="col-sm-auto" style="width: 0.8%"></div>
                    <div class="col-sm-auto" style="width: 7.0% !important">
                        <input class="form-control form-control-sm" v-model="unit.construction_year" :placeholder="$t('sales.build_year')">
                    </div>
                    <div class="col-sm-2" style="width: 13.1% !important">
                        <FormItem :id="'colour-'+index" v-model="unit.colour_id" type="select" :options="colours" :placeholder="$t('sales.select_x', [$t('navigation.colours')])" />
                    </div>
                    <div class="col-sm-2" style="width: 11.0% !important">
                        <FormItem :id="'hscode-'+index" v-model="unit.hs_code" type="text" :placeholder="$t('navigation.hs_codes')" />
                    </div>
                    <div class="col-sm-2 text-capitalize" :style="{'width': (unit.stacking_type == 'stacked-connected' ? '20% !important' : '11.0% !important')}">
                        <i class="fa fa-layer-group" v-show="unit.stacking_type == 'stacked' || unit.stacking_type == 'stacked-connected'"></i>
                        <i class="fa fa-link" v-show="unit.stacking_type == 'connected' || unit.stacking_type == 'stacked-connected'"></i>
                        &nbsp;{{unit.stacking_type}}
                    </div>
                    <div class="col-sm-auto" v-show="unit.stacking_type == 'connected'">
                        <button class="btn btn-sm btn-primary" type="button" title="Add stacked unit" @click="addStackedUnit(index, 'stacked-connected')">
                            <i class="fa fa-plus"></i>&ensp;{{$t('form.add', ['stacked'])}}
                        </button>
                    </div>
                </div>
            </template>
            </div>
        </div></div>
        <div class="text-center w-100">
            <button class="btn btn-primary btn-sm add-unit-button" type="button" @click="suggestNewJobUnit">
                <span class="fa fa-plus"></span> &nbsp;{{$t('form.add', [$t('navigation.units')])}}
            </button>
        </div>
    </div>
    <div class="text-center w-100 pt-3" v-show="addingUnits || changingPorts">
        <button class="btn btn-secondary btn-sm px-4" type="button" @click="cancel"> {{$t('overview.back')}} </button>
    </div>
    <!--end tab --> 
    <!--Begin ready tab --> 

    <!--<div class="w-100" id="ready" role="tabpanel" aria-labelledby="ready-tab" v-if="(isCreatingNew && canGoToStepFour) || (!isCreatingNew && bookingVoyageInfo && bookingVoyageInfo[0])">
        <p v-if="isCreatingNew" class="fw-bold">{{$t('operations.review_confirm_text')}}</p>
        <h5 class="mb-3 fw-bold">{{$t('navigation.voyages')}}</h5>
        <div class="row">
            <div class="col-3">{{$t('navigation.customers')}}</div>
            <div class="col-9">{{job.customer.relation ? job.customer.relation.name : job.customer.name}}</div>
        </div>
        <div class="row" v-if="isCreatingNew">
            <div class="col-3">{{$t('operations.receive_stat_mail')}}</div>
            <div class="col-9"><FormItem id="statmail" type="checkbox" v-model="job.receive_stat_mail" /></div>
        </div>
        <div class="row">
            <div class="col-3">{{$t('navigation.voyages')}}</div>
            <div class="col-9">
                <div v-for="voyage, vindex in bookingVoyageInfo" :key="vindex"> {{ voyage.name }} </div>
            </div>
        </div>
        <div class="row">
            <div class="col-3"></div>
            <div class="col-9">{{job.port_of_loading.name + ' - ' + job.port_of_discharge.name}}</div>
        </div>
        <h5 class="mt-3 mb-2 fw-bold">{{$tc('navigation.units', 2)}}</h5>
        <div class="row mb-0 pt-2 transport-unit-table fw-bold">
            <div class="col-sm-1p5 col-form-label-sm"> {{$t('overview.type')}} </div>
            <div class="col-sm-2 col-form-label-sm"> {{$t('form.condition')}} </div>
            <div class="col-sm-2 col-form-label-sm"> {{$t('navigation.makes')}}/{{$t('navigation.models')}} </div>
            <div class="col-sm-2 col-form-label-sm"> {{ $t('sales.vin') }}<span v-show="licensePlateRequired">/{{ $t('sales.license_plate') }}</span> </div>
            <div class="col-sm-2 col-form-label-sm"> {{$t('operations.dimensions')}} (L x W x H, cm) </div> 
            <div class="col-sm-1 col-form-label-sm"> {{$t('form.weight')}} (kg) </div> 
        </div>
        <template v-for="unit, uindex in (isCreatingNew ? job.units : job.units.filter(u => u.checked))" :key="uindex">
            <div class="row mb-0 transport-unit-table">
                <div class="col-sm-1p5 col-form-label-sm text-ellipsis" v-html="isCreatingNew ? unit.commodity : unit.commodity.name"></div>
                <div class="col-sm-2 col-form-label-sm text-ellipsis">
                    {{unit.condition_id == 1 ? $t('form.new') : $t('form.used')}}
                </div>
                <div class="col-sm-2 col-form-label-sm text-ellipsis">
                    {{isCreatingNew ? (unit.make && unit.model ? (unit.make + ' ' + unit.model) : unit.description) : 
                                        ((unit.makeName ? unit.makeName : unit.make.name) + ' ' + (unit.modelName ? unit.modelName : unit.model.name))}}
                </div>
                <div class="col-sm-2 col-form-label-sm text-ellipsis text-uppercase">
                    {{ unit.chassis_number || unit.license_plate ? (unit.chassis_number.replaceAll('\n', ', ') + (unit.license_plate ? (' | ' + unit.license_plate) : '')) : '-' }}
                </div>
                <div class="col-sm-2 col-form-label-sm text-ellipsis">
                    {{unit.booked_measurement.length && unit.booked_measurement.width && unit.booked_measurement.height ? 
                    ( unit.booked_measurement.length + ' x ' + unit.booked_measurement.width + ' x ' + unit.booked_measurement.height) : '-'}}
                </div> 
                <div class="col-sm-1 col-form-label-sm text-ellipsis">
                    {{unit.booked_measurement.weight ? unit.booked_measurement.weight : '-'}}
                </div> 
            </div>
            <div class="row mb-0 transport-unit-table" v-for="unit, uindex in unit.stacked_units" :key="uindex">
                <div class="col-sm-1p5 py-0 col-form-label-sm text-ellipsis border-left" v-html="isCreatingNew ? unit.commodity : unit.commodity.name"></div>
                <div class="col-sm-2 py-0 col-form-label-sm text-ellipsis">
                    {{unit.condition_id == 1 ? $t('form.new') : $t('form.used')}}
                </div>
                <div class="col-sm-2 py-0 col-form-label-sm text-ellipsis">
                    {{isCreatingNew ? (unit.unit_make_name && unit.unit_model_name ? (unit.unit_make_name + ' ' + unit.unit_model_name) : unit.description) : 
                                        ((unit.makeName ? unit.makeName : unit.make.name) + ' ' + (unit.modelName ? unit.modelName : unit.model.name))}}
                </div>
                <div class="col-sm-2 py-0 col-form-label-sm text-ellipsis text-uppercase">
                    {{ unit.chassis_number || unit.license_plate ? (unit.chassis_number.replaceAll('\n', ', ') + (unit.license_plate ? (' | ' + unit.license_plate) : '')) : '-' }}
                </div>
            </div>
        </template>
    </div>
    <div class="w-100" id="ready" role="tabpanel" aria-labelledby="ready-tab" v-else></div>-->
    
    </div>
    <div v-if="job.status == 'pending'">
        <h5 class="mb-3 fw-bold">{{$t('navigation.voyages')}}</h5>
        <div class="row">
            <div class="col-3">{{$t('navigation.customers')}}</div>
            <div class="col-9">{{job.customer.relation.name}}</div>
        </div>
        <div class="row">
            <div class="col-3">{{$t('navigation.voyages')}}</div>
            <div class="col-9">
                <div v-for="voyage, vindex in job.pending_booking.voyages" :key="vindex"> {{ voyage.name }} </div>
                <div>{{job.port_of_loading.name + ' - ' + job.port_of_discharge.name}}</div>
            </div>
        </div>
        <h5 class="mt-3 mb-2 fw-bold">{{$tc('navigation.units', 2)}}</h5>
        <div class="row mb-0 pt-2 transport-unit-table fw-bold">
            <div class="col-sm-1p5 col-form-label-sm"> {{$t('overview.type')}} </div>
            <div class="col-sm-2 col-form-label-sm"> {{$t('form.condition')}} </div>
            <div class="col-sm-2 col-form-label-sm"> {{$t('navigation.makes')}}/{{$t('navigation.models')}} </div>
            <div class="col-sm-2 col-form-label-sm"> {{ $t('sales.vin') }}<span v-show="licensePlateRequired">/{{ $t('sales.license_plate') }}</span> </div>
            <div class="col-sm-2 col-form-label-sm"> {{$t('operations.dimensions')}} (L x W x H, cm) </div> 
            <div class="col-sm-1 col-form-label-sm"> {{$t('form.weight')}} (kg) </div> 
        </div>
        <template v-for="unit, uindex in job.pending_booking.job_unit" :key="uindex">
            <div class="row mb-0 transport-unit-table">
                <div class="col-sm-1p5 col-form-label-sm text-ellipsis" v-html="isCreatingNew ? unit.commodity : unit.commodity.name"></div>
                <div class="col-sm-2 col-form-label-sm text-ellipsis">
                    {{unit.condition_id == 1 ? $t('form.new') : $t('form.used')}}
                </div>
                <div class="col-sm-2 col-form-label-sm text-ellipsis">
                    {{isCreatingNew ? (unit.make && unit.model ? (unit.make + ' ' + unit.model) : unit.description) : 
                                        ((unit.makeName ? unit.makeName : unit.make.name) + ' ' + (unit.modelName ? unit.modelName : unit.model.name))}}
                </div>
                <div class="col-sm-2 col-form-label-sm text-ellipsis text-uppercase">
                    {{ unit.chassis_number || unit.license_plate ? (unit.chassis_number.replaceAll('\n', ', ') + (unit.license_plate ? (' | ' + unit.license_plate) : '')) : '-' }}
                </div>
                <div class="col-sm-2 col-form-label-sm text-ellipsis">
                    {{unit.booked_measurement.length && unit.booked_measurement.width && unit.booked_measurement.height ? 
                    ( unit.booked_measurement.length + ' x ' + unit.booked_measurement.width + ' x ' + unit.booked_measurement.height) : '-'}}
                </div> 
                <div class="col-sm-1 col-form-label-sm text-ellipsis">
                    {{unit.booked_measurement.weight ? unit.booked_measurement.weight : '-'}}
                </div> 
            </div>
            <div class="row mb-0 transport-unit-table" v-for="unit, uindex in unit.stacked_units" :key="uindex">
                <div class="col-sm-1p5 py-0 col-form-label-sm text-ellipsis border-left" v-html="isCreatingNew ? unit.commodity : unit.commodity.name"></div>
                <div class="col-sm-2 py-0 col-form-label-sm text-ellipsis">
                    {{unit.condition_id == 1 ? $t('form.new') : $t('form.used')}}
                </div>
                <div class="col-sm-2 py-0 col-form-label-sm text-ellipsis">
                    {{isCreatingNew ? (unit.unit_make_name && unit.unit_model_name ? (unit.unit_make_name + ' ' + unit.unit_model_name) : unit.description) : 
                                      ((unit.makeName ? unit.makeName : unit.make.name) + ' ' + (unit.modelName ? unit.modelName : unit.model.name))}}
                </div>
                <div class="col-sm-2 py-0 col-form-label-sm text-ellipsis text-uppercase">
                    {{ unit.chassis_number || unit.license_plate ? (unit.chassis_number.replaceAll('\n', ', ') + (unit.license_plate ? (' | ' + unit.license_plate) : '')) : '-' }}
                </div>
            </div>
            <br />
        </template>
        <FormItem :label="$t('legacy.carrier_reference')" v-model="job.pending_booking.carrier_reference" sizeLabel="3" sizeItem="2" @oninput="setCarrierRef" />
    </div>
    <dialog id="split-dialog" class="border rounded p-0" style="width: 40rem">
        <form method="dialog" class="text-end">
            <div class="modal-header">
                <h5 class="d-inline-block text-start mb-0" style="width: 42rem">{{$t('form.split')}}</h5> 
                <button class="border-0 bg-white d-inline"><i class="fa fa-close"></i></button>
            </div>
            <div class="modal-body">
                <FormItem type="number" :label="$t('operations.split_prompt', [unitSplitData.unit.quantity - 1])" v-model="unitSplitData.amount" />
                <button class="btn btn-success" :disabled="!unitSplitData.amount || Number(unitSplitData.amount) > (unitSplitData.unit.quantity - 1)" @click="splitUnit">{{ $t('form.save') }}</button>
            </div>
        </form>
    </dialog>
    <dialog id="confirm-dialog" class="border rounded p-0" style="width: 40rem">
        <form method="dialog">
            <div class="modal-header">
                <h5 class="d-inline-block text-start mb-0" style="width: 42rem">{{$t('operations.booking_' + dialogMode)}}</h5> 
                <button class="border-0 bg-white d-inline"><i class="fa fa-close"></i></button>
            </div>
            <div class="modal-body">
                <div>{{ $t('operations.warning_' + dialogMode) }}</div>
                <FormItem type="checkbox" id="proceed-cb" :label="$t('operations.warning_proceed')" v-model="hasProceedChecked" />
                <button class="btn btn-success float-end mt-2" @click="$parent.proceed">{{ $t('form.proceed') }}</button>
            </div>
        </form>
    </dialog>
</template>

<script>
    import voyageService from "@/services/VoyageService";
    import makeService from "@/services/MakeService";
    import modelService from "@/services/ModelService";
    import AddmanualVoyageComponentVue from '@/components/voyage/VoyageFormComponent.vue';
    import JobUnitService from '@/services/JobUnitService';
    import JobService from '@/services/JobService';

    export default {
        props: ['jobItem', 'commodities', 'colours', 'hscodes', 'ports', 'customers', 'isCreatingNew', 'commoditiesById', 'userCustomer'],
        name: 'JobCreateBookingForm',
        components: {
            AddmanualVoyageComponentVue
        },
        computed: {
            job: {
                get() { return this.jobItem }
            }
        },
        watch: {
            jobItem: {
                deep: true,
                handler(jobItem) { this.$emit('contractcommoditychange', jobItem) }
            }
        },
        data() {
            return {
                unitCount: 0,
                selectedUnits:[],
                licensePlateRequired: false,
                selectedItinerary: null,
                bookingVoyageInfo: [],
                searchingVoyages: false,
                searchingVoyagesTransshipment: false,
                canGoToStepTwo: false,
                canGoToStepThree: false,
                canGoToStepFour: false,
                customVoyage: false,
                addingUnits: false,
                changingPorts: false,
                voyagesTransshipment: [],
                filteredVoyages: {direct: [], transshipment: []},
                filteredVoyagesTransshipment: null,
                sortOptionTransshipment: 1,
                sortOptions: [{id: 1, name: "Pol date"}, {id: 2, name: "Transit time"}, {id: 3, name: "Transshipment days"}],
                preferredVoyageIds: [],
                carrierLogos: {},
                unitSplitData: {unit: {quantity: 0}, index: 0, amount: null},
                nextStep: 2,
                loaded: false,
                dialogMode: null,
                hasProceedChecked: 0,
                makeService: makeService,
                modelService: modelService
            }
        },
        methods: {
            reset(){
                this.unitCount =  0;
                this.selectedUnits = [];
                this.licensePlateRequired = false;
                this.selectedItinerary = null;
                this.searchingVoyages = false;
                this.canGoToStepTwo = false;
                this.canGoToStepThree = false;
                this.canGoToStepFour = false;
                this.customVoyage = false;
                this.filteredVoyages = {direct: [], transshipment: []};
                this.preferredVoyageIds = [];
                this.nextStep = 2;
                this.loaded = false;
                this.hasProceedChecked = 0;
                this.manualChosen = false;
                this.addingUnits = false;
                this.changingPorts = false;
            },
            setManualVoyageForm(active){
                this.customVoyage = active;
                const ref = this.$refs.addmanvoyageform;
                ref.formActive = active;
                if(active){
                    ref.reset();
                }
            },
            setMode(mode){
                this.reset();
                this.loaded = true;
                if(mode == 'mode_add_unit') {
                    this.addingUnits = true;
                    this.suggestNewJobUnit();
                    this.canGoToStepTwo = true;
                    this.canGoToStepThree = true;
                    window.setTimeout(() =>  this.validateTabOne(), 200);
                }
                else if(mode == 'mode_change_ports'){
                    this.changingPorts = true;
                    this.job.port_of_loading_id = null;
                    this.job.port_of_discharge_id = null;
                }
            },
            addToSelectedUnits(e, unit){
                if(e.target.checked){
                    this.selectedUnits.push(unit);
                    this.unitCount += unit.quantity;
                }
                else{
                    let id = this.selectedUnits.findIndex(aUnit => aUnit.id == unit.id);
                    this.selectedUnits.splice(id, 1);
                    this.unitCount -= unit.quantity;
                }
                window.setTimeout(() =>  this.validateTabOne(), 200);
            },
            showSplitUnitBox(unit, index){
                document.getElementById("split-dialog").showModal();
                this.unitSplitData = {unit: unit, index: index, amount: null};
            },
            splitUnit(){
                const newUnit = this.$parent.hardCopy(this.unitSplitData.unit);
                newUnit.quantity = newUnit.quantity - this.unitSplitData.amount;
                newUnit.chassis_number = newUnit.chassis_number.split('\n').slice(this.unitSplitData.amount).join('\n');
                newUnit.license_plate = newUnit.license_plate ? newUnit.license_plate.split('\n').slice(this.unitSplitData.amount).join('\n') : '';
                this.job.units[this.unitSplitData.index].quantity = this.unitSplitData.amount;
                this.job.units[this.unitSplitData.index].chassis_number = this.unitSplitData.unit.chassis_number.split('\n').slice(0, this.unitSplitData.amount).join('\n');
                this.job.units[this.unitSplitData.index].license_plate = this.unitSplitData.unit.license_plate ? this.unitSplitData.unit.license_plate.split('\n').slice(0, this.unitSplitData.amount).join('\n') : '';
                this.job.units[this.unitSplitData.index].is_split = true;
                delete this.job.units[this.unitSplitData.index].id;
                newUnit.checked = 0;
                this.job.units.push(newUnit);
                document.getElementById("split-dialog").close();
                window.setTimeout(() =>  this.validateTabOne(), 200);
            },
            checkArray(field, array, length){
                let result = true;
                const errorText = field.nextElementSibling.children[1];
                const clone = new Set(array);
                if(clone.size !== array.length){
                    const duplicates = array.filter(item => {
                        if (clone.has(item))
                            clone.delete(item);
                        else
                            return item;
                    });
                    errorText.innerHTML = this.$t('errors.duplicate_found') + ' ' + (duplicates[0] ? duplicates[0].toUpperCase() : '');
                    return false; //Duplicates found
                }
                const cId = field.parentElement.previousElementSibling.previousElementSibling.previousElementSibling.previousElementSibling.children[0].children[0].children[0].getAttribute('data-value');
                if(array.length == length){
                    for(let item of array){
                        if(!item){
                            result = false;
                            errorText.innerHTML = this.$t('errors.not_enough_entries');
                        }
                        else if(cId && this.commoditiesById[cId] && (this.commoditiesById[cId].name == "High & Heavy" || this.commoditiesById[cId].commodity_group_id == 4)){
                            result = true;
                        }
                        else if(item.length != 17){ 
                            result = false;
                            errorText.innerHTML = "Invalid chassis number: " + item;
                        }
                    }
                }
                else{
                    errorText.innerHTML = this.$t('errors.not_enough_entries')
                    result = false;
                }
                return result;
            },
            checkChassisNr(e){
                if(e.commodity_id && this.commoditiesById[e.commodity_id] && (this.commoditiesById[e.commodity_id].name == "High & Heavy" || this.commoditiesById[e.commodity_id].commodity_group_id == 4)) return "#fefefe";
                const chassisArray = e.chassis_number ? e.chassis_number.split('\n') : [''];
                let result = "#fff";
                for(let chassis of chassisArray){
                    const length = chassis ? chassis.replaceAll('\r', '').length : 0;
                    if(length == 17) result = "#dfd";
                    else if(length > 17) return "#fdd";
                    else return "#fff";
                }
                return result;
            },
            deleteSeaTransportline(index) {
                if(confirm("Are you sure you want to delete this freight?")) {
                    this.job.units.splice(index, 1);
                    if(this.isCreatingNew)
                        this.$nextTick(() => this.validateTabOne());
                }
            },
            disableFor(step){
                if(step == 2) return !this.canGoToStepTwo;
                if(step == 3) return !this.canGoToStepTwo || !this.canGoToStepThree;
                return !this.canGoToStepTwo || !this.canGoToStepThree || !this.canGoToStepFour;
            },
            findCarrier(voyageInfo){
                const carrier = this.job.carriers.find(carr => carr.pivot.carrier_id == voyageInfo.carrier_id);
                return carrier === undefined ? '-' : carrier.relation.name;
            },
            findDateForPort(ports, dateType){
                try{
                    let portId = dateType == 'date_arrival' ? (this.job.port_of_discharge.pivot ? this.job.port_of_discharge.pivot.id : this.job.port_of_discharge.id) : this.job.port_of_loading.id;
                    let port = ports.find(port => port.id == portId);
                    const date = port.pivot[dateType] ? port.pivot[dateType] : port.pivot['date_arrival'];
                    return date;
                }
                catch(e){
                    return "";
                }
            },
            getVoyagesTransshipment(steps = 2){
                if(this.searchingVoyages) return;
                
                this.searchingVoyagesTransshipment = true;
                this.filteredVoyagesTransshipment = [];
                voyageService.searchVoyagesTransshipment({"port_of_discharge_id": this.job.port_of_discharge.id, "port_of_loading_ids": [this.job.port_of_loading.id], "segments": steps}).then(response => {
                    this.voyagesTransshipment = [];
                        response.data.voyages.forEach(v => {
                            v.routes.forEach(r => {
                                r.port_of_loading_id = v.port_of_loading_id;
                                r.port_of_discharge_id = v.port_of_discharge_id;
                                this.voyagesTransshipment.push(r);
                            })
                        })
                    this.filterVoyagesTransshipment(steps);
                    this.carrierLogos = {};
                    this.$parent.relationService.index(null,null,'carrier').then(response => {
                        response.data.forEach(carr => this.carrierLogos[carr.name] = carr.logo);
                    }).catch(error => console.log('error', error) );
                }).catch(error => console.log('error', error)
                ).finally(e => {
                    this.$nextTick(() => {
                        this.searchingVoyagesTransshipment = false;
                        this.searchingVoyageId = null;
                    });
                });
            },
            getVoyagesMultiple(portsAndCarriers, returnData = false) {
                this.searchingVoyages = true;
                return voyageService.searchVoyages({"port_of_discharge_id": this.job.port_of_discharge.id, "port_of_loading_ids": [this.job.port_of_loading.id]}).then(response => {
                    let directVoyages = [];
                    let alreadyChosen = [];
                    this.jobItem.bookings.forEach(booking => {
                        booking.voyages.forEach(voyage => {
                            alreadyChosen.push(voyage.id);
                        });
                    });
                    let voyageSet = [];
                    for(let bundle of response.data.voyages[0].routes){
                        voyageSet.push(bundle.segments[0]);
                    }
                    voyageSet.forEach(set => {
                        if(alreadyChosen.includes(set.id)) set.voyage_status_id = -1;
                    });
                    directVoyages = directVoyages.concat( voyageSet.filter(set => !(set.id in alreadyChosen)) );

                    this.filteredVoyages = directVoyages;
                    this.filteredVoyages.forEach(thisvoyage => {
                        if(thisvoyage.voyage_status_id == 1)
                            thisvoyage.voyage_status_id = this.setVoyageStatus(thisvoyage);
                    });
                    this.filteredVoyages.sort((a,b) => { return a.pol_date > b.pol_date });

                    if(this.jobItem.quote && this.jobItem.quote.quote_routes && this.jobItem.quote.quote_routes[0] && this.jobItem.quote.quote_routes[0].quote_route_segments.length > 1){
                        this.getVoyagesTransshipment(returnData);
                    }
                    if(returnData){
                        this.$parent.$refs.changeVoyagePopup.resetWithJob(this.$parent.job, this.filteredVoyages, this.$parent.$refs.unitdetails.voyageList);
                    }
                }).catch(error => console.log('error', error)
                ).finally(e => this.$nextTick(() => this.searchingVoyages = false ))
            },
            filterVoyagesTransshipment(steps) {
                this.filteredVoyagesTransshipment = [];
                let pol = this.job.port_of_loading.id;
                let pod = this.job.port_of_discharge.id;
                let filteredVoyages = this.voyagesTransshipment.filter(row => row.port_of_loading_id == pol);
                for(let i = 0; i < filteredVoyages.length; i++){
                    if(filteredVoyages[i].segments.length > 1){
                        let data = {};
                        let data1 = filteredVoyages[i].segments[0];
                        let data2 = filteredVoyages[i].segments[1];
                        data.id_string = (data1.id + '_' + pol + '_' + data1.pod_id) + '-' + (data2.id + '_' + data2.pol_id + '_' + pod);
                        data.id_object = {
                            "voyage_id": data1.id,
                            "port_of_loading_id": data1.pol_id,
                            "port_of_discharge_id": data2.pod_id,
                            "quote_route_segments": [{
                                "voyage_id": data1.id,
                                "port_of_loading_id": data1.pol_id,
                                "port_of_discharge_id": data1.pod_id,
                                "date_arr": data1.pol_date,
                                "date_dep": data1.pod_date,
                                "name": data1.vessel + ' (' + data1.number + ')'
                            },{
                                "voyage_id": data2.id,
                                "port_of_loading_id": data2.pol_id,
                                "port_of_discharge_id": data2.pod_id,
                                "date_arr": data2.pol_date,
                                "date_dep": data2.pod_date,
                                "name": data2.vessel + ' (' + data2.number + ')'
                            }]
                        };
                        data.pol_name = data1.pol_name,
                        data.tport_name = data1.pod_code,
                        data.pod_name = data2.pod_name,
                        data.carrier_1 = data1.carrier,
                        data.carrier_2 = data2.carrier,
                        data.number_1 = data1.number,
                        data.number_2 = data2.number,
                        data.vessel_1 = data1.vessel,
                        data.vessel_2 = data2.vessel,
                        data.pol_date = data1.pol_date,
                        data.pod_date = data2.pod_date;
                        data.trans_time = Math.round(Math.abs(new Date(data2.pol_date) - new Date(data1.pod_date)) / (24 * 60 * 60 * 1000));
                        data.transit_time = Math.abs(new Date(data2.pod_date) - new Date(data1.pol_date));
                        this.filteredVoyagesTransshipment.push(data);
                    }
                }
            },
            setVoyageStatus(voyage){
                let newStatus = 1;
                if(!voyage.voyage_port_commodity_statuses) return newStatus;
                voyage.voyage_port_commodity_statuses.forEach(status => {
                    if(status.port_of_loading_id == this.job.port_of_loading_id && status.port_of_discharge_id == this.job.port_of_discharge_id){
                        this.selectedUnits.forEach(unit => {
                            if(unit.commodity.commodity_group_id == status.commodity_id){
                                newStatus = status.status != 'open' ? 2 : 1;
                                return;
                            }
                        });
                    }
                });
                return newStatus;
            },
            setItinerary(info, isTransshipment = null){
                if(isTransshipment){
                    this.bookingVoyageInfo = [];
                    info.id_object.quote_route_segments.forEach(port => {
                        this.bookingVoyageInfo.push({
                            id: port.voyage_id,
                            pol_id: port.port_of_loading_id,
                            pod_id: port.port_of_discharge_id,
                            date: port.date_arr,
                            date_arr: port.date_arr,
                            date_dest: port.date_dep,
                            name: port.name
                        });
                    });
                }
                else{
                    this.bookingVoyageInfo = [{
                        id: info.id,
                        pol_id: this.job.port_of_loading_id,
                        pod_id: this.job.port_of_discharge_id,
                        date: info.pod_date,
                        date_arr: info.pol_date,
                        date_dest: info.pod_date,
                        name: info.vessel + ' (' + info.number + ')'
                    }];
                }
                this.canGoToStepThree = true;
                this.setNextButtonDisabled();
            },
            setLicensePlateRequired(){
                this.licensePlateRequired = this.checkIfLicensePlateIsRequired([{id: this.job.port_of_loading.id}], [{id: this.job.port_of_discharge.id}], this.ports);
            },
            setNextButtonDisabled(){
                this.loaded = true;
                let nextButton = document.getElementById('newsavebuttons');
                nextButton.setAttribute('class', (!this.canGoToStepTwo || !this.canGoToStepThree || ((this.isCreatingNew || this.addingUnits) && !this.canGoToStepFour)) ? 'disabled' : '');
            },
            setNextStep(e){
                let nextStep = Number(e.target.innerHTML[0]) + 1;
                this.nextStep = nextStep;
                let amountOfUnits = 0;
                this.selectedUnits.forEach(unit => amountOfUnits += unit.quantity);

                if(this.isCreatingNew && this.job.units.length == 0)
                    this.suggestNewJobUnit();

                const saveButton = document.getElementById("savebutton");
                const saveBookingButton = document.getElementById("confirmorrequest");
                const lastTab = this.isCreatingNew ? 5 : 4;
                saveButton.style.display = (this.isEditing) ? 'block' : 'none';
                saveBookingButton.style.display = (nextStep == lastTab || this.isEditing) ? 'block' : 'none';
                this.setNextButtonDisabled();
            },
            validateTabOne(e, index = 0){
                const reqfields = this.isCreatingNew ? "#addunits .form-control[required], #addunits .form-select[required]" : "#collapse-1 .form-control[required]";
                const required = document.querySelectorAll(reqfields);
                this.$nextTick(() => {
                    if(this.isCreatingNew || this.addingUnits)
                        this.canGoToStepFour = this.job.units.length > 0;
                    else
                        this.canGoToStepTwo = this.selectedUnits.length > 0;
                    required.forEach((field) => this.validateField(field, index));
                    this.setNextButtonDisabled();
                });
            },
            validateField(field, index = 0){
                let quantity = 0;
                let isTextField = field.tagName == "TEXTAREA";
                if(isTextField) {
                    field.value = field.value.replace("\r\n", "\n");
                    field.value = field.value.replace("\n\r", "\n");
                    field.value = field.value.replace("\r", "\n");
                    quantity = Number(field.parentElement.parentElement.children[1].children[0].children[0].children[0].value);
                }
                let check = isTextField ? this.checkArray(field, field.value.split("\n"), quantity) : (field.value && field.value != this.$t('form.select'));
                if(field.classList.contains('no-value'))
                    check = false;
                if(field.style.backgroundColor && field.style.backgroundColor != 'rgb(221, 255, 221)' && field.style.backgroundColor != 'rgb(254, 254, 254)'){
                    check = false;
                }
                if(!check){
                    field.classList.add("is-invalid");
                    if(this.isCreatingNew || this.addingUnits)
                        this.canGoToStepFour = false;
                    else
                        this.canGoToStepTwo = false;
                }
                else
                    field.classList.remove("is-invalid");
            },

            setCustomerData(id, name, type){
                this.job[type] = {id: id, name: name};
                if(this.job.customer_id && this.job.port_of_loading_id && this.job.port_of_discharge_id){
                    let inputArray = [{"port_of_loading_id": this.job.port_of_loading.id, "carrier_id": null}];
                    this.setLicensePlateRequired();
                    this.filteredVoyagesTransshipment = null;
                    this.getVoyagesMultiple(inputArray);
                    this.canGoToStepTwo = true;
                }
                else{
                    this.canGoToStepTwo = false;
                }
                this.setNextButtonDisabled();
            },
            suggestNewJobUnit(){
                let unit = {
                    'quantity': 1,
                    'description': null,
                    'chassis_number': '',
                    'booked_measurement': { 'length': null, 'width': null, 'height': null, 'weight': null },
                    'unit_make_id': null,
                    'unit_model_id': null,
                    'construction_year': null,
                    'colour_id': null,
                    'hs_code': null,
                    'license_plate': null,
                    'sales_currency_id': window.defaultCurrency,
                    'sales_price': '0',
                    'buying_currency_id': window.defaultCurrency,
                    'buying_price': '0',
                    'commodity_id': null,
                    'condition_id': '2',
                    'contract_id': null,
                    'tariff_id': null,
                    'surcharge_id': null,
                    'group_id': 1,
                    'stacked_units': []
                };
                let newindex = 0;
                this.job.units.forEach(u => newindex = u.order_by > newindex ? u.order_by : newindex);
                newindex++;
                unit.order_by = newindex;
                this.job.units.push(unit);
                this.$nextTick(() => this.validateTabOne());
            },
            setMakeModel(value, item){
                item.description = value ? null : '';
            },
            addStackedUnit(index, stackingType = 'stacked'){
                let newindex = 0;
                this.job.units[index].stacked_units.forEach(su => newindex = su.order_by > newindex && su.stacking_type == stackingType ? su.order_by : newindex);
                newindex++;
                if(stackingType == 'connected') newindex = 100;
                if(stackingType == 'stacked-connected') newindex += 100;
                this.job.units[index].stacked_units.push({
                    'quantity': '1',
                    'description': null,
                    'chassis_number': null,
                    'unit_make_id': null,
                    'unit_model_id': null,
                    'construction_year': null,
                    'colour_id': null,
                    'hs_code': null,
                    'license_plate': null,
                    'commodity_id': '',
                    'condition_id': '2',
                    'order_by': newindex,
                    'stacking_type': stackingType
                });
                this.job.units[index].stacked_units.sort((a, b) => a.order_by > b.order_by ? 1 : -1);
                console.log(this.job.units[index].stacked_units);
                this.$nextTick(() => this.validateTabOne());
            },
            deleteStackedUnit(unit, index) {
                if(confirm("Are you sure you want to delete this freight?")) {
                    unit.stacked_units.splice(index, 1);
                    if(this.isCreatingNew){
                        this.$nextTick(() => this.validateTabOne());
                    }
                }
            },
            
            setMM(unit, name, type){
                unit[type + 'Name'] = name;
                if(type == 'make') unit.unit_model_id = null;
            },
            getMM(unit, type){
                return unit['unit_' + type + '_id'] ? (unit[type + 'Name'] ? unit[type + 'Name'] : (unit[type] ? unit[type].name : '')) : '';
            },
            setSelectedUnits(item){
                this.selectedUnits.push(item);
                this.unitCount += item.quantity;
            },
            sortTransshipmentVoyages(){
                this.filteredVoyagesTransshipment.sort((a, b) => this.sortTransshipments(this.sortOptionTransshipment, a, b));
            },
            getBookingInfo(){
                return {
                    "description": "optional",
                    "units": this.$parent.hardCopy(this.selectedUnits),
                    "voyages":  this.bookingVoyageInfo
                };
            },
            setCarrierRef(){
                const pbcb = document.getElementById("newsavebuttons");
                pbcb.setAttribute('class', this.job.pending_booking.carrier_reference ? '' : 'disabled');
            },
            checkForDupes(unit, isForPending = false){
                const els = document.querySelectorAll('.tab-pane.active input[data-id], .tab-pane.active textarea[data-id]');
                const comparative = isForPending ? 'id' : 'order_by';
                const numbersAlreadyChecked = [];
                for(let i = els.length - 1; i >= 0; i--) {
                    const el = els[i];
                    let thisChassisNumbers = el.value.toUpperCase();
                    let identifier = Number(el.getAttribute('data-id'));
                    let matchedUnit = null, originalUnit = null, matchedNumber = '';
                    unitDuplicateFinder:
                    for(let j = els.length - 1; j >= 0; j--) {
                        let jobUnit = this.job.units.find(u => u[comparative] == Number(els[j].getAttribute('data-id')));
                        if(!jobUnit || !jobUnit.chassis_number) continue;
                        let chassisNumbers = jobUnit.chassis_number.toUpperCase().split('\n');
                        for(let chassis of chassisNumbers){
                            if(numbersAlreadyChecked.includes(chassis)) continue;
                            if(jobUnit[comparative] == identifier){
                                originalUnit = jobUnit;
                            }
                            else if(chassis && thisChassisNumbers.includes(chassis)){
                                matchedUnit = jobUnit;
                                matchedNumber = chassis;
                                numbersAlreadyChecked.push(chassis);
                            }
                            if(originalUnit && matchedUnit) break unitDuplicateFinder;
                        }
                    }
                    if(originalUnit)
                        originalUnit.duplicate = matchedUnit ? {by: (matchedUnit.make && matchedUnit.model ? (matchedUnit.make + ' ' + matchedUnit.model) : 'a unit above'), number: matchedNumber} : null;
                }
                if(!unit.chassis_number || unit.duplicate){
                    return;
                }
                JobUnitService.checkForDuplicates(unit.id, unit.chassis_number.toUpperCase()).then(response => unit.duplicate = response.data);
            },

            updateBooking(){
                const booking = this.$parent.job.bookings[this.$parent.selectedBooking].id;
                const afterLoading = function(data, parent){
                    parent.reset();
                    parent.setData(data);
                    parent.$parent.getIndex();
                }
                if(this.addingUnits){
                    const firstBLID = this.$parent.job.bookings[this.$parent.selectedBooking].bills_of_lading[0] ? this.$parent.job.bookings[this.$parent.selectedBooking].bills_of_lading[0].id : null;
                    this.job.units.forEach(unit => unit.bill_of_lading_id = firstBLID);
                    return JobService.addUnitsToBooking(booking, this.job.units).then(response => afterLoading(response.data, this.$parent));
                }
                else if(this.changingPorts)
                    return JobService.changePorts(booking, this.job, this.bookingVoyageInfo).then(response => afterLoading(response.data, this.$parent));
            },
            cancel(){
                this.addingUnits = false;
                this.changingPorts = false;
                this.job.units = this.job.units.filter(unit => unit.id !== undefined);
                document.querySelector("#jobTab > .nav-item:nth-child("+(this.$parent.selectedBooking + 1)+") > .btn").click();
            }
        }
    }
</script>

<style scoped>
    .col-sm-half {flex: 0 0 auto; width: 5%}
    .input-group select {background-color: #eee}
    .col-sm-1p5 {flex: 0 0 auto; width: 11%}
    .highlight-on-hover:hover{ background-color: #eee }
    .transport-unit-table.row{
        --bs-gutter-x: 0.5rem !important;
        margin-bottom: 5px
    }
    .transport-unit-table .col-sm-1{
        width: 8% !important;
        height: 28px
    }
    .transport-unit-table .col-sm-2{
        width: 20% !important
    }
    .transport-unit-table textarea{
        position: absolute;
        max-height: 100px !important
    }
    .transport-unit-table .input-group > div {
        font-size: 0.75rem;
        padding: 0.3rem
    }
    .vin-lp{ width: 12% !important }
    .vin-lp textarea{ width: 11.5% !important }
    .vin-only{ width: 19% !important }
    .vin-only textarea{ width: 18.5% !important }
    .is-invalid ~ .invalid-feedback, .duplicate-text{
        position: absolute;
        width: 440px;
        margin-top: 34px;
        background-color: #fff
    }
    .vin-lp .invalid-feedback.one-license-plate{
        margin-left: 20% !important
    }
    .license-column .invalid-feedback.multi-license-plate{
        margin-left: 8.2% !important
    }
    .vin-only .invalid-feedback, .duplicate-text{
        margin-left: 19.25% !important
    }
    .license-column textarea{
        width: 7.75%
    }

    .col-sm-xxs{
        flex: 0 0 auto;
        width: 26px
    }
    .col-sm-xs{
        flex: 0 0 auto;
        width: 70px
    }
    .row[data-featured="true"]{
        background-color: #def;
    }
    .row[data-featured="false"] i{
        display: none;
    }
    .loading-block{
      width: 100%;
      height: 10rem;
      background-color: #eee;
      display: flex;
      justify-content: center;
      align-items: center
    }
    .border-left{
        border-left: 4px solid gray;
    }
</style>