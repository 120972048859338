import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/carrier'

class CarrierService extends ApiService {
    constructor(){
        super()
        super.hasRelation = true;
    }
    getUrl(){
        return URL
    }
    getViaPorts(id){
        return http.get(`${URL}/via-ports/${id}`)
    }
}

export default new CarrierService()