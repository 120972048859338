import {ApiService} from '@/services/ApiService'

const URL = '/api/legacyregion'

class LegacyservicesService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    getLegacyservices() {
        return [
            {id: 13, name: 'Africa to Europe'},
            {id: 37, name: 'Africa to Far East'},
            {id: 107, name: 'Africa to Mediterranean Sea'},
            {id: 105, name: 'Africa to Middle East'},
            {id: 39, name: 'Africa to North America'},
            {id: 45, name: 'Africa to Oceania'},
            {id: 43, name: 'Africa to South America'},
            {id: 133, name: 'Caribbean to Europe'},
            {id: 135, name: 'Caribbean to Mediterranean Sea'},
            {id: 137, name: 'Caribbean to North America'},
            {id: 125, name: 'East-Mediterranean to Africa'},
            {id: 121, name: 'East-Mediterranean to Caribbean'},
            {id: 153, name: 'East-Mediterranean to Europe'},
            {id: 129, name: 'East-Mediterranean to Far East'},
            {id: 127, name: 'East-Mediterranean to Middle East'},
            {id: 117, name: 'East-Mediterranean to North America'},
            {id: 131, name: 'East-Mediterranean to Oceania'},
            {id: 119, name: 'East-Mediterranean to South America'},
            {id: 147, name: 'Europe Short Sea Atlantic Sea'},
            {id: 145, name: 'Europe Short Sea Baltic Sea'},
            {id: 151, name: 'Europe Short Sea Black Sea'},
            {id: 162, name: 'Europe Short Sea Mediterranean Sea'},
            {id: 143, name: 'Europe Short Sea North Sea'},
            {id: 3, name: 'Europe to Africa'},
            {id: 35, name: 'Europe to Africa  (Grimaldi-MSC)'},
            {id: 61, name: 'Europe to Africa (Hoegh-WWL)'},
            {id: 152, name: 'Europe to Africa (Sallaum-Niledutch)'},
            {id: 103, name: 'Europe to Caribbean'},
            {id: 141, name: 'Europe to Far East'},
            {id: 163, name: 'Europe to Mediterranean'},
            {id: 2, name: 'Europe to Middle and Far East'},
            {id: 123, name: 'Europe to Middle East'},
            {id: 33, name: 'Europe to Middle East (Hoegh, Bahri))'},
            {id: 4, name: 'Europe to North America'},
            {id: 154, name: 'Europe to North America (NMT)'},
            {id: 17, name: 'Europe to Oceania'},
            {id: 9, name: 'Europe to South America'},
            {id: 27, name: 'Far East to Africa'},
            {id: 21, name: 'Far East to Europe'},
            {id: 115, name: 'Far East to Mediterranean Sea'},
            {id: 19, name: 'Far East to Middle East'},
            {id: 23, name: 'Far East to North America'},
            {id: 53, name: 'Far East to Oceania'},
            {id: 25, name: 'Far East to South America'},
            {id: 11, name: 'India to Gulf'},
            {id: 164, name: 'Mediterranean to Europe (NMT)'},
            {id: 91, name: 'Middle East to Africa'},
            {id: 89, name: 'Middle East to Europe'},
            {id: 97, name: 'Middle East to Far East'},
            {id: 113, name: 'Middle East to Mediterranean Sea'},
            {id: 93, name: 'Middle East to North America'},
            {id: 99, name: 'Middle East to Oceania'},
            {id: 95, name: 'Middle East to South America'},
            {id: 8, name: 'North America to Africa'},
            {id: 139, name: 'North America to Caribbean'},
            {id: 6, name: 'North America to Europe'},
            {id: 49, name: 'North America to Far East'},
            {id: 111, name: 'North America to Mediterranean Sea'},
            {id: 7, name: 'North America to Middle East'},
            {id: 47, name: 'North America to Oceania'},
            {id: 41, name: 'North America to South America'},
            {id: 71, name: 'Oceania to Africa'},
            {id: 73, name: 'Oceania to Europe'},
            {id: 67, name: 'Oceania to Far East'},
            {id: 109, name: 'Oceania to Mediterranean Sea'},
            {id: 69, name: 'Oceania to Middle East'},
            {id: 63, name: 'Oceania to North America'},
            {id: 65, name: 'Oceania to South America'},
            {id: 51, name: 'Short Sea Caribbean'},
            {id: 31, name: 'Short Sea Europe'},
            {id: 85, name: 'Short Sea Far East'},
            {id: 87, name: 'Short Sea Middle East'},
            {id: 55, name: 'Short Sea North America'},
            {id: 29, name: 'Short Sea Oceania'},
            {id: 75, name: 'South America to Africa'},
            {id: 77, name: 'South America to Europe'},
            {id: 81, name: 'South America to Far East'},
            {id: 101, name: 'South America to Mediterranean Sea'},
            {id: 83, name: 'South America to Middle East'},
            {id: 59, name: 'South America to North America'},
            {id: 79, name: 'South America to Oceania'},
            {id: 155, name: 'West-Mediterranean to Africa'},
            {id: 156, name: 'West-Mediterranean to Caribbean'},
            {id: 149, name: 'West-Mediterranean to Europe'},
            {id: 157, name: 'West-Mediterranean to Far East'},
            {id: 158, name: 'West-Mediterranean to Middle East'},
            {id: 159, name: 'West-Mediterranean to North America'},
            {id: 160, name: 'West-Mediterranean to Oceania'},
            {id: 161, name: 'West-Mediterranean to South America'}


        ]
    }
}

export default new LegacyservicesService()