import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/customer'

class CustomerService extends ApiService {
    constructor(){
        super()
        super.hasRelation = true;
    }
    getUrl(){
        return URL
    }
    getDefaultShipper(id){
        return http.get(URL + `/get-default-shipper/${id}`)
    }
}

export default new CustomerService()