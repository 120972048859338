import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/country'

class CountryService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    indexForBL() {
        return http.get(`${this.getUrl()}` + '?forBL=true')
    }
}

export default new CountryService()