<template v-if="formActive">
    <ul class="nav">
        <li class="nav-item" role="presentation">
            <button class="nav-link d-none active" id="main-inv-tab" data-bs-toggle="tab" data-bs-target="#main" type="button" role="tab" aria-controls="main" aria-selected="true"></button>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent4">
        <div class="tab-pane fade show active" id="main" role="tabpanel" aria-labelledby="main-tab">
        <FormItem id="commodity_group_id" :label="`Commodity group`" v-model="model.commodity_group_id" type="select" :options="commodityGroups" :required="true" />
        <FormItem id="unit_make_id" :label="`Make`" v-model="model.unit_make_id" type="select" :options="makes" :required="true" />
        <FormItem id="name" :label="$t('overview.name')" v-model="model.name" :required="true" />
        <FormItem id="code" label="Code" v-model="model.code" :required="true" />
        <FormItem :id="'length'" label='Length' v-model="model.unit_measurement.length" type="number-with-tags" endTag="cm" />
        <FormItem :id="'width'" label='Width' v-model="model.unit_measurement.width" type="number-with-tags" endTag="cm" />
        <FormItem :id="'height'" label='Height' v-model="model.unit_measurement.height" type="number-with-tags" endTag="cm" />
        <FormItem :id="'weight'" label='Weight' v-model="model.unit_measurement.weight" type="number-with-tags" endTag="kg" />
        </div>
        
        <Files :files="model.file_info" @addFile="addFiles" parentItemName="model" v-if="itemData" />
    </div>
</template>

<script>
    import commoditygroupservice from '@/services/CommoditygroupService'
    import makeservice from '@/services/MakeService'
    import modelservice from '@/services/ModelService'
    import fileService from "@/services/FileService"
    import Files from '@/components/formpopup/Files'

    export default {
        name: 'ModelForm',
        props: ['itemData'],
        components: {
            Files
        },
        data() {
            return {
                model: {
                    unit_make_id: null,
                    'unit_measurement': {}
                },
                commodityGroups:[],
                makes:[],
                submitted: false,
                loading: false,
                invalid: {},
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.model = val    
            },
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                let valid = true;

                let required = document.querySelectorAll('#myTabContent4 input[required],#myTabContent4 select[required]');

                for(let field of required) {
                    if(!field.value || field.value == this.$t('form.select')) {
                        field.classList.add('is-invalid');
                        valid = false;
                    }
                    else{
                        field.classList.remove('is-invalid');
                    }
                }
                if(valid) valid = this.model.name && this.model.code;
                if(!valid) alert(this.$t('errors.empty_fields'));
                return valid;
            },
            getData() {
                return this.model;
            },
            setData(data) {
                this.model = data
            },
            reset() {
                this.model = {
                    'file_info': [],
                    'unit_make_id': null,
                    'commodity_group_id': null,
                    'unit_measurement': {
                        'length': null,
                        'width': null,
                        'height': null,
                        'weight': null
                    }
                }
                this.loading = false
                this.invalid = {}
                this.getMakes();
                this.getCommodityGroups();
            },
            getMakes() {
                makeservice.index().then(response => {
                    this.makes = response.data
                }).catch(error => {
                    console.log('error', error)
                })
            },
            getCommodityGroups() {
                commoditygroupservice.index().then(response => {
                    this.commodityGroups = response.data
                }).catch(error => {
                    console.log('error', error)
                })
            },
            addFiles(fileInfo){
                if (this.model.id) {
                    fileService.upload({"file_info": [fileInfo],"id": this.model.id,"model": 'model'}).then(response => {
                        this.model.file_info.unshift(response.data); 
                    }).catch(error => {
                        console.log('error', error)
                    })
                } else {
                this.model.file_info.unshift(fileInfo); 
                }

            },
            submit() {
                if(this.validate()){
                    return modelservice.store(this.model).then(response => {
                        this.$parent.closeModal(response.data, 'model');
                        return true;
                    }).catch(error => {
                        console.log('error', error)
                        return false;
                    });
                }
                else{
                    return false;
                }
            }
        }
    }
</script>
