import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/surcharge'

class SurchargeService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    search(carriers, pols, pod, date){
        return http.post(`${URL}/search`, {carriers: carriers, port_of_loading_ids: pols, port_of_discharge_id: pod, date: date});
    }
}

export default new SurchargeService()