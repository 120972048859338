<template>
<h5 class="mt-4 mb-3 fw-bold" v-show="job.additional_quotes.length == 0"> {{$t('form.no_yet', [$tc('operations.additional_quotations', 2)])}} </h5>
<div class="accordion" id="contract-accordion" v-if="isEditing">
    <div v-for="(quotation, qindex) in job.additional_quotes" :key="qindex" class="accordion-item">

        <div class="accordion-header d-flex" :id="'heading-'+qindex">
            <button class="acc-header-item header-main accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+qindex" aria-expanded="true" :aria-controls="'collapse-'+qindex">
                <div v-if="quotation.number" class="d-flex w-100">
                    <div class="col-3">
                        {{$tc('operations.additional_quotations', 1) + ': ' + this.job.number + '/' + quotation.number}}
                    </div>
                </div>
                <span v-else>{{$t('sales.new_x', [$tc('operations.additional_quotations', 1)])}}</span>
            </button>
            <button type="button" class="acc-header-item header-remove-item" @click="deleteQuotationItem(qindex)"><i class="fal fa-trash-alt"></i></button>
            <button type="button" class="acc-header-item header-arrow" @click="openAccordion"></button>
        </div>

        <div class="accordion-collapse collapse show" :id="'collapse-'+qindex" :aria-labelledby="'heading-'+qindex" data-bs-parent="contract-accordion">
            <div class="accordion-body">
                <div class="row mb-2">
                    <label class="col-form-label col-form-label-sm required-label col-sm-2"> {{ $t('navigation.bookings') }} </label>
                    <div class="col-4">
                        <select class="form-select form-select-sm" v-model="quotation.job_booking_id" :required="true">
                            <option :value="null">{{ placeholder ? placeholder : $t('form.select') }}</option>
                            <option v-for="booking, index in job.bookings" :key="index" :value="booking.id">{{ getBookingTabName(booking) }}</option>
                        </select>
                    </div>
                </div>
                <FormItem :id="'qstatus-'+qindex" :label="$t('overview.status')" type="buttongroup" v-model="quotation.status" :options="statusOptions" sizeLabel="2" sizeItem="4" :required="true" />
                <br>
                <div class="row mb-0 pt-2 transport-unit-table" v-show="quotation.additional_quote_lines.length > 0">
                    <div class="col-sm-xs col-form-label-sm required-label">
                        {{$t('form.qt')}}
                    </div>
                    <div class="col-sm-3 col-form-label-sm required-label">
                        {{$t('form.description')}}
                    </div>
                    <div class="col-1 col-form-label-sm text-center ms-4">
                        {{$t('operations.specify_units')}}
                    </div>
                    <div class="col-sm-1p5 col-form-label-sm">
                        {{$t('navigation.vendors')}}
                    </div>
                    <div class="col-2 col-form-label-sm required-label">
                        {{$t('sales.buying_rates')}}
                    </div> 
                    <div class="col-2 col-form-label-sm ps-4 ms-1 required-label">
                        {{$t('sales.sales_rates')}}
                    </div>
                    <div class="col-1 col-form-label-sm ps-4 ms-4 required-label">
                        {{$t('overview.type')}}
                    </div>
                </div>
                <template v-for="item, itemIndex in quotation.additional_quote_lines" :key="itemIndex">
                <div class="row transport-unit-table">
                    <div class="col-sm-xs">
                        <FormItem type="number" v-model="item.quantity" :required="true" />
                    </div>
                    <div class="col-sm-3" v-if="item.description == null">
                        <FormItem type="select" v-model="item.quote_description_id" :required="true" :options="quoteDescriptions" nameField="description" />
                    </div>
                    <div class="col-sm-3" v-else>
                        <FormItem type="text" v-model="item.description" :required="true" />
                    </div>
                    <button type="button" class="btn description-toggle" @click="setQuoteDescriptionType(item)"><i class="fa fa-repeat"></i></button>
                    <div class="col-1 d-flex justify-content-center">
                        <FormItem v-model="item.add_job_units" type="checkbox" @onchange="setAQUnits(item, qindex, itemIndex)" />
                    </div>
                    <div class="col-sm-1p5">
                        <FormItem v-model="item.supplier_id" type="select" :options="suppliers" placeholder="-" />
                    </div>
                    <div class="col-1">
                        <FormItem v-model="item.purchase_currency_id" type="select" :options="currencies" nameField="code" :required="true" />
                    </div> 
                    <div class="col-1">
                        <FormItem type="number" v-model="item.purchase_price" :required="true" />
                    </div>
                    <div class="col-1">
                        <FormItem v-model="item.sales_currency_id" type="select" :options="currencies" nameField="code" :required="true" />
                    </div> 
                    <div class="col-1">
                        <FormItem type="number" v-model="item.sales_price" :required="true" />
                    </div>
                    <div class="col-sm-1p5">
                        <FormItem v-model="item.cost_type" type="select" :options="costTypes" :selectDefault="false" />
                    </div>
                    <div class="col-sm-auto" >
                        <button class="btn btn-sm float-right p-1" type="button" title="Click to delete item" @click="deleteAQItem(quotation, itemIndex)"><i class="far fa-trash-alt text-danger"></i></button>
                    </div>
                </div>
                <div v-if="item.add_job_units && quotation.job_booking_id" :style="{width: '40%', marginLeft: '70px', marginBottom: '8px'}">
                    <template v-for="unit in job.bookings.find(booking => booking.id == quotation.job_booking_id).job_unit" :key="unit.id">
                        <FormItem v-model="item.job_units" type="checkbox-with-value" :content="unit.id" :id="'quote-'+qindex+'-'+itemIndex+'-'+unit.id"
                                    :label="unit.commodity.name + ', ' + (unit.condition_id == 2 ? $t('form.used') : $t('form.new')) + ': ' + unit.make.name + ' ' + (unit.model ? unit.model.name : '') + ' (' + (unit.chassis_number ? unit.chassis_number : '') + ')'" />
                    </template>
                </div>
                </template>
                <div class="row mt-4 justify-content-end me-5">
                    <a class="btn btn-sm btn-primary" style="width: 15%" title="Click to add item" @click="addQuotationItem(quotation)">
                        <span class="fa fa-plus"></span> &nbsp;{{$t('form.add', [$t('form.item')])}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row mt-4 justify-content-center">
    <a class="btn btn-sm btn-primary w-25" title="Click to add item" @click="addQuotation()">
        <span class="fa fa-plus"></span> &nbsp;{{$t('form.add', [$tc('operations.additional_quotations', 1)])}}
    </a>
</div>
<br>
<div class="row mt-4 justify-content-center" v-show="job.additional_quotes.length > 0">
    <a class="btn btn-sm btn-success" style="width: 15%" title="Click to add item" @click="saveQuotation()">
        {{$t('form.save')}}
    </a>
</div>
</template>

<script>
    import jobService from '@/services/JobService';

    export default {
        props: ['jobItem', 'currencies', 'suppliers', 'quoteDescriptions', 'isEditing'],
        name: 'JobAdditionalQuotationsForm',
        computed: {
            job: {
                get() {
                    return this.jobItem
                }
            }
        },
        data() {
            return {
                aqCount: 0,
                statusOptions: [{id: "pending", name: this.$t('quote_status.pending')}, {id: "accepted", name: this.$t('quote_status.accepted')},{id: "declined", name: this.$t('quote_status.declined')}],
                costTypes: [{id: "fixed", name: this.$t('operations.cost_fixed')}, {id: "estimate", name: this.$t('operations.cost_estimate')},{id: "maximum", name: this.$t('operations.cost_maximum')}]
            }
        },
        methods: {
            hardCopy(obj){
                return JSON.parse(JSON.stringify(obj));
            },
            getBookingTabName(booking, longName = false, unitDetailsName = false){
                const voyage = booking.voyages[0] ? booking.voyages[0] : {vessel: {}};
                if(longName){
                    const nameheader = this.$t('operations.booking') + ' ' + booking.number;
                    const vesselheader = voyage.vessel.name + ' ' + voyage.number;
                    if(unitDetailsName){
                        return nameheader + " (" + vesselheader + ")";
                    }
                    return nameheader + ": " + booking.job_unit.length + ' units, ' + vesselheader + ', (' + this.job.carriers.find(carr => carr.id = voyage.carrier_id).relation.name + '), ' + 
                           this.job.port_of_loading.code + ' > ' + this.job.port_of_discharge.code;
                }
                return booking.number + ' (' + booking.job_unit.length + ' units) ' + voyage.number;
            },

            addQuotation() {
                //Close open accordion items
                let openAccordionItems = document.querySelectorAll(".accordion-button:not(.collapsed)");
                openAccordionItems.forEach(i => {i.click()});

                this.job.additional_quotes.push({
                    'number': null,
                    'status': 'pending',
                    'job_booking_id': null,
                    'additional_quote_lines': [{
                        'id': null,
                        'quantity': 1,
                        'description': null,
                        'quote_description_id': null,
                        'supplier_id': null,
                        'purchase_currency_id': window.defaultCurrency,
                        'purchase_price': '',
                        'sales_currency_id': window.defaultCurrency,
                        'sales_price': '',
                        'cost_type': 'fixed',
                        "job_units": [],
                        'add_job_units': true
                    }],
                });

            },
            addQuotationItem(quotation) {
                quotation.additional_quote_lines.push({
                    'id': null,
                    'quantity': 1,
                    'description': null,
                    'quote_description_id': null,
                    'supplier_id': null,
                    'purchase_currency_id': window.defaultCurrency,
                    'purchase_price': '',
                    'sales_currency_id': window.defaultCurrency,
                    'sales_price': '',
                    'cost_type': 'fixed',
                    "job_units": [],
                    'add_job_units': true
                });
            },
            setQuoteDescriptionType(item){
                item.description = item.description == null ? '' : null;
                if(item.description == '') item.quote_description_id = null;
            },
            deleteQuotationItem(index) {
                if(confirm("Are you sure you want to delete this quotation item?")) this.job.additional_quotes.splice(index, 1);
            },
            deleteAQItem(quotation, index){
                quotation.additional_quote_lines.splice(index, 1);
            },
            openAccordion(e){
                e.target.previousSibling.previousSibling.click();
            },
            setAQUnits(ql, id1, id2){
                const checkboxes = document.querySelectorAll('input[name="quote-'+id1+'-'+id2+'[]"]');
                checkboxes.forEach(box => box.checked = false);
                ql.job_units = [];
            },
            saveQuotation(){
                let isValid = true;
                const accordions = this.job.additional_quotes.length;
                for(let i = 0; i < accordions; i++){
                    isValid = this.validateAccordion(i);
                    if(!isValid){
                        alert(this.$t('errors.empty_fields'));
                        const accButton = document.getElementById('heading-'+i).children[0];
                        if(accButton.classList.contains('collapsed')) accButton.click();
                        return;
                    }
                }
                jobService.createOrUpdateQuotation(this.job.additional_quotes, this.job.id).then(response => {
                    this.$toast.success(this.$t('form.updated_x', [this.$t('navigation.quotations')]));
                    for(let i in this.job.additional_quotes){
                        if(this.job.additional_quotes[i].number == null){
                            this.job.additional_quotes[i].number = response.data.additional_quotes[i].number;
                            this.aqCount++;
                        }
                    }
                    this.$forceUpdate();
                    this.$nextTick(() => {
                        this.$parent.$refs.proforma.setQuoteInvoiceLines();
                    });
                }).catch(error => {
                    console.log('error', error)
                });
            },
            validateAccordion(i, showValidation = true){
                let isValid = true;
                let required = document.querySelectorAll("#collapse-"+i+" input, #collapse-"+i+" select, #collapse-"+i+" .btn-group");
                for(let field of required){
                    let value = field.value;
                    if(field.tagName == "DIV"){
                        value = field.querySelector('input:checked');
                    }
                    const emptySelect = this.$t('form.select');
                    if((field.required || field.classList.contains('required')) && (!value || value == emptySelect)){
                        if(showValidation) field.classList.add('is-invalid');
                        isValid = false;
                    }
                    else if(showValidation) {
                        field.classList.remove('is-invalid');
                    }
                }
                return isValid;
            }
        }
    }
</script>

<style scoped>
    .col-sm-1p5 {flex: 0 0 auto; width: 11%}
    .col-sm-xs{flex: 0 0 auto;  width: 70px}
    .transport-unit-table.row{
        --bs-gutter-x: 0.5rem !important;
        margin-bottom: 5px
    }
    .transport-unit-table .col-sm-1{
        width: 8% !important;
        height: 28px
    }
    .transport-unit-table .col-sm-2{
        width: 20% !important
    }
    .transport-unit-table textarea{
        position: absolute;
        max-height: 100px !important
    }
    .transport-unit-table .input-group > div {
        font-size: 0.75rem;
        padding: 0.3rem
    }
    .is-invalid ~ .invalid-feedback{
        position: absolute;
        width: 300px;
        margin-top: 34px;
    }
    .accordion-item {
        margin-bottom: 1rem;
        border: 2px solid #0d6efd;
        border-radius: 6px
    }
    .acc-header-item { border: none }
    .accordion-header { width: 100%; }
    .accordion-button::after {
        background-image: none;
        transform: none
    }
    .acc-header-item.header-main {
        width: calc(100% - 100px);
        box-shadow: none !important;
        border-radius: 0px !important;
        transition: background-color .15s
    }
    .acc-header-item.header-main.no-delete {
        width: calc(100% - 50px) !important;
    }
    .acc-header-item.header-remove-item, .acc-header-item.header-arrow {
        width: 50px;
        transition: background-color .15s
    }
    .accordion-button:not(.collapsed), .accordion-button:not(.collapsed) + .header-remove-item, .accordion-button:not(.collapsed) + * + .header-arrow {
        background-color: #0d6efd;
        color: white
    }
    .accordion-button.collapsed, .accordion-button.collapsed + .header-remove-item, .acc-header-item.collapsed + * + .header-arrow {
        background-color: white;
        color: #0d6efd;
        font-weight: bold;
    }
    .acc-header-item.header-arrow::after {
        display: block;
        content: '\276F'
    }
    .accordion-button:not(.collapsed) + * + .acc-header-item.header-arrow::after {
        rotate: 90deg
    }
    .header-main > div > div {
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 10px
    }
    .description-toggle{
        width: auto;
        padding: 2px 4px
    }
    #details .fa-xmark, #details .fa-check{
        position: relative;
        font-size: 20px;
        top: 2px;
        width: 1em
    }
    .disabled{
        background-color: #e9ecef
    }
</style>