<template>
    <template v-if="isEditing">
    <p v-show="quoteInvoiceData.length == 0"> {{$t('form.no_yet', [$tc('navigation.bills_of_lading', 2)])}} </p>
    <div class="accordion mt-3 pe-2 main-accordion" :id="'f' + bookingitemIndex + '-accordion'" v-for="bookingitem, bookingitemIndex in quoteInvoiceData" :key="bookingitemIndex">
      <div class="accordion-header d-flex" :id="'fheading-'+bookingitemIndex">
        <input type="checkbox" class="form-check-input" v-model="bookingitem.active" :true-value="1" :false-value="0" @change="setCheckbox($event, bookingitem, null)" :disabled="bookingitem.disabled">
        <h4 class="ps-2 d-inline-block mb-3">{{ bookingitem.header }}</h4>
        <span class="text-muted ps-2 pt-1">
            ({{bookingitem.bls.length + ' ' + $tc('navigation.bills_of_lading', bookingitem.bls.length)}}<template v-if="bookingitem.addcosts.length > 0">, {{bookingitem.addcosts.length + ' ' + $tc('sales.other_costs')}}</template>)
        </span>
        <div class="col"></div>
        <button type="button" data-bs-toggle="collapse" :data-bs-target="'#f'+bookingitemIndex+'-collapse'" class="acc-header-item col-sm-auto header-arrow" aria-expanded="true"></button>
      </div>
      <div class="accordion-collapse collapse show" :id="'f' + bookingitemIndex + '-collapse'" :aria-labelledby="'fheading-'+bookingitemIndex" :data-bs-parent="'#f'+bookingitemIndex+'-accordion'">
        <div class="accordion mt-3" :id="'f' + bookingitemIndex + '-acc-' + blitemIndex"  v-for="blitem, blitemIndex in bookingitem.bls" :key="blitemIndex">
          <div class="accordion-header d-flex ps-4" :id="'fheading-'+bookingitemIndex+'-'+blitemIndex">
            <input type="checkbox" class="form-check-input" v-model="blitem.active" :true-value="1" :false-value="0"  @change="setCheckbox($event, bookingitem, blitem)" :disabled="blitem.disabled">
            <p class="fw-bold d-inline-block ps-2">{{ blitem.header }}</p>
            <div class="col"></div>
            <button type="button" data-bs-toggle="collapse" :data-bs-target="'#f'+bookingitemIndex+'-collapse-'+blitemIndex" class="acc-header-item col-sm-auto header-arrow" aria-expanded="true"></button>
          </div>
          <div class="accordion-collapse ps-5 pt-2 collapse show" :id="'f' + bookingitemIndex + '-collapse-'+blitemIndex" :aria-labelledby="'fheading-'+bookingitemIndex+'-'+blitemIndex" :data-bs-parent="'#f'+bookingitemIndex+'-acc-'+'-'+blitemIndex">
            <div class="row mb-0 transport-unit-table">
                <div class="col-sm-xs col-form-label-sm required-label"> {{$t('form.qt')}} </div>
                <div class="col-sm-1 col-form-label-sm required-label"> {{$t('overview.type')}} </div>
                <div class="col-1p9 col-form-label-sm required-label"> {{$t('form.description')}} </div>
                <div class="col-sm-1 col-form-label-sm required-label"> {{$t('navigation.vendors')}} </div>
                <div class="col-sm-1p1 col-form-label-sm required-label"> {{$t('sales.buying_rates')}} </div>
                <div class="col-0p9 col-form-label-sm required-label"> {{$t('form.per')}} </div>
                <div class="col-sm-1p1 col-form-label-sm required-label"> {{$t('sales.sales_rates')}} </div>
                <div class="col-0p9 col-form-label-sm required-label"> {{$t('form.per')}} </div>
                <div class="col-sm-1p1 col-form-label-sm"> {{$t('overview.total')}} </div>
            </div>
            <div class="row transport-unit-table" v-for="item, itemIndex in blitem.units" :key="itemIndex">
                <template v-if="item.editable">
                    <div class="col-sm-xs">
                        <FormItem type="number" v-model="item.quantity" :required="true" @onchange="invoiceOnChange" />
                    </div>
                    <div class="col-sm-1 col-form-label-sm">
                        {{item.line_type == 2 ? $t('sales.sea_freight') : $t('navigation.surcharges')}}
                    </div>
                    <div class="col-1p9">
                        <input class="form-control form-control-sm" :value="item.description">
                    </div>
                    <div class="col-sm-1">
                        <FormItem v-model="item.supplier_id" type="select" :options="suppliers" placeholder="-" />
                    </div>
                    <div class="col-sm-1p1">
                        <FormItem v-model="blitem.units[itemIndex]" type="currency-and-amount" :options="currencies" nameField="code" :calcUnitFields="PCalcUnitFieldsAlternate" :required="true" @onchange="invoiceOnChange" />
                    </div>
                    <div class="col-0p9">
                        <FormItem v-model="item.buying_rate_calculation_unit_id" type="select" :options="calcUnits" :required="true" @onchange="invoiceOnChange" />
                    </div>
                    <div class="col-sm-1p1">
                        <FormItem v-model="blitem.units[itemIndex]" type="currency-and-amount" :options="currencies" nameField="code" :calcUnitFields="SCalcUnitFieldsAlternate" :required="true" @onchange="invoiceOnChange" />
                    </div>
                    <div class="col-0p9">
                        <FormItem v-model="item.sales_rate_calculation_unit_id" type="select" :options="calcUnits" :required="true" @onchange="invoiceOnChange" />
                    </div>
                </template>
                <template v-else>
                    <div class="col-sm-xs col-form-label-sm ps-2">{{item.quantity}}</div>
                    <div class="col-sm-1 col-form-label-sm">{{item.line_type == 2 ? $t('sales.sea_freight') : $t('navigation.surcharges')}}</div>
                    <div class="col-1p9 col-form-label-sm">{{item.description}}</div>
                    <div class="col-sm-1">
                        <FormItem v-model="item.supplier_id" type="select" :options="suppliers" placeholder="-" />
                    </div>
                    <div class="col-sm-1p1">
                        <div class="input-group">
                            <div class="form-control form-control-sm bg-light w-67">{{ formatCurrency(item.buying_rate) }}</div>
                            <div class="form-control form-control-sm bg-light w-33">{{ item.buying_rate_calculation_unit_id == 5 ? '%' : setCode(item.buying_rate_currency_id)  }}</div>
                        </div>
                    </div>
                    <div class="col-0p9 col-form-label-sm">
                        {{ calcUnits[item.buying_rate_calculation_unit_id - 1] ? calcUnits[item.buying_rate_calculation_unit_id - 1].name : '??' }}
                    </div>
                    <div class="col-sm-1p1">
                        <div class="input-group">
                            <div class="form-control form-control-sm bg-light w-67">{{ formatCurrency(item.sales_rate) }}</div>
                            <div class="form-control form-control-sm bg-light w-33">{{ item.sales_rate_calculation_unit_id == 5 ? '%' : setCode(item.sales_rate_currency_id)  }}</div>
                        </div>
                    </div>
                    <div class="col-0p9 col-form-label-sm">
                        {{ calcUnits[item.sales_rate_calculation_unit_id - 1] ? calcUnits[item.sales_rate_calculation_unit_id - 1].name : '??' }}
                    </div>
                </template>
                <div class="col-sm-1p1">
                    <div class="input-group total-field">
                        <div class="form-control form-control-sm bg-light w-67">{{ formatCurrency(item.sales_rate * item.quantity * item.amount) }}</div>
                        <div class="form-control form-control-sm bg-light w-33">{{ setCode(item.sales_rate_currency_id) }}</div>
                    </div>
                </div>
                <div class="col" v-html="calcMargin(item, 2, itemIndex)"></div>
            </div>
            <!--<div class="d-flex flex-row-reverse pe-4">
                <label class="ms-5 pt-1">{{ $t('operations.add_to_invoice') }}</label>
                <FormItem type="slider" v-model="bookingSave[blitemIndex]" :options="[1,0]" />
            </div>-->
          </div>
        </div>
      </div>
      <template v-if="bookingitem.addcosts.length">
        <hr />
        <input type="checkbox" class="form-check-input ms-4" v-model="bookingitem.allAddCostsActive" :true-value="1" :false-value="0"  @change="setCheckbox($event, bookingitem, null, 'addcosts')" :disabled="bookingitem.allAddCostsActiveDisabled">
        <p class="fw-bold d-inline-block ps-2">{{ $t('sales.other_costs') }}</p>
      </template>
      <div class="row ps-4" v-for="item, itemIndex in bookingitem.addcosts" :key="item.id">
        <div class="col-auto pe-0">
            <input type="checkbox" class="form-check-input" v-model="item.active" :true-value="1" :false-value="0"  @change="setCheckbox($event, bookingitem, item, 'addcosts')" :disabled="item.disabled">
        </div>
        <div class="row transport-unit-table ps-2 pe-0" style="width: calc(100% - 30px)">
        <div class="col-sm-xs col-form-label-sm ps-2">
            {{item.quantity}}
        </div>
        <div class="col-sm-1 col-form-label-sm">
            Add. quote
        </div>
        <div class="col-1p9" v-if="item.description == null">
            <FormItem type="select" v-model="item.quote_description_id" :disabled="true" :options="quoteDescriptions" nameField="description" />
        </div>
        <div class="col-1p9 col-form-label-sm" v-else>
            {{item.description}}
        </div>
        <div class="col-sm-1">
            <FormItem v-model="item.supplier_id" type="select" :options="suppliers" placeholder="-" />
        </div>
        <template v-if="item.cost_type == 'fixed'">
        <div class="col-sm-1p1">
            <div class="input-group">
                <div class="form-control form-control-sm bg-light w-67">{{ formatCurrency(item.purchase_price) }}</div>
                <div class="form-control form-control-sm bg-light w-33">{{ setCode(item.purchase_currency_id) }}</div>
            </div>
        </div>
        <div class="col-0p9 col-form-label-sm">
            {{ item.purchase_rate_unit_id ? calcUnits[item.purchase_rate_unit_id - 1].name : calcUnits[0].name }}
        </div>
        <div class="col-sm-1p1">
            <div class="input-group">
                <div class="form-control form-control-sm bg-light w-67">{{ formatCurrency(item.sales_price) }}</div>
                <div class="form-control form-control-sm bg-light w-33">{{ setCode(item.sales_currency_id) }}</div>
            </div>
        </div>
        <div class="col-0p9 col-form-label-sm">
            {{ item.sales_rate_unit_id ? calcUnits[item.sales_rate_unit_id - 1].name : calcUnits[0].name }}
        </div>
        </template>
        <template v-else>
            <div class="col-sm-1p1">
                <FormItem v-model="addQuote.additional_quote_lines[itemIndex]" type="currency-and-amount" :options="currencies" nameField="code" :calcUnitFields="PCalcUnitFields" :required="true" @onchange="invoiceOnChange" />
            </div>
            <div class="col-0p9">
                {{ item.sales_rate_unit_id ? calcUnits[item.sales_rate_unit_id - 1].name : calcUnits[0].name }}
            </div>
            <div class="col-sm-1p1">
                <FormItem v-model="addQuote.additional_quote_lines[itemIndex]" type="currency-and-amount" :options="currencies" nameField="code" :calcUnitFields="SCalcUnitFields" :required="true" @onchange="invoiceOnChange" />
            </div>
            <div class="col-0p9">
                {{ item.sales_rate_unit_id ? calcUnits[item.sales_rate_unit_id - 1].name : calcUnits[0].name }}
            </div>
        </template>
        <div class="col-sm-1p1 total-field">
            <div class="input-group">
                <div class="form-control form-control-sm bg-light w-67">{{ formatCurrency(item.sales_price * item.quantity) }}</div>
                <div class="form-control form-control-sm bg-light w-33">{{ setCode(item.sales_currency_id) }}</div>
            </div>
        </div>
        <div class="col" v-html="calcMargin(item, 4, itemIndex)"></div>
        </div>
      </div>
    </div>

    <!--<div class="row transport-unit-table" v-for="item, itemIndex in job.quote.quote_transport_lines" :key="item.id">
        <div class="col-sm-xs">
            <FormItem type="number" v-model="item.quantity" :required="true" @onchange="invoiceOnChange" />
        </div>
        <div class="col-sm-1 col-form-label-sm">
            Transport
        </div>
        <div class="col-1p9">
            <FormItem v-model="item.description" :required="true" />
        </div>
        <div class="col-sm-1">
            {{ setVendor(item) }}
            <FormItem v-model="item.supplier_id" type="select" :options="suppliers" placeholder="-" />
        </div>
        <div class="col-sm-1p1">
            <div class="input-group">
                <div class="form-control form-control-sm bg-light w-67">{{ formatCurrency(item.purchase_price) }}</div>
                <div class="form-control form-control-sm bg-light w-33">{{ setCode(item.purchase_currency_id) }}</div>
            </div>
        </div>
        <div class="col-0p9 col-form-label-sm">
            {{ item.purchase_calculation_unit_id ? calcUnits[item.purchase_calculation_unit_id - 1].name : calcUnits[0].name }}
        </div>
        <div class="col-sm-1p1">
            <div class="input-group">
                <div class="form-control form-control-sm bg-light w-67">{{ formatCurrency(item.sales_price) }}</div>
                <div class="form-control form-control-sm bg-light w-33">{{ setCode(item.sales_currency_id) }}</div>
            </div>
        </div>
        <div class="col-0p9 col-form-label-sm">
            {{ item.sales_calculation_unit_id ? calcUnits[item.sales_calculation_unit_id - 1].name : calcUnits[0].name }}
        </div>
        <div class="col-sm-1p1">
            <div class="input-group total-field">
                <div class="form-control form-control-sm bg-light w-67">{{formatCurrency(item.sales_price * item.quantity)}}</div>
                <div class="form-control form-control-sm bg-light w-33">{{ setCode(item.sales_currency_id) }}</div>
            </div>
        </div>
        <div class="col" v-html="calcMargin(item, 1, itemIndex)"></div>
    </div>
    <div class="row transport-unit-table" v-for="item, itemIndex in job.quote.quote_general_costs" :key="item.id">
        <div class="col-sm-xs">
            <FormItem type="number" v-model="item.quantity" :required="true" @onchange="invoiceOnChange" />
        </div>
        <div class="col-sm-1 col-form-label-sm">
            Add. cost
        </div>
        <div class="col-1p9">
            <FormItem v-model="item.description" :required="true" />
        </div>
        <div class="col-sm-1">
            {{ setVendor(item) }}
            <FormItem v-model="item.supplier_id" type="select" :options="suppliers" placeholder="-" />
        </div>
        <div class="col-sm-1p1">
            <div class="input-group">
                <div class="form-control form-control-sm bg-light w-67">{{ formatCurrency(item.purchase_rate) }}</div>
                <div class="form-control form-control-sm bg-light w-33">{{ setCode(item.purchase_rate_currency_id) }}</div>
            </div>
        </div>
        <div class="col-0p9 col-form-label-sm">
            {{ calcUnits[item.purchase_rate_calculation_unit_id - 1] ? calcUnits[item.purchase_rate_calculation_unit_id - 1].name : '?' }}
        </div>
        <div class="col-sm-1p1">
            <div class="input-group">
                <div class="form-control form-control-sm bg-light w-67">{{ formatCurrency(item.sales_rate) }}</div>
                <div class="form-control form-control-sm bg-light w-33">{{ setCode(item.sales_rate_currency_id) }}</div>
            </div>
        </div>
        <div class="col-0p9 col-form-label-sm">
            {{ calcUnits[item.sales_rate_calculation_unit_id - 1] ? calcUnits[item.sales_rate_calculation_unit_id - 1].name : '?' }}
        </div>
        <div class="col-sm-1p1">
            <div class="input-group total-field">
                <div class="form-control form-control-sm bg-light w-67">
                    <template v-if="calcUnits[item.sales_rate_calculation_unit_id - 1] && calcUnits[item.sales_rate_calculation_unit_id - 1].name == 'FF'">
                        {{ formatCurrency(item.sales_rate * item.quantity) }}
                    </template>
                    <template v-else>
                        {{ formatCurrency(item.sales_rate) }}
                    </template>
                </div>
                <div class="form-control form-control-sm bg-light w-33">{{ setCode(item.sales_rate_currency_id) }}</div>
            </div>
        </div>
        <div class="col" v-html="calcMargin(item, 3, itemIndex)"></div>
    </div>
    <template v-for="addQuote in job.additional_quotes" :key="addQuote.id">
    <div class="row transport-unit-table" v-for="item, itemIndex in addQuote.additional_quote_lines" :key="item.id">
        <div class="col-sm-xs">
            <FormItem type="number" v-model="item.quantity" :required="true" @onchange="invoiceOnChange" />
        </div>
        <div class="col-sm-1 col-form-label-sm">
            Add. quote
        </div>
        <div class="col-1p9" v-if="item.description == null">
            <FormItem type="select" v-model="item.quote_description_id" :required="true" :options="quoteDescriptions" nameField="description" />
        </div>
        <div class="col-1p9" v-else>
            <FormItem type="text" v-model="item.description" :required="true" />
        </div>
        <div class="col-sm-1">
            <FormItem v-model="item.supplier_id" type="select" :options="suppliers" placeholder="-" />
        </div>
        <template v-if="item.cost_type == 'fixed'">
        <div class="col-sm-1p1">
            <div class="input-group">
                <div class="form-control form-control-sm bg-light w-67">{{ formatCurrency(item.purchase_price) }}</div>
                <div class="form-control form-control-sm bg-light w-33">{{ setCode(item.purchase_currency_id) }}</div>
            </div>
        </div>
        <div class="col-0p9 col-form-label-sm">
            {{ item.purchase_rate_unit_id ? calcUnits[item.purchase_rate_unit_id - 1].name : calcUnits[0].name }}
        </div>
        <div class="col-sm-1p1">
            <div class="input-group">
                <div class="form-control form-control-sm bg-light w-67">{{ formatCurrency(item.sales_price) }}</div>
                <div class="form-control form-control-sm bg-light w-33">{{ setCode(item.sales_currency_id) }}</div>
            </div>
        </div>
        <div class="col-0p9 col-form-label-sm">
            {{ item.sales_rate_unit_id ? calcUnits[item.sales_rate_unit_id - 1].name : calcUnits[0].name }}
        </div>
        </template>
        <template v-else>
            <div class="col-sm-1p1">
                <FormItem v-model="addQuote.additional_quote_lines[itemIndex]" type="currency-and-amount" :options="currencies" nameField="code" :calcUnitFields="PCalcUnitFields" :required="true" @onchange="invoiceOnChange" />
            </div>
            <div class="col-0p9">
                {{ item.sales_rate_unit_id ? calcUnits[item.sales_rate_unit_id - 1].name : calcUnits[0].name }}
            </div>
            <div class="col-sm-1p1">
                <FormItem v-model="addQuote.additional_quote_lines[itemIndex]" type="currency-and-amount" :options="currencies" nameField="code" :calcUnitFields="SCalcUnitFields" :required="true" @onchange="invoiceOnChange" />
            </div>
            <div class="col-0p9">
                {{ item.sales_rate_unit_id ? calcUnits[item.sales_rate_unit_id - 1].name : calcUnits[0].name }}
            </div>
        </template>
        <div class="col-sm-1p1 total-field">
            <div class="input-group">
                <div class="form-control form-control-sm bg-light w-67">{{ formatCurrency(item.sales_price * item.quantity) }}</div>
                <div class="form-control form-control-sm bg-light w-33">{{ setCode(item.sales_currency_id) }}</div>
            </div>
        </div>
        <div class="col" v-html="calcMargin(item, 4, itemIndex)"></div>
    </div>
    </template>-->
    <!--<div class="row transport-unit-table" v-for="item, itemIndex in extraInvoiceLines" :key="itemIndex">
        <div class="col-sm-xs">
            <FormItem type="number" v-model="item.quantity" :required="true" @onchange="invoiceOnChange" />
        </div>
        <div class="col-sm-1">
            <FormItem v-model="item.invoice_type" type="select" :options="invoiceLineTypes" :required="true" />
        </div>
        <div class="col-1p9">
            <FormItem v-model="item.description" :required="true" />
        </div>
        <div class="col-sm-1">
            <FormItem v-model="item.supplier_id" type="select" :options="suppliers" placeholder="-" />
        </div>
        <div class="col-sm-1p1">
            <FormItem v-model="extraInvoiceLines[itemIndex]" type="currency-and-amount" :options="currencies" nameField="code" :calcUnitFields="PCalcUnitFields" :required="true" @onchange="invoiceOnChange" />
        </div>
        <div class="col-0p9">
            <FormItem v-model="item.purchase_rate_unit_id" type="select" :options="calcUnits" :required="true" @onchange="invoiceOnChange" />
        </div>
        <div class="col-sm-1p1">
            <FormItem v-model="extraInvoiceLines[itemIndex]" type="currency-and-amount" :options="currencies" nameField="code" :calcUnitFields="SCalcUnitFields" :required="true" @onchange="invoiceOnChange" />
        </div>
        <div class="col-0p9">
            <FormItem v-model="item.sales_rate_unit_id" type="select" :options="calcUnits" :required="true" @onchange="invoiceOnChange" />
        </div>
        <div class="col-sm-1p1">
            <div class="input-group total-field">
                <div class="form-control form-control-sm bg-light w-67">
                    {{ formatCurrency(item.sales_price * item.quantity) }}
                </div>
                <div class="form-control form-control-sm bg-light w-33">{{ setCode(item.sales_currency_id) }}</div>
            </div>
        </div>
        <div class="col" v-html="calcMargin(item, 5,itemIndex)"></div>
        <button type="button" class="btn col-auto ps-1 pe-0 py-0" @click="removeExtraInvoiceLine(itemIndex)">
            <i class="fa fa-trash-alt text-danger"></i>
        </button>
    </div>-->

    <!--<div class="row pe-3">
        <div class="col-6">
            <button type="button" class="btn btn-primary" @click="addExtraInvoiceLine">
                <i class="fa fa-plus"></i>&ensp;{{ $t('form.add', [$t('accounting.invoice_lines')]) }}
            </button>
        </div>
        <div :class="'col-6 flex-row-reverse pe-4 ' + (shouldShowAddCostSlider() ? 'd-flex' : 'd-none')">
            <label class="ms-3 pt-1">{{ $t('operations.add_to_invoice_2') }}</label>
            <FormItem type="slider" v-model="bookingSave['default']" :options="[1,0]" />
        </div>
    </div>-->
    <div class="row mt-4 position-fixed w-75" style="bottom: 1rem">
        <button type="button" class="btn btn-success col-2 ms-3" @click="createInvoiceFromProForma(false)" :disabled="loading || activeRows == 0">
            <i class="fa fa-file-import"></i>&ensp;Create invoice ({{activeBLs}} B/Ls)
        </button>
    </div>
    </template>
</template>

<script>
    import InvoiceService from '@/services/InvoiceService';
    import moment from 'moment';

    export default {
        props: ['jobItem', 'suppliers', 'quoteDescriptions', 'currencies', 'currenciesById', 'isEditing'],
        name: 'JobProFormaInvoiceForm',
        computed: {
            job: {
                get() {
                    return this.jobItem
                }
            }
        },
        data() {
            return {
                carriers:[],
                extraInvoiceLines: [],
                quoteInvoiceLines: [],
                quoteInvoiceData: [],
                tempCurrency: null,
                defaultCurrency: null,
                setInvoice: false,
                activeRows: 0,
                activeBLs: 0,
                margins: {},
                bookingSave: {},
                totals: {'sub': {}, 'vat': {}, 'grand': '-', 'code': ''},
                calcUnits: [{id: 1, name: 'unit'}, {id: 2, name: 'w/m'}, {id: 3, name: 'l/m'}, {id: 4, name: 'cbm'}, {id: 5, name: '%'}, {id: 6, name: 'flat fee'}, {id: 7, name: 'lumpsum'}, {id: 8, name: 'ton'}],
                invoiceLineTypes: [{id: 0, name: 'Transport'}, {id: 2, name: 'Sea fright'}, {id: 1, name: 'Surcharge'}, {id: 3, name: 'Add. cost'}, {id: 4, name: 'Add. quote'}],
                PCalcUnitFields: ['purchase_price', 'purchase_currency_id'],
                SCalcUnitFields: ['sales_price', 'sales_currency_id'],
                PCalcUnitFieldsAlternate: ['buying_rate', 'buying_rate_currency_id'],
                SCalcUnitFieldsAlternate: ['sales_rate', 'sales_rate_currency_id'],
                vatAmounts: [{id: 0, name: '0%'}],
            }
        },
        methods: {
            hardCopy(obj){
                return JSON.parse(JSON.stringify(obj));
            },

            /*getInvoiceGrandTotal(){
                const els = document.querySelectorAll('#invoices .total-field .w-67');
                const currs = document.querySelectorAll('#invoices .total-field .w-33');
                let subtotal = 0;
                this.totals.sub = {};
                this.totals.vat = {};
                let grandTotal = 0;
                let totalPRate = 0, totalSRate = 0;
                let totals = this.totals;
                let targetCurrency = this.currencies.find(curr => curr.id == this.tempCurrency);
                if(!targetCurrency) targetCurrency = this.currencies.find(curr => curr.id == this.defaultCurrency);
                els.forEach((el, index) => {
                    const nmbr = Number(el.innerHTML.replace('.', '').replace(',', '.'));
                    const rawRate = (isNaN(nmbr) ? 0 : nmbr);
                    const cCode = currs[index].innerHTML;
                    const thisSubTotal = this.calculateRoe(cCode, rawRate, targetCurrency);
                    subtotal += thisSubTotal[1];
                    totals.sub = this.addVatTotals(this.totals.sub, thisSubTotal, rawRate, cCode);
                });
                const currencySuffix = ' ' + targetCurrency.code;
                grandTotal = subtotal;
                for(let cstIndex in this.totals.sub) {
                    let item = this.totals.sub[cstIndex];
                    item.total = this.formatCurrency(item.total) + currencySuffix;
                    item.raw = this.formatCurrency(item.raw) + ' ' + item.code;
                }

                totals.grand = this.formatCurrency(grandTotal) + currencySuffix;
                totals.grandsub = this.formatCurrency(subtotal) + currencySuffix;
                totals.code = targetCurrency.code;
                for(let margin in this.margins){
                    totalPRate += this.margins[margin][0];
                    totalSRate += this.margins[margin][1];
                }
                this.totals.margin = (((totalPRate / totalSRate) - 1) * 100).toFixed(2) + '%';
                this.totals.marginColor = totalPRate > totalSRate ? 'text-success' : 'text-danger';
            },*/
            calculateRoe(currCode, value, targetCurrency){
                if(!targetCurrency) return [1, value, 'USD'];
                if(currCode == targetCurrency.code){
                    return [1, value, targetCurrency.code];
                }
                let currency = this.currencies.find(curr => curr.code == currCode);
                if(!currency) currency = this.currencies.find(currency => currency.is_default == 1);
                if(currency.currency_rates && targetCurrency.currency_rates){
                    const exRate = targetCurrency.currency_rates.exchange_rate_usd / currency.currency_rates.exchange_rate_usd;
                    return [exRate, exRate * value, currency.code];
                }
                return [1, value, targetCurrency.code];
            },
            /*addVatTotals(totalContainer, rates, rawRate, currId){
                let item = totalContainer;
                if(item[currId]){
                    item[currId].total += rates[1];
                    item[currId].raw += rawRate;
                }
                else{
                    item[currId] = {
                        total: rates[1],
                        roe: rates[0].toFixed(4).replace('.', ','),
                        code: rates[2],
                        raw: rawRate
                    }
                }
                return item;
            },
            addExtraInvoiceLine(){
                this.extraInvoiceLines.push({
                    quantity: '',
                    invoice_type: null,
                    description: '',
                    supplier_id: null,
                    purchase_currency_id: this.defaultCurrency,
                    purchase_price: null,
                    purchase_rate_unit_id: null,
                    sales_currency_id: this.defaultCurrency,
                    amount: null,
                    sales_price: null,
                    sales_rate_unit_id: null,
                    vat: null
                });
            },
            removeExtraInvoiceLine(index){
                this.extraInvoiceLines.splice(index, 1);
                this.invoiceOnChange();
            },*/
            calcAmount(item, mode, isBuying = false){
                let unit = '';
                let rate = '';
                if(mode == 1){
                    if(isBuying){
                        unit = this.setCode(item.buying_rate_calculation_unit_id);
                        rate = Number(item.buying_rate);
                    }
                    else{
                        unit = this.setCode(item.sales_rate_calculation_unit_id);
                        rate = Number(item.sales_rate);
                    }
                }
                if(mode == 2){
                    if(isBuying){
                        unit = this.setCode(item.purchase_rate_currency_id);
                        rate = item.purchase_rate;
                    }
                    else{
                        unit = this.setCode(item.sales_rate_currency_id);
                        rate = item.sales_rate;
                    }
                }
                if(mode == 3)
                    return isBuying ? (item.purchase_rate_unit_id == 6 ? 1 : item.amount) : (item.sales_rate_unit_id == 6 ? 1 : item.amount);

                if(unit == 'WM' && item.weight) return item.weight;
                if(unit == 'to' && item.weight) return item.weight / 1000;
                if(unit == 'LM' && item.length) return item.length;
                if(unit == 'CBM')  return (item.length * item.width * item.height) / 1000000;
                if(unit == 'PCT') return rate;
                return 1;
            },
            invoiceOnChange(){
                this.$forceUpdate();
                //this.$nextTick(() => this.getInvoiceGrandTotal());
            },
            /*shouldShowAddCostSlider(){
                try{
                    return this.job.quote.quote_transport_lines.length > 0 || this.job.quote.quote_general_costs.length > 0 || this.job.additional_quotes.length > 0 || this.extraInvoiceLines.length > 0;
                }
                catch(e){
                    return false;
                }
            },*/
            setCheckbox(event, bookingitem, blitem, addcosts = false){
                if(event.target.checked){
                    if(blitem && addcosts){
                        this.activeRows++;
                        if(!bookingitem.addcosts.find(bl => !bl.active)) bookingitem.allAddCostsActive = 1;
                    }
                    else if(blitem){
                        this.activeRows++;
                        this.activeBLs++;
                        if(!bookingitem.bls.find(bl => !bl.active)) bookingitem.active = 1;
                    }
                    else if(addcosts){
                        bookingitem.addcosts.filter(bl => !bl.disabled).forEach(bl => {
                            this.activeRows += (bl.active ? 0 : 1);
                            bl.active = 1;
                        });
                        bookingitem.allAddCostsActive = 1;
                    }
                    else{
                        bookingitem.bls.filter(bl => !bl.disabled).forEach(bl => {
                            this.activeRows += (bl.active ? 0 : 1);
                            this.activeBLs += (bl.active ? 0 : 1);
                            bl.active = 1;
                        });
                        bookingitem.addcosts.filter(bl => !bl.disabled).forEach(bl => {
                            this.activeRows += (bl.active ? 0 : 1);
                            bl.active = 1;
                        });
                        bookingitem.allAddCostsActive = 1;
                    }
                }
                else{
                    if(blitem && addcosts){
                        this.activeRows--;
                        if(bookingitem.addcosts.find(bl => !bl.active)) bookingitem.allAddCostsActive = 0;
                    }
                    if(blitem){
                        this.activeRows--;
                        this.activeBLs--;
                        if(bookingitem.bls.find(bl => !bl.active)) bookingitem.active = 0;
                    }
                    else if(addcosts){
                        bookingitem.addcosts.filter(bl => !bl.disabled).forEach(bl => {
                            this.activeRows -= (bl.active ? 1 : 0);
                            bl.active = 0;
                        });
                        bookingitem.allAddCostsActive = 0;
                    }
                    else{
                        bookingitem.bls.filter(bl => !bl.disabled).forEach(bl => {
                            this.activeRows -= (bl.active ? 1 : 0);
                            this.activeBLs -= (bl.active ? 1 : 0);
                            bl.active = 0;
                        });
                        bookingitem.addcosts.filter(bl => !bl.disabled).forEach(bl => {
                            this.activeRows -= (bl.active ? 1 : 0);
                            bl.active = 0;
                        });
                        bookingitem.allAddCostsActive = 0;
                    }
                }
            },
            calcMargin(item, mode, index){
                let amount = 1;
                let p_rate = 0, s_rate = 0, p_curr = null, s_curr = null;
                if(mode == 1 || mode == 4 || mode == 5){
                    const pAmount = mode == 5 ? item.quantity : 1;
                    const sAmount = mode == 5 ? item.quantity : 1;
                    p_rate = item.purchase_price * pAmount;
                    s_rate = item.sales_price * sAmount;
                    p_curr = item.purchase_currency_id;
                    s_curr = item.sales_currency_id;
                }
                if(mode == 2){
                    p_rate = Number(item.buying_rate) * item.quantity;
                    s_rate = Number(item.sales_rate) * item.quantity;
                    p_curr = item.buying_rate_currency_id;
                    s_curr = item.sales_rate_currency_id;
                }
                if(mode == 3){
                    p_rate = item.purchase_rate;
                    s_rate = item.sales_rate;
                    p_curr = item.purchase_rate_currency_id;
                    s_curr = item.sales_rate_currency_id;
                }
                const targetCurrency = this.currencies.find(curr => curr.id == this.tempCurrency);
                if(p_curr != this.tempCurrency) p_rate = this.calculateRoe(this.setCode(p_curr - 1), p_rate, targetCurrency)[1];
                if(s_curr != this.tempCurrency) s_rate = this.calculateRoe(this.setCode(s_curr - 1), s_rate, targetCurrency)[1];
                amount = ((s_rate / p_rate) - 1) * 100;
                this.margins[mode + '-' + index] = [s_rate, p_rate];
                if(isNaN(amount) || (amount === Infinity)) amount = 0;
                const style = amount > 0 ? 'bg-success text-white' : (amount < 0 ? 'bg-danger text-white' : 'bg-light');
                return '<div class="form-control form-control-sm margin-amount '+style+'">' + (amount.toFixed(2) + '%') + '</div>';
            },
            hasSurveyedUnits(bl){
                let result = false;
                for(let unit of bl.job_units){
                    if(unit.is_surveyed) return true;
                }
                bl.job_unit_containers.forEach(container => { container.job_units.forEach( unit => { if(unit.is_surveyed) result = true } ) } );
                return result;
            },
            createInvoiceFromProForma(closeWhenDone = false){
                //Validation
                let valid = true;
                const required = document.querySelectorAll('#invoices input, #invoices select');

                for(let field of required) {
                    if(!field.value || field.value == this.$t('form.select')) {
                        field.classList.add('is-invalid');
                        valid = false;
                    }
                    else{
                        field.classList.remove('is-invalid');
                    }
                }
                if(!valid) {
                    alert(this.$t('errors.empty_fields'));
                    return;
                }
                //this.loading = true;
                this.quoteInvoiceLines = [];
                this.quoteInvoiceData.forEach(dat => {
                    dat.bls.filter(bl => bl.active).forEach(bl => {
                        bl.units.forEach(unit => {
                            this.quoteInvoiceLines.push(unit);
                        });
                        bl.active = 0;
                        bl.disabled = true;
                    });
                    dat.addcosts.filter(bl => bl.active).forEach(bl => {
                        this.quoteInvoiceLines.push(bl);
                        bl.active = 0;
                        bl.disabled = true;
                    });
                    if(dat.active){
                        dat.active = 0;
                        if(!dat.bls.find(bl => !bl.disabled)) dat.disabled = true;
                        if(!dat.addcosts.find(bl => !bl.disabled)) dat.allAddCostsDisabled = true;
                    }
                });
                const invoice = {
                    "date": moment().format('YYYY-MM-DD'),
                    //"total_exl_vat": this.totals.grandsub.replace(/[A-Za-z]/g, '').replace('.', '').replace(',', '.'),
                    //"total_inc_vat": this.totals.grand.replace(/[A-Za-z]/g, '').replace('.', '').replace(',', '.'),
                    "office_id": this.job.quote.sales_manager.office.id,
                    "customer_id": this.job.customer.id,
                    "currency_id": this.tempCurrency,
                    "is_pro_forma": true,
                    "jobs": [{ 'id': this.job.id }],
                    "voyage_id": null,
                    "invoice_lines": [],
                    "port_of_loading": this.job.port_of_loading.name,
                    "sales_manager": this.job.quote.sales_manager.name,
                    "job_number": this.job.number
                }
                //Transport
                /*for(let item of this.job.quote.quote_transport_lines){
                    invoice.invoice_lines.push({
                        "description": item.description,
                        "quantity": item.quantity,
                        "amount": 1,
                        "vat": 0,
                        "sales_rate": item.sales_price,
                        "sales_rate_currency_id": item.sales_currency_id,
                        "sales_rate_calculation_unit_id": this.calcUnits[0].id,
                        "buying_rate": item.purchase_price,
                        "buying_rate_currency_id": item.purchase_currency_id,
                        "buying_rate_calculation_unit_id": item.purchase_rate_calculation_unit_id,
                        "supplier_id": item.supplier_id,
                        "line_type": 0,
                        "is_approved": this.bookingSave.default
                    })
                }*/
                //Quote Lines
                for(let item of this.quoteInvoiceLines){
                    if(item.line_type == 2)
                        invoice.invoice_lines.push({
                            "description": item.description,
                            "quantity": item.quantity,
                            "amount": 1,
                            "vat": 0,
                            "sales_rate": item.sales_rate,
                            "sales_rate_currency_id": item.sales_rate_currency_id,
                            "sales_rate_calculation_unit_id": item.sales_rate_calculation_unit_id,
                            "buying_rate": item.buying_rate,
                            "buying_rate_currency_id": item.buying_rate_currency_id,
                            "buying_rate_calculation_unit_id": item.buying_rate_calculation_unit_id,
                            "supplier_id": item.supplier_id,
                            "line_type": item.line_type,
                            //"is_approved": this.bookingSave[item.bl_id],
                            "bill_of_lading_id": item.bl_id,
                            "surcharges": []
                        })
                    else if(item.line_type == 4)
                        invoice.invoice_lines.push({
                            "description": item.description ? item.description : this.quoteDescriptions.find(qd => qd.id == item.quote_description_id).description,
                            "quantity": item.quantity,
                            "amount": 1,
                            "vat": 0,
                            "sales_rate": item.sales_price,
                            "sales_rate_currency_id": item.sales_currency_id,
                            "sales_rate_calculation_unit_id": this.calcUnits[0].id,
                            "supplier_id": item.supplier_id,
                            "line_type": 4,
                            "buying_rate": item.purchase_price,
                            "buying_rate_currency_id": item.purchase_currency_id,
                            "buying_rate_calculation_unit_id": item.purchase_rate_unit_id,
                            //"is_approved": this.bookingSave.default
                        })
                    else
                        invoice.invoice_lines.at(-1).surcharges.push({
                            "description": item.description,
                            "quantity": item.quantity,
                            "amount": 1,
                            "vat": 0,
                            "sales_rate": item.sales_rate,
                            "sales_rate_currency_id": item.sales_rate_currency_id,
                            "sales_rate_calculation_unit_id": item.sales_rate_calculation_unit_id,
                            "buying_rate": item.buying_rate,
                            "buying_rate_currency_id": item.buying_rate_currency_id,
                            "buying_rate_calculation_unit_id": item.buying_rate_calculation_unit_id,
                            "supplier_id": item.supplier_id,
                            "line_type": item.line_type,
                            //"is_approved": this.bookingSave[item.bl_id],
                            "bill_of_lading_id": item.bl_id
                        })
                }
                //Add Costs
                /*for(let item of this.job.quote.quote_general_costs){
                    invoice.invoice_lines.push({
                        "description": item.description,
                        "quantity": item.quantity,
                        "amount": 1,
                        "vat": 0,
                        "sales_rate": item.sales_rate,
                        "sales_rate_currency_id": item.sales_rate_currency_id,
                        "sales_rate_calculation_unit_id": item.sales_rate_calculation_unit_id,
                        "buying_rate": item.purchase_rate,
                        "buying_rate_currency_id": item.purchase_rate_currency_id,
                        "buying_rate_calculation_unit_id": item.purchase_rate_calculation_unit_id,
                        "supplier_id": item.supplier_id,
                        "line_type": 3,
                        "is_approved": this.bookingSave.default
                    })
                }*/
                //Add Quotes
                /*for(let container of this.job.additional_quotes){
                    for(let item of container.additional_quote_lines){
                        invoice.invoice_lines.push({
                            "description": item.description ? item.description : this.quoteDescriptions.find(qd => qd.id == item.quote_description_id).description,
                            "quantity": item.quantity,
                            "amount": 1,
                            "vat": 0,
                            "sales_rate": item.sales_price,
                            "sales_rate_currency_id": item.sales_currency_id,
                            "sales_rate_calculation_unit_id": this.calcUnits[0].id,
                            "supplier_id": item.supplier_id,
                            "line_type": 4,
                            "buying_rate": item.purchase_price,
                            "buying_rate_currency_id": item.purchase_currency_id,
                            "buying_rate_calculation_unit_id": item.purchase_rate_unit_id,
                            "is_approved": this.bookingSave.default
                        })
                    }
                }*/
                //Extra Lines
                /*for(let item of this.extraInvoiceLines){
                    invoice.invoice_lines.push({
                        "description": item.description,
                        "quantity": item.quantity,
                        "amount": 1,
                        "vat": 0,
                        "sales_rate": item.sales_price,
                        "sales_rate_currency_id": item.sales_currency_id,
                        "sales_rate_calculation_unit_id": item.sales_rate_unit_id,
                        "supplier_id": item.supplier_id,
                        "line_type": item.invoice_type,
                        "buying_rate": item.purchase_price,
                        "buying_rate_currency_id": item.purchase_currency_id,
                        "buying_rate_calculation_unit_id": item.purchase_rate_unit_id,
                        "is_approved": this.bookingSave.default
                    })
                }*/
                this.activeRows = 0;
                function onSuccess(self, shouldCloseWhenDone){
                    self.loading = false;
                    self.$toast.success( self.$t('accounting.invoice_saved') );
                    if(shouldCloseWhenDone) { self.$parent.$parent.close() }
                }
                function onError(error, self){
                    self.loading = false;
                    self.toastError(error)
                    console.log('error', error)
                }
                //Send it!
                /*if(this.job.invoice && this.job.invoice.id){
                    invoice.id = this.job.invoice.id;
                    InvoiceService.update(invoice).then(onSuccess(this, closeWhenDone)).catch(e => onError(e, this));
                }
                else{
                    InvoiceService.store(invoice).then(onSuccess(this, closeWhenDone)).catch(e => onError(e, this));
                }*/
                this.$parent.job.invoices.push(invoice);
                
                this.$nextTick(() => {
                    document.getElementById("jobTab").lastElementChild.childNodes[1].click();
                });
            },
            /*setInvoiceData(){
                let itemIndexes = [0,0,0,0];
                for(let invoiceLine of this.job.invoice.invoice_lines){
                    const setExtraInvoiceLine = function(invoiceLine, extraInvoiceLines, bookingSave){
                        extraInvoiceLines.push({
                            quantity: invoiceLine.quantity,
                            invoice_type: invoiceLine.line_type,
                            description: invoiceLine.description,
                            supplier_id: invoiceLine.supplier_id,
                            purchase_currency_id: invoiceLine.buying_rate_currency_id ? invoiceLine.buying_rate_currency_id : 1,
                            purchase_price: invoiceLine.buying_rate ? invoiceLine.buying_rate : 0,
                            purchase_rate_unit_id: invoiceLine.buying_rate_calculation_unit_id ? invoiceLine.buying_rate_calculation_unit_id : 1,
                            sales_currency_id: invoiceLine.sales_rate_currency_id ? invoiceLine.sales_rate_currency_id : 1,
                            amount: invoiceLine.amount,
                            sales_price: invoiceLine.sales_rate,
                            sales_rate_unit_id: invoiceLine.sales_rate_calculation_unit_id
                        });
                        bookingSave.default = invoiceLine.is_approved;
                    }
                    //Transport
                    if(invoiceLine.line_type == 0){
                        const item = this.job.quote.quote_transport_lines[ itemIndexes[0] ];
                        if(!item) { setExtraInvoiceLine(invoiceLine, this.extraInvoiceLines, this.bookingSave); continue; }
                        item.description = invoiceLine.description;
                        item.quantity = invoiceLine.quantity;
                        item.supplier_id = invoiceLine.supplier_id;
                        itemIndexes[0]++;
                        this.bookingSave.default = invoiceLine.is_approved;
                    }
                    //Quote Lines
                    if(invoiceLine.line_type == 1 || invoiceLine.line_type == 2){
                        const item = this.quoteInvoiceLines[ itemIndexes[1] ];
                        if(!item) { setExtraInvoiceLine(invoiceLine, this.extraInvoiceLines, this.bookingSave); continue; }
                        item.description = invoiceLine.description;
                        item.quantity = invoiceLine.quantity;
                        item.supplier_id = invoiceLine.supplier_id;
                        item.commodity = null;
                        itemIndexes[1]++;
                        this.bookingSave[item.bl_id] = invoiceLine.is_approved;
                    }
                    //Add Costs
                    if(invoiceLine.line_type == 3){
                        const item = this.job.quote.quote_general_costs[ itemIndexes[2] ];
                        if(!item) { setExtraInvoiceLine(invoiceLine, this.extraInvoiceLines, this.bookingSave); continue; }
                        item.description = invoiceLine.description;
                        item.quantity = invoiceLine.quantity;
                        item.supplier_id = invoiceLine.supplier_id;
                        itemIndexes[2]++;
                        this.bookingSave.default = invoiceLine.is_approved;
                    }
                }
                const addQuoteData = this.job.invoice.invoice_lines.filter(item => item.line_type == 4);
                //Add Quotes
                for(let container of this.job.additional_quotes){
                    for(let item of container.additional_quote_lines){
                        const quoteItem = addQuoteData[ itemIndexes[3] ];
                        if(!quoteItem) break;
                        item.description = quoteItem.description;
                        item.quantity = quoteItem.quantity;
                        item.supplier_id = quoteItem.supplier_id;
                        item.sales_rate = quoteItem.sales_rate;
                        item.sales_rate_currency_id = quoteItem.sales_rate_currency_id ? quoteItem.sales_rate_currency_id : item.sales_rate_currency_id;
                        item.purchase_currency_id = quoteItem.buying_rate_currency_id ? quoteItem.buying_rate_currency_id : item.purchase_currency_id;
                        item.purchase_price = quoteItem.buying_rate ? quoteItem.buying_rate : item.purchase_price;
                        itemIndexes[3]++;
                        this.bookingSave.default = quoteItem.is_approved;
                    }
                }
            },*/
            setVendor(item){
                if(!item.supplier_id) item.supplier_id = null;
                return '';
            },
            setQuoteInvoiceLines(){
                this.quoteInvoiceData = [];
                this.activeRows = 0;
                let index = 0;
                this.bookingSave = {'default': false};
                const invoiceLines = this.job.invoices.map(invoice => invoice.invoice_lines).flat();
                for(var bIndex in this.job.bookings){
                    var booking = this.job.bookings[bIndex];
                    const header = this.$t('navigation.bookings') + ' ' + booking.number + ' (' + booking.voyages[0].vessel.name + ' ' + booking.voyages[0].number + ')';
                    let bookingItem = {
                        "header": this.$t('navigation.bookings') + ' ' + booking.number,
                        "active": 0,
                        "allAddCostsActive": 0,
                        "bls": [],
                        "addcosts": []
                    };
                    for(var blIndex in booking.bills_of_lading){
                        var billOfLading = booking.bills_of_lading[blIndex];
                        if(billOfLading.id === null) continue;
                        
                        let alreadyInvoiced = false;
                        for(let invoice of invoiceLines){
                            if(invoice.bill_of_lading_id == billOfLading.id){
                                alreadyInvoiced = true;
                                break;
                            }
                        }
                        if(alreadyInvoiced) break;

                        let jobUnits = billOfLading.job_units;
                        let containerUnits = [];
                        for(var container of billOfLading.job_unit_containers){
                            for(var cunit of container.job_units){
                                containerUnits.push(cunit);
                            }
                        }
                        let allUnits = jobUnits.concat(containerUnits);
                        let blItem = {
                            "header": this.$t('navigation.bills_of_lading') + ' ' + billOfLading.number,
                            "active": 0,
                            "already_invoiced": 0,
                            "units": []
                        };
                        for(var jIndex in allUnits){
                            var unit = allUnits[jIndex];
                            let blNumber = booking.bills_of_lading[blIndex].number;
                            let blId = booking.bills_of_lading[blIndex].id;
                            this.bookingSave[blId] = false;
                            
                            var itemIndex = this.job.quote.quote_lines.findIndex(someQL => someQL.id == unit.group_id);
                            let item = null, qlc = null, surchargesFound = false;
                            if(itemIndex != -1){
                                item = this.job.quote.quote_lines[itemIndex];
                                qlc = item.quote_line_carriers[0];
                                surchargesFound = true;
                            }
                            let newDescription = item ? item.description : '';
                            if(unit.commodity){
                                newDescription = unit.commodity.name + ', ' + (unit.condition_id == 2 ? this.$t('form.used') : this.$t('form.new')) + ': ';
                            }
                            if(unit.make){
                                newDescription += (unit.make.name + ' ');
                            }
                            if(unit.model){
                                newDescription += (unit.model.name + ' ');
                            }
                            newDescription += ('(' + unit.chassis_number + ')');
                            const newInvoice = {
                                "description": newDescription,
                                "quantity": qlc ? (qlc.sales_rate_calculation_unit_id == 1 ? 1 : this.calcAmount(qlc, 1)) : 1,
                                "amount": qlc ? this.calcAmount(qlc, 1) : 1,
                                "vat": 0,
                                "sales_rate": qlc ? qlc.sales_rate : 0,
                                "sales_rate_currency_id": qlc ? qlc.sales_rate_currency_id : this.defaultCurrency,
                                "sales_rate_calculation_unit_id": qlc ? qlc.sales_rate_calculation_unit_id : 1,
                                "supplier_id": null,
                                "line_type": 2,
                                "buying_rate": qlc ? qlc.buying_rate : 0,
                                "buying_rate_currency_id": qlc ? qlc.buying_rate_currency_id : this.defaultCurrency,
                                "buying_rate_calculation_unit_id": qlc ? qlc.buying_rate_calculation_unit_id : 1,
                                "booking_header": (blIndex == 0 && jIndex == 0) ? header : false,
                                "bl_number": blNumber,
                                "bl_id": blId,
                                "bl_separator": jIndex == 0,
                                "editable": qlc ? false : true,
                                "index": (index + 1 - 1)
                            };
                            blItem.units.push(newInvoice);
                            index++;
                            let surchargeIndex = (itemIndex + 1);
                            while(surchargesFound){
                                let nextItem = this.job.quote.quote_lines[surchargeIndex];
                                if(nextItem && (nextItem.surcharge_id !== null || nextItem.is_surcharge)){
                                    qlc = nextItem.quote_line_carriers[0];
                                    if(qlc){
                                        const newSurcharge = {
                                            "description": nextItem.description,
                                            "quantity": qlc.sales_rate_calculation_unit_id == 1 ? nextItem.quantity : (qlc.sales_rate_calculation_unit_id == 5 ? (newInvoice.sales_rate * 0.01) : this.calcAmount(qlc, 1)),
                                            "amount": qlc.sales_rate_calculation_unit_id == 5 ? (newInvoice.sales_rate * 0.01) : this.calcAmount(qlc, 1),
                                            "vat": 0,
                                            "sales_rate": qlc.sales_rate,
                                            "sales_rate_currency_id": qlc.sales_rate_currency_id,
                                            "sales_rate_calculation_unit_id": qlc.sales_rate_calculation_unit_id,
                                            "supplier_id": null,
                                            "line_type": 1,
                                            "buying_rate": qlc.buying_rate,
                                            "buying_rate_currency_id": qlc.buying_rate_currency_id,
                                            "buying_rate_calculation_unit_id": qlc.buying_rate_calculation_unit_id,
                                            "booking_header": false,
                                            "bl_number": blNumber,
                                            "bl_id": blId,
                                            "bl_separator": false,
                                            "index": (index + 1 - 1)
                                        };
                                        blItem.units.push(newSurcharge);
                                        index++;
                                    }
                                    surchargeIndex++;
                                }
                                else{
                                    surchargesFound = false;
                                }
                            }
                        }
                        bookingItem.bls.push(blItem);
                    }
                    for(let container of this.job.additional_quotes.filter(aq => aq.job_booking_id == booking.id)){
                        for(let item of container.additional_quote_lines){
                            item.active = 0;
                            item.line_type = 4;
                            bookingItem.addcosts.push(item);
                        }
                    }
                    this.quoteInvoiceData.push(bookingItem);
                }
                this.job.invoices = [];
            },
            setCode(value){
                const item = this.currenciesById[value];
                return item ? item.code : '-';
            },
        }
    }
</script>

<style scoped>
    .input-group select {background-color: #eee}
    .col-sm-1p1 {flex: 0 0 auto; width: 10%}
    .col-0p9 {flex: 0 0 auto; width: 7%}
    .col-1p9 {flex: 0 0 auto; width: 28%}
    .col-sm-xs{flex: 0 0 auto;  width: 70px}
    .w-67{ width: calc(100% - 36px); flex: unset }
    .w-33{ width: 36px; flex: unset }
    .transport-unit-table.row{
        --bs-gutter-x: 0.5rem !important;
        margin-bottom: 5px
    }
    .transport-unit-table .col-sm-1{
        width: 8% !important;
        height: 28px
    }
    .transport-unit-table .col-sm-2{
        width: 20% !important
    }
    .transport-unit-table textarea{
        position: absolute;
        max-height: 100px !important
    }
    .transport-unit-table .input-group > div {
        font-size: 0.75rem;
        padding: 0.3rem
    }
    .is-invalid ~ .invalid-feedback{
        position: absolute;
        width: 300px;
        margin-top: 34px;
    }
    .disabled{
        background-color: #e9ecef
    }

    .main-accordion:not(#f0-accordion){
        padding-top: .5rem;
        border-top: 1px solid var(--bs-primary)
    }
    .accordion-header{
        border-bottom: 1px solid lightgray
    }
    .accordion-item {
        margin-bottom: 1rem;
    }
    .accordion-header { width: 100%; }
    .accordion-button::after {
        background-image: none;
        transform: none
    }
    .acc-header-item.header-main {
        box-shadow: none !important;
        border-radius: 0px !important;
    }
    .acc-header-item.header-arrow {
        background: transparent;
        border: none;
        align-self: baseline
    }
    .acc-header-item.header-arrow::after {
        transition: rotate .15s
    }
    .acc-header-item.header-arrow::after {
        display: block;
        content: '\276F'
    }
    .acc-header-item:not(.collapsed).header-arrow::after {
        rotate: 90deg
    }
</style>