<template>
    <div class="row" v-if="!jobItem">
        <ul class="nav nav-main nav-tabs my-1 px-2" :id="'myTab3-'+index" role="tablist">
            <li class="nav-item ms-1" role="presentation">
                <button class="nav-link active" :id="'generaldets-tab-'+index" data-bs-toggle="tab" :data-bs-target="'#generaldets-'+index" type="button" role="tab" aria-controls="generaldets" @click="setTabs(1)">{{$t('operations.personal_details')}}</button>
            </li>
            <li class="nav-item ms-1" role="presentation">
                <button class="nav-link" :id="'iteniary-tab-'+index" data-bs-toggle="tab" :data-bs-target="'#iteniary-'+index" type="button" role="tab" aria-controls="iteniary" @click="setTabs(2)">{{$t('operations.bl_details')}}</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" :id="'containers-tab-'+index" data-bs-toggle="tab" :data-bs-target="'#containers-'+index" type="button" role="tab" aria-controls="containers" @click="setTabs(3)">{{$tc('navigation.units', 2)}}</button>
            </li>
            <li class="nav-item" role="presentation" v-if="billOflading.is_letterofcredit">
                <button class="nav-link" :id="'loc-tab-'+index" data-bs-toggle="tab" :data-bs-target="'#loc-'+index" type="button" role="tab" aria-controls="loc" @click="setTabs(5)">{{$t('operations.letter_of_credit')}}</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" :id="'preview-tab-'+index" data-bs-toggle="tab" :data-bs-target="'#preview-'+index" type="button" role="tab" aria-controls="preview" @click="setTabs(4)">{{$t('operations.preview_save')}}</button>
            </li>
        </ul>
    </div>
    <h5 class="mt-3 mb-0" v-if="billOflading.fromJob"> {{ $t('operations.from_job_notice') }} <button class="btn btn-link fs-5 px-1 pt-0" @click="openJobBL">J{{billOflading.job_booking.number.slice(1, -2)}}</button> </h5>
    <h5 v-else-if="billOflading.legacy" class="mt-3 mb-0">
        {{ $t('operations.legacy') }}<a :href="'https://booking.nmtworld.com/booking/bldetails/'+billOflading.legacy_booking_bl_id" target="_blank">NMT Booking</a> 
        <span v-show="isFinal('status') || billOflading.voyageClosed">&ensp;{{ $t(billOflading.uneditable ? 'operations.final_notice_2' : (billOflading.voyageClosed ? 'operations.final_notice_3' : 'operations.final_notice')) }}.</span>
    </h5>
    <h5 class="mt-3 mb-0" v-else-if="isFinal('status')"> {{ $t(billOflading.uneditable ? 'operations.final_notice_2' : 'operations.final_notice') }} </h5>
    <h5 class="mt-3 mb-0" v-else-if="billOflading.voyageClosed">{{ $t('operations.final_notice_3') }}</h5>
    <h5 class="mt-3 mb-0" v-else-if="jobItem == null">&nbsp;</h5>
    <div class="row tab-content pb-3 px-3" :id="'myTabContent3-'+index">
        <div class="show active pt-4" :class="{'tab-pane fade': !jobItem}" :id="'generaldets-'+index" role="tabpanel" aria-labelledby="generaldets-tab">
            <div class="row">
                <div class="col-6 bl-personal-details pe-5" v-if="loaded && billOflading.shipper">
                    <p class="fw-bold">{{$t('operations.shipper')}}</p>
                    <FormItem id="shipper-street" :label="$t('overview.name') + ' & ' + $t('navigation.addresses')" v-model="billOflading.shipper.address" type="textarea-bl" sizeLabel="3" sizeItem="9" :required="isFinal()" :disabled="isFinal()" />
                    <FormItem id="shipper-country" :label="$t('navigation.countries')" v-model="billOflading.shipper.country_id" type="livesearch" sizeLabel="3" sizeItem="9" :options="countries" 
                                :content="fillSearch(countries, billOflading.shipper.country_id)" @onchange="setDefaultCountry(billOflading.shipper)" :required="isFinal()" :disabled="isFinal()" />
                    <FormItem id="shipper-phone" :label="$t('form.phone')" v-if="!billOflading.shipper.refresh" v-model="billOflading.shipper.phone" type="phone" sizeLabel="3" sizeItem="9" :phoneDefaultCountry="billOflading.shipper.defaultISO" :disabled="isFinal()" />
                    <FormItem id="shipper-email" :label="$t('form.email')" v-model="billOflading.shipper.email" type="email" sizeLabel="3" sizeItem="9" :disabled="isFinal()" />

                    <p class="mt-2 fw-bold">{{$t('operations.consignee')}}</p>

                    <FormItem id="cons-street" :label="$t('overview.name') + ' & ' + $t('navigation.addresses')" v-model="billOflading.consignee.address" type="textarea-bl" sizeLabel="3" sizeItem="9" @oninput="(e) => {alsoFillInNotify(e, billOflading, 'address')}" :disabled="isFinal()" />
                    <FormItem id="cons-country" :label="$t('navigation.countries')" v-model="billOflading.consignee.country_id" type="livesearch" :options="countries" sizeLabel="3" sizeItem="9" :disabled="isFinal()"
                                :content="fillSearch(countries, billOflading.consignee.country_id)" :required="isFinal()" @onchange="(e) => {alsoFillInNotify(e, billOflading, 'country_id'); setDefaultCountry(billOflading.consignee)}" />
                    <FormItem id="cons-phone" :label="$t('form.phone')" v-if="!billOflading.consignee.refresh" v-model="billOflading.consignee.phone" type="phone" :required="false"  
                                @oninput="(e) => alsoFillInNotify(e, billOflading, 'phone', true)" :phoneDefaultCountry="billOflading.consignee.defaultISO" sizeLabel="3" sizeItem="9" :disabled="isFinal()" />
                    <FormItem id="cons-email" :label="$t('form.email')" v-model="billOflading.consignee.email" type="email" :required="false" sizeLabel="3" sizeItem="9"
                                @oninput="(e) => alsoFillInNotify(e, billOflading, 'email')" :disabled="isFinal()" />

                    <p class="mt-2 fw-bold">{{$t('operations.notify')}}</p>

                    <FormItem :id="'same-checkmark-'+index" :label="$t('operations.same_as_consignee')" v-model="billOflading.notify_same_as_consignee" type="checkbox" @onchange="(e) => setNotify(e, billOflading)" :disabled="isFinal()" />
                    <template v-if="!jobItem || !billOflading.notify_same_as_consignee">
                        <FormItem id="notify-street" :label="$t('overview.name') + ' & ' + $t('navigation.addresses')" v-model="billOflading.notify.address" type="textarea-bl" sizeLabel="3" sizeItem="9" :disabled="isFinal('notify')" />
                        <FormItem id="notify-country" :label="$t('navigation.countries')" v-model="billOflading.notify.country_id" type="livesearch" :options="countries" sizeLabel="3" sizeItem="9"
                                :content="fillSearch(countries, billOflading.notify.country_id)" @onchange="setDefaultCountry(billOflading.notify)" :required="isFinal()" :disabled="isFinal('notify')" />
                        <FormItem id="notify-phone" :label="$t('form.phone')" v-if="!billOflading.notify.refresh" v-model="billOflading.notify.phone" type="phone" 
                                :phoneDefaultCountry="billOflading.notify.defaultISO" :required="false" sizeLabel="3" sizeItem="9" :disabled="isFinal('notify')" />
                        <FormItem id="notify-email" :label="$t('form.email')" v-model="billOflading.notify.email" type="email" :required="false" sizeLabel="3" sizeItem="9" :disabled="isFinal('notify')" />
                    </template>

                    <p class="mt-2 fw-bold">{{$t('operations.second_notify')}}</p>

                    <FormItem :id="'second-notify-'+index" :label="$t('operations.has_second_notify')" v-model="billOflading.has_second_notify" type="checkbox" @onchange="(e) => setSecondNotify(e, billOflading)" :disabled="isFinal()" />
                    <template v-if="billOflading.second_notify != null">
                        <FormItem id="second_notify-street" :label="$t('overview.name') + ' & ' + $t('navigation.addresses')" v-model="billOflading.second_notify.address" type="textarea-bl" sizeLabel="3" sizeItem="9" :disabled="isFinal()" />
                        <FormItem id="second_notify-country" :label="$t('navigation.countries')" v-model="billOflading.second_notify.country_id" type="livesearch" :options="countries" sizeLabel="3" sizeItem="9"
                                :content="fillSearch(countries, billOflading.second_notify.country_id)" @onchange="setDefaultCountry(billOflading.second_notify)" :required="isFinal()" :disabled="isFinal()" />
                        <FormItem id="second_notify-phone" :label="$t('form.phone')" v-if="!billOflading.second_notify.refresh" v-model="billOflading.second_notify.phone" type="phone" 
                                :phoneDefaultCountry="billOflading.second_notify.defaultISO" sizeLabel="3" sizeItem="9" :required="false" :disabled="isFinal()" />
                        <FormItem id="second_notify-email" :label="$t('form.email')" v-model="billOflading.second_notify.email" type="email" :required="false" sizeLabel="3" sizeItem="9" :disabled="isFinal()" />
                    </template>
                </div>
                <div class="col-6 bl-personal-details" v-else-if="loaded">
                    <FormItem id="shipper-leg" :label="$t('operations.shipper')" v-model="billOflading.shipper_name" type="textarea" options="5" extraOption="uppercase" sizeLabel="3" sizeItem="9" :required="isFinal()" :disabled="isFinal()" />
                    <FormItem id="consignee-leg" :label="$t('operations.consignee')" v-model="billOflading.consignee_name" type="textarea" options="5" extraOption="uppercase" sizeLabel="3" sizeItem="9" :required="isFinal()" :disabled="isFinal()" />
                    <FormItem id="notify-leg" :label="$t('operations.notify')" v-model="billOflading.notify_name" type="textarea" options="5" extraOption="uppercase" sizeLabel="3" sizeItem="9" :required="isFinal()" :disabled="isFinal()" />
                </div>
                <div v-else class="col-6 bl-personal-details"></div>
                <div class="col-6 position-relative ps-4 pe-3">
                    <div v-if="job" class="w-100 text-muted align-self-end text-end my-2"> <i class="fa fa-info-circle pt-1"></i>&ensp;<span class="col-form-label-sm">{{$t('operations.queue_info')}}</span> </div>
                    <div v-else class="d-flex">
                        <img class="d-block" :src="'../img/logo.png'" />
                        <div class="w-100 text-muted align-self-end text-end"> <i class="fa fa-info-circle pt-1"></i>&ensp;<span class="col-form-label-sm">{{$t('operations.queue_info')}}</span> </div>
                    </div>
                    <FormItem :id="'blstatus'+index" label="B/L Status" type="buttongroup" v-model="billOflading.bill_of_lading_status_id" :disabled="billOflading.fromJob || billOflading.uneditable || isCustomer" :required="!isCustomer" @onchange="setHeader(1)"
                              :options="[{id: 1, name: this.$t('bl_status.1')}, {id: 2, name: this.$t('bl_status.2')}, {id: 3, name: this.$t('bl_status.3'), disabled: !billOflading.can_finalize}]" />
                    <div class="row mb-2" v-if="!billOflading.can_finalize && !isCustomer">
                        <div class="col-4 col-form-label-sm">{{$t('operations.sv_approval')}}</div>
                        <div class="col-8">
                            <FormItem :id="'statusrequestapp'+index" type="checkbox" :label="$t('operations.sv_approval_request')" v-model="billOflading.request_approval" :disabled="billOflading.bill_of_lading_status_id == 4" />
                        </div>
                        <div class="col-4 ps-0 text-end" v-if="billOflading.status_namedate">
                            <span v-show="billOflading.supervisor_approval_id == 3" class="text-danger col-form-label-sm"> {{$t('quote_status.declined')}}: {{billOflading.status_namedate}} </span>
                            <span v-show="billOflading.supervisor_approval_id == 4" class="text-success col-form-label-sm">{{$t('quote_status.accepted')}}</span>
                            <span v-show="billOflading.supervisor_comment && billOflading.supervisor_approval_id == 3">
                                &hairsp;<i class="fa fa-info-circle hoverable"></i>
                                <div class="text-bubble"> <pre class="m-0 p-0"> {{billOflading.supervisor_comment}} </pre> </div>
                            </span>
                        </div>
                    </div>
                    <FormItem :id="'bltype'+index" :label="$t('operations.bl_type')" type="buttongroup" v-model="billOflading.bl_type" :options="typeOptions" :required="!isCustomer" :disabled="isCustomer || isFinal('statusOrJob')" />
                    <div class="row mb-2" v-show="billOflading.bl_type == 'Original'">
                        <div class="col-4"></div>
                        <div class="col-8">
                            <FormItem :id="'onethirdoriginal'+index" type="checkbox" :label="$t('operations.one_third_original')" v-model="billOflading.one_third_per_shipsmail" :disabled="isCustomer || isFinal('statusOrJob')" />
                        </div>
                    </div>
                    <FormItem :id="'releasetype'+index" :label="$t('operations.release')" type="buttongroup" v-model="billOflading.release_type" :options="releaseOptions" :required="true" :disabled="billOflading.fromJob" />
                    <div class="row mb-2">
                        <div class="col-4"></div>
                        <div class="col-8">
                            <FormItem :id="'is_lc'+index" :label="$t('operations.letter_of_credit')" v-model="billOflading.is_letterofcredit" type="checkbox" :disabled="isFinal()" />
                        </div>
                    </div>
                    <template v-if="!billOflading.id || jobItem || billOflading.showPaymentOptions">
                    <FormItem :id="'ptype'+index" :label="$t('operations.bl_payment')" type="buttongroup" v-model="billOflading.payment_option" :options="paymentOptions" @onclick="setCollect" :required="!isCustomer" :disabled="isCustomer || isFinal('statusOrJob')" />
                    <div class="row mb-2" v-if="billOflading.payment_option == 'COLLECT'">
                        <div class="col-4 col-form-label-sm required-label">{{$t('operations.collect_amount')}}</div>
                        <div class="col-6">
                            <FormItem id="acn" type="number" v-model="billOflading.amount_collect" :required="true" :disabled="isCustomer || isFinal('statusOrJob')" />
                        </div>
                        <div class="col-sm-2">
                            <FormItem id="acci" v-model="billOflading.amount_collect_currency_id" type="select" :options="currencies" nameField="code" :disabled="isCustomer || isFinal('statusOrJob')" />
                        </div>
                    </div>
                    <div class="row mb-2" v-show="billOflading.payment_option == 'COLLECT'">
                        <div class="col-4"></div>
                        <div class="col-8">
                            <FormItem :id="'includeclause'+index" type="checkbox" :label="$t('operations.collect_clause_include')" v-model="billOflading.collect_clause" :disabled="isCustomer || isFinal('statusOrJob')" />
                        </div>
                    </div>
                    </template>
                    <div class="row mb-1">
                        <div class="col-4"></div>
                        <div class="col-8"> <FormItem :id="'printdimensions'+index" type="checkbox" :label="$t('operations.printdimensions')" v-model="billOflading.printdimensions" :disabled="isCustomer || isFinal()" /> </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-4 pe-0 text-end"> <span class="badge bg-danger text-uppercase"> {{$t('form.new')}} </span> </div>
                        <div class="col-8"> <FormItem :id="'intransit'+index" type="checkbox" :label="$t('operations.in_transit')" v-model="billOflading.in_transit" :disabled="isFinal('statusLite')" /> </div>
                    </div>
                    <FormItem v-if="billOflading.in_transit" id="bl_country_id" :label="$t('navigation.countries')" v-model="billOflading.country_id" type="livesearch" :options="countries" 
                                :content="fillSearch(countries, billOflading.country_id)" :disabled="isFinal('statusLite')" />
                    <FormItem id="internalnotes" :label="$t('operations.internal_notes')" v-model="billOflading.internal_notes" extraOption="uppercase" type="textarea" @onchange="setUpperCase(billOflading, 'internal_notes')" v-if="!isCustomer" />
                    <FormItem id="reference" :label="$t('form.reference')" v-model="billOflading.reference" :disabled="isFinal()" />
                    <FormItem id="additionalnotes" :label="$t('operations.additional_notes')" v-model="billOflading.additional_notes" extraOption="uppercase" type="textarea" @onchange="setUpperCase(billOflading, 'additional_notes')" :disabled="isFinal()" />
                    <div class="d-flex text-danger">
                        <div class="col-4"></div>
                        <div class="col-8 ps-2" v-show="showSurveyedWarning"> <i class="fa fa-warning mx-1"></i> Please use the 'surveyed' option </div>
                        <div class="col-8 ps-2" v-show="showInTransitWarning"> <i class="fa fa-warning mx-1"></i> Please use the 'in transit' option </div>
                    </div>
                            
                    <FormItem v-if="!billOflading.legacy && !isCustomer" id="freight" :label="$t('sales.freight')" v-model="billOflading.freight" type="number" :disabled="isFinal()" />

                    <div v-if="jobItem">
                        <FormItem type="livesearch" id="bld1" v-model="billOflading.preload_vessel_id" :service="vesselService" :label="$t('sales.preload_vessel')" v-if="!isCustomer"
                                :content="billOflading.local_vessel" @onchange="(id, name, item) => billOflading.local_vessel = name" :disabled="isFinal('status')" />
                        <FormItem type="livesearch" id="bld2" v-model="billOflading.preload_port_id" :options="ports" :label="$t('sales.preload_port')" :disabled="isFinal('status')"
                                :content="billOflading.local_vessel_port" @onchange="(id, name, item) => billOflading.local_vessel_port = name" v-if="!isCustomer" />
                        <FormItem v-if="jobItem.bookings[selectedBooking].operatedByOptions.length > 1" :label="$t('operations.operated_by')" type="select" 
                                :options="jobItem.bookings[selectedBooking].operatedByOptions" v-model="billOflading.operated_by_office_id" :required="true" />

                        <h5 class="mt-4" v-show="!isCustomer">{{ $t('operations.issue_header') }}</h5>
                        <FormItem v-if="!isCustomer" type="radiogroup" v-model="billOflading.issuer" sizeItem="12" :disabled="isFinal()" :id="'nmtorcarrier'+index"
                                :options="[{id: 'nmt', name: 'NMT B/L'}, {id: 'carrier', name: 'Carrier B/L'}, {id: 'nmt_carrier', name: $t('operations.issue_opt_both', ['NMT', 'Carrier B/L'])}]" />
                        <template v-if="billOflading.issuer && billOflading.issuer.length > 4 && !isCustomer">
                            <FormItem id="issue-1" :label="$t('operations.issue_field_1')" v-model="billOflading.carrier_reference" :disabled="isFinal()" />
                            <div class="row">
                                <div class="col-4 col-form-label-sm"> {{$t('operations.issue_field_2')}} </div>
                                <div class="col-8"> 
                                    <div v-if="billOflading.carrier_bl_file_info && billOflading.carrier_bl_file_info[0]" class="mb-1">
                                        <a class="col-5" href="#">{{billOflading.carrier_bl_file_info[0].name}}</a>&ensp;<i class="btn p-0 fa fa-close text-danger" @click="billOflading.carrier_bl_file_info = null"></i>
                                    </div>
                                    <div v-if="billOflading.carrier_bl_file" class="mb-1">
                                        <a class="col-5" href="#" @click="downloadUploadedFile(billOflading.carrier_bl_file)">{{billOflading.carrier_bl_file.name}}</a>&ensp;
                                        <i v-show="!isFinal()" class="btn p-0 fa fa-close text-danger" @click="billOflading.deletedAttachments = [billOflading.carrier_bl_file.id]; billOflading.carrier_bl_file = null"></i>
                                    </div>
                                    <input class="form-control form-control-sm" type="file" @change="uploadFile($event, billOflading, 'carrier_bl_file_info')" accept="application/pdf" :disabled="isFinal()" />
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>



        <div class="tab-pane fade pt-4" v-if="!jobItem" :id="'iteniary-'+index" role="tabpanel" aria-labelledby="iteniary-tab">
            <div class="w-50" v-if="!isResetting">
            <template v-if="!job">
                <FormItem :label="$t('navigation.voyages')" id="bld7" :content="billOflading.voyage ? (billOflading.voyage.vessel.name + ' ' + billOflading.voyage.number) : ''" :service="voyageService"
                        :disabled="isFinal()" v-model="billOflading.voyage_id" type="livesearch" nameField="name" :required="true" @onchange="(id, name, item) => setNumber(id, name.split(' ').at('-1'), 0, item)" />
                <FormItem :label="$t('operations.operated_by')" type="select" :options="operatedByOptions" v-model="billOflading.operated_by_office_id" :required="true" />
                <FormItem type="livesearch" id="bld1" v-model="billOflading.preload_vessel_id" :service="vesselService" :label="$t('sales.preload_vessel')"
                        :content="billOflading.local_vessel" @onchange="(id, name, item) => billOflading.local_vessel = name" :disabled="isFinal('status')" />
                <FormItem type="livesearch" id="bld2" v-model="billOflading.preload_port_id" :options="ports" :label="$t('sales.preload_port')" :disabled="isFinal('status')"
                        :content="billOflading.preload_port ? billOflading.preload_port.name : ''" @onchange="(id, name, item) => billOflading.local_vessel_port = name" />
                <FormItem type="select" id="bld3" v-model="billOflading.port_of_loading_id" :options="polpodOptions" :label="$t('sales.loading_ports')" :required="true" 
                        @onchange="(e, opts, item) => setNumber(item.id, item.code, 1, item)" :disabled="!billOflading.voyage_id || isFinal()" />
                <FormItem type="select" id="bld4" v-model="billOflading.port_of_discharge_id" :options="polpodOptions" :label="$t('sales.discharge_ports')" :required="true" 
                        @onchange="(e, opts, item) => setNumber(item.id, item.code, 2, item)" :disabled="!billOflading.voyage_id || isFinal()" />
                <div v-if="billOflading.port_of_final_destination && billOflading.legacy" class="row">
                    <div class="col-form-label col-form-label-sm col-sm-4">{{ $t('operations.lc_port_final') }}</div>
                    <div class="col-sm-8"><div class="form-control form-control-sm disabled">{{ billOflading.port_of_final_destination }}</div></div>
                </div>
                <FormItem v-else type="livesearch" id="bld5" v-model="billOflading.port_of_final_destination_id" :options="ports" @onchange="(id, name, item) => setNumber(id, null, 3, item)" 
                        :content="fillSearch(ports, billOflading.port_of_final_destination_id, billOflading.port_of_final_destination)" :label="$t('operations.lc_port_final')" :disabled="isFinal()" />
                <br />
                <FormItem type="livesearch" id="bld9" v-model="billOflading.office_id" :options="offices" :label="$t('navigation.offices')" :content="billOflading.office" @onchange="setBranches" :required="true" :disabled="isFinal()" />
                <FormItem v-if="branches.length > 0 && !isFinal()" :label="$t('form.branch')" v-model="billOflading.branch_id" type="select" id="bld8" :options="branches" @onchange="setCity" :required="true" />
                <div v-if="billOflading.customer_name && billOflading.legacy" class="row">
                    <div class="col-form-label col-form-label-sm col-sm-4">{{ $t('navigation.customers') }}</div>
                    <div class="col-sm-8">{{ billOflading.customer_name }}</div>
                </div>
                <div v-if="billOflading.carrier_reference && billOflading.legacy" class="row">
                    <div class="col-form-label col-form-label-sm col-sm-4">{{ $t('legacy.carrier_reference') }}</div>
                    <div class="col-sm-8">{{ billOflading.carrier_reference }}</div>
                </div>
                <FormItem v-if="!(billOflading.customer_name && billOflading.legacy)" v-model="billOflading.customer_id" type="livesearch" id="bld6" :service="customerService"
                          nameField="name" :content="billOflading.customer" :label="$t('navigation.customers')" :required="true" :disabled="isFinal()" @onchange="getDefaultShipper" />
            </template>
            <h5 class="mt-4" v-show="!billOflading.legacy">{{ $t('operations.issue_header') }}</h5>
            <FormItem v-if="!billOflading.legacy" type="radiogroup" v-model="billOflading.issuer" sizeItem="12" :disabled="isFinal()" :id="'nmtorcarrier'+index"
                     :options="[{id: 'nmt', name: 'NMT B/L'}, {id: 'carrier', name: 'Carrier B/L'}, {id: 'nmt_carrier', name: $t('operations.issue_opt_both', ['NMT', 'Carrier B/L'])}]" />
            <template v-if="billOflading.issuer && billOflading.issuer.length > 4 && !billOflading.legacy">
                <FormItem id="issue-1" :label="$t('operations.issue_field_1')" v-model="billOflading.carrier_reference" :disabled="isFinal()" />
                <div class="row">
                    <div class="col-4 col-form-label-sm"> {{$t('operations.issue_field_2')}} </div>
                    <div class="col-8"> 
                        <div v-if="billOflading.carrier_bl_file_info && billOflading.carrier_bl_file_info[0]" class="mb-1">
                            <a class="col-5" href="#">{{billOflading.carrier_bl_file_info[0].name}}</a>&ensp;<i class="btn p-0 fa fa-close text-danger" @click="billOflading.carrier_bl_file_info = null"></i>
                        </div>
                        <div v-if="billOflading.carrier_bl_file" class="mb-1">
                            <a class="col-5" href="#" @click="downloadUploadedFile(billOflading.carrier_bl_file)">{{billOflading.carrier_bl_file.name}}</a>&ensp;
                            <i v-show="!isFinal()" class="btn p-0 fa fa-close text-danger" @click="billOflading.deletedAttachments = [billOflading.carrier_bl_file.id]; billOflading.carrier_bl_file = null"></i>
                        </div>
                        <input class="form-control form-control-sm" type="file" @change="uploadFile($event, billOflading, 'carrier_bl_file_info')" accept="application/pdf" :disabled="isFinal()" />
                    </div>
                </div>
            </template>
            </div>
        </div>


        <template v-if="jobItem">
            <div v-html="getPDFHeaderHTML()" class="pdf-block pt-2"></div>
            <div class="d-flex justify-content-end pb-2">
                <FormItem class="w-25" :label="$t('overview.surveyer')" type="select" v-model="billOflading.surveyor_id" :options="surveyers" :required="hasSurveyedUnits(billOflading)" :disabled="isCustomer || isFinal('statusOrJob')" />
            </div>
            <hr/>
        </template>


        <div :class="{'tab-pane fade pt-4': !jobItem, 'd-none': jobItem && !billOflading.is_letterofcredit}" :id="'loc-'+index" role="tabpanel" aria-labelledby="loc-tab">
            <div v-if="!jobItem" v-html="getPDFHeaderHTML()" class="pdf-block"></div>
            <h4 v-else class="fw-bold mx-3 ms-0"> {{ $t('operations.letter_of_credit') }} </h4>

            <section class="row unit-details-section">
                <div class="col-4">
                    <div class="col-form-label-sm">{{$t('operations.lc_marks')}}</div>
                    <div class="w-75">
                        <textarea style="min-height: 200px" class="form-control text-uppercase" v-model="billOflading.lc_marks_and_numbers" @change="setUpperCase(billOflading, 'lc_marks_and_numbers')" :disabled="isFinal()"></textarea>
                    </div>
                </div>
                <div class="col-4">
                    <div class="col-form-label-sm">{{$t('operations.lc_desc')}}</div>
                    <div class="w-100">
                        <textarea style="min-height: 200px" class="form-control text-uppercase" v-model="billOflading.lc_description_of_goods" @change="setUpperCase(billOflading, 'lc_description_of_goods')" :disabled="isFinal()"></textarea>
                    </div>
                </div>
                <div class="col-4">
                    <div class="col-form-label-sm">{{$t('overview.from')}}</div>
                    <input v-model="billOflading.local_vessel_port" @change="setUpperCase(billOflading, 'local_vessel_port')" class="form-control form-control-sm text-uppercase" :disabled="isFinal()" />
                    <div class="col-form-label-sm">{{$t('sales.loading_ports')}}</div>
                    <input v-model="billOflading.lc_port_of_loading_name" @change="setUpperCase(billOflading, 'lc_port_of_loading_name')" class="form-control form-control-sm text-uppercase" :disabled="isFinal()" />
                    <div class="col-form-label-sm">{{$t('sales.discharge_ports')}}</div>
                    <input v-model="billOflading.lc_port_of_discharge_name" @change="setUpperCase(billOflading, 'lc_port_of_discharge_name')" class="form-control form-control-sm text-uppercase" :disabled="isFinal()" />
                    <div class="col-form-label-sm">{{$t('operations.lc_port_final')}}</div>
                    <input v-model="billOflading.lc_port_of_final_destination_name" @change="setUpperCase(billOflading, 'lc_port_of_final_destination_name')" class="form-control form-control-sm text-uppercase" :disabled="isFinal()" />
                </div>
            </section>
            <hr v-if="jobItem" />
        </div>



        <div class="pt-4" :class="{'tab-pane fade': !jobItem}" :id="'containers-'+index" role="tabpanel" aria-labelledby="containers-tab">
            <div :class="billOflading.showPendingUnits ? 'modal modal-in-modal show d-block' : 'd-none'" id="addUnitsModal" tabindex="-1" aria-labelledby="addUnitsModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                <div class="modal-content adjustable">
                    <div class="modal-header">
                        <h5 class="col-11 fw-bold">{{$t('operations.let_unit_select')}}</h5>
                        <button class="btn btn-sm col-1" type="button" @click="billOflading.showPendingUnits = false"> <i class="fa fa-xl fa-close"></i> </button>
                    </div>
                    <div class="modal-body" v-if="jobItem && hasPendingUnits">
                        <div class="row fw-bold col-form-label-sm">
                            <div class="col-1"> <FormItem :id="'surveyed-all'+index" v-model="all_selected" type="checkbox" @onchange="selectAll" /> </div>
                            <div class="col-4"> {{ $t('sales.vin') }} </div>
                            <div class="col-3"> {{ $t('navigation.makes') + '/' + $t('navigation.models') }} </div>
                            <div class="col-4"> {{ $t('form.weight') }} </div>
                        </div>
                        <label class="row col-form-label-sm" v-for="(unit) in pending_units" :key="unit.id" :for="'surveyed-'+unit.id">
                            <div class="col-1"> <FormItem :id="'surveyed-'+index+'-'+unit.id" v-model="unit.selected" type="checkbox" @onchange="setSelectAll" /> </div>
                            <div class="col-4 text-ellipsis text-uppercase"> {{ unit.chassis_number }} </div>
                            <div class="col-3 text-ellipsis"> {{ unit.make.name + ' ' + unit.model.name }} </div>
                            <div class="col-4 text-ellipsis number-format"> {{ unit.weight }} </div>
                        </label>
                    </div>
                    <div class="modal-footer d-flex">
                        <button type="button" class="btn btn-success" @click.prevent="addJobUnits()"><i class="fa fa-arrow-down-to-line"></i>&ensp;{{$t('form.add', [$tc('navigation.units', 2)])}}</button>
                    </div>
                </div>
                </div>
            </div>
            <div v-if="!jobItem" v-html="getPDFHeaderHTML()" class="pdf-block"></div>

            <div class="d-flex justify-content-between mb-3" v-if="!jobItem">
                <div class="w-75 gap-1">
                    <button class="btn btn-success me-5" type="button" v-if="jobItem && hasPendingUnits" :disabled="isFinal()" @click="showPendingUnits">
                        <span class="fa fa-ellipsis"></span> &nbsp;{{$t('operations.let_unit_select')}}
                    </button>
                    <button class="btn btn-secondary me-5" type="button" v-else-if="billOflading.job_booking_id" disabled="">
                        <span class="fa fa-ellipsis"></span> &nbsp;{{$t('operations.let_unit_select_done')}}
                    </button>
                    <button class="btn btn-success" type="button" @click="suggestNewJobUnit" :disabled="isFinal()">
                        <span class="fa fa-plus"></span> &nbsp;{{$t('form.add', [$t('operations.manual_unit')])}}
                    </button>
                    <!--<button class="btn btn-secondary" type="button" :disabled="true">
                        <span class="fa fa-plus"></span> &nbsp;{{$t('form.add', ['mafi'])}}
                    </button>
                    <button class="btn btn-secondary" type="button" @click="addContainer(billOflading)" :disabled="true">
                        <span class="fa fa-plus"></span> &nbsp;{{$t('form.add', [$t('navigation.containers')])}}
                    </button>-->
                </div>
                <div class="w-25">
                    <FormItem :label="$t('overview.surveyer')" type="select" v-model="billOflading.surveyor_id" :options="surveyers" :required="hasSurveyedUnits(billOflading)" :disabled="isFinal('statusOrJob')" />
                </div>
            </div>
            <section class="unit-details-section" :class="{'for-job': jobItem != null, 'from-job': mode == 'job'}" v-show="billOflading.job_units.length">
                <div class="col-4 pe-0">
                    <p class="fw-bold">{{$tc('navigation.units', 2)}}</p>
                    <div class="d-flex fw-bold">
                        <div class="col-sm-1 ps-1">#</div>
                        <div class="col-sm-auto" :style="{width: job ? '180px' : '213px'}"> {{ $t('sales.vin') }} </div>
                        <div class="col-sm-auto" :style="{width: job ? '60px' : '73px'}">Kgs.</div>
                        <div class="col-sm-2">Cbm.</div>
                        <div class="col-sm-1 p-0"></div>
                    </div>
                    <div id="fixed-unit-list" :class="{'for-job': jobItem != null, 'from-job': mode == 'job'}">
                        <div v-for="unit, uIndex in billOflading.job_units" :key="uIndex" class="d-flex mb-1 drag-and-drop" @click="selectUnit(unit)" :id="'uli-'+billOflading.id+'-'+uIndex"
                            :draggable="!isFinal()" @dragstart="selectUnit(unit); onDragStart($event, billOflading, uIndex, false)" @dragend="onDragEnd" :class="{'selected': (selectedUnit && (selectedUnit.id ? selectedUnit.id == unit.id : selectedUnit.uuid == unit.uuid))}">
                            <span :class="{'droppable': dragData.isDragging}" @dragenter="onDragOver($event, true, uIndex)" 
                                    @dragleave="onDragOver($event, false)" @drop="onDrop($event, billOflading.job_units, false)" @dragover="$event.preventDefault()"></span>
                            <div class="col-sm-1 ps-1 col-form-label-sm"> <span>{{uIndex + 1}}</span> </div>
                            <div class="col-sm-6 text-uppercase col-form-label-sm"> {{ unit.chassis_number ? unit.chassis_number : $t('sales.new_x', [$t('navigation.units')]) }} </div>
                            <div class="col-sm-2 number-format col-form-label-sm"> {{ unit.weight }} </div>
                            <div class="col-sm-2 number-format col-form-label-sm"> {{ unit.volume }} </div>
                            <div class="col-sm-1 p-0" :style="{'visibility': !jobItem || jobItem.bookings[this.selectedBooking].bills_of_lading[0].id != blItem.id ? 'visible' : 'hidden'}"> 
                                <button class="btn btn-sm" type="button" @click="removeJobUnit(index, uIndex)" :disabled="isFinal()"><i class="fa fa-close text-danger"></i></button> 
                            </div>
                        </div>
                        <div class="row mb-5 drag-and-drop">
                            <span :class="{'droppable': dragData.isDragging}" @dragenter="onDragOver($event, true, billOflading.job_units.length)" 
                                @dragleave="onDragOver($event, false)" @drop="onDrop($event, billOflading.job_units, false)" @dragover="$event.preventDefault()"></span>
                        </div>
                        <button class="btn btn-success" type="button" @click="showPendingUnits" v-if="jobItem && !dragData.isDragging && !isFinal() && index != 0 && hasPendingUnits">
                            <span class="fa fa-plus"></span> &nbsp;{{$t('form.add', [$tc('navigation.units', 2).toLowerCase()])}}
                        </button>
                    </div>
                </div>
                <div class="col-8">
                <div id="fixed-unit-details" :class="{'for-job': jobItem != null, 'from-job': mode == 'job'}">
                    <p class="fw-bold">{{$t('operations.unit_details')}}</p>
                    <template v-if="selectedUnit">
                    <div class="d-flex mb-2">
                        <div class="col-auto pe-2 col-form-label-sm" style="width: 10.33%">
                            <FormItem :id="'cond-'+selectedUnit.id" v-model="selectedUnit.quantity" type="select" :options="$parent.quantityOptions" :selectDefault="false" :disabled="isFinal()" />
                        </div>
                        <div class="col-auto pe-2 col-form-label-sm" style="width: 15%">
                            <FormItem :id="'cond-'+selectedUnit.id" v-model="selectedUnit.condition_id" type="select" :options="$parent.conditionOptions" :selectDefault="false" :disabled="isFinal()" />
                        </div>
                        <div class="col-auto pe-2 col-form-label-sm" style="width: 8%">
                            <div class="pt-1">UNIT</div>
                        </div>
                        <div class="col-3 pe-2 pt-1">
                            <FormItem :id="'make-'+index" v-model="selectedUnit.unit_make_id" type="livesearch" :service="makeService" :content="selectedUnit.make ? selectedUnit.make.name : ''" 
                                    @onchange="(id, name) => {selectedUnit.make = {id: id, name: name}; setDescription(selectedUnit) }" :disabled="isFinal()" />
                        </div>
                        <div class="col-3 pe-2 pt-1">
                            <FormItem :id="'model-'+index" v-model="selectedUnit.unit_model_id" type="livesearch" :service="modelService" :content="selectedUnit.model ? selectedUnit.model.name : ''"
                                    :disabled="!selectedUnit.unit_make_id || isFinal()" @onchange="(a, b) => {selectedUnit.model = {id: a, name: b}; setDescription(selectedUnit) }" :extraOptions="{'make_id': selectedUnit.unit_make_id}" />
                        </div>
                        <div class="col-sm-2 pt-1">
                            <FormItem :id="'surveyed-'+index+'-'+selectedUnit.id" v-model="selectedUnit.is_surveyed" type="checkbox" :label="$t('operations.is_surveyed')" :disabled="isCustomer || isFinal('statusOrJob')" />
                        </div>
                    </div>
                    <div class="d-flex mb-0">
                        <div class="col-4 pe-2 col-form-label-sm pe-0" :class="{'required-label': true}"> {{ $t('sales.vin') }} </div>
                        <div class="col-sm-2 pe-2 col-form-label-sm" :class="{'required-label': licensePlateRequired}"> {{ $t('sales.license_plate') }} </div>
                        <div class="col-sm-2 pe-2 col-form-label-sm pe-0" :class="{'required-label': buildYearRequired}"> {{ $t('sales.build_year') }} </div>
                        <div class="col-sm-2 pe-2 col-form-label-sm" :class="{'required-label': colourRequired}"> {{ $t('navigation.colours') }} </div>
                        <div class="col-sm-2 col-form-label-sm" :class="{'required-label': hsCodeRequired}"> {{ $t('navigation.hs_codes') }} </div>
                    </div>
                    <div class="d-flex mb-2">
                        <div class="col-4 pe-2 col-form-label-sm text-ellipsis license-plate pt-0">
                            <input class="form-control form-control-sm license-plate" v-model="selectedUnit.chassis_number" @keydown="licensePlateAlterInput" :disabled="isFinal()"
                                @change="toUpper($event.target, selectedUnit)" :style="{'backgroundColor': checkChassisNr(selectedUnit.chassis_number)}" :required="true">
                        </div>
                        <div class="col-sm-2 pe-2 col-form-label-sm text-ellipsis license-plate pt-0 optional">
                            <input class="form-control form-control-sm license-plate" v-model="selectedUnit.license_plate" @keydown="licensePlateAlterInput" :required="licensePlateRequired"
                                @change="toUpper($event.target); setDescription(selectedUnit)" :disabled="isFinal()">
                        </div>
                        <div class="col-sm-2 pe-2">
                            <FormItem :id="'year-'+selectedUnit.id" v-model="selectedUnit.construction_year" type="number" @onchange="setDescription(selectedUnit)" :required="buildYearRequired" :disabled="isFinal()" />
                        </div>
                        <div class="col-sm-2 pe-2">
                            <FormItem :id="'color-'+selectedUnit.id" v-model="selectedUnit.colour_id" type="select" :options="colours" content="narrow" @onchange="setDescription(selectedUnit)" :required="colourRequired" :disabled="isFinal()" />
                        </div>
                        <div class="col-sm-2">
                            <FormItem :id="'hscode-'+selectedUnit.id" v-model="selectedUnit.hs_code" type="text" content="narrow" @onchange="setDescription(selectedUnit)" :required="hsCodeRequired" :disabled="isFinal()" />
                        </div>
                    </div>
                    <div class="d-flex mb-0">
                        <template v-if="selectedUnit.booked_measurement && !job">
                            <div class="col-2 col-form-label-sm required-label"> {{ $t('form.length') }} </div>
                            <div class="col-2 col-form-label-sm required-label"> {{ $t('form.width') }} </div>
                            <div class="col-2 col-form-label-sm required-label"> {{ $t('form.height') }} </div>
                        </template>
                        <div class="col-sm-2 col-form-label-sm required-label"> {{ $t('form.weight') }} </div>
                        <div class="col-sm-2 col-form-label-sm required-label"> {{ $t('form.volume') }} </div>
                    </div>
                    <div class="d-flex mb-2">
                        <template v-if="selectedUnit.booked_measurement && !job">
                            <div class="col-2 pe-2">
                                <FormItem :id="'lengthsp-'+selectedUnit.id" v-model="selectedUnit.booked_measurement.length" type="number-with-tags" endTag="cm" :required="true"  :disabled="isFinal()" @onchange="calcVolume(selectedUnit)" />
                            </div>
                            <div class="col-2 pe-2">
                                <FormItem :id="'widthsp-'+selectedUnit.id" v-model="selectedUnit.booked_measurement.width" type="number-with-tags" endTag="cm" :required="true"  :disabled="isFinal()" @onchange="calcVolume(selectedUnit)" />
                            </div>
                            <div class="col-2 pe-2">
                                <FormItem :id="'heightsp-'+selectedUnit.id" v-model="selectedUnit.booked_measurement.height" type="number-with-tags" endTag="cm" :required="true"  :disabled="isFinal()" @onchange="calcVolume(selectedUnit)" />
                            </div>
                        </template>
                        <div class="col-sm-2 pe-2">
                            <FormItem :id="'weightsp-'+selectedUnit.id" v-model="selectedUnit.weight" type="number-with-tags" endTag="kg" :required="true"  :disabled="isFinal()" />
                        </div>
                        <div class="col-sm-2 pe-2">
                            <FormItem :id="'volumesp-'+selectedUnit.id" v-model="selectedUnit.volume" type="number-with-tags" endTag="cbm" :required="true"  :disabled="isFinal()" />
                        </div>
                    </div>
                    <template v-if="!billOflading.is_letterofcredit">
                        <hr>
                        <p class="fw-bold">{{$t('operations.bl_preview')}}</p>
                        <div class="d-flex">
                            <div class="col-sm-2 col-form-label-sm">{{selectedUnit.quantity + ' ' + (selectedUnit.condition_id == 2 ? 'USED' : 'NEW') + ' UNIT' + (selectedUnit.quantity != 1 ? 'S' : '')}}</div>
                            <div id="textarea-dialog" class="col-8">
                                <textarea rows="5" class="form-control mb-2 text-uppercase w-100" v-model="selectedUnit.description" :disabled="isFinal()"></textarea>
                                <FormItem :id="'fdcbdc'+index" type="checkbox" v-model="selectedUnit.use_generated_unit_description" :label="$t('operations.use_gen_desc')" :disabled="isFinal()" @onchange="setDescription(selectedUnit)" />
                            </div>
                        </div>
                    </template>
                    </template>
                    <div class="pt-2 text-uppercase">
                        <div v-show="billOflading.surveyor_id"> 
                            CARGO CONDITION AS PER SURVEY REPORT ISSUED BY {{fillSearch(surveyers, billOflading.surveyor_id)}}
                        </div>
                        <div v-if="billOflading.in_transit && billOflading.country_id"> 
                            CARGO IN TRANSIT TO {{fillSearch(countries, billOflading.country_id)}} AT CONSIGNEES OWN RISK AND EXPENSE
                        </div>
                        <div v-else-if="billOflading.is_letterofcredit && billOflading.lc_port_of_final_destination_name"> 
                            CARGO IN TRANSIT TO {{billOflading.lc_port_of_final_destination_name}}
                        </div>
                        <div v-else-if="billOflading.port_of_final_destination && billOflading.port_of_final_destination_id"> 
                            CARGO IN TRANSIT TO {{billOflading.port_of_final_destination.name ? billOflading.port_of_final_destination.name : fillSearch(ports, billOflading.port_of_final_destination)}}
                        </div>
                    </div>
                </div>
                </div>
            </section>
            <section class="d-flex" style="min-height: calc(100vh - 550px)" v-if="billOflading.job_units.length == 0">
                <div class="m-auto text-center">
                    <h5>No units in this Bill of Lading</h5>
                    <p v-show="!billOflading.legacy">This looks empty. Please <span v-show="jobItem"><span class="link" @click="showPendingUnits">select some job units</span>
                                                    </span><span v-show="!jobItem"> add <span class="link" @click="suggestNewJobUnit">a manual unit</span></span> to get started.</p>
                </div>
            </section>
        </div>



        <div class="pt-4" :class="{'tab-pane fade': !jobItem}" :id="'preview-'+index" role="tabpanel" aria-labelledby="preview-tab">
            <template v-if="!jobItem">
                <div v-html="getPDFHeaderHTML()" class="pdf-block"></div>
                <div class="row pdf-header mb-4">
                    <div class="col-3">Marks and Nos.</div>
                    <div class="col-3">Number and kind of packages</div>
                    <div class="col-3">Description of goods</div>
                    <div class="col-1p5">Gross weight</div>
                    <div class="col-1p5">Measurement</div>
                </div>
                <div v-if="billOflading.is_letterofcredit" class="row pdf-unit">
                    <pre class="col-3" style="font-size: .9rem">{{billOflading.lc_marks_and_numbers}}</pre>
                    <div class="col-3"> </div>
                    <pre class="col-3" style="font-size: .9rem">{{billOflading.lc_description_of_goods}}</pre>
                    <div class="col-1p5">{{calcTotals.weight}} KGS</div>
                    <div class="col-1p5">{{billOflading.printdimensions ? (calcTotals.cbm + ' CBM') : ""}}</div>
                </div>
                <div v-else v-for="unit, uindex in billOflading.job_units" :key="uindex" class="row pdf-unit">
                    <div class="col-3">{{unit.chassis_number}}</div>
                    <div class="col-sm-2">{{unit.quantity + ' ' + (unit.condition_id == 2 ? 'USED' : 'NEW') + ' UNIT' + (unit.quantity != 1 ? 'S' : '')}}</div>
                    <div class="col-1 text-end">{{unit.is_surveyed && hasUnsurveyedUnits ? '*' : ''}}</div>
                    <pre class="col-3" style="font-size: .9rem">{{unit.description}}</pre>
                    <div class="col-1p5">{{unit.weight}} KGS</div>
                    <div class="col-1p5">{{billOflading.printdimensions ? (unit.volume + ' CBM') : ""}}</div>
                </div>
            </template>
            <div v-if="mode == 'job'" class="mt-2 d-flex gap-2 justify-content-end" :class="{'jobunit-displacement': jobItem}">
                <FormItem type="download" label="Download ISF" startTag="fal fa-file-excel" :service="$parent.downloadExcel" v-if="billOflading.id" :functionArgs="['isf', billOflading.id, billOflading.number]" />
                <FormItem type="download" id="savedownloadbutton" :label="$t('form.save_download')" startTag="fal fa-file-pdf" :service="$parent.save" :functionArgs="[billOflading, index, this, true]" />
                <FormItem type="download" id="saveblbutton" :label="$t('form.save')" startTag="fal fa-save" :options="{send: true}" :service="$parent.save" :functionArgs="[billOflading, index, this, false]" />
            </div>
        </div>
    </div>
</template>

<script>
    import jobService from '@/services/JobService';
    import voyageService from '@/services/VoyageService';
    import vesselService from '@/services/VesselService';
    import makeService from "@/services/MakeService";
    import modelService from "@/services/ModelService";
    import billofladingService from '@/services/BillOfLadingService';
    import fileService from "@/services/FileService";
    import customerService from "@/services/CustomerService";
    import store from '@/store/user';

    export default {
        props: ['jobItem', 'colours', 'hscodes', 'currencies', 'countries', 'surveyers', 'containerTypes', 'loaded', 'blItem', 'selectedBooking', 'index', 'ports', 'loadingCountry', 'dischargeCountry', 'offices', 'isManualResetting', 'mode', 'isCustomer'],
        name: 'BillOfLadingComponentNew',
        computed: {
            job: { get() { return this.jobItem } },
            billOflading: { get() { return this.blItem } },
            hasPendingUnits() { return this.jobItem.bookings[this.selectedBooking].bills_of_lading[0].id != this.blItem.id && this.jobItem.bookings[this.selectedBooking].bills_of_lading[0].job_units.length > 1 },
            buildYearRequired() { return this.isFinal() && (this.loadingCountry.construction_year_required_export || this.dischargeCountry.construction_year_required_import) },
            colourRequired() { return this.isFinal() && (this.loadingCountry.colour_required_export || this.dischargeCountry.colour_required_import) },
            hsCodeRequired() { return this.isFinal() && (this.loadingCountry.hs_code_required_export || this.dischargeCountry.hs_code_required_import) },
            licensePlateRequired() { return this.isFinal() && (this.loadingCountry.license_plate_required_export || this.dischargeCountry.license_plate_required_import) },
            showSurveyedWarning() { return this.billOflading.additional_notes && this.billOflading.additional_notes.match(/survey/i) },
            showInTransitWarning() { return this.billOflading.additional_notes && this.billOflading.additional_notes.match(/transit/i) }
        },
        watch: {
            isManualResetting: {
                immediate: true,
                handler(to) {
                    if(to){
                        this.reset();
                        this.$parent.isResetting = false;
                    }
                }
            },
        },
        data() {
            return {
                dragData: {bl: null, dragIndex: null, dropIndex: null, isDragging: false},
                selectedBL: 0,
                bookingSave: {},
                onPageDragOverEvent: null,
                typeOptions: [{id: "Original", name: this.$t('operations.original_bl')}, {id: "Sea Waybill", name: this.$t('operations.express_bl')}],
                releaseOptions: [{id: "PRINT", name: this.$t('operations.release_print')}, {id: "EMAIL", name: this.$t('operations.release_email')}],
                paymentOptions: [{id: 'PREPAID', name: this.$t('operations.prepaid')}, {id: 'COLLECT', name: this.$t('operations.collect')}],
                calcTotals: {quanity: 0, weight: 0, cbm: 0},
                polpodOptions: [],
                isResetting: false,
                loading: false,
                invalid: {},
                surcharges: [],
                branches: [],
                operatedByOptions: [],
                pending_units: [],
                numberAsArray: ["","","",""],
                voyageService: voyageService,
                fullVoyageName: '',
                setUnitsModalOpen: false,
                hasUnsurveyedUnits: false,
                makeService: makeService,
                modelService: modelService,
                selectedUnit: false,
                all_selected: false,
                vesselService: vesselService,
                customerService: customerService
            }
        },
        methods: {   
            hardCopy(obj){
                return JSON.parse(JSON.stringify(obj));
            },
            reset(){
                this.all_selected = false;
                this.polpodOptions = [];
                this.branches = [];
                this.operatedByOptions = [];
                this.numberAsArray = ["","","",""];
                this.fullVoyageName = '';
                this.hasUnsurveyedUnits = false;
                this.selectedUnit = false;
                this.setUnitsModalOpen = false;
                this.autoSelectUnit();
                if(!this.job){
                    const el = document.getElementById("generaldets-tab-"+this.index);
                    if(el) el.click();
                }
                if(this.jobItem){
                    this.calcTotals = {quanity: 0, weight: 0, cbm: 0};
                    this.billOflading.job_units.forEach(unit => {
                        this.calcTotals.quantity += Number(unit.quanity);
                        this.calcTotals.weight += Number(unit.weight);
                        this.calcTotals.cbm += Number(unit.volume);
                        if(!this.hasUnsurveyedUnits && !unit.is_surveyed) this.hasUnsurveyedUnits = true;
                    });
                }
                this.isResetting = true;
                window.setTimeout(() => this.isResetting = false, 100);
            },
            
            setSecondNotify(e, bl){
                if(e.target.checked){
                    bl.second_notify = {
                        "name": "",
                        "email": "",
                        "phone": "",
                        "defaultISO": "NL",
                        "address": "",
                        "country_id": null
                    };
                }
                else{
                    bl.second_notify = null;
                }
            },
            setNotify(e, bl){
                if(e.target.checked){
                    let fields = ["name", "phone", "email", "country_id", "address"];
                    fields.forEach((field) => bl.notify[field] = bl.consignee[field]);
                }
            },
            alsoFillInNotify(e, bl, field, fromPhone = false){
                if(bl.notify_same_as_consignee){
                    const value = e && e.target ? e.target.value : e;
                    bl.notify[field] = value;
                }
                if(fromPhone)
                    this.$forceUpdate();
            },
            setDefaultCountry(item) {
                const match = this.countries.find(country => country.id == item.country_id);
                item.defaultISO = match ? match.iso : 'NL';
                item.refresh = true;
                this.$nextTick(() => item.refresh = false);
            },
            setUpperCase(item, field = 'address'){
                item[field] = item[field].toUpperCase();
            },
            updateBL(bl, index){
                //Validation
                if(!bl.release_type){
                    alert("Please select the release type");
                    return;
                }
                if(!this.isCustomer && (!bl.payment_option || !bl.bl_type)){
                    alert(this.$t('errors.bl'));
                    return;
                }
                if(this.hasSurveyedUnits(bl) && !bl.surveyor_id){
                    alert(this.$t('errors.bl_2'));
                    return;
                }
                if(bl.in_transit && !bl.country_id){
                    alert(this.$t('errors.bl_3'));
                    return;
                }
                if(bl.job_booking_voyage_manifest && bl.job_booking_voyage_manifest.find(manifest => manifest.is_nmt_bl === null)){
                    alert(this.$t('errors.bl_4'));
                    return;
                }
                const requiredQuery = !this.isFinal() ? "#iteniary input" : ("#generaldets-"+index+" input, #generaldets-"+index+" .vue-tel-input");
                let isValid = true;
                let required = document.querySelectorAll(requiredQuery);
                for(let field of required){
                    let value = field.value;
                    if(field.tagName == "DIV"){
                        value = field.children[1].value;
                    }
                    if((field.required || field.classList.contains('required')) && !value){
                        field.classList.add('is-invalid');
                        isValid = false;
                    }
                    else{
                        field.classList.remove('is-invalid');
                    }
                }
                if(!isValid){
                    alert(this.$t('errors.empty_fields'));
                    return;
                }
                if(this.isFinal('statusLite') && (store.getters.getCompanyHq || bl.can_approve_finalize_own || bl.operated_by_office_id == store.getters.getCompanyId)){
                    bl.supervisor_approval_id = 4;
                }
                jobService.createOrUpdateBLDetails(bl, bl.id).then(response => {
                    this.$toast.success(this.$t('form.' + (bl.id === null ? 'created' : 'updated') + '_x', [this.$t('navigation.bills_of_lading')]));
                    if(this.job && this.mode == 'job' && bl.id === null){
                        this.$parent.blCount++;
                    }
                    bl.id = response.data.id;
                    bl.number = response.data.number;
                    if(this.job && this.mode == 'job'){
                        this.$parent.$refs.contactHistory.addMessage(response.data.new_message);
                        this.job.bookings[this.selectedBooking].bills_of_lading.forEach(thisbl => {
                            thisbl.job_booking_voyage_manifest.forEach((jbv, jbvindex) => {
                                if(jbv.is_nmt_bl === null){
                                    jbv.is_nmt_bl = bl.job_booking_voyage_manifest[jbvindex].is_nmt_bl;
                                    jbv.is_consolidated = bl.job_booking_voyage_manifest[jbvindex].is_consolidated;
                                }
                            });
                        });
                    }
                    else{
                        this.$parent.polCountry = response.data.port_of_loading.country;
                        this.$parent.podCountry = response.data.port_of_discharge.country;
                    }
                    this.$forceUpdate();
                }).catch(error => { console.log('error', error) });
            },
            deleteBL(bl){
                if(confirm(this.$t('form.are_you_sure_delete') + ' ' + bl.number + '?')){
                    jobService.deleteBL(bl.id).then(deleteResponse => {
                        jobService.show(this.job.id).then(response => {
                            window.tabToOpen = (this.selectedBooking + 1) - 1;
                            this.reset();
                            this.setData(response.data);
                        }).catch(error => { console.log('error', error) });
                    }).catch(error => { console.log('error', error) });
                }
            },
            saveAndDownloadPDF(args) {
                if(!this.$parent.validate()){
                    return false;
                }
                if(!this.billOflading.id){
                    return billofladingService.store(this.billOflading).then((response) => {
                        this.billOflading.id = response.data.id;
                        this.billOflading.number = response.data.number;
                        return this.downloadPDF(['bill-of-lading', response.data.id, response.data.number]);
                    });
                }
                else{
                    return billofladingService.update(this.billOflading).then((response) => {
                        return this.downloadPDF(['bill-of-lading', response.data.id, response.data.number]);
                    });
                }
            },
            downloadPDF(args) {
                const type = args[0];
                const value = args[1];
                const name = args[2];
                delete this.billOflading.file_info;
                return jobService.downloadPDF(type, value).then(response => this.triggerDownload(response.data, name + ".pdf")).catch(error => this.toastError(error) );
            },
            downloadExcel(args) {
                const type = args[0];
                const value = args[1];
                const name = args[2];
                return jobService.downloadExcel(type, value).then(response => this.triggerDownload(response.data, name + ".xlsx")).catch(error => this.toastError(error) );
            },
            addJobUnit(index, bl, juSize){
                const jobUnits = this.billOflading.job_units;
                let unit = this.hardCopy(this.pending_units[index]);
                this.pending_units.splice(index, 1);
                unit.order_by = jobUnits.length;
                unit.use_generated_unit_description = 1;
                this.setDescription(unit);
                jobUnits.splice(juSize, 0, unit);
            },
            addJobUnits(){
                const size = this.pending_units.length - 1;
                const juSize = this.billOflading.job_units.length;
                for(let i = size; i >= 0; i--){
                    if(this.pending_units[i].selected){
                        this.pending_units[i].uuid = this.pending_units[i].id;
                        let bm = this.pending_units[i].booked_measurement;
                        if(!bm && this.job) bm = this.job.bookings[this.selectedBooking].job_unit.find(ju => ju.id == this.pending_units[i].id).booked_measurement;
                        this.pending_units[i].volume = (bm.height * bm.width * bm.length) / 1000000;
                        this.pending_units[i].weight = bm.weight;
                        this.addJobUnit(i, this.billOflading, juSize);
                    }
                }
                this.billOflading.showPendingUnits = false;
                this.all_selected = false;
                this.autoSelectUnit(juSize);
            },
            selectAll(){
                this.pending_units.forEach(unit => {unit.selected = this.all_selected});
            },
            setSelectAll(){
                this.all_selected = this.pending_units.find(unit => {return !unit.selected}) == null;
            },
            showPendingUnits(){
                this.billOflading.showPendingUnits = !this.billOflading.showPendingUnits;
                if(this.billOflading.showPendingUnits) this.pending_units = this.jobItem.bookings[this.selectedBooking].bills_of_lading[0].job_units;
            },
            suggestNewJobUnit(){
                let unit = {
                    'active': 1,
                    'quantity': 1,
                    'description': '',
                    'chassis_number': '',
                    'length': null,
                    'width': null,
                    'height': null,
                    'weight': null,
                    'volume': null,
                    'unit_make_id': null,
                    'unit_model_id': null,
                    'construction_year': null,
                    'colour_id': null,
                    'hs_code': null,
                    'license_plate': null,
                    'sales_currency_id': window.defaultCurrency,
                    'sales_price': '0',
                    'buying_currency_id': window.defaultCurrency,
                    'buying_price': '0',
                    'commodity_id': null,
                    'condition_id': '2',
                    'contract_id': null,
                    'tariff_id': null,
                    'surcharge_id': null,
                    "weight_unit_measurements_id": 3,
                    "volume_unit_measurements_id": 3,
                    "booked_measurement": { "length": null, "width": null, "height": null },
                    "use_generated_unit_description": 1,
                    "uuid": Math.floor(Math.random() * 10000) * -1
                };
                unit.order_by = this.billOflading.job_units.length;
                this.setDescription(unit);
                this.billOflading.job_units.push(unit);
                this.$nextTick(() => document.getElementById("uli-"+this.billOflading.id+'-'+unit.order_by).click());
            },
            removeJobUnit(blIndex, uIndex, cIndex = null){
                if(this.pending_units && this.billOflading.job_units[uIndex].id){
                    if(!confirm("Do you want to cancel this unit?")) return;
                    const jobUnits = this.billOflading.job_units;
                    const unit = this.hardCopy(jobUnits[uIndex]);
                    jobUnits.splice(uIndex, 1);
                    unit.selected = false;
                    this.pending_units.push(unit);
                    if(this.selectedUnit && this.selectedUnit.id == unit.id)
                        this.selectedUnit = false;
                    if(this.mode == 'job' && this.billOflading.job_units.length == 0)
                        this.$parent.deleteBL(null, this.$parent.job.bookings[this.$parent.selectedBooking], blIndex, this.billOflading);
                }
                else if(confirm("Do you want to delete this unit permenantly?")){
                    this.billOflading.job_units.splice(uIndex, 1);
                }
            },
            addContainer(bl){
                /*bl.job_unit_containers.push({
                    "id": null,
                    "name": bl.job_unit_containers.length + 1,
                    "number": "",
                    "seal_number": "",
                    "description": "",
                    "tare": '',
                    "order_by": bl.job_unit_containers.length + 1,
                    "container_type_id": null,
                    "job_units": []
                })*/
            },
            fillContainer(e, container){
                e.preventDefault();
                let jobUnits = this.dragData.bl.job_units;
                let element = jobUnits[this.dragData.dragIndex];
                element.container_id = container.id;
            },
            removeContainer(blIndex, container, cIndex){
                const unitList = this.billOflading.job_units;
                const order = unitList.length;
                let i = 1;
                container.job_units.forEach(unit => {
                    unit.order_by = (order + i)
                    unitList.push(this.hardCopy(unit));
                    i++;
                });
                this.billOflading.job_unit_containers.splice(cIndex, 1);
            },
            setDescription(unit){
                if(!unit.use_generated_unit_description) return;
                const make = unit.make ? unit.make.name.toUpperCase() : '';
                const model = unit.model ? unit.model.name.toUpperCase() : '';
                const buildYear = unit.construction_year ? unit.construction_year : '';
                const colour = this.colours.find(c => c.id == unit.colour_id);
                const hscode = unit.hs_code;
                unit.description = make ? (make + ' ' + model) : (unit.description ? unit.description : '').split("\n")[0];
                if(unit.license_plate) unit.description += ("\nLICENSE PLATE: " + unit.license_plate);
                if(buildYear) unit.description += ("\nBUILD YEAR: " + buildYear);
                if(colour) unit.description += ("\nCOLOR: " + colour.name);
                if(hscode) unit.description += ("\nHS CODE: " + hscode);
            },
            checkContainerNr(e){
                e.target.value = e.target.value.toUpperCase();
                let value = e.target.value;
                const warningLabel = e.target.nextElementSibling;
                let alphabetNumerical = {
                    'A' : 10, 'B' : 12, 'C' : 13, 'D' : 14, 'E' : 15, 'F' : 16, 'G' : 17, 'H' : 18, 'I' : 19,
                    'J' : 20, 'K' : 21, 'L' : 23, 'M' : 24, 'N' : 25, 'O' : 26, 'P' : 27, 'Q' : 28, 'R' : 29,
                    'S' : 30, 'T' : 31, 'U' : 32, 'V' : 34, 'W' : 35, 'X' : 36, 'Y' : 37, 'Z' : 38
                };
                let pattern = /^([A-Z]{3})(U|J|Z)(\d{6})(\d)$/;
                let valid = value.match(pattern);
                if(valid){
                    let checkValue = 0;
                    for(let i = 0; i < 4; i++){
                        checkValue += (alphabetNumerical[ value[i] ] * Math.pow(2, i));
                    }
                    for(let i = 4; i < 10; i++){
                        checkValue += Number(value[i]) * Math.pow(2, i);
                    }
                    let checkValueSumDiff = Math.floor(checkValue / 11) * 11;
                    checkValue = checkValue - checkValueSumDiff;
                    checkValue = checkValue == 10 ? '0' : checkValue.toString();
                    valid = value[10] === checkValue;
                    if(!valid){
                        warningLabel.children[1].innerHTML = this.$t('errors.container_check');
                    }
                }
                else{
                    warningLabel.children[1].innerHTML = this.$t('errors.container_nr');
                }

                if(valid){
                    e.target.classList.remove('is-invalid');
                    warningLabel.classList.add('d-none');
                }
                else{
                    e.target.classList.add('is-invalid');
                    warningLabel.classList.remove('d-none');
                }
            },
            fillSearch(list, value, alternateValue){
                if(alternateValue && typeof alternateValue == "string") return alternateValue;
                const match = list.find(item => item.id == value);
                return match ? (match.name ? match.name : match.relation.name) : '';
            },

            getPDFHeaderHTML(){
                let bl = this.billOflading;
                let lc_fdp = bl.is_letterofcredit && bl.lc_port_of_final_destination_name ? bl.lc_port_of_final_destination_name : (bl.port_of_final_destination_id ? this.fillSearch(this.ports, bl.port_of_final_destination_id, bl.port_of_final_destination) : null);
                let nm_fdp = bl.port_of_final_destination ? (bl.port_of_final_destination.name ? bl.port_of_final_destination.name : bl.port_of_final_destination) : null;
                let fdp = lc_fdp ? lc_fdp : (nm_fdp ? nm_fdp : '---');
                return `<div class="row pdf-header">
                            <div class="col-3">Local Vessel</div>
                            <div class="col-3">From</div>
                        </div>
                        <div class="row pdf-below-header">
                            <div class="col-3">${bl.local_vessel ? bl.local_vessel : '---' }</div>
                            <div class="col-3">${bl.local_vessel_port ? bl.local_vessel_port : '---' }</div>
                        </div>
                        <div class="row pdf-header">
                            <div class="col-3">Ocean Vessel</div>
                            <div class="col-3">Port of Loading</div>
                        </div>
                        <div class="row pdf-below-header">
                            <div class="col-3">${bl.bill_of_lading_status_id == 1 ? '---' : bl.voyage_name }</div>
                            <div class="col-3">${bl.is_letterofcredit ? bl.lc_port_of_loading_name : bl.port_of_loading_name }</div>
                        </div>
                        <div class="row pdf-header">
                            <div class="col-3">Port of Discharge</div>
                            <div class="col-3">Port of Final Destination</div>
                            <div class="col-3">Freight payable at</div>
                            <div class="col-3">${bl.bl_type == 'Original' ? 'Number of original Bs/L' : '' }</div>
                        </div>
                        <div class="row pdf-below-header mb-3">
                            <div class="col-3">${bl.is_letterofcredit ? bl.lc_port_of_discharge_name : bl.port_of_discharge_name }</div>
                            <div class="col-3">${fdp}</div>
                            <div class="col-3">${bl.payment_option == 'COLLECT' ? 'DESTINATION' : (bl.city ? bl.city : "PURMEREND") }</div>
                            <div class="col-3">${bl.bl_type == 'Original' ? 'THREE (3)' : '' }</div>
                        </div>`;
            },

            calcAmount(item, mode, isBuying = false){
                let unit = '', rate = '';
                if(mode == 1){
                    if(isBuying){
                        unit = this.setCode(item.buying_rate_calculation_unit_id);
                        rate = Number(item.buying_rate);
                    }
                    else{
                        unit = this.setCode(item.sales_rate_calculation_unit_id);
                        rate = Number(item.sales_rate);
                    }
                }
                if(mode == 2){
                    if(isBuying){
                        unit = this.setCode(item.purchase_rate_currency_id);
                        rate = item.purchase_rate;
                    }
                    else{
                        unit = this.setCode(item.sales_rate_currency_id);
                        rate = item.sales_rate;
                    }
                }
                if(mode == 3) return isBuying ? (item.purchase_rate_unit_id == 6 ? 1 : item.amount) : (item.sales_rate_unit_id == 6 ? 1 : item.amount);

                if(unit == 'WM' && item.weight) return item.weight;
                if(unit == 'to' && item.weight) return item.weight / 1000;
                if(unit == 'LM' && item.length) return item.length;
                if(unit == 'CBM')  return (item.length * item.width * item.height) / 1000000;
                if(unit == 'PCT') return rate;
                return 1;
            },
            calcVolume(unit){
                if(unit.booked_measurement.length && unit.booked_measurement.width && unit.booked_measurement.height){
                    unit.volume = (Number(unit.booked_measurement.length) * Number(unit.booked_measurement.width) * Number(unit.booked_measurement.height)) / 1000000;
                }
            },
            hasSurveyedUnits(bl){
                let result = false;
                for(let unit of bl.job_units){
                    if(unit.is_surveyed) return true;
                }
                bl.job_unit_containers.forEach(container => { container.job_units.forEach( unit => { if(unit.is_surveyed) result = true } ) } );
                return result;
            },
            setQuoteInvoiceLines(){
                this.quoteInvoiceLines = [];
                this.bookingSave = {'default': false};
                for(var bIndex in this.job.bookings){
                    var booking = this.job.bookings[bIndex];
                    const header = this.$t('navigation.bookings') + ' ' + booking.number + ' (' + booking.voyages[0].vessel.name + ' ' + booking.voyages[0].number + ')';
                    for(var blIndex in booking.bills_of_lading){
                        var billOflading = booking.bills_of_lading[blIndex];
                        if(billOflading.id === null) continue;
                        let jobUnits = billOflading.job_units;
                        let containerUnits = [];
                        for(var container of billOflading.job_unit_containers){
                            for(var cunit of container.job_units){
                                containerUnits.push(cunit);
                            }
                        }
                        let allUnits = jobUnits.concat(containerUnits);
                        for(var jIndex in allUnits){
                            var unit = allUnits[jIndex];
                            let blNumber = booking.bills_of_lading[blIndex].number;
                            let blId = booking.bills_of_lading[blIndex].id;
                            this.bookingSave[blId] = false;
                            
                            var itemIndex = this.job.quote.quote_lines.findIndex(someQL => someQL.id == unit.group_id);
                            let item = null, qlc = null, surchargesFound = false;
                            if(itemIndex != -1){
                                item = this.job.quote.quote_lines[itemIndex];
                                qlc = item.quote_line_carriers[0];
                                surchargesFound = true;
                            }
                            let newDescription = item ? item.description : '';
                            if(unit.commodity) newDescription = unit.commodity.name + ', ' + (unit.condition_id == 2 ? this.$t('form.used') : this.$t('form.new')) + ': ';
                            if(unit.make) newDescription += (unit.make.name + ' ');
                            if(unit.model) newDescription += (unit.model.name + ' ');
                            newDescription += ('(' + unit.chassis_number + ')');
                            const newInvoice = {
                                "description": newDescription,
                                "quantity": qlc ? (qlc.sales_rate_calculation_unit_id == 1 ? 1 : this.calcAmount(qlc, 1)) : 1,
                                "amount": qlc ? this.calcAmount(qlc, 1) : 1,
                                "vat": 0,
                                "sales_rate": qlc ? qlc.sales_rate : 0,
                                "sales_rate_currency_id": qlc ? qlc.sales_rate_currency_id : window.defaultCurrency,
                                "sales_rate_calculation_unit_id": qlc ? qlc.sales_rate_calculation_unit_id : 1,
                                "supplier_id": null,
                                "line_type": 2,
                                "buying_rate": qlc ? qlc.buying_rate : 0,
                                "buying_rate_currency_id": qlc ? qlc.buying_rate_currency_id : window.defaultCurrency,
                                "buying_rate_calculation_unit_id": qlc ? qlc.buying_rate_calculation_unit_id : 1,
                                "booking_header": (blIndex == 0 && jIndex == 0) ? header : false,
                                "bl_number": blNumber,
                                "bl_id": blId,
                                "bl_separator": jIndex == 0 
                            };
                            this.quoteInvoiceLines.push(newInvoice);
                            let surchargeIndex = (itemIndex + 1);
                            while(surchargesFound){
                                let nextItem = this.job.quote.quote_lines[surchargeIndex];
                                if(nextItem && nextItem.surcharge_id !== null){
                                    qlc = nextItem.quote_line_carriers[0];
                                    if(qlc){
                                        this.quoteInvoiceLines.push({
                                            "description": nextItem.description,
                                            "quantity": qlc.sales_rate_calculation_unit_id == 1 ? nextItem.quantity : (qlc.sales_rate_calculation_unit_id == 5 ? (newInvoice.sales_rate * 0.01) : this.calcAmount(qlc, 1)),
                                            "amount": qlc.sales_rate_calculation_unit_id == 5 ? (newInvoice.sales_rate * 0.01) : this.calcAmount(qlc, 1),
                                            "vat": 0,
                                            "sales_rate": qlc.sales_rate,
                                            "sales_rate_currency_id": qlc.sales_rate_currency_id,
                                            "sales_rate_calculation_unit_id": qlc.sales_rate_calculation_unit_id,
                                            "supplier_id": null,
                                            "line_type": 1,
                                            "buying_rate": qlc.buying_rate,
                                            "buying_rate_currency_id": qlc.buying_rate_currency_id,
                                            "buying_rate_calculation_unit_id": qlc.buying_rate_calculation_unit_id,
                                            "booking_header": false,
                                            "bl_number": blNumber,
                                            "bl_id": blId,
                                            "bl_separator": false
                                        });
                                    }
                                    surchargeIndex++;
                                }
                                else{
                                    surchargesFound = false;
                                }
                            }
                        }
                    }
                }
            },
            getDefaultShipper(){
                if(!this.billOflading.shipper || !this.billOflading.customer_id || this.billOflading.shipper.address) return;
                customerService.getDefaultShipper(this.billOflading.customer_id).then(response => {
                    if(!response.data.address) return;
                    this.billOflading.shipper = response.data;
                    if(!this.billOflading.shipper.phone) this.setDefaultCountry(this.billOflading.shipper);
                });
            },
            setNumber(id, name, index, item, alterNumber = true){
                if(index == 3){ //final destination
                    if(item || (id && name)){
                        this.billOflading.port_of_final_destination = {
                            id: item ? item.id : id,
                            name: item ? item.name : name
                        };
                    }
                    return;
                }
                if(alterNumber){
                    this.numberAsArray[index] = name ? name : "";
                    this.billOflading.number = "";
                    this.numberAsArray.forEach(n => {
                        this.billOflading.number += n ? n : "****";
                    });
                }
                if(index == 0){ //voyage
                    if(alterNumber && item){
                        this.billOflading.voyage_name = item.name;
                        this.fullVoyageName = item.name;
                    }
                    voyageService.getPortsAndOperators(id).then(response => {
                        this.polpodOptions = response.data.ports;
                        this.polpodOptions.sort((a, b) => a.name > b.name ? 1 : -1);
                        this.operatedByOptions = response.data.operators;
                        this.operatedByOptions.forEach(opt => opt.name = opt.relation.name);
                        this.operatedByOptions.sort((a, b) => a.name > b.name ? 1 : -1);
                    }).catch(e => console.log(e));
                }
                if(!item) return;
                if(index == 1){ //port of loading
                    this.billOflading.port_of_loading_name = item.name;
                    this.billOflading.lc_port_of_loading_name = item.name.toUpperCase();
                }
                if(index == 2){ //port of discharge
                    this.billOflading.port_of_discharge_name = item.name;
                    this.billOflading.lc_port_of_discharge_name = item.name.toUpperCase();
                }
            },
            setHeader(onStatusChange = false){
                if(!this.billOflading.number) return;
                const detailsspan = document.getElementById("detailsHeaderAbove") || document.getElementById("detailsSpan");
                const voyagename = this.billOflading.voyage ? (this.billOflading.voyage.vessel.name + ' ' + this.billOflading.voyage.number) : this.fullVoyageName;
                let prelude = (this.billOflading.id ? (" " + this.billOflading.number) : '') + ": ";
                if(onStatusChange && this.billOflading.bill_of_lading_status_id == 1 && !this.billOflading.number.includes("-BL-")){
                    const nameAsArray = store.getters.getName.split(' ');
                    prelude = "NEW-BL-" + nameAsArray[0].toUpperCase() + nameAsArray.at(-1).substring(0, 1).toUpperCase() + "-****: ";
                }
                if(onStatusChange && this.billOflading.bill_of_lading_status_id > 1 && this.billOflading.number.includes("-BL-") && this.billOflading.voyage){
                    prelude = (this.billOflading.voyage.number + this.billOflading.port_of_loading.code + this.billOflading.port_of_discharge.code).toUpperCase() + "****: ";
                }
                if(detailsspan && voyagename && this.billOflading.port_of_loading_name && this.billOflading.port_of_discharge_name)
                    detailsspan.innerHTML = prelude + voyagename + ', ' + this.billOflading.port_of_loading_name + ' - ' + this.billOflading.port_of_discharge_name;
            },
            setCode(value){
                const item = this.currenciesById[value];
                return item ? item.code : '-';
            },
            setBranches(emptyId = true, data = {}) {
                let thisoffice = data.find ? data.find(office => office.id == this.billOflading.office_id) : this.offices.find(office => office.id == this.billOflading.office_id);
                if(thisoffice){
                    let branchnames = [];
                    this.branches = [];
                    if(emptyId){
                        this.billOflading.branch_id = null;
                    }
                    thisoffice.addresses.forEach(add => {
                        if(add.branch && !branchnames.includes(add.branch)){
                            this.branches.push({id: add.id, name: add.branch, city: add.city});
                            branchnames.push(add.branch);
                        }
                        else if(!add.branch){
                            this.branches.push({id: add.id, name: 'Default', city: add.city});
                        }
                    });
                    if(this.branches.length == 1){
                        this.billOflading.branch_id = this.branches[0].id;
                        if(!this.billOflading.legacy)
                            this.setCity(0, 0, thisoffice.addresses[0]);
                    }
                }
            },
            setCity(a, b, item){
                if(!item) return;
                this.billOflading.city = item.city ? item.city : "NOT SPECIFIED";
            },
            checkChassisNr(e){
                const length = e ? e.length : 0;
                if(length == 17)
                    return "#dfd";
                if(length > 17)
                    return "#fdd";
                return "#fff";
            },
            setCollect(e){
                this.billOflading.collect_clause = e.target.value == 2 ? 1 : 0;
            },
            autoSelectUnit(i = 0){
                if((i > 0 || !this.selectedUnit) && this.billOflading.job_units[i]){
                    this.selectUnit(this.billOflading.job_units[i]);
                }
            },
            selectUnit(unit){
                this.$nextTick(() => {
                    this.selectedUnit = unit;
                    const req = document.querySelectorAll('#containers .unit-details-section input[required], #containers .unit-details-section select[required]');
                    req.forEach(el => {
                        if(!el.value || el.value == this.$t('form.select'))
                            el.classList.add('is-invalid');
                        else
                            el.classList.remove('is-invalid');
                    });
                });
            },
            setShowPaymentOptions(){
                this.billOflading.showPaymentOptions = false;
                if(!this.billOflading.job_booking_voyage_manifest || this.billOflading.job_booking_voyage_manifest.length == 0){
                    this.billOflading.showPaymentOptions = true;
                    return;
                }
                this.billOflading.job_booking_voyage_manifest.forEach(manifest => {
                    if(manifest.is_nmt_bl) this.billOflading.showPaymentOptions = true;
                });
            },

            setTabs(index){
                const prevbutton = document.getElementById('prevbutton');
                if(!prevbutton) return;
                const nextbutton = document.getElementById('nextbutton');
                const savebutton = document.getElementById('savebutton');
                const downloadbutton = document.getElementById('savedownloadbutton');
                prevbutton.style.display = index != 1 ? 'block' : 'none';
                nextbutton.style.display = index != 4 ? 'block' : 'none';
                savebutton.style.display = index == 4 || this.billOflading.id ? 'block' : 'none';
                downloadbutton.style.display = index == 4 ? 'block' : 'none';
                this.hasUnsurveyedUnits = false;
                if(index == 4){
                    this.calcTotals = {quanity: 0, weight: 0, cbm: 0};
                    this.billOflading.job_units.forEach(unit => {
                        this.calcTotals.quantity += Number(unit.quanity);
                        this.calcTotals.weight += Number(unit.weight);
                        this.calcTotals.cbm += Number(unit.volume);
                        if(!this.hasUnsurveyedUnits && !unit.is_surveyed) this.hasUnsurveyedUnits = true;
                    })
                }
                if(index > 1 && this.job){
                    this.setHeader();
                }
            },
            downloadUploadedFile(file){
                fileService.download(file.id).then(response => this.triggerDownload(response.data, file.name) ).catch(error => this.toastError(error));
            },
            isFinal(mode = 'normal'){
                if(mode == 'notify')
                    return this.billOflading.notify_same_as_consignee || this.billOflading.bill_of_lading_status_id == 3 || this.billOflading.legacy || this.billOflading.fromJob || this.billOflading.uneditable;
                if(mode == 'normal')
                    return this.billOflading.bill_of_lading_status_id == 3 || this.billOflading.legacy || this.billOflading.fromJob || this.billOflading.uneditable;
                if(mode == 'statusOrJob')
                    return this.billOflading.bill_of_lading_status_id == 3 || this.billOflading.fromJob  || this.billOflading.uneditable;
                if(mode == 'status')
                    return this.billOflading.bill_of_lading_status_id == 3 || this.billOflading.uneditable;
                if(mode == 'statusLite')
                    return this.billOflading.bill_of_lading_status_id == 3;
            },
            openJobBL(){
                let newWindow = window.open('./jobs#'+ this.billOflading.job_booking.job_id);
                newWindow.tabToOpen = this.billOflading.job_booking.number;
                newWindow.BLToOpen = this.billOflading.number;
                newWindow.toUnitDetailPage = true;
            },

            onDragStart(e, bl, index, container){
                if(this.billOflading.legacy) return;
                window.dontShowDropzone = true;
                window.setTimeout(() => {
                    window.dragShadow = e.target;
                    window.dragShadow.classList.add("drag-row");
                    e.target.classList.add('dragging');
                    this.dragData.bl = bl;
                    this.dragData.dragIndex = index;
                    this.dragData.isDragging = true;
                    this.dragData.container = container;
                    this.dragData.item = container ? {...container.job_units[index]} : {...bl.job_units[index]};
                }, 100);
            },
            onDragEnd(e){
                if(!e.target) return;
                e.target.classList.remove('dragging');
                window.dragShadow.classList.remove("drag-row");
                window.dragShadow.style.removeProperty('top');
                window.dragShadow.style.removeProperty('left');
                this.dragData.isDragging = false;
                window.dragShadow = null;
                document.removeEventListener("dragover", this.onPageDragOverEvent);
                this.onPageDragOverEvent = null;
                window.dontShowDropzone = false;
            },
            onDragOver(e, isEnter, index){
                e.preventDefault();
                if(!e.target.classList.contains('droppable')) return;
                if(isEnter)
                    e.target.classList.add("drag-here");
                else
                    e.target.classList.remove("drag-here");
                this.dragData.dropIndex = isEnter ? index : null;
            },
            onDrop(e, list, isContainer){
                e.preventDefault();
                if(this.dragData.dropIndex == null) return;

                const prevList = this.dragData.container ? this.dragData.container.job_units : this.dragData.bl.job_units;
                const newList = list;
                const realElement = this.dragData.item;
                const dropIndex = this.dragData.dragIndex < this.dragData.dropIndex ? (this.dragData.dropIndex - 1) : this.dragData.dropIndex;

                prevList.splice(this.dragData.dragIndex, 1);
                newList.splice(dropIndex, 0, realElement);
                this.$nextTick(() => {
                    for(let index in prevList) prevList[index].order = Number(index);
                    for(let index in newList) newList[index].order = Number(index);
                })
            }
        }
    }
</script>

<style scoped>
    .input-group select {background-color: #eee}
    .disabled{background-color: #e9ecef}
    .col-1p5 {flex: 0 0 auto; width: 12%}
    .unit-details-section.for-job{
        display: flex;
    }
    .unit-details-section.for-job > .col-4{
        width: 362px;
    }
    #fixed-unit-list, #fixed-unit-details{
        position: fixed;
        overflow: hidden auto;
    }
    #fixed-unit-list{
        height: calc(100vh - 573px);
        width: 430px;
    }
    #fixed-unit-list.for-job{
        height: 350px !important;
        width: 360px !important;
        position: static !important;
    }
    #fixed-unit-details{
        height: calc(100vh - 515px);
        margin-left: 440px;
        translate: 0 -58px;
        width: 900px;
    }
    #fixed-unit-details.for-job{
        height: unset !important;
        translate: unset !important;
        margin-left: 0px !important;
        padding-left: 20px;
        width: 795px !important;
        position: static !important;
    }
    #fixed-unit-details.for-job:not(.from-job){
        width: 949px !important;
    }
    #fixed-unit-details.from-job{
        width: 738px !important;
    }
    .droppable.drag-here{
        background-color: #eee;
        margin-top: 5px;
        max-width: 30rem;
    }
    .drag-here ~ div {
        margin-top: 2.5rem;
    }
    .drag-and-drop > span{
        display: none;
        position: absolute;
        width: 75rem;
        height: 2rem;
        background-color: transparent;
        transform: translate(0, -5px);
    }
    .drag-and-drop.selected{
        background-color: #eee !important;
    }
    span.droppable{
        display: block !important;
    }
    .dragging{
        visibility: hidden
    }
    .drag-row{
        position: fixed;
        background-color: white;
        width: 1090px;
        opacity: 0.5
    }
    .pdf-unit{
        font-size: .9rem;
        text-transform: uppercase;
    }
    .unit-details-section {
        min-height: 5rem;
    }
    .unit-details-section > div:nth-child(2){
        border-left: 1px solid #aaa;
    }
    #addUnitsModal .modal-content{
        min-height: unset !important
    }
    #addUnitsModal .modal-header{
        background-color: white !important;
        border-color: #aaa !important;
        color: black !important;
    }
    #addUnitsModal .modal-body{
        min-height: 30% !important
    }
    .unit-details-section .col-8 .row > div{
        padding-left: 4px;
        padding-right: 4px
    }
    .link{
        cursor: pointer;
        text-decoration: underline;
    }
    .text-bubble{
        position: absolute;
        transform: translate(-100px, 5px);
        z-index: 1001;
        border: 1px solid black;
        border-radius: 0.5rem;
        background-color: white;
        padding: 4px 6px;
        width: 320px;
        text-align: center
    }
    .text-bubble::before{
        content: url("data:image/svg+xml,%3Csvg width='20' height='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline points='8,2 16,10 0,10' style='fill:black;stroke:black;stroke-width:1'/%3E%3C/svg%3E ");
        position: absolute;
        display: block;
        transform: translate(290px, -20px);
    }
    i.hoverable:not(:hover) + .text-bubble{
        display: none !important;
    }
    .btn-link{
        line-height: 1rem;
        position: relative;
        bottom: 2px
    }
    h5{
        font-weight: bold
    }
    .jobunit-displacement{
        position: fixed;
        bottom: 12px;
        width: min(1160px, calc(100% - 210px))
    }
</style>