import {ApiService} from '@/services/ApiService'

const URL = '/api/terminal'

class TerminalService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
}

export default new TerminalService()