<template>

<!--- begin transport  --> 
        <div class="row">
            <h5 class="mt-4 mb-3 fw-bold">{{transportType == "pre" ? $t('sales.transport_to') : $t('sales.transport_from')}}</h5>
        </div> 
        <div class="row">
            <template v-if="pols">
                <div class="col-sm-auto col-form-label-sm required-label" style="width: 16.4%">
                    {{$tc('sales.loading_ports', 1)}}
                </div>
                <div class="col-sm-auto col-form-label-sm required-label" style="width: 79px">
                    {{$t('form.qt')}}
                </div>
                <div class="col-sm-4 col-form-label-sm required-label">
                    {{$t('form.description')}}
                </div>
            </template>
            <template v-else>
                <div class="col-sm-auto col-form-label-sm required-label" style="width: 16.4%">
                    {{$tc('sales.discharge_ports', 1)}}
                </div>
                <div class="col-sm-auto col-form-label-sm required-label" style="width: 79px">
                    {{$t('form.qt')}}
                </div>
                <div class="col-sm-4 col-form-label-sm required-label">
                    {{$t('form.description')}}
                </div>
            </template>
            <div class="col-sm-5">
                <div class="row">
                    <div class="col-sm-6 col-form-label-sm required-label">
                        {{$t('sales.purchase_price')}}
                    </div>  
                    <div class="col-sm-6 col-form-label-sm required-label">
                        {{$t('sales.sales_price')}}
                    </div>      
                </div>
            </div> 
            
            <div class="col-sm-1">
            </div>   
        
        </div>
            <div>
            <div class="row mb-1" v-for="(transportline, loopIndex) in thisquotationtransportlines" :key="loopIndex">
                <template v-if="pols">
                    <div class="col-sm-auto" style="width: 16.4%">
                        <!--<input class="form-control form-control-sm" v-model="transportline.data.quantity">-->
                        <select :id="'port-for-transport-'+loopIndex" class="form-select form-select-sm required-field" v-model="transportline.data.port_of_loading_id" @change="devalidate">
                            <option :value="null">{{$t('form.select')}} </option>
                            <option v-for="prt in pols" :key="(prt.port_id ? prt.port_id : prt.id)" :value="(prt.port_id ? prt.port_id : prt.id)">
                                {{prt.name}}
                            </option>
                        </select> 
                    </div>
                    <div class="col-sm-auto" style="width: 79px">
                        <input type="number" class="form-control form-control-sm required-field" v-model="transportline.data.quantity" @change="devalidate">
                    </div>
                    <div class="col-sm-4">
                        <input class="form-control form-control-sm required-field" v-model="transportline.data.description" @change="devalidate">
                    </div>
                </template>
                <template v-else>
                    <div class="col-sm-auto col-form-label-sm" style="width: 16.4%">
                        {{$parent.pod && $parent.pod[0] ? $parent.pod[0].name : ''}}
                    </div>
                    <div class="col-sm-auto" style="width: 79px">
                        <input type="number" class="form-control form-control-sm required-field" v-model="transportline.data.quantity" @change="devalidate">
                    </div>
                    <div class="col-sm-4">
                        <input class="form-control form-control-sm required-field" v-model="transportline.data.description" @change="devalidate">
                    </div>
                </template>
                <div class="col-sm-5">
                    <div class='row'>
                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-sm-6"> 
                                    <select v-model="transportline.data.purchase_currency_id"  class="form-select form-select-sm">
                                        <option :value='null'>{{$t('form.select')}}</option>
                                        <option v-for="currency in currencies" :key="currency.id" :value="currency.id">
                                            {{currency.code}}
                                        </option>
                                    </select>
                                </div>    
                                <div class="col-sm-6">    
                                    <input class="form-control form-control-sm required-field" v-model="transportline.data.purchase_price" @change="devalidate">  
                                </div>
                            </div>
                        </div> 
                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-sm-6"> 
                                <select v-model="transportline.data.sales_currency_id"  class="form-select form-select-sm">
                                        <option :value='null'>{{$t('form.select')}}</option>
                                        <option v-for="currency in currencies" :key="currency.id" :value="currency.id">
                                            {{currency.code}}
                                        </option>
                                    </select>
                                </div>    
                                <div class="col-sm-6">    
                                    <input class="form-control form-control-sm required-field" v-model="transportline.data.sales_price" @change="devalidate">  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-auto p-0" style="width: 2rem">
                    <a class="btn btn-sm text-danger px-1" title="Click to delete item" @click="deleteTransportLine(transportline.index)"><i class="far fa-trash-alt"></i></a>
                </div>  
            </div>
            <div>
        </div> 
        </div>
        <div class="row mt-4 justify-content-center">
            <a class="btn btn-sm btn-primary w-25" title="Click to add item"  @click="addTransportline()">
                <span class="fa fa-plus"></span> &nbsp;{{$t('form.add', [$t('sales.transport_item')])}}
            </a>
        </div>
        
<!--- end transport -->  
      
</template>

<script>
   
    export default {
        name: 'QuaotationtransportFormComponent',
        emits: ['addTransportLine', 'deleteTransportLine'],
        props: ['initialTransportLines', 'transportType','currencies','pols'],
        computed: {
            thisquotationtransportlines: {
                get() {
                    return this.initialTransportLines
                }
            }
           
        },
        data() {
            return {
                 

            }
        },
        methods: {
            addTransportline() {
                this.$emit('addTransportLine', this.transportType); 
            },
            deleteTransportLine(index) {
                this.$emit('deleteTransportLine', index); 
            },
            devalidate(e){
                if(e.target.value){
                    e.target.classList.remove('is-invalid');
                }
            }
        }
    }
</script>