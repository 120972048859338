<template>
    
    <ul class="nav nav-tabs mb-3" id="myTab" role="tablist" style="font-size: 0.95rem !important">
        <li class="nav-item" role="presentation">
            <button class="nav-link active" id="customer-tab" data-bs-toggle="tab" data-bs-target="#customer" type="button" role="tab" aria-controls="customer" aria-selected="true" @click="setNextStep($event, 2)">{{'1 - ' + $t('sales.customer_ports')}}</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="carrier-tab" data-bs-toggle="tab" data-bs-target="#carrier" type="button" role="tab" aria-controls="carrier" @click="setNextStep($event, 3)" :disabled="disableFor(2)">{{'2 - ' + $t('sales.carrier_voyage')}}</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="freight-tab" data-bs-toggle="tab" data-bs-target="#freight" type="button" role="tab" aria-controls="freight" @click="setNextStep($event, 4)" :disabled="disableFor(3)">{{'3 - ' + $t('sales.transport_unit')}}</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="rates-tab" data-bs-toggle="tab" data-bs-target="#rates" type="button" role="tab" aria-controls="rates" @click="setNextStep($event, 5)" :disabled="disableFor(4)">{{'4 - ' + $t('sales.sea_fright_rates')}}</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="addcost-tab" data-bs-toggle="tab" data-bs-target="#addcost" type="button" role="tab" aria-controls="addcost" @click="setNextStep($event, 6)" :disabled="disableFor(5)">{{'5 - ' + $t('sales.other_costs')}}</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="terms-tab" data-bs-toggle="tab" data-bs-target="#terms" type="button" role="tab" aria-controls="terms" @click="setNextStep($event, 7)" :disabled="disableFor(6)">{{'6 - ' + $t('sales.terms_1') + ' & ' + $t('sales.terms_2') }}</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" @click="setNextStep($event, 8)" :disabled="disableFor(7)">{{'7 - ' + $t('sales.contract_details')}}</button>
        </li>
    </ul>

    <div v-show="quoteAlreadyAccepted">{{ $t('sales.quote_already_accepted') }}</div>
    <div class="tab-content" id="myTabContent" :class="{'disable-all': quoteAlreadyAccepted && nextStep != 8}">

    <div class="tab-pane fade show active" id="customer" role="tabpanel" aria-labelledby="customer-tab">
    <template v-if="formActive">
    <!--Begin Customer tab -->
        <h5 class="mt-4 mb-3 fw-bold">{{$t('navigation.general_details')}} </h5>
        <FormItem id="sdate" :label="$t('sales.valid_from')" type="datepicker" v-model="quotation.valid_from" sizeItem="2" @onchange="validateDate(true)" :required="true" />
        <div class="row mb-1">
            <label class="col-sm-2 col-form-label-sm required-label">{{$t('sales.valid_to')}}</label>
            <div class="col-sm-2 has-validation end-date">
                <FormItem id="edate" type="datepicker" v-model="quotation.valid_till" @onchange="validateDate(false)" :required="true" />
                <div class="invalid-feedback"> <i class="fa fa-warning"></i>&nbsp;<span id="edatespan"></span></div>
            </div>
        </div>
        <FormItem id="user_id" :label="$t('overview.salesmanager')" v-model="quotation.user_id" type="livesearch" :service="userService" :content="quotation.sales_manager ? quotation.sales_manager.name : ''" sizeItem="4" :required="true" />
        <div class="row mb-1">
            <label class="col-sm-2 col-form-label-sm">{{$t('overview.creator')}}</label>
            <div class="col-sm-2"> {{ quotation.creator ? quotation.creator.name : userName }} </div>
        </div>
        <FormItem id="curr" :label="$t('navigation.currencies')" v-model="quotation.currency_id" type="select" :options="currencies" nameField="code" @onchange="setSeatranspportSelectedCurrencies()" sizeItem="2" :required="true" />
        <template v-if="!isDraft">
            <div class="row" v-if="checkExpired">
                <label for="status-exp" class="col-sm-2 col-form-label-sm">{{$t('overview.status')}}</label>
                <div class="col-sm-4"> {{$t('sales.quote_expired')}} </div>
            </div>
            <div class="row mb-2" v-if="quotation.status == 'accepted'">
                <label for="option-exp" class="col-sm-2 col-form-label-sm">{{$t('sales.option')}}</label>
                <div :class="quoteAlreadyAccepted ? 'w-75' : 'col-sm-4'">
                    <span v-if="quoteAlreadyAccepted">{{ safeFind(forwardingOptions, 'carrier', quotation.option_carrier_id, 'text') }}</span>
                    <select v-else v-model="quotation.option_carrier_id" class="form-select form-select-sm" id="option-id">
                        <option :value="null" disabled="">{{ $t('form.select') }}</option>
                        <optgroup v-for="optiongroup, index in forwardingOptions" :key="index" :label="optiongroup.text">
                            <option v-for="option, index2 in optiongroup.options" :key="index2" :value="option"> {{option.text}} </option>
                        </optgroup>
                    </select>
                </div>
            </div>
            <div class="row">
                <label for="customer_website" class="col-sm-2 col-form-label-sm">{{$tc('sales.customer_website')}}</label>
                <div class="col-sm-4">
                    <input class="form-control form-control-sm" id="customer_website" autocomplete="off" disabled :value="`https://feedback.nmtmainport.com/` + quotation.id" />
                </div>
                <div class="col-sm-2">
                    <a class="feedback-link" href='#' @click="copyCustomerWebsiteLink">{{$t('form.copy', [$t('form.link')])}}</a> &nbsp;&nbsp;
                    <a class="feedback-link" :href='"/quotationreply/" + quotation.id' target="_blank" @click="copyCustomerWebsiteLink">{{$t('form.open', [$t('form.link')])}}</a>
                </div>  
            </div>
        </template>

        <h5 class="mt-4 mb-3 fw-bold">{{$tc('navigation.customers', 1)}}</h5>
        
        <div class="d-flex">
            <FormItem id="customer_id" :label="$t('navigation.customers')" v-model="quotation.customer_id" type="livesearch" :content="quotation.customer ? quotation.customer.relation.name : ''"
                     :options="customers" sizeLabel="33" sizeItem="8" @onchange="onChangeCustomer" :required="true" style="width: 51%" />
            <div class="pt-1"> <button class="btn btn-success add-makemodel ms-1" type="button" @click="setAddCustomerForm(true)" v-show="!quotation.customer_id">+</button> </div>
        </div>
        <FormItem id="customer_reference" :label="$t('navigation.customers') + ' ref'" v-model="quotation.customer_reference" sizeItem="4" />

        <h5 class="mt-4 mb-3 fw-bold">{{$t('sales.port_transport')}}</h5>
        <FormItem id="loading_port" :label="$t('sales.loading_ports')" @add="onChangePol" @delete="removePort" type="livesearch-list" :options="ports" 
                  v-model="quotation.ports_of_loading" :content="quotation.ports_of_loading.name" sizeItem="4" :required="true" />
        <FormItem id="discharge_port" :label="$t('sales.discharge_ports')" @onchange="onChangePod" type="livesearch"
                 :options="ports" :content="quotation.ports_of_discharge[0] ? quotation.ports_of_discharge[0].name : ''" sizeItem="4" :required="true" />

        <div class="row">
            <div class="col-sm-2"></div>
            <div class="col-sm-2p4">
                <FormItem id="pre_transport" :label="$t('sales.transport_to')" v-model="quotation.pre_transport" type="checkbox" @onchange="autoFillTransport($event, 'pre')" />
            </div>
            <div class="col-sm-2p4">
                <FormItem id="post_transport" :label="$t('sales.transport_from')"  v-model="quotation.post_transport" type="checkbox" @onchange="autoFillTransport($event, 'post')" />
            </div>
        </div>
    </template>
    </div>

    <!--end tab --> 
    <!--Begin Carrier tab --> 

    <div class="tab-pane fade" id="carrier" role="tabpanel" aria-labelledby="carrier-tab">
        <div v-show="!customVoyage" id="carrier-normal">
            <div class="d-flex">
                <div class="w-50" style="min-height: 10.7rem">
                    <h5 class="mt-2 mb-3 fw-bold" @click="textX">{{$t('overview.preferred_carrier')}}</h5>
                    <FormItem id="carrier_id" :label="$tc('navigation.carriers', 2)" type="livesearch-list" :options="carriers" v-model="quotation.carriers" 
                            :content="quotation.carriers.name" @add="addCarrierAndSurcharges" @delete="deleteCarrier" :required="true" />
                </div>
                <div class="w-50">
                    <p class="mt-2 fw-bold" v-show="Object.keys(carrierSuggestions).filter(id => !quotation.carriers.find(c => c.carrier_id == id)).length">{{ $t('form.suggestions') }}</p>
                    <template v-for="(name, id) in carrierSuggestions" :key="id">
                        <span v-if="!quotation.carriers.find(c => c.carrier_id == id)" @click="addSuggestion(id)" class="fake-link mb-1">{{name}}<br /></span>
                    </template>
                </div>
            </div>

            <QuotationVoyage :quotation="quotation" :carrier_ids="carrier_ids" :qvoyages="quotation.quote_routes" :pols="carrierPols" ref="voyageComp" />
        </div>
        <!--<div v-show="customVoyage">
            <AddmanualVoyageComponentVue :itemData="{}" :fromJobOrQuote="true" :setPending="true" ref="addmanvoyageform" />
        </div>-->

        <!--<div class="d-flex justify-content-center my-4">
            <button class="btn btn-primary btn-sm w-25" type="button" @click="setManualVoyageForm(!customVoyage)">
                <i :class="{'fa fa-plus': !customVoyage, 'fa fa-arrow-left': customVoyage}"></i>
                &ensp;{{customVoyage ? $t('overview.back') : $t('operations.manual_voyage')}}
            </button>
        </div>-->

    </div>

    <!--end tab --> 
    <!--Begin Freight and Rates tabs --> 

        <QuotationSeatransportFormComponent
            ref="seatransportcomponent" :initialSeaTransportLines="quotation.quote_lines" :currencies="currencies" :commodities="commodities" :selCurrency="quotation.currency_id"
            :pol="quotation.ports_of_loading" :pod="quotation.ports_of_discharge" :masterRates="masterRatesList" :isForwarding="quotation.is_forward" :surcharges="surcharges"
            :carriers="carrier_ids" :customer="quotation.customer_id" :carrierPols="carrierPols" :licensePlateRequired="isLicensePlateRequired"
            :pretransport="quotation.pre_transport ? filterTransport('pre') : null" :posttransport="quotation.post_transport ? filterTransport('post') : null" 
            @disableNext="disableStepFour()" @enableNext="disableStepFour()"
        />

    <!--end tabs -->
    <!--Begin Additional Costs tab --> 

    <div class="tab-pane fade" id="addcost" role="tabpanel" aria-labelledby="addcost-tab">
        <h5 class="mt-4 mb-3 fw-bold">{{$tc('navigation.general_costs', 2)}} </h5>
        <p v-if="!quotation.quote_general_costs">Loading...</p>
        <template v-else>
            <div class="row fw-bold mb-2">
                <div class="col-sm-0p5"> </div>
                <div class="col-sm-1"> {{$t('form.qt')}} </div>
                <div class="col-sm-2"> {{$t('form.description')}} </div>
                <div class="col-sm-2"> {{$tc('navigation.relations', 1)}} </div>
                <div class="col-sm-2p5"> {{$tc('sales.buying_rates', 1)}} </div>
                <div class="col-sm-2p5"> {{$tc('sales.sales_rates', 1)}} </div>
            </div>
            <!-- Not Commodity Dependent -->
            <template v-for="(costs, costIndex) in quotation.quote_general_costs" :key="costIndex">
                <div class="row mb-2" v-if="!costs.commodity_dependent">
                    <div class="col-sm-0p5">
                        <input class="form-check-input" type="checkbox" name="generalcosts" :true-value="true" :false-value="false" v-model="costs.checked" :data-set-nr="'n-'+costIndex" @change="devalidate">
                    </div>
                    <div class="col-sm-1" v-if="costs.sales_rate_calculation_unit_id == 6">
                        <input :value="1" class="form-control form-control-sm" :data-belongs-to="'n-'+costIndex" disabled>
                    </div>
                    <div class="col-sm-1" v-else>
                        <input v-model="costs.quantity" class="form-control form-control-sm" :data-belongs-to="'n-'+costIndex" @change="devalidate">
                    </div>
                    <div class="col-sm-2">
                        <input v-model="costs.description" class="form-control form-control-sm" :data-belongs-to="'n-'+costIndex" @change="devalidate">
                    </div>
                    <div class="col-sm-2">
                        <select v-model="costs.relation_id" class="form-select form-select-sm not-required" v-if="costs.relation_type != 'none'" :data-belongs-to="'n-'+costIndex" @change="devalidate">
                            <option :value="null">{{$t('form.select')}}</option>
                            <template v-if="costs.relation_type == 'carrier'">       <option v-for="item in carriers" :key="item.id" :value="getId(item)"> {{item.name}} </option> </template>
                            <template v-else-if="costs.relation_type == 'customer'"> <option v-for="item in customers" :key="item.id" :value="item.id"> {{item.name}} </option> </template>
                            <template v-else-if="costs.relation_type == 'terminal'"> <option v-for="item in allTerminals" :key="item.id" :value="item.id"> {{item.name}} </option> </template>
                        </select>
                    </div>
                    <div class="col-sm-2p5">
                        <div class="input-group">
                            <select v-model="costs.purchase_rate_currency_id" class="form-select form-select-sm" @change="devalidate" :data-belongs-to="'n-'+costIndex">
                                <option v-for="currency in currencies" :key="currency.id" :value="currency.id"> {{currency.code}} </option>
                            </select>
                            <input type='number' step="0.01" v-model="costs.purchase_rate" class="form-control form-control-sm" :data-belongs-to="'n-'+costIndex" @change="devalidate" />
                            <select v-model="costs.purchase_rate_calculation_unit_id" class="form-select form-select-sm" @change="devalidate" :data-belongs-to="'n-'+costIndex">
                                <option value=''>{{$t('form.select')}}</option>
                                <option v-for="calculation_unit in calc_units_no_percentage" :key="calculation_unit.id" :value="calculation_unit.id"> {{calculation_unit.name}} </option>
                            </select>
                        </div>
                    </div> 
                    <div class="col-sm-2p5">
                        <div class="input-group">
                            <select v-model="costs.sales_rate_currency_id" class="form-select form-select-sm" @change="devalidate" :data-belongs-to="'n-'+costIndex">
                                <option value=''>{{$t('form.select')}}</option>
                                <option v-for="currency in currencies" :key="currency.id" :value="currency.id"> {{currency.code}} </option>
                            </select>
                            <input type='number' step="0.01" v-model="costs.sales_rate" @change="devalidate" class="form-control form-control-sm" :data-belongs-to="'n-'+costIndex" />
                            <select v-model="costs.sales_rate_calculation_unit_id" class="form-select form-select-sm" @change="devalidate" :data-belongs-to="'n-'+costIndex">
                                <option value=''>{{$t('form.select')}}</option>
                                <option v-for="calculation_unit in calc_units_no_percentage" :key="calculation_unit.id" :value="calculation_unit.id"> {{calculation_unit.name}} </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-auto p-0" v-show="costs.general_cost_id === null">
                        <button class="btn p-0" type="button" @click="deleteGeneralCost(costIndex)"><i class="fa fa-trash-alt text-danger"></i></button>
                    </div>
                </div>
            </template>
            <!-- Commodity Dependent -->
            <template v-for="(costs, costIndex) in quotation.quote_general_costs" :key="costIndex">
                <div class="row mb-2 pt-2 border-top" v-if="costs.commodity_dependent && isFirstOfCommodies(costIndex)">
                    <div class="col-sm-0p5"> </div>
                    <div class="col-sm-3">
                        <input v-model="costs.description" class="form-control form-control-sm" @change="generalCostMultiFill($event, 'description', costs.general_cost_id)">
                    </div>
                    <div class="col-sm-2">
                        <select v-model="costs.relation_id" class="form-select form-select-sm not-required" v-if="costs.relation_type != 'none'" @change="generalCostMultiFill($event, 'relation_id', costs.relation_id)">
                            <option :value="null">{{$t('form.select')}}</option>
                            <template v-if="costs.relation_type == 'carrier'">       <option v-for="item in carriers" :key="item.id" :value="getId(item)"> {{item.name}} </option> </template>
                            <template v-else-if="costs.relation_type == 'customer'"> <option v-for="item in customers" :key="item.id" :value="item.id"> {{item.name}} </option> </template>
                            <template v-else-if="costs.relation_type == 'terminal'"> <option v-for="item in allTerminals" :key="item.id" :value="item.id"> {{item.name}} </option> </template>
                        </select>
                    </div>
                </div>
                <div class="row mb-2" v-if="costs.commodity_dependent && hasSelectedCommodity(costs.comm_id)">
                    <div class="col-sm-0p5">
                        <input class="form-check-input" type="checkbox" name="generalcosts" :true-value="true" :false-value="false" 
                               v-model="costs.checked" :data-set-nr="'c-'+costIndex" :disabled="costs.relation_type != 'none' && !costs.relation_id">
                    </div>
                    <div class="col-sm-1" v-if="costs.sales_rate_calculation_unit_id == 6">
                        <input :value="1" class="form-control form-control-sm" :data-belongs-to="'n-'+costIndex" disabled>
                    </div>
                    <div class="col-sm-1" v-else>
                        <input v-model="costs.quantity" class="form-control form-control-sm" :data-belongs-to="'c-'+costIndex">
                    </div>
                    <div class="col-sm-2 ps-3 pt-1">
                        {{ costs.comm_name }}
                    </div>
                    <div class="col-sm-2"></div>
                    <div class="col-sm-2p5">
                        <div class="input-group">
                            <select v-model="costs.purchase_rate_currency_id" class="form-select form-select-sm" @change="devalidate" :data-belongs-to="'c-'+costIndex">
                                <option v-for="currency in currencies" :key="currency.id" :value="currency.id"> {{currency.code}} </option>
                            </select>
                            <input type='number' step="0.01" v-model="costs.purchase_rate" class="form-control form-control-sm" :data-belongs-to="'c-'+costIndex" @change="devalidate" />
                            <select v-model="costs.purchase_rate_calculation_unit_id" class="form-select form-select-sm" @change="devalidate" :data-belongs-to="'c-'+costIndex">
                                <option value=''>{{$t('form.select')}}</option>
                                <option v-for="calculation_unit in calc_units_no_percentage" :key="calculation_unit.id" :value="calculation_unit.id"> {{calculation_unit.name}} </option>
                            </select>
                        </div>
                    </div> 
                    <div class="col-sm-2p5">
                        <div class="input-group">
                            <select v-model="costs.sales_rate_currency_id" class="form-select form-select-sm" @change="devalidate" :data-belongs-to="'c-'+costIndex">
                                <option value=''>{{$t('form.select')}}</option>
                                <option v-for="currency in currencies" :key="currency.id" :value="currency.id"> {{currency.code}} </option>
                            </select>
                            <input type='number' step="0.01" v-model="costs.sales_rate" @change="devalidate" class="form-control form-control-sm" :data-belongs-to="'c-'+costIndex" />
                            <select v-model="costs.sales_rate_calculation_unit_id" class="form-select form-select-sm" @change="devalidate" :data-belongs-to="'c-'+costIndex">
                                <option value=''>{{$t('form.select')}}</option>
                                <option v-for="calculation_unit in calc_units_no_percentage" :key="calculation_unit.id" :value="calculation_unit.id"> {{calculation_unit.name}} </option>
                            </select>
                        </div>
                    </div>
                </div>
            </template>
            <div class="row mt-4 justify-content-center">
                <a class="btn btn-sm btn-primary w-25" title="Click to add item"  @click="addGeneralCost">
                    <span class="fa fa-plus"></span> &nbsp;{{$t('form.add', [$tc('navigation.general_costs', 1)])}}
                </a>
            </div>
        </template>
    </div>

    <!--end tab -->
    <!--Begin Terms & attachments tab --> 

    <div class="tab-pane fade" id="terms" role="tabpanel" aria-labelledby="terms-tab">
        <h5 class="mt-4 mb-3 fw-bold">{{$t('sales.terms_1')}}</h5>
        <textarea v-model="quotation.terms" class="form-control form-control-sm w-50" rows="10"></textarea>
        <template v-if="quotation.ports_of_loading[0] && quotation.ports_of_discharge[0] && quotation.ports_of_discharge[0].country && 
                       (quotation.ports_of_discharge[0].country.iso == 'US' || quotation.ports_of_loading.find(pol => pol.port && pol.port.country.iso == 'US'))">
            <h5 class="mt-4 mb-3 fw-bold">{{$t('sales.terms_clause')}}</h5>
            <p>This NRA contains all applicable charges as per our written agreement and no other charges will affect the ocean transportation services to be rendered.<br>
               This NRA follows FMC rules as per 46 CFR, Part 532 et seq.<br>
              “THE SHIPPER'S BOOKING OF CARGO AFTER RECEIVING THE TERMS OF THIS NRA OR NRA AMENDMENT CONSTITUTES ACCEPTANCE OF THE RATES AND TERMS OF THIS NRA OR NRA AMENDMENT.”</p>
        </template>
        <h5 class="mt-4 mb-3 fw-bold">{{$t('sales.terms_2')}}</h5>
        <input type="file" multiple="" class="d-none" @change="uploadAttachment($event, true)" />
        <div class="file-drop" @dragover.prevent="dropzoneHover($event, 'blue')" @dragleave="dropzoneHover($event, '#888')" @drop="uploadAttachment($event)" @click="$event.target.previousElementSibling.click()">
            {{$t('form.drop_files')}}
        </div>
        <br />
        <h6 class="mt-4 mb-3 fw-bold" v-if="(!quotation.attachments || quotation.attachments.length == 0) && quotation.attachments_info.length == 0">{{$t('form.no_yet', [$tc('form.files', 2)])}}</h6>
        <div class="row mt-2" v-for="(file, index) in quotation.attachments_info" :key="index">
            <div class="col-auto" style="width: 50px"><i :class="'fa-thin fa-'+getFileIcon(file.type)"></i></div>
            <div class="col-3 text-truncate">{{file.name}}</div>
            <div class="col-auto p-0 text-truncate" style="width: calc(7% + 61px)">{{file.type}}</div>
            <div class="col-auto pe-0 text-end" style="width: 7%">{{file.size}}</div>
            <button class="col-auto btn p-0" style="width: 60px" type="button" @click="removeAttachment(quotation.attachments_info, index, file.name)"><i class="fa fa-close text-danger"></i></button>
        </div>
        <div class="row mt-2" v-for="(file, index) in quotation.attachments" :key="index">
            <div class="col-auto" style="width: 50px"><i :class="'fa-thin fa-'+getFileIcon(file.type)"></i></div>
            <a class="col-3 text-truncate" href="#" @click="download(file)" v-if="isLoggedIn">{{file.name ? file.name : file.path}}</a>
            <div v-else class="col-3 text-truncate">{{file.name}}</div>
            <div class="col-auto p-0 text-truncate" style="width: calc(7% + 61px)">{{file.type}}</div>
            <div class="col-auto pe-0 text-end" style="width: 7%">{{file.size}}</div>
            <button class="col-auto btn p-0" style="width: 60px" type="button" @click="removeAttachment(quotation.attachments, index, file.name)"><i class="fa fa-close text-danger"></i></button>
        </div>
    </div>

    <!--end tab --> 
    <!--Begin Summary tab --> 

    <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
        <h5 class="mt-4 mb-3 fw-bold">{{$t('sales.contract_details')}}</h5>
        <div class="container" v-if="!this.quotation.id">
            <p>Summary not available</p>
        </div>
        <div class="container" v-else-if="costSummaryHTML == null">
            <p>{{ $t('form.please_wait') }}</p>
        </div>
        <div class="container" v-else>
            <div v-html="costSummaryHTML"></div>
        </div>
    </div>

    <!--end tab --> 
    <template v-if="!fromJob">
        <Files :files="quotation.file_info" @addFile="() => {return null}" parentItemName="quotation" ref="filestab" />
        <ContactHistory :id="quotation.id" :comments="true" :forQuotation="true" ref="contactHistory" />
    </template>
    </div>

    <dialog id="addcustomer-dialog" style="width: 1400px; border: none; background-color: transparent; overflow: hidden">
        <form method="dialog" class="modal-content w-100">
            <div class="modal-header w-100 py-3">
                <h5 style="font-size: 1.2rem !important" class="ps-2 mb-0" id="detailsHeaderAbove">{{$t('form.add', [$tc('navigation.customers')])}}</h5>
                <button id="fakeCloseBtn3" type="button" class="btn-close" aria-label="Close" @click="setAddCustomerForm(false)"></button>
            </div>
            <div class="p-4" style="overflow-y: auto"> 
                <AddCustomerComponent :itemData="{}" :fromQuote="true" ref="addcustomerform" />
            </div>
            <div class="d-flex justify-content-end gap-3 p-3 border-top">
                <button type="button" class="btn btn-success" id="innernextbutton" @click="setCRMStep(1)">> {{$t('form.continue_to_step')}} <span id="innernextstep">2</span></button>
                <FormItem :options="{send: true}" startTag="fa fa-save" :service="saveCustomer" type="download" :label="$t('form.save')" id="innersavebutton" />
            </div>
        </form>
    </dialog>

</template>

<script>
    import relationService from "@/services/RelationService";
    import portService from "@/services/PortService";
    import commodityService from "@/services/CommodityService";
    import currencyService from "@/services/CurrencyService";
    import calculationunitService from "@/services/CalculationunitService";
    import contractService from "@/services/ContractService";
    import generalcostsService from "@/services/GeneralcostService";
    import surchargeService from "@/services/SurchargeService";
    import userService from "@/services/UserService";
    import terminalService from"@/services/TerminalService";
    import store from '@/store/user'
    import moment from 'moment'
    import QuotationSeatransportFormComponent from '../quotation/QuotationSeatransportFormComponent'
    import AddmanualVoyageComponentVue from '@/components/voyage/VoyageFormComponent.vue'
    import AddCustomerComponent from '@/components/relation/RelationFormComponent.vue'
    import QuotationVoyage from '../quotation/QuotationVoyage.vue'
    import Files from '@/components/formpopup/Files.vue'
    import ContactHistory from '@/components/formpopup/ContactHistory.vue'
    import fileService from "@/services/FileService";
    import quotationService from '@/services/QuotationService';
   
    export default {
        name: 'QuotationForm',
        props: ['itemData', 'formWrapper', 'fromJob'],
        components: {
            QuotationSeatransportFormComponent,
            QuotationVoyage,
            Files,
            ContactHistory,
            //AddmanualVoyageComponentVue,
            AddCustomerComponent
        },
        data() {
            return {
                quotation: {'ports_of_loading': [], 'ports_of_discharge': [], 'carriers': [], 'quote_lines': [], 'attachments_info': []},
                userName: '',
                carrierPols: {},
                customers: [],
                generalcosts: {'none': [], 'carrier': [], 'customer': [], 'terminal': []},
                carriers:[],
                carrierSuggestions: {},
                carrier_ids:[],
                commodities: {all: [], preferred: [], size: 0},
                currencies:[],
                calc_units:[],
                calc_units_no_percentage:[],
                conditions:[{id: 1, name: 'New'}, {id: 2, name: 'Used'}],
                ports: [],
                contracts:[],
                masterRatesList: [],
                salesmanagers: [],
                voyageCount: 0,
                costSummaryHTML: null,
                tempGeneralCosts: null,
                submitted: false,
                loading: false,
                invalid: {},
                isDraft: false,
                isEditing: false,
                canGoToStepTwo: false,
                canGoToStepThree: false,
                canGoToStepFour: false,
                nextStep: 2,
                innerformStep: 1,
                isAutoSaving: false,
                quarterOverlap: false,
                customVoyage: false,
                surcharges: [],
                forwardingOptions: [],
                allTerminals: [],
                terminals: [],
                isLicensePlateRequired: false,
                terminalsNeedUpdate: false,
                carriersNeedUpdate: false,
                quoteAlreadyAccepted: false,
                userService: userService,
                dataItemsLoaded: 0,
                dataItemsToLoad: 7,
                allDataLoaded: false,
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.quotation = val    
            },
        },
        computed: {
            isLoggedIn() {
                return store.getters.isLoggedIn;
            },
            getPolName: function(){
                return this.quotation.ports_of_loading[0] ? (this.quotation.ports_of_loading[0].port ? this.quotation.ports_of_loading[0].port.name : this.quotation.ports_of_loading[0].name) : '';
            },
            getSelectedPoL : function(){
                return (this.quotation.ports_of_loading.length == 0) ? [] : this.ports.filter(row => row.id == this.quotation.ports_of_loading[0].port_id);
            }, 
            getSelectedPoD : function(){
                return (this.quotation.ports_of_discharge.length == 0) ? [] : this.ports.filter(row => row.id == this.quotation.ports_of_discharge[0].id);
            },
            checkExpired : function(){
                return ((this.quotation.status == 'draft' || this.quotation.status == 'pending') && moment().format('YYYY-MM-DD') >= this.quotation.valid_till);
            }
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(showAlert = true){
                let message = "";
                let valid = true;
                let required = document.querySelectorAll('#formModal .dp__input_wrap input.is-invalid');
                const selectDefault = this.$t('form.select');

                //Check tab 1
                if(this.quotation.customer_id == null || this.quotation.ports_of_loading.length == 0 || this.quotation.ports_of_discharge.length == 0){
                    valid = false;
                    message = this.$t('errors.empty_fields');
                }
                if(this.quotation.status == 'accepted' && this.quotation.option_carrier_id == null){
                    valid = false;
                    message = "You must select a carrier option.";
                }
                if(this.quotation.valid_from > this.quotation.valid_till){
                    valid = false;
                    message = this.$t('errors.date_inverse');
                }
                if(!valid) {
                    if(showAlert) alert(message);
                    document.querySelectorAll(".nav-link")[0].click();
                    return valid;
                }

                //Check tab 2
                if(this.quotation.carriers.length == 0){
                    valid = false;
                    message = "You must select a carrier.";
                }
                if(!valid) {
                    if(showAlert){
                        alert(message);
                        document.querySelectorAll(".nav-link")[1].click();
                    }
                    return valid;
                }

                //Check tab 3
                //Check selects and number fields
                required = document.querySelectorAll('#freight .required-field');
                for(let field of required) {
                    if(field.value === '' || field.value === null || (field.classList.contains("simple-typeahead-input") && field.classList.contains("is-invalid"))){
                        valid = false;
                        field.classList.add("is-invalid");
                        message = this.$t('errors.empty_fields');
                    }
                    else{
                        field.classList.remove("is-invalid");
                    }
                }
                const emptyRequiredMeasureFields = document.querySelectorAll('#freight .measure-field.is-invalid');
                if(emptyRequiredMeasureFields.length > 0) {
                    valid = false;
                    message = this.$t('errors.empty_fields');
                }
                //Check if there is at least one item
                if(this.quotation.quote_lines.length == 0){
                    valid = false;
                    message = "There are no quote lines.";
                }

                if(!valid) {
                    if(showAlert){
                        alert(message);
                        document.querySelectorAll(".nav-link")[2].click();
                    }
                    return valid;
                }
                //Check tab 4
                required = document.querySelectorAll('#q-fragment div[data-required="true"] input:not([disabled]), #q-fragment div[data-required="true"] select');
                for(let field of required) {
                    if(field.value === '' || field.value === selectDefault){
                        valid = false;
                        field.classList.add("is-invalid");
                        message = this.$t('errors.empty_fields');
                    }
                    else{
                        field.classList.remove("is-invalid");
                    }
                }
                if(!valid) {
                    if(showAlert){
                        alert(message);
                        document.querySelectorAll(".nav-link")[3].click();
                    }
                    return valid;
                }
                //Check tab 5
                required = document.querySelectorAll('#addcost input, #addcost select');
                for(let field of required) {
                    if(field.value === '' || field.value === selectDefault){
                        let checkbox = document.querySelector('[data-set-nr="'+field.getAttribute("data-belongs-to")+'"');
                        if(checkbox != null && checkbox.checked){
                            valid = false;
                            field.classList.add("is-invalid");
                            message = this.$t('errors.empty_fields');
                        }
                        else{
                            field.classList.remove("is-invalid");
                        }
                    }
                    else{
                        field.classList.remove("is-invalid");
                    }
                }
                if(!valid) {
                    if(showAlert){
                        alert(message);
                        document.querySelectorAll(".nav-link")[4].click();
                    }
                    return valid;
                }
                if(this.quotation.status == 'accepted'){
                    this.quotation.option = this.hardCopy(this.quotation.option_carrier_id);
                    this.quotation.option_carrier_id = this.quotation.option_carrier_id.carrier;
                    this.quotation.port_of_discharge_id = this.quotation.ports_of_discharge[0].id;
                }
                this.postValidationSteps(valid);
                if(showAlert && this.quotation.status == 'draft'){
                    this.quotation.status = 'pending';
                }
                return valid;
            },
            validateDate(isFromDate){
                if(isFromDate){
                    this.quotation.valid_till = moment(this.quotation.valid_from).add(30, 'days').format('YYYY-MM-DD');
                }
                let sdateSpan = document.querySelector("#edatespan");
                let isValid = true;
                if(this.quotation.valid_from > this.quotation.valid_till){
                    isValid = false;
                    sdateSpan.innerHTML = this.$t('errors.date_inverse');
                }
                if(isValid){
                    document.querySelectorAll(".dp__input_wrap").forEach(field => field.classList.remove('is-invalid'));
                    sdateSpan.parentElement.classList.remove('d-block');
                }
                else{
                    document.querySelectorAll(".dp__input_wrap").forEach(field => field.classList.add('is-invalid'));
                    sdateSpan.parentElement.classList.add('d-block');
                }
                this.canGoToStepTwo = !this.checkTabOne();
                this.setNextButtonDisabled();
                if(isValid && this.canGoToStepThree){
                    this.quarterOverlap = moment(this.quotation.valid_from).quarter() != moment(this.quotation.valid_till).quarter();
                    this.getContractsReal(0, true, true);
                }
            },
            postValidationSteps(valid){
                this.tempGeneralCosts = this.hardCopy(this.quotation.quote_general_costs);
                //Only checked general costs will be saved
                const glength = this.quotation.quote_general_costs.length - 1;
                for(var i = glength; i >= 0; i--){
                    if(!this.quotation.quote_general_costs[i].checked){
                        this.quotation.quote_general_costs.splice(i, 1);
                    }
                }
                if(valid){
                    this.quotation.quote_lines.forEach((ql, order) => {
                        ql.order_by = order;
                        if(this.$refs.seatransportcomponent.isInSurcharges(ql.description)) return;
                        if(ql.description){
                            ql.unit_make_id = null;
                            ql.unit_model_id = null;
                        }
                    });
                }
            },
            getData() {
                let returndata = this.hardCopy(this.quotation);
                returndata.quote_general_costs = returndata.quote_general_costs.filter(row => row.checked)
                return returndata;
            },
            setData(data) {
                if(data) {
                    console.log(data);
                    this.quotation = data;
                    this.quotation.carriers = [];
                    this.quotation.attachments = this.quotation.file_info.filter(file => file.is_attachment);
                    this.quotation.file_info = this.quotation.file_info.filter(file => !file.is_attachment);
                    this.quotation.checkedcosts = this.hardCopy(this.quotation.quote_general_costs.filter(cost => cost.should_show));
                    this.quotation.quote_general_costs = [];
                    this.quotation.attachments_info = [];
                    this.isDraft = this.quotation.status == 'draft';
                    this.quoteAlreadyAccepted = this.quotation.status == 'accepted';
                    this.quarterOverlap = moment(this.quotation.valid_from).quarter() != moment(this.quotation.valid_till).quarter();
                    this.isEditing = true;
                    this.formWrapper.setSlider(data.hide_surcharges ? 1 : 0, data.status == "accepted");
                    for(let i in data.quote_routes){
                        let transarr = [];
                        const voyage = this.quotation.quote_routes[i];
                        voyage.quote_route_segments.forEach(transvoy => {
                            transarr.push({
                                voyage_id: transvoy.voyage_id,
                                port_of_loading_id: transvoy.port_of_loading_id,
                                port_of_discharge_id: transvoy.port_of_discharge_id,
                                carrier_id: transvoy.carrier_id,
                                name: transvoy.voyage.number
                            });
                        });
                        if(voyage.quote_route_segments.length > 1){
                            this.$refs.voyageComp.checkForTransshipments = true;
                        }
                        this.quotation.quote_routes[i] = {
                            voyage_id: voyage.voyage_id,
                            port_of_loading_id: voyage.port_of_loading_id,
                            port_of_discharge_id: voyage.port_of_discharge_id,
                            preferred: voyage.preferred,
                            quote_route_segments: transarr,
                            origin_port_id: voyage.port_of_loading.port_id
                        };
                    }
                }
                if(this.dataItemsLoaded < this.dataItemsToLoad){
                    this.loadData();
                    return;
                }
                this.allDataLoaded = true;
                this.$refs.voyageComp.reset();

                this.generalcosts.carrier = [];
                this.generalcosts.customer = [];
                this.generalcosts.terminal = [];
                this.userName = store.getters.getName;
                this.terminalsNeedUpdate = false;
                this.carriersNeedUpdate = false;
                this.canGoToStepTwo = !this.checkTabOne(); 
                this.canGoToStepThree = this.canGoToStepTwo;
                this.canGoToStepFour = this.canGoToStepThree && !(this.quotation.quote_lines.length == 0 || this.quotation.quote_lines.find(ql => (ql.commodity_id == null && ql.surcharge_id == null && !ql.is_surcharge)) != null);
                this.nextStep = 2;
                document.getElementById('nextbutton').disabled = false;
                document.getElementById('customer-tab').click();
                this.forwardingOptions = [];
                //Set pre-transport
                this.quotation.pre_transport = 0;
                this.quotation.post_transport = 0;
                this.quotation.quote_transport_lines.forEach(tline => {
                    if(tline.type == 'pre') this.quotation.pre_transport = 1;
                    else this.quotation.post_transport = 1;
                });
                
                //Set carriers
                let carriers = [];
                let carrierIds = [];
                this.masterRatesList = [];
                this.quotation.quote_lines.forEach((ql, index) => {
                    ql.uuid = index;
                    ql.quote_line_carriers.forEach(carr => {
                        const carrId = carr.carrier_id;
                        if(!carrierIds.includes(carrId)){
                            let newCarrier = this.hardCopy(carr);
                            newCarrier.name = this.carriers.find(tc => tc.id == newCarrier.carrier_id).name;
                            carriers.push(newCarrier);
                            carrierIds.push(carrId);
                            this.masterRatesList.push({'id': carrId, 'carrier_id': carrId, 'buying_rate_currency_id': window.defaultCurrency, 'sales_rate_currency_id': window.defaultCurrency, 
                                    'name': newCarrier.name, 'cname': this.safeFind(this.ports, 'id', newCarrier.port_of_loading_id, 'name') + ', ' + newCarrier.name, 'port_of_loading_id': carr.port_of_loading_id});
                            this.carrierPols[carrId] = null;
                        }
                    });
                });
                this.masterRatesList.sort(function(a,b){ return (a.cname > b.cname) ? 1 : -1});
                carriers.sort(function(a,b){ return (a.name > b.name) ? 1 : -1});
                let portCarrierCombinations = {};
                this.quotation.quote_route_ids = [];
                this.quotation.quote_routes.forEach((r, rindex) => {
                    let idstringarray = [];
                    r.quote_route_segments.forEach(s => {
                        if(!portCarrierCombinations[s.carrier_id]) portCarrierCombinations[s.carrier_id] = [];
                        portCarrierCombinations[s.carrier_id].push(r.origin_port_id);
                        idstringarray.push(s.voyage_id + '_' + s.port_of_loading_id + '_' + s.port_of_discharge_id);
                    });
                    this.quotation.quote_route_ids.push(idstringarray.join('-'));
                })
                carriers.forEach(c => {
                    if(portCarrierCombinations[c.carrier_id]){
                        portCarrierCombinations[c.carrier_id].forEach(port => c[port] = 1);
                    }
                });
                this.quotation.carriers = carriers;
                if(carriers.length == 0)
                    this.canGoToStepThree = false;
                else
                    carriers.forEach(carr => this.carrier_ids.push(carr.carrier_id));

                this.getGeneralcosts('carrier', carrierIds, this.quotation.checkedcosts);
                this.getGeneralcosts('customer', this.quotation.customer_id, this.quotation.checkedcosts);
                this.filterGeneralCosts(this.generalcosts.none, undefined, this.quotation.checkedcosts);
                this.quotation.checkedcosts.filter(cc => cc.general_cost_id === null).forEach(cc => {
                    const newCost = cc;
                    newCost.checked = true, newCost.show = true;
                    this.quotation.quote_general_costs.push(newCost);
                });
                this.quotation.quote_general_costs.forEach(cost => {
                    if(cost.general_cost_id === null) cost.relation_type = 'none';
                });

                if(this.quotation.fileInfo === undefined){
                    this.quotation.fileInfo = [];
                }

                //Add contract / tariff numbers
                this.quotation.quote_lines.filter(item => !item.surcharge_id).forEach((ql, qlIndex) => {
                    ql.quote_line_carriers.forEach(qlc => {
                        if(qlc.contract && qlc.contract.number){
                            qlc.currentQuarterOnly = qlc.contract.end_date < this.quotation.valid_till;
                            qlc.tariffOrContractNr = 'contract ' + qlc.contract.number + (qlc.currentQuarterOnly ? this.$t('sales.valid_x', [this.formatDate(qlc.contract.end_date, 'shortDate')]) : '');
                        }
                        else if(qlc.tariff && qlc.tariff.number){
                            qlc.currentQuarterOnly = qlc.tariff.end_date < this.quotation.valid_till;
                            qlc.tariffOrContractNr = 'tariff ' + qlc.tariff.number + (qlc.currentQuarterOnly ? this.$t('sales.valid_x', [this.formatDate(qlc.tariff.end_date, 'shortDate')]) : '');
                        }
                        if(this.quarterOverlap){
                            const startDate = qlc.tariff ? qlc.tariff.start_date : (qlc.contract ? qlc.contract.start_date : null);
                            if(!qlc.currentQuarterOnly && startDate) qlc.tariffOrContractNr += this.$t('sales.valid_y', [this.formatDate(startDate, 'shortDate')]);
                            qlc.currentQuarterOnly = true;
                        }
                    });
                });
                if(this.quotation.quote_lines.length == 0 || this.quotation.quote_lines.find(ql => (ql.commodity_id == null && ql.surcharge_id == null && !ql.is_surcharge)) != null){
                    this.canGoToStepFour = false;
                }
                if(!this.quoteAlreadyAccepted){
                    this.getContractsReal(0, false);
                }
                
                this.$nextTick(() => {
                    if(!this.fromJob){
                        //Workaround for setting header title and quote line titles
                        const elc = document.querySelector('#customer_id_wrapper input');
                        let cName = elc ? elc.value : '';
                        const qNumber = this.quotation.number === null ? ": " : " (" + this.quotation.number + "): ";
                        const polNames = this.quotation.ports_of_loading.length == 1 ? this.getSelectedPoL[0].name : (this.getSelectedPoL[0].name + ' +' + (this.quotation.ports_of_loading.length - 1));
                        document.getElementById("detailsSpan").innerHTML = qNumber + cName + "&ensp;" + polNames + " &rarr; " + this.getSelectedPoD[0].name;
                        let id = this.quotation.quote_lines.push({});
                        this.quotation.quote_lines.splice(id - 1, 1);
                        //Set message feed
                        this.$refs.contactHistory.getMessages(this.quotation.messages);
                    }

                    //Get voyages
                    let resultArr = [];
                    this.quotation.ports_of_loading.forEach((pol, polIndex) => {
                        pol.name = pol.port.name;
                        if(!pol.carriers.length) return;
                        let polCarriers = [];
                        pol.carriers.forEach(polCarrier => {
                            const carrierId = polCarrier.pivot ? polCarrier.pivot.carrier_id : (polCarrier.carrier_id ? polCarrier.carrier_id : polCarrier.id);
                            resultArr.push({port_of_loading_id: pol.port_id, carrier_id: carrierId});
                            const port = this.ports.filter(row => row.id == pol.port_id);
                            pol.name = port.length > 0 ? port[0].name : '';
                            this.carrierPols[carrierId] = polIndex;
                            polCarriers.push({id: carrierId});
                        });
                        pol.carriers = polCarriers;
                    });
                    //Get forwarding options
                    this.$nextTick(() => {
                        this.$refs.voyageComp.getVoyagesMultiple(resultArr);
                        for(let itemIndex in this.masterRatesList) this.forwardingOptions.push( this.$refs.seatransportcomponent.getDisplayName(itemIndex, true, '', this.masterRatesList[itemIndex].carrier_id) );
                        this.getSurcharges(null);
                    });

                    let portIds = [this.quotation.ports_of_loading[0].port_id, this.quotation.ports_of_discharge[0].id];
                    this.getVoyageGeneralcosts('terminal', {'voyages': this.quotation.quote_routes, 'ports': portIds}, this.quotation.checkedcosts);
                    this.setSeatranspportSelectedCurrencies();
                    if(this.quotation.status != 'draft'){
                        let saveButton = document.getElementById('savebutton');
                        saveButton.style.display = 'block';
                        saveButton.disabled = this.quoteAlreadyAccepted;
                        saveButton.inert = this.quoteAlreadyAccepted;
                        document.getElementById("saveeditjobbutton").style.display = this.quotation.status == 'pending' ? 'block' : 'none';
                    }
                    this.setLicensePlateRequired();
                    delete this.quotation.checkedcosts;
                    this.$refs.seatransportcomponent.loadData();
                });
            },
            reset(isEditing = false, isOnPortChange = false, onRemovePort = false) {
                this.isEditing = isEditing;
                if(!this.isEditing && this.dataItemsLoaded < this.dataItemsToLoad){
                    this.quotation.quote_general_costs = [];
                    this.canGoToStepTwo = false, this.canGoToStepThree = false, this.canGoToStepFour = false;
                    this.loadData();
                    return;
                }

                this.$refs.voyageComp.reset();
                this.masterRatesList = [];
                this.quarterOverlap = false, this.customVoyage = false, this.quoteAlreadyAccepted = false;
                let quoteCopy = {};
                if(isOnPortChange){
                    this.quotation.quote_general_costs = [];
                    this.canGoToStepThree = false, this.canGoToStepFour = false;
                    quoteCopy = this.hardCopy(this.quotation);
                }
                else{
                    this.userName = store.getters.getName;
                    this.isDraft = true;
                    this.terminalsNeedUpdate = false;
                    this.carriersNeedUpdate = false;
                    this.canGoToStepTwo = false, this.canGoToStepThree = false, this.canGoToStepFour = false;
                }
                this.generalcosts.carrier = [], this.generalcosts.customer = [], this.generalcosts.terminal = [];
                this.carrier_ids = [];
                this.carrierSuggestions = {};
                this.formWrapper.setSlider(0, false);
                this.quotation = {
                    'id': null,
                    'number': null,
                    'version': null,
                    'customer_reference':null,
                    'pre_transport': false,
                    'post_transport': false,
                    'currency_id': window.defaultCurrency,
                    'lump_sum_purchase_currency_id':null,
                    'lump_sum_purchase_cost':null,
                    'lump_sum_price_currency_id': null,
                    'lump_sum_price': null,
                    'lump_sum_markup':null,
                    'customer_id': null,
                    'is_forward': 1,
                    'hide_surcharges': 0,
                    'user_id': store.getters.userId === null ? this.salesmanagers.find(manager => manager.name == store.getters.getName).id : store.getters.userId,
                    'status': 'draft',
                    'option_carrier_id': null,
                    'ports_of_loading': [],
                    'ports_of_discharge': [],
                    'valid_from': moment().format('YYYY-MM-DD'),
                    'valid_till': moment().add(30, 'days').format('YYYY-MM-DD'),
                    'carriers': [],
                    'quote_routes': [],
                    'file_info': [],
                    'quote_lines': [],
                    'quote_transport_lines': [],
                    'quote_general_costs': [],
                    'terms': '',
                    'attachments': [],
                    'attachments_info': []
                };
                this.loading = false
                this.invalid = {}
                this.nextStep = 2;
                if(!this.isEditing){
                    this.filterGeneralCosts(this.generalcosts.none);
                }
                this.setLicensePlateRequired();
                if(!this.fromJob) this.$refs.contactHistory.empty();
                document.getElementById('customer-tab').click();
                this.setSeatranspportSelectedCurrencies();

                if(this.quotation.user_id) this.quotation.sales_manager = { 'id': this.quotation.user_id, 'name': store.getters.getName };

                this.$nextTick(() => {
                    const livesearches = document.querySelectorAll(".simple-typeahead:not(#user_id_wrapper) input");
                    livesearches.forEach(el => el.value = '');
                    this.$refs.seatransportcomponent.addSeaTransportline(null, true);
                    const elsm = document.querySelector("#user_id_wrapper input");
                    if(elsm) elsm.value = this.quotation.sales_manager.name;
                    if(isOnPortChange){
                        this.quotation.id = quoteCopy.id;
                        this.quotation.valid_from = quoteCopy.valid_from, this.quotation.valid_till = quoteCopy.valid_till;
                        this.quotation.ports_of_loading = quoteCopy.ports_of_loading, this.quotation.ports_of_discharge = quoteCopy.ports_of_discharge;
                        this.quotation.ports_of_loading.forEach(pol => pol.carriers = []);
                        this.quotation.pre_transport = quoteCopy.pre_transport, this.quotation.post_transport = quoteCopy.post_transport;
                        this.quotation.external_access_key = quoteCopy.external_access_key, this.quotation.external_access_key_active = quoteCopy.external_access_key_active;
                        this.quotation.customer = quoteCopy.customer, this.quotation.customer_id = quoteCopy.customer_id;
                        this.quotation.customer_reference = quoteCopy.customer_reference;
                        this.quotation.user_id = quoteCopy.user_id;
                        this.quotation.currency_id = quoteCopy.currency_id;
                        this.quotation.number = quoteCopy.number;
                        this.quotation.version = quoteCopy.version;
                        this.quotation.status = quoteCopy.status;
                        this.quotation.creator = quoteCopy.creator;
                        this.quotation.file_info = quoteCopy.file_info;
                        this.quotation.sales_manager = quoteCopy.sales_manager;
                        this.quotation.messages = quoteCopy.messages;
                        this.canGoToStepTwo = !this.checkTabOne();
                        this.setNextButtonDisabled();
                        this.setLicensePlateRequired();
                        if(onRemovePort) this.onChangePol(1, '', {});
                    } else {
                        document.getElementById('nextbutton').disabled = true;
                        document.getElementById("downloadshowpdfbutton").style.display = 'none';
                        document.getElementById("saveeditjobbutton").style.display = 'none';
                    }
                });
            },
            setManualVoyageForm(active){
                this.customVoyage = active;
                const ref = this.$refs.addmanvoyageform;
                ref.formActive = active;
                if(active){
                    ref.reset();
                }
            },
            setAddCustomerForm(active){
                const ref = this.$refs.addcustomerform;
                ref.formActive = active;
                if(active){
                    document.getElementById('addcustomer-dialog').showModal();
                    this.innerformStep = 1;
                    ref.reset();
                }
                else{
                    document.getElementById('addcustomer-dialog').close();
                }
            },
            setCRMStep(plusminus){
                this.innerformStep += plusminus;
                this.$refs.addcustomerform.setNextStep(this.innerformStep);
            },
            async saveCustomer(){
                if(!this.$refs.addcustomerform.validate()) return;
                return relationService.store(this.$refs.addcustomerform.getData()).then(response => {
                    this.quotation.customer_id = response.data.customer.id;
                    this.quotation.customer = {relation: {name: response.data.name}};
                    this.setAddCustomerForm(false);
                }).catch(error => console.log('error', error) );
            },
            
            filterTransport(type){
                return this.quotation.quote_transport_lines.map((data, index) => ({ data, index })).filter(row => row.data.type == type);
            },
            getAllTerminals(){
                terminalService.index().then(response => {
                    this.allTerminals = response.data;
                    this.checkData();
                }).catch(error => console.log('error', error) );
            },
            
            addTransportLine(type){
                const pol = (this.quotation.ports_of_loading.length == 1) ? this.quotation.ports_of_loading[0].port_id : null;
                this.quotation.quote_transport_lines.push({
                    'quantity': '1',
                    'description': null,
                    'port_of_loading_id': pol,
                    'sales_currency_id': window.defaultCurrency,
                    'sales_price': null,
                    'purchase_currency_id': window.defaultCurrency,
                    'purchase_price': null,
                    'type': type
                });
            },  
            deleteTransportLine(index){
                if(confirm("Are you sure you want to delete this transport item?")) this.quotation.quote_transport_lines.splice(index, 1);
            },
            getCommodities() {
                commodityService.getWithPreferredGroupings(this.quotation).then(response => this.commodities = response.data ).catch(error => console.log('error', error) );
            },
            getContracts() {
                this.$nextTick(() => this.getContractsReal());
            },
            getContractsReal(id = 0, getSurcharges = true, autoUpdate = false) {
                if(!this.quotation.customer_id || this.quotation.ports_of_loading.length == 0 || this.quotation.ports_of_discharge.length == 0 || this.quotation.carriers.length == 0) {
                    return;
                }
                if(getSurcharges)
                    this.getSurcharges(null, autoUpdate);
                let port_of_loading_id = [(this.quotation.ports_of_loading[id].port_id ? this.quotation.ports_of_loading[id].port_id : this.quotation.ports_of_loading[id].id)];
                let port_of_discharge_id = this.quotation.ports_of_discharge[0].id;
                contractService.getCustomerContracts(this.quotation.customer_id, port_of_loading_id, port_of_discharge_id, this.carrier_ids, this.quotation.valid_till
                ).then(response => {
                    this.contracts = [];
                    if(response.data.contract_items == null){
                        if(autoUpdate){
                            for(let index = this.quotation.quote_lines.length - 1; index >= 0; index--){
                                if(!this.quotation.quote_lines[index].is_surcharge)
                                    this.$refs.seatransportcomponent.prefillTariffs(index, 0, false, true);
                            }
                        }
                        return;
                    }
                    response.data.contract_items.forEach(tariffItem => { tariffItem.contract_item_commodities.forEach(comm => {
                        comm.commodities.forEach(item => {
                            let commcarrier = null;
                            let currentQuarterOnly = tariffItem.contract.end_date < this.quotation.valid_till;
                            commcarrier = {
                                buying_rate: comm.purchase_price,
                                buying_rate_currency_id: comm.purchase_price_currency_id,
                                buying_rate_calculation_unit_id: comm.purchase_price_calculation_unit_id,
                                contract_id: tariffItem.contract_id,
                                sales_rate: comm.sales_price,
                                sales_rate_currency_id: comm.sales_price_currency_id,
                                sales_rate_calculation_unit_id: comm.sales_price_calculation_unit_id,
                                tariff_item_commodity_id: null,
                                currentQuarterOnly: currentQuarterOnly
                            };
                            this.contracts.push({
                                'sales_currency_id': comm.sales_price_currency_id,
                                'sales_price': comm.sales_price,
                                'buying_currency_id': comm.purchase_price_currency_id,
                                'buying_price': comm.purchase_price,
                                'commodity_id': item.id,
                                'condition_id': comm.condition_id,
                                'quote_line_carrier': commcarrier,
                                'measurement_conditional_description': comm.measurement_conditional_description,
                                'is_based_off_tariffs': tariffItem.contract.is_based_off_tariffs,
                                'is_pro_rata': comm.is_pro_rata,
                                'carriers': tariffItem.carriers,
                                'loading_ports': tariffItem.loadingPorts,
                                'number': tariffItem.contract.number,
                                'start_date': tariffItem.contract.start_date,
                                'end_date': tariffItem.contract.end_date
                            });
                        });
                    })});
                    if(autoUpdate){
                        this.$nextTick(() => {
                            for(let index = this.quotation.quote_lines.length - 1; index >= 0; index--){
                                if(!this.quotation.quote_lines[index].is_surcharge){
                                    this.$refs.seatransportcomponent.prefillTariffs(index, 0, false, true);
                                }
                            }
                        });
                    }
                }).catch(error => console.log('error', error) );
            },
            filter(array, value){
                const matches = array.filter(row => row.id == value);
                if(matches.length == 1){
                    const name = matches[0].code !== undefined ? 'code' : 'name';
                    return matches[0][name];
                }
                return '';
            },
            getVoyageGeneralcosts(relation, relationData, checkedcosts = []) {
                generalcostsService.search('voyage', relationData).then(response => {
                    this.generalcosts[relation] = response.data
                    this.filterGeneralCosts(this.generalcosts[relation], relation, checkedcosts);
                }).catch(error => console.log('error', error) );
            },
            getGeneralcosts(relation, relationData, checkedcosts = []) {
                generalcostsService.search(relation, relationData).then(response => {
                    this.generalcosts[relation] = response.data
                    this.filterGeneralCosts(this.generalcosts[relation], relation, checkedcosts);
                    if(relation == 'none'){
                        this.checkData();
                    }
                }).catch(error => console.log('error', error) );
            },
            filterGeneralCosts(generalcosts, relationType = 'none', checkedcosts = []){
                const relationTypeConst = (relationType + '');
                const length = (this.quotation.quote_general_costs.length - 1);
                for (var costId = length; costId >= 0; costId--){
                    if(this.quotation.quote_general_costs[costId].relation_type == relationTypeConst) {
                        this.quotation.quote_general_costs.splice(costId, 1);
                    }
                }
                let totalComms = 0;
                this.quotation.quote_lines.forEach(ql => {
                    const number = Number(ql.quantity)
                    if(!ql.surcharge_id) totalComms += isNaN(number) ? 1 : number;
                });
                //Can't have zero
                if(totalComms == 0) totalComms = 1;

                for(let cost of generalcosts) {
                    if(cost.managed_by_relations){
                        if(cost.commodity_dependent){
                            for(let commodity of cost.relation_commodity)
                                this.setGeneralCosts(checkedcosts, cost.id, totalComms, cost.description, commodity, true, true, relationTypeConst);
                        }
                        else{
                            for(let rel of cost.relation)
                                this.setGeneralCosts(checkedcosts, cost.id, totalComms, cost.description, rel, false, true, relationTypeConst);
                        }
                    }
                    else if(cost.commodity_dependent) {
                        for(let commodity of cost.commodities)
                            this.setGeneralCosts(checkedcosts, cost.id, totalComms, cost.description, commodity, true, false, relationTypeConst);
                    }
                    else{
                        this.setGeneralCosts(checkedcosts, cost.id, totalComms, cost.description, cost, false, false, relationTypeConst);
                    }
                }
            },
            setGeneralCosts(checkedcosts, costId, totalComms, description, rateData, commDependent, byRelations, relationTypeConst){
                let newItem = {
                    "description": description,
                    "purchase_rate": rateData.purchase_rate,
                    "sales_rate": rateData.sales_rate,
                    "purchase_rate_currency_id": rateData.purchase_rate_currency_id,
                    "sales_rate_currency_id": rateData.sales_rate_currency_id,
                    "purchase_rate_calculation_unit_id": rateData.purchase_rate_calculation_unit_id,
                    "sales_rate_calculation_unit_id": rateData.sales_rate_calculation_unit_id,
                    "commodity_dependent": commDependent,
                    "managed_by_relations": byRelations,
                    "general_cost_commodity_id": commDependent && !byRelations ? rateData.id : null,
                    "comm_id": commDependent ? rateData.commodity.id : undefined,
                    "comm_name": commDependent ? rateData.commodity.name : null,
                    "relation_general_cost_commodity_id": commDependent && byRelations ? rateData.id : null,
                    "relation_type": relationTypeConst,
                    "relation_name": byRelations ? rateData.relation.name : '-',
                    "relation_id": byRelations ? rateData.relation_id : undefined,
                    "checked": false,
                    "should_show": true,
                    "general_cost_id": costId,
                    "quantity": totalComms
                };
                if(checkedcosts.length > 0) {
                    let found = checkedcosts.find(row => (row.general_cost_id == costId && row.general_cost_commodity_id == newItem.general_cost_commodity_id &&
                                                          row.relation_general_cost_commodity_id == newItem.relation_general_cost_commodity_id));
                    if(found) {
                        newItem.checked = true, newItem.should_show = true;
                        newItem.quantity = found.quantity;
                        newItem.description = found.description;
                        newItem.purchase_rate = found.purchase_rate;
                        newItem.purchase_rate_currency_id = found.purchase_rate_currency_id;
                        newItem.purchase_rate_calculation_unit_id = found.purchase_rate_calculation_unit_id;
                        newItem.sales_rate = found.sales_rate;
                        newItem.sales_rate_currency_id = found.sales_rate_currency_id;
                        newItem.sales_rate_calculation_unit_id = found.sales_rate_calculation_unit_id;
                        if(byRelations) newItem.relation_id = found.relation_id;
                        if(commDependent) newItem.comm_id = found.comm_id;
                        if(commDependent && byRelations) newItem.relation_general_cost_commodity_id = found.relation_general_cost_commodity_id;
                        if(commDependent && !byRelations) newItem.general_cost_commodity_id = found.general_cost_commodity_id;
                    }
                }
                this.quotation.quote_general_costs.push(newItem);
            },
            checkTabOne(){
                return this.quotation.ports_of_loading.length == 0 || this.quotation.ports_of_discharge.length == 0 || this.quotation.customer_id == null
                         || this.quotation.ports_of_discharge[0] == null || this.quotation.valid_from > this.quotation.valid_till;
            },
            onChangeCustomer(id) {
                if(this.canGoToStepThree)
                    this.getContractsReal(0, false, true);
                else
                    this.getContracts();
                this.canGoToStepTwo = !this.checkTabOne();
                this.setNextButtonDisabled();
                this.getGeneralcosts('customer', id);
            },
            onChangePol(id, name, item) {
                item.port_id = id;
                item.carriers = [];
                if(id && this.quotation.ports_of_discharge[0] && this.quotation.ports_of_loading.length){
                    this.$refs.voyageComp.getVoyages();
                    quotationService.getCarrierSuggestions(this.quotation.ports_of_discharge[0].id, this.quotation.valid_till, this.quotation.ports_of_loading.map(x => x.port_id || x.id)).
                                     then(response => this.carrierSuggestions = response.data);
                }
                this.afterChangePort();
            },
            onChangePod(id, name) {
                this.quotation.ports_of_discharge = [{id: id, name: name}];
                if(id && this.quotation.ports_of_loading.length){
                    this.$refs.voyageComp.getVoyages();
                    quotationService.getCarrierSuggestions(id, this.quotation.valid_till, this.quotation.ports_of_loading.map(x => x.port_id || x.id))
                                    .then(response => this.carrierSuggestions = response.data);
                }
                this.afterChangePort();
            },
            removePort(index, carriers){
                carriers.forEach(carrier => this.carrierPols[carrier] = null);
                for(let cpol in this.carrierPols)
                    if(this.carrierPols[cpol] != null && index < this.carrierPols[cpol]) this.carrierPols[cpol]--;
                this.afterChangePort(true);
            },
            afterChangePort(onRemovePort = false){
                if(this.quotation.ports_of_loading[0] && this.quotation.ports_of_loading[0].port_id && this.quotation.ports_of_discharge[0] && this.quotation.ports_of_discharge[0].id){
                    this.$nextTick(() => this.reset(this.isEditing, true, onRemovePort) );
                }
                else{
                    this.canGoToStepTwo = !this.checkTabOne();
                    this.setNextButtonDisabled();
                    this.setLicensePlateRequired();
                }
            },
            addCarrierAndSurcharges(id, name, item){
                item.carrier_id = item.id;
                this.getSurcharges(id);
            },
            addSuggestion(id){
                this.quotation.carriers.push({id: id, name: this.carrierSuggestions[id], carrier_id: id});
                this.quotation.carriers.sort(function(a,b){ return (a.name > b.name) ? 1 : -1});
                this.getSurcharges(Number(id));
            },

            setNextStep(e, nextStepNumber){
                let nextStep = nextStepNumber ? nextStepNumber : (Number(e.target.innerHTML[0]) + 1);

                if(nextStep == 2 && this.isEditing){
                    this.forwardingOptions = [];
                    this.setQuoteHeader();
                    for(let itemIndex in this.masterRatesList) this.forwardingOptions.push( this.$refs.seatransportcomponent.getDisplayName(itemIndex, true, '', this.masterRatesList[itemIndex].carrier_id) );
                }
                if(nextStep > 3)
                    this.$refs.voyageComp.filterCarriers();
                if((nextStep == 3 || nextStep == 7) && !this.quoteAlreadyAccepted)
                    this.focusFirstInputField(e);
                if(nextStep == 4)
                     this.getCommodities();
                window.dontShowDropzone = nextStep == 7;
                if(!this.isEditing && (nextStep == 4 || nextStep == 5))
                    this.$refs.seatransportcomponent.loadData();
                if(nextStep != 8 && this.$parent.getAutoSaveEnabled())
                    this.autoSave(false);
                else if(nextStep == 8)
                    this.autoSave(true);

                this.nextStep = nextStep;
                document.getElementById("nextStep").innerHTML = nextStep;
                let nextButton = document.getElementById('nextbutton');
                let surchargeSlider = nextButton.nextElementSibling;
                let saveButton = surchargeSlider.nextElementSibling.nextElementSibling;
                if(!saveButton) return;
                saveButton.style.display = (this.quotation.status != 'draft' || nextStep > 7) ? 'block' : 'none';
                this.setNextButtonDisabled();
                nextButton.style.display = nextStep > 7 ? 'none' : 'block';
                surchargeSlider.style.display = nextStep != 8 ? 'none' : 'block';
                surchargeSlider.nextElementSibling.style.display = 'none';
                if(this.terminalsNeedUpdate){
                    this.terminalsNeedUpdate = false;
                    this.getGeneralcosts('terminal', this.terminals);
                }
                if(this.carriersNeedUpdate){
                    this.carriersNeedUpdate = false;
                    let carrierIds = [];
                    this.quotation.carriers.forEach(carr => carrierIds.push(carr.carrier_id));
                    this.$refs.voyageComp.getVoyages();
                    this.getGeneralcosts('carrier', carrierIds);
                }
                this.quarterOverlap = moment(this.quotation.valid_from).quarter() != moment(this.quotation.valid_till).quarter();
                saveButton.disabled = this.quoteAlreadyAccepted;
            },
            setQuoteHeader(){
                if(!this.fromJob){
                    const custEl = document.querySelector('#customer_id_wrapper input');
                    if(this.quotation.customer_id && this.getSelectedPoL.length > 0 && this.getSelectedPoD.length > 0 && custEl){
                        const cName = custEl.value;
                        const qNumber = this.quotation.number === null ? ": " : " (" + this.quotation.number + "): ";
                        const polNames = this.quotation.ports_of_loading.length == 1 ? this.getSelectedPoL[0].name : (this.getSelectedPoL[0].name + ' +' + (this.quotation.ports_of_loading.length - 1));
                        document.getElementById("detailsSpan").innerHTML = qNumber + cName + "&ensp;" + polNames + " &rarr; " + this.getSelectedPoD[0].name;
                    }
                    else document.getElementById("detailsSpan").innerHTML = "";
                }
            },
            setNextButtonDisabled(){
                let nextButton = document.getElementById('nextbutton');
                if(this.nextStep == 2) {
                    nextButton.disabled = !this.canGoToStepTwo;
                    if(this.canGoToStepTwo) this.$nextTick(() => this.setQuoteHeader());
                }
                else if(this.nextStep == 3) nextButton.disabled = !this.canGoToStepThree;
                else if(this.nextStep == 4) nextButton.disabled = !this.canGoToStepFour;
            },

            addCarrier(carrierId)  {
                const cId = Number(carrierId);
                this.carriersNeedUpdate = true;
                this.carrierPols[cId] = null;
                //Add rates to commodities
                const newIndex = this.quotation.carriers.findIndex( (el) => this.getId(el) == cId);
                this.carrier_ids.splice(newIndex, 0, cId);
                if(this.quotation.ports_of_loading.length == 1){
                    this.quotation.ports_of_loading[0].carriers.push({id: cId});
                    this.addMasterRates(cId, newIndex, this.quotation.ports_of_loading[0].port_id);
                }
                this.$refs.voyageComp.filterVoyages(this.carrier_ids);
                this.getContracts();
            },
            deleteCarrier(index, carriers, item) {
                let carrId = item.carrier_id ? item.carrier_id : item.id;
                let selId = this.carrier_ids.findIndex(id => id == carrId);
                this.carrier_ids.splice(selId, 1);
                for(let i = this.masterRatesList.length - 1; i >= 0; i--){
                    if(this.masterRatesList[i].carrier_id == carrId){
                        this.masterRatesList.splice(i, 1);
                        this.quotation.quote_lines.forEach(item => item.quote_line_carriers.splice(i, 1) );
                    }
                }
                if(this.quotation.carriers.length == 0){
                    this.canGoToStepThree = false;
                    this.setNextButtonDisabled();
                }
                this.quotation.ports_of_loading.forEach(port => {
                    if(port.carriers){
                        let size = port.carriers.length - 1;
                        for(let i = size; i >= 0; i--){
                            if(port.carriers[i] == carrId || port.carriers[i].id == carrId){
                                port.carriers.splice(i, 1);
                            }
                        }
                    }
                });
                this.masterRatesList.sort(function(a,b){ return (a.cname > b.cname) ? 1 : -1});
                this.$refs.voyageComp.filterVoyages(this.carrier_ids);
                this.carriersNeedUpdate = true;
                this.getContracts();
            },
            addMasterRates(cId, cIndex, polId){
                var newArr = {
                    'id': 0,
                    'carrier_id': cId,
                    'port_of_loading_id': polId,
                    'buying_rate_calculation_unit_id': null,
                    'buying_rate_currency_id': window.defaultCurrency, 
                    'buying_rate': '',
                    'cname': this.safeFind(this.ports, 'id', polId, 'name') + ', ' + this.quotation.carriers[cIndex].name,
                    'name': this.quotation.carriers[cIndex].name,
                    'sales_rate_calculation_unit_id': null,
                    'sales_rate_currency_id': window.defaultCurrency, 
                    'sales_rate': '', 
                    'tariff_item_commodity_id': 0
                };
                this.masterRatesList.splice(cIndex, 0, this.hardCopy(newArr));
                this.quotation.quote_lines.forEach((item, itemIndex) => {
                    let thisNewArr = this.hardCopy(newArr);
                    if(this.masterRatesList.length > 1 && !item.surcharge_id){
                        thisNewArr.sales_rate = item.quote_line_carriers[0].sales_rate;
                        thisNewArr.sales_rate_calculation_unit_id = item.quote_line_carriers[0].sales_rate_calculation_unit_id;
                        thisNewArr.sales_rate_currency_id = item.quote_line_carriers[0].sales_rate_currency_id;
                    }
                    //Add rates to each commodity
                    thisNewArr.surcharge_id = item.surcharge_id;
                    item.quote_line_carriers.splice(cIndex, 0, thisNewArr);
                    if(!item.surcharge_id){
                        this.$refs.seatransportcomponent.setSurcharges(item, itemIndex);
                    }
                });
                this.masterRatesList.sort(function(a,b){ return (a.cname > b.cname) ? 1 : -1});
                this.$nextTick(() => this.$refs.seatransportcomponent.getAllTotals() );
                if(!this.canGoToStepThree){
                    this.canGoToStepThree = true;
                    this.setNextButtonDisabled();
                }
            },
            removeMasterRates(cId, polId){
                for(let i = this.masterRatesList.length - 1; i >= 0; i--){
                    if(this.masterRatesList[i].carrier_id == cId && this.masterRatesList[i].port_of_loading_id == polId){
                        this.masterRatesList.splice(i, 1);
                        this.quotation.quote_lines.forEach(item => item.quote_line_carriers.splice(i, 1) );
                    }
                }
            },
            calcCostSummary(setSurchargeVisible = null){
                if(!this.quotation.id) return;
                this.costSummaryHTML = null;
                quotationService.getSummary(this.quotation.id, setSurchargeVisible).then(response => {
                    this.costSummaryHTML = response.data;
                    document.getElementById("downloadshowpdfbutton").style.display = 'block';
                }).catch(error => { this.toastError(error); console.log('error', error) });
            },
            autoSave(calcCostAfter){
                if(this.quotation.status == 'accepted'){
                    if(calcCostAfter)
                        this.calcCostSummary();
                    return false;
                }
                if(this.isAutoSaving || (this.quotation.status != 'draft' && !calcCostAfter)) return false;
                if(this.quotation.ports_of_loading.length == 0) return false;
                if(this.quotation.status != 'draft' || calcCostAfter){
                    if(!this.validate(false)){
                        if(calcCostAfter)
                            this.costSummaryHTML = "<p>" + this.$t('errors.empty_fields') + "</p>";
                        console.log('no autosave - validation failed');
                        return;
                    }
                }
                else
                    this.postValidationSteps(true);

                this.isAutoSaving = true;
                
                if(!this.quotation.id){
                    quotationService.store(this.quotation).then(response => {
                        this.quotation.id = response.data.id;
                        this.quotation.version = response.data.version;
                        this.quotation.number = response.data.number;
                        this.quotation.quote_general_costs = this.tempGeneralCosts;
                        this.quotation.ports_of_loading.forEach((pol, index) => this.quotation.ports_of_loading[index].port = response.data.ports_of_loading[index].port);
                        this.quotation.ports_of_discharge[0].country = response.data.ports_of_discharge[0].country;
                        this.setIds(response.data);
                        this.$parent.$parent.getIndex();
                        console.log('autosave create');
                    }).catch(error => console.log('error', error)
                    ).finally(() =>  this.isAutoSaving = false);
                }
                else{
                    quotationService.update(this.quotation).then(response => {
                        this.quotation.quote_general_costs = this.tempGeneralCosts;
                        this.quotation.ports_of_loading.forEach((pol, index) => this.quotation.ports_of_loading[index].port = response.data.ports_of_loading[index].port);
                        this.quotation.ports_of_discharge[0].country = response.data.ports_of_discharge[0].country;
                        this.setIds(response.data);
                        console.log('autosave update');
                        if(calcCostAfter){
                            this.calcCostSummary();
                        }
                    }).catch(error => console.log('error', error) 
                    ).finally(() => this.isAutoSaving = false);
                }
                this.$parent.setAutoSaveDisabled();
            },
            setIds(data){
                for(let i in this.quotation.quote_lines){
                    if(typeof(data.quote_lines[i].id) == 'undefined') continue;
                    this.quotation.quote_lines[i].id = data.quote_lines[i].id;
                    for(let j in this.quotation.quote_lines[i].quote_line_carriers){
                        this.quotation.quote_lines[i].quote_line_carriers[j].id = data.quote_lines[i].quote_line_carriers[j].id;
                    }
                }
                for(let i in this.quotation.quote_transport_lines){
                    if(data.quote_transport_lines[i])
                        this.quotation.quote_transport_lines[i].id = data.quote_transport_lines[i].id;
                }
            },
            
            getId(item){
                return item.carrier_id ? item.carrier_id : item.id;
            },
            setForwarding(e){
                this.quotation.is_forward = Number(e.target.value);
                this.quotation.ports_of_loading.forEach((port) => {if(!port.name) port.name = port.port.name});
            },
            
            addCarrierToPol(polIndex, cIndex){
                const carrier = this.quotation.carriers[cIndex];
                let carrId = this.getId(carrier);
                //Remove
                for(let pol of this.quotation.ports_of_loading){
                    for(let thisCarrierId in pol.carriers){
                        if(pol.carriers[thisCarrierId] == carrId) {
                            pol.carriers.splice(thisCarrierId, 1);
                            break;
                        }
                    }
                }
                this.quotation.ports_of_loading.forEach(pol => {
                    if(!pol.carriers || pol.carriers[0] == carrId) pol.carriers = [];
                });
                if(polIndex == -1 || polIndex == null){
                    return;
                }
                //Add
                this.quotation.ports_of_loading[polIndex].carriers.push(carrId);
                //Get voyages
                this.carrierPols[carrId] = polIndex;
                this.$refs.voyageComp.filterVoyages(carrier);
                
                //Force update
                this.masterRatesList.push({});
                this.masterRatesList.pop();
            },
            setLicensePlateRequired(){
                this.isLicensePlateRequired = this.checkIfLicensePlateIsRequired(this.quotation.ports_of_loading, this.quotation.ports_of_discharge, this.ports);
            },
            disableStepFour(){
                this.$nextTick(() => {
                    let shouldDisable = this.quotation.quote_lines.length == 0;
                    for(let comm of this.quotation.quote_lines){
                        if(comm != undefined && comm.surcharge == undefined && !comm.is_surcharge){
                            if(!comm.quantity || !comm.commodity_id) {
                                shouldDisable = true;
                                break;
                            }
                        }
                    }
                    this.canGoToStepFour = !shouldDisable;
                    this.setNextButtonDisabled();
                });
            },
            disableFor(step){
                if(step == 2) return !this.canGoToStepTwo;
                if(step == 3) return !this.canGoToStepTwo || !this.canGoToStepThree;
                return !this.canGoToStepTwo || !this.canGoToStepThree || !this.canGoToStepFour;
            },
            getSurcharges(cId = null, isAutoUpdate = false){
                let carriers = [];
                const thisOfficeId = store.getters.getCompanyId;
                if(this.isEditing){
                    this.quotation.carriers.forEach(carr => {
                        carriers.push(carr.carrier_id ? carr.carrier_id : carr.id);
                    })
                }
                else{
                    carriers = this.carrier_ids;
                }
                let pols = this.quotation.ports_of_loading.map(port => (port.port_id ? port.port_id : port.id));
                let pod = this.quotation.ports_of_discharge[0].id;
                surchargeService.search(carriers, pols, pod, this.quotation.valid_till).then(response => {
                    this.surcharges = response.data;
                    this.surcharges.forEach(sc => sc.fromOtherOffice = sc.office_id != thisOfficeId);
                    this.$nextTick(() => {
                        if(cId != null) this.addCarrier(cId);
                        if(this.isEditing || isAutoUpdate) this.quotation.quote_lines.forEach((ql, qlIndex) => {
                            if(ql.surcharge_id) return;
                            let i = qlIndex + 1;
                            let max = this.quotation.quote_lines.length - 1;
                            const surchargeList = [];
                            while(i <= max){
                                if(!this.quotation.quote_lines[i].surcharge_id) break;
                                surchargeList.push(this.quotation.quote_lines[i]);
                                i++;
                            }
                            this.$refs.seatransportcomponent.setSurcharges(ql, qlIndex, surchargeList);
                        });
                    });
                }).catch(error => console.log('error', error) );
            },
            getTerminals(e, voyageInfo){
                let selectedPorts = this.hardCopy(this.quotation.ports_of_loading);
                let pod = this.hardCopy(this.quotation.ports_of_discharge[0]);
                pod.port_id = pod.id;
                selectedPorts.push(pod);
                if(!voyageInfo.ports){
                    return;
                }
                voyageInfo.ports.forEach(port => {
                    const index = selectedPorts.findIndex(selport => selport.port_id == port.id);
                    if(index >= 0){
                        if(e.target.checked)
                            this.terminals.push(port.pivot.terminal_id);
                        else
                            this.terminals.splice(index, 1);
                    }
                });
                this.terminalsNeedUpdate = true;
            },
            hasSelectedCommodity(commodityId){
                let result = false;
                if(this.quotation.quote_lines === undefined) return false;
                this.quotation.quote_lines.forEach(ql => {
                    if(ql.commodity_id == commodityId){
                        result = true;
                    }
                });
                return result;
            },
            isFirstOfCommodies(index){
                if(index == 0) return true;
                return this.quotation.quote_general_costs[index].general_cost_id != this.quotation.quote_general_costs[index - 1].general_cost_id;
            },
            generalCostFillQuantities(){
                let totalComms = 0;
                this.quotation.quote_lines.forEach(ql => {
                    const number = Number(ql.quantity);
                    if(!ql.surcharge_id) totalComms += (isNaN(number) ? 1 : number);
                });
                //Can't have zero
                if(totalComms == 0) totalComms = 1;
                this.quotation.quote_general_costs.forEach(qgc => {
                    let commsOfTypeTotal = 0;
                    if(qgc.commodity_dependent){
                        let commodity = this.quotation.quote_lines.find(ql => (ql && !ql.surcharge_id && ql.commodity_id == qgc.comm_id));
                        if(commodity !== undefined){
                            this.quotation.quote_lines.forEach(ql => {
                                const number = Number(ql.quantity);
                                if(!ql.surcharge_id && ql.commodity_id == qgc.comm_id) commsOfTypeTotal += (isNaN(number) ? 1 : number);
                            });
                            qgc.quantity = commsOfTypeTotal;
                        }
                    }
                    else{
                        qgc.quantity = totalComms;
                    }
                });
            },
            generalCostMultiFill(e, field, value){
                this.devalidate(e);
                this.quotation.quote_general_costs.forEach(qgc => {
                    if(qgc.general_cost_id == value) qgc[field] = e.target ? e.target.value : 0;
                })
            },
            hardCopy(obj){
                return JSON.parse(JSON.stringify(obj));
            },
            copyCustomerWebsiteLink() {
                let text = document.getElementById('customer_website').value;
                navigator.clipboard.writeText(text);
                this.$toast.info(this.$t('form.copied'));
            },
            setSeatranspportSelectedCurrencies(){
                this.$refs.seatransportcomponent.setSelectedCurrency();
            },
            autoFillTransport(e, type){
                const tLength = e.target.checked ? this.quotation.quote_transport_lines.filter(qtl => qtl.type == type).length : this.quotation.quote_transport_lines.length - 1;
                if(e.target.checked && tLength == 0)
                    this.addTransportLine(type);
                else if(!e.target.checked){
                    for(let i = tLength; i >= 0; i--){
                        if(this.quotation.quote_transport_lines[i].type == type){
                            this.quotation.quote_transport_lines.splice(i, 1);
                        }
                    }
                }
                if(type == 'pre') this.quotation.pre_transport = e.target.checked ? 1 : 0;
                else this.quotation.post_transport = e.target.checked ? 1 : 0;
            },
            addGeneralCost(){
                this.quotation.quote_general_costs.push({
                    "checked": true,
                    "show": true,
                    "quantity": 1,
                    "description": '',
                    "purchase_rate": '',
                    "sales_rate": '',
                    "purchase_rate_currency_id": window.defaultCurrency,
                    "sales_rate_currency_id": window.defaultCurrency,
                    "purchase_rate_calculation_unit_id": 1,
                    "sales_rate_calculation_unit_id": 1,
                    "commodity_dependent": false,
                    "managed_by_relations": false,
                    "general_cost_commodity_id": null,
                    "comm_id": null,
                    "comm_name": '',
                    "relation_general_cost_commodity_id": null,
                    "relation_type": 'none',
                    "relation_name": null,
                    "relation_id": null,
                    "general_cost_id": null
                })
            },
            deleteGeneralCost(index){
                this.quotation.quote_general_costs.splice(index, 1);
            },
            
            devalidate(e){
                if(e.target.value)
                    e.target.classList.remove('is-invalid');
            },

            dropzoneHover(e, color){
                if(e.target.hasAttribute('aria-hidden')){
                    e.target.style.color = color;
                    e.target.parentElement.style.borderColor = color;
                }
                else{
                    e.target.style.borderColor = color;
                    e.target.style.color = color;
                }
            },
            async uploadAttachment(e, isOnClick = false){
                e.preventDefault();
                if(!isOnClick) this.dropzoneHover(e, '#888');
                if (isOnClick || e.dataTransfer.items) {
                    let files = isOnClick ? e.target.files : e.dataTransfer.items;
                    this.$refs.filestab.dropzoneDrop(files, true, true);
                }
            },
            removeAttachment(list, index, name){
                if(confirm(this.$t("form.are_you_sure_delete") + ' ' + name)){
                    list.splice(index, 1)
                }
            },
            download(file){
                fileService.download(file.id).then(response => {
                    this.triggerDownload(response.data, file.name + '.' + file.type);
                }).catch(error => this.toastError(error) );
            },

            checkData(){
                this.dataItemsLoaded++;
                if(this.dataItemsLoaded >= this.dataItemsToLoad && !this.allDataLoaded){
                    if(this.isEditing)
                        this.setData(false);
                    else
                        this.reset();
                }
            },
            loadData(){
                if(this.fromJob){
                    this.dataItemsLoaded += 3;
                }
                else{
                    relationService.index(null,null,'customer').then(response => {
                        this.customers = response.data
                        this.checkData();
                    }).catch(error => console.log('error', error) );
                    portService.index().then(response => {
                        this.ports = response.data
                        this.checkData();
                    }).catch(error => console.log('error', error) );
                    currencyService.indexWithRates().then(response => {
                        this.currencies = response.data
                        window.defaultCurrency = this.currencies.filter(currency => currency.is_default == 1)[0].id;
                        this.checkData();
                    }).catch(error => console.log('error', error) );
                }
                relationService.index(null,null,'carrier').then(response => {
                    this.carriers = response.data
                    this.checkData();
                }).catch(error => console.log('error', error) );
                calculationunitService.index().then(response => {
                    this.calc_units = response.data.filter(unit => unit.id != 6);
                    this.calc_units_no_percentage = response.data.filter(unit => unit.surcharge_only == 0);
                    this.$refs.seatransportcomponent.setCalcUnits(this.calc_units);
                    this.checkData();
                }).catch(error => console.log('error', error) );
                this.getGeneralcosts('none', null);
                this.getAllTerminals();
                this.getCommodities();
            }

        },
        mounted() {
            this.costSummaryHTML = null;
        }
    }
</script>

<style scoped>
    .input-group select {background-color: #eee}
    .col-sm-0p5 {flex: 0 0 auto; width: 2.66%}
    .nav-link:not([disabled]) { color: black !important }
    .col-sm-2p4 {flex: 0 0 auto; width: 20%}
    .col-sm-2p5 {flex: 0 0 auto; width: 27%}
    .file-drop{
        border: 3px dashed #888;
        color: #888;
        text-align: center;
        width: 50%;
        padding: 1rem;
        border-radius: 0.5rem
    }
    .file-drop:hover{
        border-color: #0d6efd !important;
        color: #0d6efd !important;
        cursor: pointer
    }
</style>
<style>
    #customer label.col-sm-4, #carrier-normal label.col-sm-4, #freight label.col-sm-4, #rates label.col-sm-4, #addcost label.col-sm-4, #addcustomer-dialog label.col-sm-4 { width: 16.67% !important }
    #invoice-table td:not(.item){ white-space: nowrap }
    #myTabContent.disable-all input, #myTabContent.disable-all a:not(.feedback-link):not(.side-cts-button), #myTabContent.disable-all button, #myTabContent.disable-all select, #myTabContent.disable-all label, 
    #myTabContent.disable-all #terms, #myTabContent.disable-all #files, #myTabContent.disable-all #chistory{
        pointer-events: none;
        opacity: 0.6
    }
</style>