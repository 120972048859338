import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/user'

class UserService extends ApiService {
  constructor(){
      super()
  }
  getUrl(){
      return URL
  }
  indexFilteredByOffice(officeId) {
    let queryUrl = '';
    if(officeId){
      queryUrl = `?office_id=${officeId}`;
    }
    return http.get(`${URL}` + queryUrl)
  }
}

export default new UserService()