<template>
    <template v-if="!quotation.carriers || quotation.carriers.length == 0">
        <h5 class="mb-3 fw-bold">{{$t('sales.voyage_direct')}}</h5>
        <div>{{$t('form.no_selected', [$tc('navigation.carriers', 1)])}}</div>
    </template>
    <template v-else-if="searchingVoyages">
        <h5 class="mb-3 fw-bold">{{$t('sales.voyage_direct')}}</h5>
        <div class="loading-block"><i class="fa fa-spinner fa-spin fa-xl"></i></div>
    </template>
    <template v-else>
        <template v-if="quotation.ports_of_loading.length > 1">
            <div class="pb-2" v-for="carrier in quotation.carriers" :key="carrier.carrier_id">
                <div class="fw-bold"> {{ carrier.name }} </div>
                <div class="d-flex w-100 gap-5">
                    <FormItem v-for="pol in quotation.ports_of_loading" :key="pol.port_id" :id="'cx_'+pol.port_id+'_'+carrier.carrier_id" type="checkbox-with-value" :label="pol.name" 
                              v-model="pol.carriers" name="pol.carriers[]" :content="{id: carrier.carrier_id}" @onchange="filterVoyages(pol.port_id, carrier.carrier_id)" />
                </div>
            </div>
            <br />
        </template>
        <template v-for="(pol, polId) in quotation.ports_of_loading" :key="polId">
            <h5 class="mb-3 fw-bold">{{$t('sales.voyage_direct')}} - {{pol.name}} </h5>
            <template v-if="filteredVoyagesForwarding[pol.port_id] && filteredVoyagesForwarding[pol.port_id].length > 0">
                <div class="row fw-bold">
                    <div class="col-sm-auto">&emsp;</div>
                    <div class="col-sm-2"> {{ $t('navigation.vessels') }} </div>
                    <div class="col-sm-2"> {{ $t('navigation.voyages') }} </div>
                    <div class="col-sm-3 ps-0"> {{ $t('navigation.carriers') }} </div>
                    <div class="col-sm-1 ps-0"> ETD </div>
                    <div class="col-sm-2"> ETA </div>
                    <div class="col-sm-1"> {{ $t('overview.status') }} </div>
                </div>
                <label v-for="voyageInfo in filteredVoyagesForwarding[pol.port_id]" :key="voyageInfo.id_string" class="row hoverable border-top py-1" :for="voyageInfo.id_string">
                    <div class="col-sm-auto">
                        <FormItem :id="voyageInfo.id_string" v-model="$parent.quotation.quote_route_ids" type="checkbox-with-value" nameField="quoteVoyages[]" :content="voyageInfo.id_string" @onchange="(e) => getTerminals(e, voyageInfo)" />  
                    </div>
                    <div class="col-sm-2"> {{ voyageInfo.vessel }} </div>
                    <div class="col-sm-2"> {{ voyageInfo.number }} </div>
                    <div class="col-sm-3 ps-0"> {{ voyageInfo.carrier }} </div>
                    <div class="col-sm-1 ps-0"> {{ formatDate(voyageInfo.pol_date, "longDateAmerican") }} </div>
                    <div class="col-sm-2 pe-0"> {{ formatDate(voyageInfo.pod_date, "longDateAmerican") }} </div>
                    <div class="col-sm-1"> {{ $t('voyage_status.' + voyageInfo.voyage_status_id) }} </div>
                </label>
            </template>
            <div v-else>{{$t('form.no_available', [$tc('navigation.voyages', 2)])}}</div>
            <div class="d-flex justify-content-center my-4" v-if="!transshipmentsActive[pol.port_id]">
                <button class="btn btn-light w-50" type="button" @click="getVoyagesTransshipment(0, pol.port_id, true)">
                    <i class="fa fa-chevron-down"></i>&ensp;{{$t('sales.voyage_trans_see')}}
                </button>
            </div>
            <template v-else-if="searchingVoyagesTransshipment">
                <h5 class="my-3 fw-bold">{{$t('sales.voyage_trans')}}</h5>
                <div class="loading-block"><i class="fa fa-spinner fa-spin fa-xl"></i></div>
            </template>
            <div v-else class="mb-4">
                <div class="d-flex my-3" v-if="filteredVoyagesTransshipmentForwarding[pol.port_id].length != 0">
                    <h5 class="fw-bold d-block col-8">{{$t('sales.voyage_trans')}}</h5>
                    <div class="col-4">
                        <FormItem id="sortoptions" type="buttongroup" v-model="sortOptionTransshipmentForwarding[pol.port_id]" :options="sortOptions" sizeItem="12" @onchange="sortTransshipmentVoyages(pol.port_id)" />
                    </div>
                </div>

                <div class="row fw-bold" v-show="filteredVoyagesTransshipmentForwarding[pol.port_id].length != 0">
                    <div class="col-sm-auto">&emsp;</div>
                    <div class="col-sm-2"> </div>
                    <div class="col-sm-2"> ETD </div>
                    <div class="col-sm-2 ps-0"> Transshipment </div>
                    <div class="col-sm-2"> </div>
                    <div class="col-sm-2"> ETA </div>
                </div>
                <label v-for="voyageInfo in filteredVoyagesTransshipmentForwarding[pol.port_id]" :key="voyageInfo.id_string" :for="voyageInfo.id_string" class="row py-1 border-top">
                    <div class="col-sm-auto" style="padding-top: 10px">
                        <FormItem :id="voyageInfo.id_string" v-model="$parent.quotation.quote_route_ids" type="checkbox-with-value" nameField="quoteVoyages[]" :content="voyageInfo.id_string" @onchange="(e) => getTerminals(e, voyageInfo)" />  
                    </div>
                    <div class="col-sm-1 ps-5">
                        <img v-if="carrierLogos[voyageInfo.carrier_1]" :src="carrierLogos[voyageInfo.carrier_1]" height="42" width="42" draggable="false" />
                    </div>
                    <div class="col-sm-2"> 
                        {{ voyageInfo.vessel_1 + ' ' + voyageInfo.number_1 }} <br />
                        <span class="text-muted col-form-label-sm"> {{ voyageInfo.carrier_1 }} </span>
                    </div>
                    <div class="col-sm-1"> {{ formatDate(voyageInfo.pol_date, "longDateAmerican") }} </div>
                    <div class="col-sm-2 ps-0"> 
                        {{ voyageInfo.tport_name }} <br />
                        <span class="text-muted col-form-label-sm"> {{ voyageInfo.trans_time + ' ' + $tc('time.day', voyageInfo.trans_time).toLowerCase() }} </span>
                    </div>
                    <div class="col-sm-1 ps-5">
                        <img v-if="carrierLogos[voyageInfo.carrier_2]" :src="carrierLogos[voyageInfo.carrier_2]" height="42" width="42" draggable="false" />
                    </div>
                    <div class="col-sm-2"> 
                        {{ voyageInfo.vessel_2 + ' ' + voyageInfo.number_2 }}  <br />
                        <span class="text-muted col-form-label-sm"> {{ voyageInfo.carrier_2 }} </span>
                    </div>
                    <div class="col-sm-1 pe-0"> {{ formatDate(voyageInfo.pod_date, "longDateAmerican") }} </div>
                </label>
                <div v-show="filteredVoyagesTransshipmentForwarding[pol.port_id].length == 0">
                    <h5 class="mt-4 mb-3 fw-bold">{{$t('sales.voyage_trans')}}</h5>
                    <div>{{$t('form.no_available', [$tc('navigation.voyages', 2)])}}</div>
                </div>
                <div class="d-flex justify-content-center my-4">
                    <button class="btn btn-light w-50" type="button" @click="transshipmentsActive[pol.port_id] = false">
                        <i class="fa fa-chevron-up"></i>&ensp;{{$t('sales.voyage_trans_hide')}}
                    </button>
                </div>
            </div>
        </template>
    </template>

</template>

<script>
    import voyageService from "@/services/VoyageService";
    export default {
        props: ['quotation', 'pols', 'carrier_ids', 'qvoyages'],
        name: 'QuotationVoyage',
        data() {
            return {
                voyages: [],
                voyagesTransshipment: [],
                searchingVoyages: false,
                searchingVoyagesTransshipment: false,
                checkForTransshipments: false,
                searchingVoyageId: null,
                voyageCount: 0,
                sortOptionTransshipment: 1,
                sortOptionTransshipmentForwarding: {},
                sortOptions: [{id: 1, name: "Pol date"}, {id: 2, name: "Transit time"}, {id: 3, name: "Transshipment days"}],
                
                filteredVoyagesTransshipment: null,
                filteredVoyages: [],
                filteredVoyagesForwarding: {},
                filteredVoyagesTransshipmentForwarding: [],
                transshipmentsActive: {},
                carrierLogos: {}
            }
           
        },
        computed: {
            quoteVoyages: {
                get() {
                    return this.qvoyages
                }
            },
            carrierPols: {
                get() {
                    return this.pols
                }
            },
        },
        methods: {
            reset(){
                this.voyages = [];
                this.voyagesTransshipment = [];
                this.searchingVoyages = false;
                this.searchingVoyagesTransshipment = false;
                this.searchingVoyageId = null;
                this.voyageCount = 0;
                this.sortOptionTransshipment = 1;
                this.sortOptionTransshipmentForwarding = {};
                this.filteredVoyagesTransshipment = null;
                this.filteredVoyages = [];
                this.filteredVoyagesForwarding = {};
                this.filteredVoyagesTransshipmentForwarding = {};
                this.transshipmentsActive = {};
            },
            getId(item){
                return item.carrier_id ? item.carrier_id : item.id;
            },
            sortTransshipmentVoyages(cIndex = null){
                this.filteredVoyagesTransshipmentForwarding[cIndex].sort((a, b) => this.sortTransshipments(this.sortOptionTransshipmentForwarding[cIndex], a, b));
            },
            getfromCarrier(item, field){
                return item.relation != undefined && item.relation[field] != undefined ? item.relation[field] : item[field];
            },
            addCarrierToPol(a, cIndex){
                this.transshipmentsActive[cIndex] = false;
                this.$parent.addCarrierToPol(a, cIndex);
            },
            hasVoyages(arr){
                if(arr == undefined || !arr || arr == null || typeof arr[Symbol.iterator] !== 'function') return false;
                let returnValue = false;
                for(let item of arr){
                    if(this.isInCarriers(item)){
                        returnValue = true;
                        break;
                    }
                }
                if(returnValue) return true;
                return returnValue;
            },
            isInCarriers(item){
                let found = false;
                try{
                    const id = item.carrier_id ? item.carrier_id : item.id;
                    for(let carrier of this.$parent.quotation.carriers){
                        const id2 = this.getId(carrier);
                        if(id == id2){
                            found = true;
                            break;
                        }
                    }
                }
                catch(e){
                    found = false;
                }
                return found;
            },
            getVoyagesTransshipment(carrier, cIndex, forwarding, steps = 2){
                this.transshipmentsActive[cIndex] = true;
                if(!this.filteredVoyagesTransshipmentForwarding[cIndex]){
                    this.filteredVoyagesTransshipmentForwarding[cIndex] = [];
                    this.sortOptionTransshipmentForwarding[cIndex] = 1;
                    this.getVoyages(cIndex, carrier, forwarding, true, steps);
                }
            },
            getTerminals(e, data){
                if(e.target.checked){
                    this.$parent.quotation.quote_routes.push(data.id_object);
                } else {
                    const qrIndex = this.$parent.quotation.quote_routes.findIndex(qr => Number(qr.voyage_id) == data.id_object.voyage_id && qr.origin_port_id == data.id_object.origin_port_id);
                    this.$parent.quotation.quote_routes.splice(qrIndex, 1);
                }
                this.$parent.getTerminals(e, data);
            },
            hardCopy(obj){
                return JSON.parse(JSON.stringify(obj));
            },

            getVoyages(optionalId, optionalCarrier, setCarrier = true, transshipment = false, steps = 2) {
                if(this.searchingVoyages) return;
                if(this.$parent.checkTabOne()) {
                    this.$parent.canGoToStepTwo = false;
                    this.$parent.setNextButtonDisabled();
                    return;
                }
                let carrier = optionalCarrier === undefined ? 0 : optionalCarrier;
                let ports_of_loading = this.quotation.ports_of_loading.map(port => (port.port_id || port.id));
                let port_of_discharge_id = this.quotation.ports_of_discharge[0].id;
                carrier = this.quotation.carriers && this.quotation.carriers.length  == 0 ? null : this.quotation.carriers[carrier];

                //if(this.carrierLogos === {}){
                    this.$parent.carriers.forEach(carr => this.carrierLogos[carr.name] = carr.logo);
                //}
                
                if(optionalCarrier !== undefined){
                    this.searchingVoyageId = optionalCarrier;
                }
                if(transshipment){
                    this.searchingVoyagesTransshipment = true;
                    this.filteredVoyagesTransshipment = [];
                    voyageService.searchVoyagesTransshipment({"port_of_discharge_id": port_of_discharge_id, "port_of_loading_ids": ports_of_loading, "segments": steps}).then(response => {
                        console.log(response.data);
                        this.voyagesTransshipment = [];
                        response.data.voyages.forEach(v => {
                            v.routes.forEach(r => {
                                r.port_of_loading_id = v.port_of_loading_id;
                                r.port_of_discharge_id = v.port_of_discharge_id;
                                this.voyagesTransshipment.push(r);
                            })
                        })
                        this.filterVoyagesTransshipment(steps);
                    }).catch(error => console.log('error', error)
                    ).finally(e => {
                        this.$nextTick(() => {
                            this.searchingVoyagesTransshipment = false;
                            this.searchingVoyageId = null;
                        });
                    })
                }
                else{
                    this.voyageCount = 0;
                    this.searchingVoyages = true;
                    this.filteredVoyagesTransshipment = null;
                    voyageService.searchVoyages({"port_of_discharge_id": port_of_discharge_id, "port_of_loading_ids": ports_of_loading}).then(response => {
                        console.log(response.data);
                        this.voyages = [];
                        response.data.voyages.forEach(v => {
                            v.routes.forEach(r => {
                                r.port_of_loading_id = v.port_of_loading_id;
                                r.port_of_discharge_id = v.port_of_discharge_id;
                                this.voyages.push(r);
                            })
                        })
                        this.filterVoyages();
                    }).catch(error => console.log('error', error)
                    ).finally(e => {
                        this.$nextTick(() => {
                            this.searchingVoyages = false;
                            this.searchingVoyageId = null;
                        });
                    })
                }
                
            },

            getVoyagesMultiple(portsAndCarriers) {
                this.getVoyages(0, 0, false);
            },

            filterVoyages(pol = null, carrier = null) {
                if(pol && carrier){
                    if(this.$parent.masterRatesList.find(item => item.carrier_id == carrier && item.port_of_loading_id == pol)){
                        this.$parent.removeMasterRates(carrier, pol);
                    }
                    else{
                        this.$parent.addMasterRates(carrier, this.$parent.quotation.carriers.findIndex(c => c.id == carrier), pol);
                    }
                }
                if(!this.quotation.carriers.length || !this.quotation.ports_of_loading[0] || !this.quotation.ports_of_discharge[0]) return;
                if(this.$parent.quotation.quote_route_ids === undefined){
                    this.$parent.quotation.quote_route_ids = [];
                }
                let voyages = this.$parent.hardCopy(this.voyages);
                let pod = this.quotation.ports_of_discharge[0];
                this.voyageCount = 0;
                this.quotation.ports_of_loading.forEach(pol => {
                    this.filteredVoyagesForwarding[pol.port_id] = [];
                    let filteredVoyages = voyages.filter(row => row.port_of_loading_id == pol.port_id && pol.carriers.find(c => c.id == row.segments[0].carrier_id));
                    this.voyageCount += filteredVoyages.length;
                    for(let i = 0; i < filteredVoyages.length; i++){
                        if(filteredVoyages[i].segments.length == 1){
                            let data = filteredVoyages[i].segments[0];
                            data.id_string = data.id + '_' + data.pol_id + '_' + data.pod_id;
                            data.id_object = {
                                "origin_port_id": this.safeFind(this.$parent.ports, 'code', data.pol_code, 'id'),
                                "voyage_id": data.id,
                                "port_of_loading_id": data.pol_id,
                                "port_of_discharge_id": data.pod_id,
                                "quote_route_segments": [{
                                    "voyage_id": data.id,
                                    "port_of_loading_id": data.pol_id,
                                    "port_of_discharge_id": data.pod_id,
                                    "carrier_id": data.carrier_id,
                                    "name": data.number
                                }]
                            };
                            this.filteredVoyagesForwarding[pol.port_id].push(data);
                        }
                    }
                });
                if(Object.keys(this.filteredVoyagesTransshipmentForwarding).length > 0){
                    this.filterVoyagesTransshipment(2);
                }
                if(this.checkForTransshipments){
                    this.checkForTransshipments = false;
                    this.searchingVoyages = false;
                    this.quotation.ports_of_loading.forEach(p => {
                        this.transshipmentsActive[p.port_id] = true;
                        this.filteredVoyagesTransshipmentForwarding[p.port_id] = [];
                        this.sortOptionTransshipmentForwarding[p.port_id] = 1;
                    });
                    this.getVoyages(0, 0, 0, true, 2);
                }
            },

            filterVoyagesTransshipment(steps) {
                let carriersById = {};
                this.quotation.carriers.forEach(carr => {
                    carriersById[carr.carrier_id] = carr;
                })
                let voyages = this.$parent.hardCopy(this.voyagesTransshipment);
                let pod = this.quotation.ports_of_discharge[0];
                this.voyageCount = 0;
                this.quotation.ports_of_loading.forEach(pol => {
                    this.filteredVoyagesTransshipmentForwarding[pol.port_id] = [];
                    let filteredVoyages = voyages.filter(row => row.port_of_loading_id == pol.port_id && (this.quotation.ports_of_loading.length == 1 ? 
                                                                (carriersById[row.segments[0].carrier_id] && carriersById[row.segments[1].carrier_id]) : 
                                                                (carriersById[row.segments[0].carrier_id] && carriersById[row.segments[0].carrier_id][pol.port_id] && 
                                                                 carriersById[row.segments[1].carrier_id] && carriersById[row.segments[1].carrier_id][pol.port_id])));
                    this.voyageCount += filteredVoyages.length;
                    for(let i = 0; i < filteredVoyages.length; i++){
                        if(filteredVoyages[i].segments.length > 1){
                            let data = {};
                            let data1 = filteredVoyages[i].segments[0];
                            let data2 = filteredVoyages[i].segments[1];
                            data.id_string = (data1.id + '_' + data1.portvoyage_pol_id + '_' + data1.portvoyage_pod_id) + '-' + (data2.id + '_' + data2.portvoyage_pol_id + '_' +data2.portvoyage_pod_id);
                            data.id_object = {
                                "origin_port_id": data.pol_id,
                                "voyage_id": data1.id,
                                "port_of_loading_id": data1.portvoyage_pol_id,
                                "port_of_discharge_id": data2.portvoyage_pod_id,
                                "quote_route_segments": [{
                                    "voyage_id": data1.id,
                                    "port_of_loading_id": data1.portvoyage_pol_id,
                                    "port_of_discharge_id": data1.portvoyage_pod_id,
                                    "carrier_id": data1.carrier_id,
                                    "name": data1.number
                                },{
                                    "voyage_id": data2.id,
                                    "port_of_loading_id": data2.portvoyage_pol_id,
                                    "port_of_discharge_id": data2.portvoyage_pod_id,
                                    "carrier_id": data2.carrier_id,
                                    "name": data2.number
                                }]
                            };
                            data.pol_name = data1.pol_name,
                            data.tport_name = data1.pod_code,
                            data.pod_name = data2.pod_name,
                            data.carrier_1 = data1.carrier,
                            data.carrier_2 = data2.carrier,
                            data.number_1 = data1.number,
                            data.number_2 = data2.number,
                            data.vessel_1 = data1.vessel,
                            data.vessel_2 = data2.vessel,
                            data.pol_date = data1.pol_date,
                            data.pod_date = data2.pod_date;
                            data.trans_time = Math.round(Math.abs(new Date(data2.pol_date) - new Date(data1.pod_date)) / (24 * 60 * 60 * 1000));
                            data.transit_time = Math.abs(new Date(data2.pod_date) - new Date(data1.pol_date));
                            this.filteredVoyagesTransshipmentForwarding[pol.port_id].push(data);
                        }
                    }
                });
            },

            findDateForPort(ports, carrierIndex, dateType, setShortdate = true, pol = null){
                try{
                    let portId = dateType == 'date_arrival' ? 
                        (this.quotation.ports_of_discharge[0].pivot ? this.quotation.ports_of_discharge[0].pivot.port_id : this.quotation.ports_of_discharge[0].id)
                        : (pol ? pol : this.quotation.ports_of_loading[carrierIndex].port_id);
                    let port = ports.find(port => port.id == portId);
                    const date = port.pivot[dateType] ? port.pivot[dateType] : port.pivot['date_arrival'];
                    if(setShortdate)
                        return port ? this.formatDate(date, "shortDate") : "";
                    return port ? {date: date, code: port.code, id: portId} : {date: '', code: ''};
                }
                catch(e){
                    return "";
                }
            },

            filterCarriers(){
                //Filter carriers and weed out those that are not part of any selected voyage
                if(!this.quoteVoyages || Object.keys(this.filteredVoyagesTransshipmentForwarding).length > 0) return;
                const carriers = this.$parent.quotation.carriers;
                const voyageMap = this.quoteVoyages.map(qv => Number(qv.id || qv.voyage_id));
                const selectedVoyages = this.voyages.filter(v => voyageMap.includes(v.segments[0].id)).map(v2 => v2.segments[0].carrier_id);
                if(selectedVoyages.length == 0) return;

                const carrlength = carriers.length - 1;
                for(let i = carrlength; i >= 0; i--){
                    const carr = carriers[i];
                    const cId = carr.carrier_id ? carr.carrier_id : carr.id;
                    if(!selectedVoyages.includes(cId)){
                        console.log('deletus ' + carr.name);
                        //this.$parent.deleteCarrier(i, [], {id: cId}, false);
                        //this.$parent.quotation.carriers.splice(i, 1);
                    }
                }
            }
        }
    }
</script>
<style scoped>
    .closed-badge{
        width: 12%;
        padding: 0 1rem
    }
    .closed-badge > div{
        background-color: rgba(var(--bs-danger-rgb)) !important;
        color: white
    }
    .loading-block{
      width: 100%;
      height: 10rem;
      background-color: #eee;
      display: flex;
      justify-content: center;
      align-items: center
    }
</style>