import {ApiService} from '@/services/ApiService'

const URL = '/api/commodity'
import http from '@/http-common'

class CommodityService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    getWithPreferredGroupings(quotation){
        const polsAsArray = [];
        quotation.ports_of_loading.forEach(p => p.carriers.forEach(c => polsAsArray.push([p.port_id ? p.port_id : p.id, c.id])));
        let carriersJSON = (quotation.ports_of_discharge[0] && polsAsArray.length > 0) ? JSON.stringify(polsAsArray) : 'null';
        if(carriersJSON != 'null') carriersJSON += `&pod=${quotation.ports_of_discharge[0].id}&date=${quotation.valid_till}`;
        return http.get(`${this.getUrl()}?preferred_groupings=${carriersJSON}`);
    }
}

export default new CommodityService()