<template>
    
    <DataTable ref="dt" 
        :value="items.data" dataKey="id" 
        :totalRecords="items.total"
        :sortField="sort" :sortOrder="sortOrder"
        :paginator="true" :rows="rows" :alwaysShowPaginator="false" :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}" responsiveLayout="scroll"
        :lazy="true" :loading="loading" @page="onPage($event)" @sort="onSort($event)" @filter="onFilter($event)" @row-click="(d) => openOnClickOverlay(d.originalEvent, d.data)"
        v-model:expandedRows="expandedRows"
    >
        
        <template #header v-if="showHeader">
            <div class='row'>
                <div class='col-sm-9'>
                    <h1 class="pb-3" v-show="showName">{{moduleNamePlural}}</h1>
                </div>
                <div class='col-sm-3 text-end' style="min-height: 45pt">
                    <button class="btn btn-success mt-2" data-bs-toggle="modal" data-bs-target="#formModal" @click="addItem()" id='btnNew' v-show="showAddButton"><i class="fal fa-plus"></i>&ensp;{{$t('sales.new_x', [moduleName.toLowerCase()])}}</button>
                </div>
            </div>

            <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#formModal" id="deeplinkModalButton"></button>
            
            <div class="d-flex" v-if="searchBefore">
                <div :class="'form-group search ' + (filterOptions ? 'col-10' : 'w-100')">
                    <i class="fa fa-search" aria-hidden="true"></i>
                    <input type="text" class="form-control" id="search" :placeholder="$t('overview.search')" @change="onFilter()" @input="onInput()" v-model="filters['global'].value" autocomplete="off">
                </div>
                <div class="col-2 ps-4 pb-4" v-show="filterOptions">
                    <button v-if="filterOptions" type="button" class="btn btn-secondary btn-sec-overview w-100 h-100" data-bs-toggle="modal" data-bs-target="#filterModal" @click="$refs.overviewfilters.onOpen" :disabled="loading">
                        <i class="fa" :class="{'fa-bars-filter': (!loading || !filtersActive), 'fa-spinner fa-spin': loading && filtersActive}"></i>&emsp;{{$t('form.filter') + ' (' + filtersActive + ')'}}
                    </button>
                </div>
            </div>

            <slot name="extrasearchoptions"></slot>

            <div class="d-flex mt-3" v-if="!searchBefore">
                <div class="form-group search w-100">
                    <i class="fa fa-search search-after" aria-hidden="true"></i>
                    <input type="text" class="form-control" id="search" :placeholder="$t('overview.search')" @change="onFilter()" @input="onInput()" v-model="filters['global'].value" autocomplete="off">
                </div>
            </div>
            
        </template>

        <slot name="columns"></slot>
        <slot name="actionscolumn">
            <Column v-if="showAction" header="" :exportable="false" :sortable="false" style="width:50px; text-align:center">
                <template #body="slotProps">

                    <div class="d-none edit-button" @click="editItem(slotProps.data)" @blur="unselect" data-bs-toggle="modal" data-bs-target="#formModal"></div>
                    &nbsp;
                    <div :class="showDropdown ? 'dropdown d-inline' : 'd-none'">
                        <button type="button" id="dropdownMenuButton1" class="btn btn-secondary btn-sec-overview btn-sm" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa-light fa-ellipsis"></i></button>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <a class="dropdown-item" v-if="downloadFileType && (!slotProps.data.status || slotProps.data.status != 'draft')" @click="downloadPDF(slotProps.data)">
                                    <i class="fal fa-download"></i> Download {{fileTypes[downloadFileType]}}
                                </a>
                            </li>
                            <li v-if="showCopy">
                                <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#formModal" @click="copyItem(slotProps.data)">
                                    <i class="fal fa-copy"></i> Copy
                                </a>
                            </li>
                            <template v-if="extraOptions">
                                <li v-for="extraOption, index in extraOptions" :key="index" v-show="(extraOption.show === undefined || extraOption.show(slotProps))">
                                    <a class="dropdown-item" @click="extraOption.function(slotProps)">
                                        <i :class="'fal fa-' + extraOption.icon"></i> {{ extraOption.name }}
                                    </a>
                                </li>
                            </template>
                            <li v-if="checkIfCanShowDelete == null || checkIfCanShowDelete(slotProps.data)">
                                <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#deleteModal" @click="delItem(slotProps.data)">
                                    <i class="fal fa-trash-alt"></i> Delete
                                </a>
                            </li>
                        </ul>
                        
                    </div>
                    <div :class="!showDropdown && downloadFileType && (!slotProps.data.status || slotProps.data.status != 'draft') ? 'dropdown d-inline' : 'd-none'">
                        <button type="button" id="dropdownMenuButtonAlt" class="btn btn-secondary btn-sec-overview btn-sm" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa-light fa-ellipsis"></i></button>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButtonAlt">
                            <li>
                                <a class="dropdown-item" v-if="downloadFileType" @click="downloadPDF(slotProps.data)">
                                    <i class="fal fa-download"></i> Download {{fileTypes[downloadFileType]}}
                                </a>
                            </li>
                        </ul>
                    </div>
                    
                </template>
            </Column>
        </slot>
        <template #expansion="slotProps"><slot name="expandedrows" v-bind="slotProps"></slot></template>
        
    </DataTable>

    <FormPopup v-if="showAction && !jobForm" v-on:save="save" v-on:del="del" :itemId="item ? item.id : null" :itemName="item && deleteText ? deleteText(item) : (item ? getItemName(item) : null)" :moduleName="moduleName"
              :blueHeader="blueHeader" :modalSize="modalSize" :modalHeight="modalHeight" :modalWidth="modalWidth" :isNew="isNew" :isCopy="isCopying" :itemStatus="item ? item.status : null" :hideFooter="hideFooter" :loading="itemLoading">
        <slot name='form'></slot>
        <template #modal-footer><slot name="modal-footer"></slot></template>
    </FormPopup>
    <template v-else-if="jobForm">
        <JobForm ref="jobform" v-on:save="save" v-on:del="del" :itemId="item ? item.id : null" :itemName="getItemName(item)" :isCopy="isCopying" :loading="itemLoading" />
        <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{$t('form.delete') + ' job'}}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        {{$t('form.are_you_sure_delete')}} <b>{{getItemName(item)}}</b>?
                    </div>
                    <div class="modal-footer d-flex">
                        <button type="button" class="btn btn-dark" data-bs-dismiss="modal">{{$t('form.no')}}</button>
                        <button type="button" class="btn btn-danger" @click.prevent="del">{{$t('form.yes')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </template>

    <!-- For filtering -->
    <Filters :filterOptions="filterOptions" ref="overviewfilters" />
    
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import { ObjectUtils } from 'primevue/utils';
import DataTable from '@/components/datatable/DataTable.vue';
import Filters from '@/components/OverviewFilter.vue';
import Column from '@/components/column';
import FormPopup from '@/components/FormPopup.vue';
import JobForm from '@/components/jobs/JobFormNew.vue';
import moment from 'moment';

export default {
    name: 'Overview',
    props: {
        'moduleNamePlural': {},
        'moduleName': {},
        'sort': {},
        'sortOrder': { type: Number, default: 1},
        'itemNameField': {},
        'itemExtraNameField': {},
        'itemService': {},
        'itemFormRef': {},
        'modalSize': {},
        'modalHeight': {},
        'modalWidth': {},
        'extraParam': null,
        'blueHeader': { type: Boolean, default: false},
        'indexOnMount': { type: Boolean, default: true},
        'downloadFileType': { type: String, default: ''},
        'showCopy': { type: Boolean, default: false},
        'showAction': { type: Boolean, default: true},
        'showDropdown': { type: Boolean, default: true},
        'showHeader': { type: Boolean, default: true},
        'showName': { type: Boolean, default: true},
        'showAddButton': { type: Boolean, default: true},
        'searchBefore': { type: Boolean, default: true},
        'selectFields': { type: Array },
        'extraOptions': { type: Array },
        'filterOptions': { type: Array },
        'autoSaveEnabled': { type: Boolean, default: false },
        'autoFocusInput': { type: Boolean, default: true },
        'hideFooter': { type: Boolean, default: false},
        'jobForm': { type: Boolean, default: false},
        'checkIfCanShowDelete': null,
        'deleteText': null
    },
    components: {
        DataTable,
        Column,
        FormPopup,
        Filters,
        JobForm
    },
    emits: ['refreshList'],
    data() {
        return {
            items: {
                'data': [],
                'total': 0
            },
            loading: true,
            lazyParams: {},
            page: 1,
            filters: {},
            extraParams: false,
            extraParamOnMount: true,
            filtersActive: 0,
            filterData: [],
            isSaving: false,
            selectedRow: null,
            rows: 18,
            
            item: {},
            itemLoading: false,
            isCopying: false,
            isNew: false,
            expandedRows: null,
            fileTypes: {'pdf': "PDF", 'xlsx': "Excel"}
        }
    },
    methods: {
        onPage(event) {
            this.page = event.page + 1
            this.getIndex();
        },
        onSort(event) {
            this.page = 1;
            this.lazyParams = event;
            this.lazyParams.route = this.$route.name
            if(this.selectFields){
                this.lazyParams.selectFields = this.selectFields
            }
            this.getIndex();
        },
        onFilter() {
            if(['Manifest Status', 'Status & Print'].includes(this.$route.name)){
                window.documentationSearch = this.filters['global'].value;
            }
            this.lazyParams.filters = this.filters;
            this.getIndex();
        },
        setExtraParams(param) {
            this.extraParams = param;
            this.lazyParams.filters = this.filters;
            this.getIndex();
        },
        onInput() {
            if(this.filters['global'].value == '') {
                this.onFilter();
            }
        },
        initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },
        getIndex(autoOpenModal = false) {
          window.scrollTo(0, 0);
          let shouldOpenModal = autoOpenModal && this.showAction;
          //this.lazyParams.rows = this.rows;
          if(this.filterData.length > 0){
            this.getIndexFiltered(this.filterData, shouldOpenModal);
            return;
          }
          const savedFilters = window.savedFilterData[this.$route.name];
          if(savedFilters){
            this.$refs.overviewfilters.filterData = savedFilters;
            this.$refs.overviewfilters.save(true);
            return;
          }
          this.loading = true
          this.item = {}
          let extraParameters = null;
          if(this.extraParam && this.extraParamOnMount){
            extraParameters = this.extraParam;
          }
          if(this.extraParams){
            extraParameters = this.extraParams;
          }
          else{
            this.extraParamOnMount = false;
          }
          if(this.lazyParams.filters && this.lazyParams.filters.global.value){
            //Format for number search
            if(this.lazyParams.filters.global.value[0].match(/[0-9]/)){
                let result = '';
                let commaPeriodOccurences = (this.lazyParams.filters.global.value.match(/,|\./g) || []).length;
                for(let character of this.lazyParams.filters.global.value){
                    if(character == ',' || character == '.'){
                        commaPeriodOccurences--;
                        if(commaPeriodOccurences == 0){
                            result += ',';
                        }
                    }
                    else{
                        result += character;
                    }
                }
                this.lazyParams.filters.global.value = result;
            }
          }
          if(this.lazyParams.filters.global.value){
            this.lazyParams.filters.global.value = this.lazyParams.filters.global.value.trim();
          }
          this.itemService.index(this.page, {
            lazyEvent: JSON.stringify(this.lazyParams)
          }, extraParameters).then(response => {
            this.$nextTick(() => this.$emit('refreshList'));
            this.items = response.data;
            this.loading = false;
            //Deep-link to automatically open modal
            if(window.location.hash && shouldOpenModal){
                const id = window.location.hash.substring(1);
                document.getElementById('deeplinkModalButton').click();
                this.editItem({'id': id});
            }
          }).catch(error => {
            console.log('error', error)
          })
        },
        getIndexFiltered(filterData, autoOpenModal = false) {
          this.loading = true;
          this.item = {};
          let extraParameters = null;
          if(this.extraParam && this.extraParamOnMount){
            extraParameters = this.extraParam;
          }
          if(this.extraParams){
            extraParameters = this.extraParams;
          }
          else{
            this.extraParamOnMount = false;
          }
          let lazyParams = JSON.parse(JSON.stringify(this.lazyParams));
          lazyParams.filterData = filterData;
          //lazyParams.rows = this.rows;
          this.filterData = filterData;
          this.itemService.index(this.page, { lazyEvent: JSON.stringify(lazyParams)}, extraParameters ).then(response => {
            this.$nextTick(() => this.$emit('refreshList'));
            this.items = response.data;
            this.loading = false;
            //Deep-link to automatically open modal
            if(window.location.hash && autoOpenModal){
                const id = window.location.hash.substring(1);
                document.getElementById('deeplinkModalButton').click();
                this.editItem({'id': id});
            }
          }).catch(error => {
            console.log('error', error)
          })
        },
        getItemName(item){
            if(this.itemExtraNameField && this.itemNameField){
                return ObjectUtils.resolveFieldData(item, this.itemNameField) + ObjectUtils.resolveFieldData(item, this.itemExtraNameField);
            }
            else if(this.itemNameField){
                return ObjectUtils.resolveFieldData(item, this.itemNameField);
            }
            return item[this.sort];
        },
        addItem() {
            let itemFormRef = this.itemFormRef || this.$refs.jobform;
            itemFormRef.active(true);
            itemFormRef.reset(false);
            this.isNew = true;
            this.$nextTick(() => {
                this.itemLoading = false;
                if(this.moduleName != this.$t('navigation.jobs')){
                    this.item = {};
                }
                this.isCopying = false;
                if(this.autoFocusInput){
                    this.focusFirstInput();
                }
            });
        },
        copyItem(item) {
            this.itemLoading = true;
            this.itemFormRef.active(true);
            this.isNew = true;
            this.$nextTick(() => {
                this.itemFormRef.reset(false, true);

                this.itemService.show(item.id).then(response => {
                    this.itemLoading = false;
                    this.item = this.itemFormRef.getData();
                    this.item.isCopy = true;
                    let data = response.data;
                    data.file_info = [];
                    if(data.start_date && data.end_date){
                        const newStartDate = moment().quarter(moment().quarter() + 1).startOf('quarter').format('YYYY-MM-DD');
                        if(data.start_date != newStartDate){
                            data.start_date = newStartDate
                            data.end_date = moment().quarter(moment().quarter() + 1).endOf('quarter').format('YYYY-MM-DD');
                        }
                        else{
                            data.start_date = moment(data.start_date).quarter(moment(data.start_date).quarter() + 1).startOf('quarter').format('YYYY-MM-DD');
                            data.end_date = moment(data.end_date).quarter(moment(data.end_date).quarter() + 1).endOf('quarter').format('YYYY-MM-DD');
                        }
                    }
                    if(data.invoice_status_id || data.is_finalized){
                        data.invoice_status_id = 1;
                        data.is_finalized = 0;
                        data.is_mailed = 0;
                        data.is_exported = 0;
                        data.export_status = null;
                        data.date = moment().format('YYYY-MM-DD');
                    }
                    if(data.file_info !== undefined){
                        data.file_info = [];
                    }
                    delete data.finalized;
                    delete data.id;
                    data.number = '';
                    this.itemFormRef.setData(data);
                    this.isCopying = true;
                    console.log('copy');
                }).catch(error => {
                    this.toastError(error)
                    console.log('error', error) // create notification-toaster for user error
                })
            });
        },
        editItem(item) {
            this.itemLoading = true;
            let itemFormRef = this.itemFormRef || this.$refs.jobform;
            itemFormRef.active(true);
            this.isNew = false;
            this.$nextTick(() => {
                itemFormRef.reset(true);
                this.isCopying = false;
                this.itemService.show(item.id).then(response => {
                    if(this.moduleName != this.$t('navigation.jobs')){
                        this.item = response.data
                    }
                    itemFormRef.setData(response.data);
                    this.itemLoading = false;
                    window.location.hash = item.id;
                }).catch(error => {
                    this.toastError(error)
                    console.log('error', error) // create notification-toaster for user error
                })
            });
        },
        delItem(item) {
            this.item = {...item};
        },
        downloadPDF(item) {
            this.item = {...item};
            this.itemService.downloadPDF(this.item.id).then(response => {
                this.triggerDownload(response.data, `${this.item.number}.${this.downloadFileType}`);
            }).catch(error => {
                this.toastError(error)
            })

        },
        save(e) {
            if(this.isSaving) return false;
            let itemFormRef = this.itemFormRef || this.$refs.jobform;
            let validate = itemFormRef.validate();
            if(!validate) return false;
            let item = itemFormRef.getData();
            window.location.hash = '';
            this.setSaveButton(e, true);
            if((this.item && this.item.id) || (this.autoSaveEnabled && item.id)) {
                this.itemService.update(item).then(() => {
                    //this.itemFormRef.reset();
                    this.getIndex();
                    this.setSaveButton(e, false);
                    document.querySelector("#modal-close-button").click();
                }).catch(error => {
                    this.setSaveButton(e, false, true);
                    this.toastError(error)
                    console.log('error', error) // create notification-toaster for user error
                })
            } else {
                this.itemService.store(item).then(() => {
                    //this.itemFormRef.reset();
                    this.setSaveButton(e, false);
                    this.getIndex();
                    document.querySelector("#modal-close-button").click();
                }).catch(error => {
                    this.setSaveButton(e, false, true);
                    this.toastError(error)
                    console.log('error', error) // create notification-toaster for user error
                })
            }
        },
        del() {
            this.itemService.delete(this.item.id, this.item).then(() => {
                this.getIndex();
                document.querySelector("#deleteModal .btn-close").click();
            }).catch(error => {
                this.toastError(error)
                console.log('error', error) // create notification-toaster for user error
            })
            
        },
        setSaveButton(e, active, isError = false){
            this.isSaving = active;
            if(typeof(e) != 'undefined')
            {
                e.target.disabled = active;
            }
            if(active == false && !isError){
                window.setTimeout(() => {
                    this.itemFormRef.active(false);
                }, 600);
            }
        },
        focusFirstInput(){
            window.setTimeout(() => {
                const el = document.querySelector('form input, form select');
                if(el && el.type != "radio" && el.type != "checkbox" && !el.classList.contains('dp__input') && !el.value){
                    el.focus();
                }
            }, 600);
        },
        expandAll(){
            this.expandedRows = this.items.data.filter(p => p.id);
        },
        collapseAll(){
            this.expandedRows = null;
        },
        unselect(e){
            e.target.parentElement.parentElement.classList.remove('selected');
        },
        openOnClickOverlay(e, data){
            if(e.target.tagName == 'BUTTON' || e.target.tagName == 'A'){
                return;
            }
            let el = e.target;
            while(el && el.tagName != 'TR'){
                el = el.parentElement;
            }
            if(this.showAction){
                el = el.lastElementChild.firstElementChild;
                if(el.classList.contains('edit-button')) el.click();
            }
            else{
                el = el.lastElementChild.firstElementChild;
                if(el) {
                    el.click();
                    console.log('here', el);
                }
                else if(this.$parent.onOverviewSelect){
                    this.$parent.onOverviewSelect(data);
                    this.selectedRow = data;
                }
            }
        },
        get(column){
            return this.items[column];
        }
    },
    created() {
        this.initFilters();
    },
    mounted() {
        if(window.documentationSearch && ['Manifest Status', 'Status & Print'].includes(this.$route.name)){
            this.filters['global'].value = window.documentationSearch;
        }
        this.lazyParams = {
          first: 0,
          rows: this.$refs.dt.rows,
          sortField: this.sort,
          sortOrder: this.sortOrder,
          filters: this.filters,
          route: this.$route.name,
          selectFields: this.selectFields
        }

        if(this.indexOnMount){
            this.getIndex(true);
        }
        
    }
}
</script>

<style scoped>

.ms-n5 {
    margin-left: -40px;
}

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

a.fa-stack {
    cursor: pointer;
}
.dropdown-menu {
    inset: 0px 25px auto auto !important;
}

.dropdown-item {
    cursor: pointer;
}

</style>

<style>
.overlay {
    padding: 0;
    position: absolute;
    right: 0;
    width: calc(100% - 6rem);
    height: 2.5rem;
    opacity: 0.01;
    margin-right: 6rem
}
.overlay:hover{
    background-color: #000;
    cursor: pointer
}
.overlay-nodropdown{
    margin-right: 0rem !important;
    width: 100% !important
}
.overlay-selected{
    background-color: #0095ff !important;
    opacity: 0.1 !important
}

.pi-sort-alt:before {
    content: "";
}
.p-link:hover{
    transition: background-color 0.1s
}
.p-link.p-highlight{
    background-color: #1565c0 !important;
    border-color: #1565c0 !important
}
.p-link:not(.p-highlight):not(.p-disabled){
    color: #1565c0 !important
}
.p-row-toggler{
    padding: 4px;
    border: none !important
}
.p-paginator > button:first-child{
  border-radius: 5px 0 0 5px;
}
.p-paginator > button:last-child{
  border-radius: 0 5px 5px 0;
}
button .p-row-toggler-icon{
    transition: transform 0.15s ease-in-out;
}
button .p-row-toggler-icon::after{
    font-family: "Font Awesome 6 Pro";
    content: "\f054" !important;
}
button span.pi-chevron-down{
    transform: rotate(90deg);
}
.p-row-toggler-icon{
    user-select: none;
}
.p-datatable-row-expansion, .collapsing-body{
    animation: fadeIn 0.15s;
    height: 40px
}
.p-add-pad > *:not(.p-set-pad){
    padding-left: .5rem;
    padding-right: .5rem
}
.p-set-pad{
    padding-left: 31px
}
.btn-group-inner-table{
    padding: 0 0 0 25px !important
}

.btn-sec-overview{
    padding: .25rem .5rem !important;
    height: 28px
}

.datatable-inner{
    table-layout: fixed !important;
    width: 100%;
    margin: 0px !important;
    padding: 0px !important;
    line-height: 2.2rem;
}
.datatable-inner > thead:not(.solo-head) > th{
    padding-top: 13px !important;
    padding-bottom: 13px !important;
}
.datatable-inner > thead:not(.solo-head) > th, .datatable-inner > tbody > tr > td{
    border-bottom: unset !important;
    border-top: 1px solid #DBE1E5 !important;
    color: #78909C;
}
.datatable-inner > tbody > tr:not(.p-add-pad) > td{
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.datatable-inner > tbody > tr.p-add-pad > td{
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}
.solo-head{
    visibility: hidden;
}
.job-expander{
    width: 30px; 
    position: relative; 
    z-index: 100; 
    overflow: unset !important; 
    height: 55px
}

.p-sortable-column-icon{
    font-family: "Font Awesome 6 Pro";
    margin-left: 8px
}
.pi-sort-alt::before {
    content: "\f883";
}
.pi-sort-amount-up-alt::before {
    content: "\f885";
}
.pi-sort-amount-down::before {
    content: "\f884";
}

.text-bubble-ov{
    position: fixed;
    text-align: center;
    z-index: 1111;
    background-color: #333;
    color: white;
    border-radius: 4px;
    padding: 4px;
    max-width: 300px;
    font-family: unset;
    white-space: pre-line;
}
.error-badge:not(:hover) > div > .text-bubble-ov{
    display: none !important;
}
i.hoverable:hover{
    cursor: help !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>