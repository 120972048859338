<template>
    <template v-if="relation_item.relation_general_cost">
        <div v-for="cost, costIndex in relation_item.relation_general_cost" :key="cost.id">
            <h5 class="mt-2">{{cost.description}}</h5>
            <FormItem :id="'purchase_'+parentIndex+'_'+index+'_'+stIndex" type="currency-and-unit" v-model="relation_item.relation_general_cost[costIndex]" 
                      :options="currencyAndUnitOptions" :required="true" inputGroupType="purchase_rate" :label="$t('sales.purchase_rates')" />
            <FormItem :id="'sales_'+parentIndex+'_'+index+'_'+stIndex" type="currency-and-unit" v-model="relation_item.relation_general_cost[costIndex]" 
                      :options="currencyAndUnitOptions" :required="true" inputGroupType="sales_rate" :label="$t('sales.sales_rates')" />
        </div>
    </template>
    <template v-if="relation_item.relation_general_cost_commodities">
        <div v-for="cost in relation_item.relation_general_cost_commodities" :key="cost.id">
            <h5 class="mt-2">{{cost.description}}</h5>
            <div class="row mt-1">
                <div class="col-sm-2"></div>
                <div class="col-sm-5 col-form-label">{{$t('sales.purchase_rates')}}</div>
                <div class="col-sm-5 col-form-label">{{$t('sales.sales_rates')}}</div>
            </div>
            <div class="row mt-1 auto-layout" v-for="comm, commIndex in cost.commodities" :key="comm.id">
                <label for="commodity_id" class="col-sm-2 col-form-label">{{comm.commodity.name}}</label>
                <FormItem :id="'purchase_'+parentIndex+'_'+index+'_'+stIndex" type="currency-and-unit" v-model="cost.commodities[commIndex]" 
                          :options="currencyAndUnitOptions" :required="true" inputGroupType="purchase_rate" />
                <FormItem :id="'sales_'+parentIndex+'_'+index+'_'+stIndex" type="currency-and-unit" v-model="cost.commodities[commIndex]" 
                          :options="currencyAndUnitOptions" :required="true" inputGroupType="sales_rate" />
            </div>
        </div>
    </template>
</template>

<script>
    export default {
        name: 'RelationFormSubcomponent',
        props: ['relationItem', 'currencies', 'calculation_units', 'type'],
        watch: {
            
        },
        computed: {
            relation_item: {
                get() {
                    return this.relationItem
                }
            }
        },
        data() {
            return {
                currencyAndUnitOptions: { 'currencies': this.currencies, 'calculation_units': this.calculation_units},
                loading: false,
                invalid: {}
            }
        }
    }
</script>

<style scoped>
    .auto-layout div{
        flex: 0 0 auto;
        width: 41.5%
    }
</style>