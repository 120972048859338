import {ApiService} from '@/services/ApiService'

const URL = '/api/vessel'

class VesselService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    livesearch(query, field) {
        return super.livesearch(query, field, ['id', 'code'])
    }
}

export default new VesselService()