<template>
    <li id="contactHistoryElement" class="nav-link" role="presentation" v-if="parentItemName != 'job'">
        <button :class="getButtonClasses" id="chistory-tab" data-bs-toggle="tab" data-bs-target="#chistory" type="button" role="tab" aria-controls="chistory" aria-selected="true" @click="hideFooterButtons">
            {{$t('sales.contact_history')}}&ensp;<span v-show="comments" class="badge">{{history.filter(message => message.type != 'history').length}}</span>
        </button>
    </li>
    <div :class="{'tab-pane fade' : parentItemName != 'job'}" id="chistory" role="tabpanel" aria-labelledby="chistory-tab">
        <div>
            <div :class="comments ? 'col-7 border-right ps-3' : 'col-12'">
                <template v-if="comments">
                    <div class="d-flex" v-if="comments || history.length > 0">
                        <h3 class="mt-4 mb-3 fw-bold">{{$t('navigation.activity')}}</h3>
                        <div class="d-flex pt-4 pb-3 ps-4">
                            <input id="activity-display-1" type="radio" class="d-none" value="all" v-model="activityDisplay">
                            <label :for="'activity-display-1'" :class="'btn me-2 ' + (activityDisplay == 'all' ? 'btn-primary' : 'btn-outline-primary')">{{$t('form.all')}}</label>
                            <input id="activity-display-2" type="radio" class="d-none" value="comment" v-model="activityDisplay">
                            <label :for="'activity-display-2'" :class="'btn me-2 ' + (activityDisplay == 'comment' ? 'btn-primary' : 'btn-outline-primary')">{{$tc('form.remark', 2)}}</label>
                            <input id="activity-display-3" type="radio" class="d-none" value="history" v-model="activityDisplay">
                            <label :for="'activity-display-3'" :class="'btn me-2 ' + (activityDisplay == 'history' ? 'btn-primary' : 'btn-outline-primary')">{{$t('sales.contact_history')}}</label>
                        </div>
                    </div>
                    <h5 class="mt-4 mb-3 fw-bold" v-else>{{$t('form.no_yet', [$t('sales.contact_history')])}}</h5>
                    <div class="overflow-y-auto pe-2">
                        <article v-for="(message, index) in history" :key="index" v-show="activityDisplay == 'all' || activityDisplay == message.type">
                            <div class="d-flex">
                                <div class="badge badge-user" :style="{'background-color': message.color}">{{message.initials}}</div>
                                <div class="text-muted ps-3">{{message.user}} - {{message.time}}</div>
                            </div>
                            <div class="user-comment">
                                {{message.message}}
                            </div>
                        </article>
                    </div>
                </template>
                <template v-else>
                    <h3 class="mt-4 mb-3 fw-bold" v-if="comments || history.length > 0">{{$t('sales.contact_history')}}</h3>
                    <h5 class="mt-4 mb-3 fw-bold" v-else>{{$t('form.no_yet', [$t('sales.contact_history')])}}</h5>
                    <article v-for="(message, index) in history" :key="index">
                        <div class="d-flex">
                            <div class="col-3">
                                <div>{{message.time}}</div>
                            </div>
                            <div class="col-9" v-if="message.type == 'history' || !comments">
                                <p>{{message.message}}</p>
                            </div>
                            <div class="col-9 text-danger" v-else>
                                <p>{{$t('form.remark')}} - {{message.user}}: {{message.message}}</p>
                            </div>
                        </div>
                    </article>
                </template>
            </div>

            <div v-if="comments"  class="col-5 px-4">
                <div class="position-fixed">
                    <h5 class="mt-4 mb-3">{{$t('form.add', [$t('form.remark')])}}</h5>
                    <div class="mb-2">{{$t('form.remark')}}</div>
                    <textarea id="contact-history-textarea" v-model="newMessage"></textarea>
                    <div class="row justify-content-end px-2">
                        <button type="button" class="btn btn-success w-auto me-1" @click="sendMessage">{{$t('form.save')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store/user'
    import quotationService from "@/services/QuotationService";
    import jobService from '@/services/JobService';
    export default {
        name: 'ContactHistory',
        props: ['id', 'comments', 'tabClass', 'forQuotation', 'parentItemName'],
        computed: {
            getButtonClasses() {
                return "nav-link ms-auto pt-0 colour-black";
            }
        },
        data() {
            return {
                history: [],
                newMessage: '',
                activityDisplay: 'all'
            }
        },
        methods: {
            sendMessage(e){
                if(!this.newMessage) return;
                const name = store.getters.getName;
                const inidata = this.setColorAndInitials(name);
                this.history.splice(0, 0,{
                    'user': name,
                    'message': this.newMessage,
                    'date': Date(),
                    'time': this.formatDate(Date(), 'dateTimeHistory'),
                    'type': 'comment',
                    'color': inidata.color,
                    'initials': inidata.initials
                });
                if(this.forQuotation)
                    quotationService.addMessage(this.newMessage, this.id).catch(error => console.log('error', error))
                else
                    jobService.addMessage(this.newMessage, this.id).catch(error => console.log('error', error))

                this.newMessage = "";
            },
            addMessage(message){
                const inidata = this.setColorAndInitials(message.user_id ? message.user.name : 'X');
                this.history.splice(0, 0, {
                    'user': (message.user_id ? message.user.name : "Customer"),
                    'message': message.content.replaceAll("\\n", "\n"),
                    'date': message.created_at,
                    'type': message.comment ? 'comment' : 'history',
                    'time': this.formatDate(Date(), 'dateTimeHistory'),
                    'color': inidata.color,
                    'initials': inidata.initials
                });
            },
            getMessages(feed){
                if(this.history.length == 0){
                    const feedCount = feed.length - 1;
                    for(let i = feedCount; i >= 0; i--){
                        let message = feed[i];
                        const inidata = this.setColorAndInitials(message.user_id ? message.user.name : 'X');
                        this.history.push({
                            'user': (message.user_id ? message.user.name : "Customer"),
                            'message': message.content.replaceAll("\\n", "\n"),
                            'date': message.created_at,
                            'time': this.formatDate(message.created_at, 'dateTimeHistory'),
                            'type': message.is_log ? 'history' : 'comment',
                            'color': inidata.color,
                            'initials': inidata.initials
                        });
                    }
                }
            },
            empty(){
                this.history = [];
                this.activityDisplay = 'all';
            },
            hideFooterButtons(){
                document.getElementById('nextbutton').style.display = 'none';
                if(!this.forQuotation) document.getElementById('savebutton').style.display = 'none';
            },
            setColorAndInitials(name){
                const initialsarr = name.split(' ').map(n => n[0]);
                const initials = initialsarr.at(0) + initialsarr.at(-1);
                const color = name.toHSL();
                return { initials: initials, color: color };
            }
        },
        mounted() {
            if(this.parentItemName != 'job'){
                this.$nextTick(() => {
                    //Set contact history tab
                    let fragment = new DocumentFragment();
                    fragment.append(document.getElementById('contactHistoryElement'));
                    document.querySelector(this.tabClass ? this.tabClass : 'ul.nav').appendChild(fragment);
                });
            }
        }
    }

</script>

<style scoped>
    .dropzone{
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 999999;
        background-color: rgba(0,0,0,0.5);
        transition: visibility 180ms, opacity 180ms;
    }
    .dropzone-hidden{
        visibility: hidden;
        opacity: 0
    }
    .dropzone-text {
        position: fixed;
        width: 100%;
        top: calc(50vh - 50px);
        color: #fff;
        text-align: center
    }
    .badge {
        background-color: #aaa;
        color: black;
        border-radius: 10em
    }
    .badge-user {
        padding: .9rem 0;
        color: white !important;
        border-radius: 100rem;
        font-size: 1rem;
        font-weight: 500;
        width: 41px
    }
    .border-right{
        border-right: 2px solid
    }
    #chistory > div {
        min-height: 500px;
        display: flex;
    }
    article p {
        white-space: pre-line
    }
    textarea {
        min-height: 160px;
        width: 450px
    }
    .chat-profile-image {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center
    }
    .user-comment{
        position: relative;
        left: 56px;
        width: 700px;
        bottom: 20px;
    }
    .overflow-y-auto{
        max-height: calc(100vh - 278px);
        overflow-y: auto
    }
</style>