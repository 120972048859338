<template>
    <div>
        <h3 class="mt-2">{{$tc('navigation.ports', 2)}}</h3>
        <p v-show="requiredPorts.length"> 
            <span class="text-capitalize">{{$t('form.required')}}:</span>
            <template v-for="(port, pindex) in requiredPorts" :key="port.id">
                <span v-show="pindex != 0">,&nbsp;</span> <span class="fake-link" @click="addOption(0, port)"> {{port.name}} </span>
            </template>
        </p>
        <p v-show="suggestedPorts.length"> 
            <span class="text-capitalize">{{$t('form.suggestions')}}:</span>
            <template v-for="(port, pindex) in suggestedPorts" :key="port.id"> 
                <span v-show="pindex != 0">,&nbsp;</span> <span class="fake-link"  @click="addOption(0, port)"> {{port.name}} </span>
            </template>
        </p>
        <div class="row">
            <div class="col-sm-4 required-label">
                {{$tc('navigation.ports', 1)}}
            </div>
            <div class="col-sm-3" style="width: 23%">
                {{$tc('navigation.terminals', 1)}}
            </div>
            <div class="col-sm-1p5 required-label">
                ETA
            </div>
            <div class="col-sm-1p5">
                ETD
            </div>
            <div class="col-sm-1">
                {{$t('operations.date_loaded')}}
            </div>
            <div class="col-sm-auto ms-4">
                {{$t('voyage_status.2')}}
            </div>
        </div>
        <template v-for="(voyageport,index) in thisvoyageport" :key="voyageport.cId">
            <div class="row mb-1">
                <div class="col-sm-4">
                    <div class="row">
                        <div class="col-sm-8 port-search">
                            <FormItem :id="'port-'+(voyageport.cId)" type="livesearch" v-model="voyageport.pivot.port_id" :options="ports" :content="voyageport.name" @onchange="(id, n, item) => {getPortReps(item); getViaPorts(id, voyageport)}" />
                        </div>
                        <div class="col-sm-1 ps-0">
                            <a class="btn btn-sm btn-danger float-right" title="Click to delete row" @click="deleteOption(index, voyageport.id)">
                                <i class="far fa-trash-alt"></i>
                            </a>
                        </div>
                        <div class="col-sm-3">
                            <a class="btn btn-sm btn-primary float-right" title="Add via port" @click="addViaPort(voyageport)"><i class="far fa-arrow-turn-down-right"></i>&ensp;{{$t('operations.via')}}</a>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 terminal-select" style="width: 23%">
                    <template v-if="voyageport.pivot.port_id && filteredRelations(voyageport.pivot.port_id).length > 0">
                        <FormItem :id="'terminal-'+index" v-model="voyageport.pivot.terminal_id" type="select" :options="filteredRelations(voyageport.pivot.port_id)" />
                    </template>
                </div>
                <div class="col-sm-1p5 date-validation">
                    <FormItem :id="'eta-'+index" v-model="voyageport.pivot.date_arrival" type="datepicker" />
                </div>
                <div class="col-sm-1p5">
                    <FormItem :id="'etd-'+index" v-model="voyageport.pivot.date_departure" type="datepicker" />
                </div>
                <div class="col-sm-1p5">
                    <div class="form-control form-control-sm bg-light"> {{ voyageport.pivot.date_loaded }} </div>
                </div>
                <div class="col-sm-auto">
                    <FormItem :id="'checkbox-'+index" v-model="voyageport.pivot.closed" type="checkbox" />
                </div>
            </div>
            <div class="row mb-1" v-for="(viaport,viaIndex) in voyageport.port_voyage_via_port" :key="viaport.temp_id">
                <i class="text-primary far fa-arrow-turn-down-right column-via-icon pt-2"></i>
                <div class="column-via-port" v-if="!viaport.is_new">{{viaport.name}}</div>
                <div class="column-via-port" v-else>
                    <FormItem :id="'viaport-'+index+'-'+viaIndex" type="livesearch" v-model="viaport.id" :options="filteredPorts(voyageport.port_voyage_via_port)" 
                                :content="viaport.name" @onchange="(id, name) => viaport.name = name" />
                </div>
                <div class="col-sm-1" >
                    <button v-if="viaport.is_new" class="btn text-danger p-0" type="button" @click="deleteOnEmpty(voyageport, viaIndex)"><i class="fa fa-trash-alt"></i></button>
                    <FormItem v-else :id="'checkbox-'+index" v-model="viaport.active" type="checkbox" />
                </div>
            </div>
        </template>
        <div class="btn btn-primary float-right mt-2" title="Click to add row" @click="addOption">
            <span class="fa fa-plus-square"></span> &nbsp;{{$t('form.add', [$t('navigation.ports')])}}
        </div>
        <div class="clearfix"></div>
    </div>


</template>

<script>
    import Relationservice from '@/services/RelationService'

    export default {
        props: ['initialVoyageport', 'ports'],
        name: 'VoyagePortFormComponent',
        computed: {
            thisvoyageport: {
                get() {
                    return this.initialVoyageport
                }
            }
        },
        watch: {
            initialVoyageport: {
                deep: true,
                handler(initialVoyageport) {
                    this.$emit('voyageportchange', initialVoyageport)
                }
            }
        },
        data() {
            return {
                relations: [],
                requiredPorts: [],
                suggestedPorts: []
            }
           
        },
        methods: {
            getRelations() {
                Relationservice.index(null,null,'terminal').then(response => {
                    this.relations = response.data
                }).catch(error => {
                    console.log('error', error)
                })
            },
            addOption(e, port = null) {
                const item = {
                    id: null,
                    'pivot': {
                        'id': null,
                        'port_id': port ? port.id : null,
                        'terminal_id': null,
                        'date_arrival': null,
                        'date_departure': null,
                        'date_loaded': null,
                        'closed': false
                    },
                    "cId": Math.random().toString(36).substring(2, 9),
                    'port_voyage_via_port': [],
                    "name": port ? port.name : ""
                };
                if(port && this.thisvoyageport.length == 1 && this.thisvoyageport[0].pivot.port_id == null){
                    this.thisvoyageport[0] = item;
                }
                else{
                    this.thisvoyageport.push(item);
                }
            },
            addViaPort(port){
                if(!port.port_voyage_via_port){
                    port.port_voyage_via_port = [];
                }
                port.port_voyage_via_port.push({
                    'id': null,
                    'is_new': true,
                    'name': null,
                    'active': 1,
                    'temp_id': Math.random(),
                    'new_recurring': true
                });
            },
            deleteOnEmpty(voyageport, viaIndex){
                voyageport.port_voyage_via_port.splice(viaIndex, 1);
            },
            getPortReps(item){
                this.$parent.getPortReps(item);
            },
            getViaPorts(id, voyageport){
                const ports = this.$parent.getViaPortsForId(id);
                voyageport.port_voyage_via_port = [];
                ports.forEach(port => {
                    voyageport.port_voyage_via_port.push({
                        'id': port.pivot.port_id,
                        'is_new': false,
                        'name': port.name,
                        'active': 1,
                        'new_recurring': false
                    });
                });
            },
            deleteOption(index, id) {
                this.$parent.removePortReps(id);
                this.thisvoyageport.splice(index, 1);
            },
            filteredRelations(portId) {
                return this.relations.filter(term => {
                    return term.port_id == portId
                })
            },
            filteredPorts(viaPorts) {
                return this.ports.filter(port => {
                    return viaPorts.find(viaport => port.id === (viaport.to_port ? viaport.to_port.id : viaport.id)) === undefined
                })
            }
        },
        mounted() {
            this.getRelations()
        }
    }
</script>
<style scoped>
.form-check-input {
    position: relative !important;
    top: 5px;
    left: 16px
}
.column-via-icon{
    flex: 0 0 auto;
    width: 3%
}
.column-via-port{
    flex: 0 0 auto;
    width: 19.25%;
    font-size: .875rem
}
.column-via-port + div{
    padding: 2px 0 0 6px !important
}
.col-sm-1p5{
    width: 13%;
    flex: 0 0 auto
}
</style>